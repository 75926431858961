import React from 'react';
import useFormHelperElements from 'hooks/useFormHelperElements';
import { ModalProps } from 'components/Modal/Modal.component';
import Modal from 'components/Modal';

import './ConfirmModal.styles.scss';

export type ConfirmModalProps = {
  description?: string;
  okText?: string;
  cancelText?: string;
  onConfirm: () => void;
} & ModalProps;

const ConfirmModal: React.FC<Partial<ConfirmModalProps>> = ({
  children,
  description,
  title,
  okText = 'OK',
  cancelText = 'Cancel',
  onConfirm,
  onClose,
  ...modalProps
}) => {
  const { Cancel, Submit } = useFormHelperElements();

  return (
    <Modal
      open
      renderAsPortal={false}
      className="bb-confirm-popup"
      onClose={onClose}
      {...modalProps}
      title={<p>{title}</p>}
    >
      <div>
        <p>{description}</p>
        {children}
        <div className="bb-confirm-popup__form-actions">
          <Cancel className="cancel-btn" onClick={onClose}>
            {cancelText}
          </Cancel>
          <Submit className="submit-btn" onClick={onConfirm}>
            {okText}
          </Submit>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
