import { FixtureType } from 'models/Fixture';
import { SportType } from 'models/Sport';
import { TeamData } from 'models/Team';
import { createContext } from 'react';
import utils from 'utils';
import { TeamFilterData } from './Teams.provider';

export type TeamsContextProps = {
  loadingAddToFavorites: boolean;
  loadingUpcomingFixtures: boolean;
  loadingTeams: boolean;
  teamSearchString: string;
  currentSelectedSport: SportType | 'all';
  currenSelectedTeam: TeamData;
  teams: Array<TeamData>;
  upcomingFixtures: Array<FixtureType>;
  searchTeams: Array<TeamData>;
  filterTeamsData: TeamFilterData;
  setLoadingAddToFavorites: (value: boolean) => void;
  setTeams: (values: Array<TeamData>) => void;
  setUpcomingFixtures: (value: Array<FixtureType>) => void;
  setSearchTeams: (values: Array<TeamData>) => void;
  setTeamSearchString: (value: string) => void;
  setCurrentSelectedTeam: (value: TeamData) => void;
  setCurrentSelectedSport: (value: SportType | 'all') => void;
  setFilterTeamsData: (value: TeamFilterData) => void;
  addTeamToFavorites: () => void;
  removeFromFavorites: () => void;
  onTeamScroll: () => void;
  onUpcomingFixturesScroll: () => void;
};

export default createContext<Partial<TeamsContextProps>>({
  loadingTeams: false,
  loadingAddToFavorites: false,
  loadingUpcomingFixtures: false,
  teamSearchString: '',
  searchTeams: [],
  teams: [],
  upcomingFixtures: [],
  filterTeamsData: null,
  currenSelectedTeam: null,
  currentSelectedSport: 'BASEBALL',
  setTeams: utils.noop,
  setUpcomingFixtures: utils.noop,
  setLoadingAddToFavorites: utils.noop,
  setTeamSearchString: utils.noop,
  setSearchTeams: utils.noop,
  setCurrentSelectedTeam: utils.noop,
  setCurrentSelectedSport: utils.noop,
  setFilterTeamsData: utils.noop,
  addTeamToFavorites: utils.noop,
  removeFromFavorites: utils.noop,
  onTeamScroll: utils.noop,
  onUpcomingFixturesScroll: utils.noop,
});
