import React from 'react';

type Props = { className?: string };
export default ({ className }: Props) => (
  <svg
    className={className}
    width="64"
    height="64"
    viewBox="0 0 64 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="64" height="64" rx="14" fill="#F0A501" />
    <path
      d="M34.204 16L33.3276 19.9199C33.0238 21.2839 33.9166 22.4005 35.3159 22.4005H45.681L43.5397 32H30.6256L29.7493 35.9199C29.683 36.2178 29.6844 36.5267 29.7535 36.8239C29.8225 37.1212 29.9575 37.3991 30.1483 37.6372C30.3392 37.8753 30.581 38.0675 30.8561 38.1996C31.1312 38.3317 31.4324 38.4004 31.7375 38.4005H42.1027L39.9566 48H26.4961C25.3235 48.0009 24.1657 47.7383 23.1083 47.2315C22.051 46.7246 21.121 45.9866 20.3873 45.072C19.6536 44.1573 19.1349 43.0894 18.8695 41.9473C18.6041 40.8051 18.5989 39.6179 18.8542 38.4735L20.4231 31.4676H26.979C23.4643 30.1649 21.5067 26.6172 22.4325 22.4735L23.8812 16H34.204Z"
      fill="#1D1D1B"
    />
  </svg>
);
