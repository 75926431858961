import httpClient from 'api/httpClient';
import {
  BetData,
  BettingTipStatusType,
  FilterBetTypes,
  ShowBetTypesEnhanced,
  ShowFeedBetTypes,
  SortBetTypes,
  OutcomeFilterBetsTypes,
  TicketData,
  UserBetData,
  FilterFeedBetTypes,
} from 'models/Bet';
import PaginatedData from 'models/PaginatedData';
import { SportType } from 'models/Sport';

const betPath = (routePath: TemplateStringsArray) => `bets/${routePath}`;

function getTickets(params: {
  page: number;
  limit?: number;
  sport?: SportType;
}) {
  return httpClient.get<PaginatedData<TicketData>>(`tickets`, {
    params,
  });
}

function getBetsForTicket(
  params: { page: number; limit?: number },
  ticketId: string,
) {
  return httpClient.get<PaginatedData<BetData>>(`tickets/${ticketId}/bets`, {
    params,
  });
}

function getBetStats(params: { ticketId: string }) {
  return httpClient.get(betPath`tickets/stats`, { params });
}

function getBetDetails(params: {
  userId: string;
  ticketId: string;
  fixtureId: string;
}) {
  return httpClient.get(betPath`details`, { params });
}

function getUserBets(params: {
  page: number;
  limit?: number;
  show?: ShowBetTypesEnhanced;
  filter?: FilterBetTypes;
  sort?: SortBetTypes;
  sport?: SportType;
}) {
  return httpClient.get<{
    count: number;
    currentPage: number;
    rows: Array<UserBetData>;
    totalPages: number;
  }>(betPath`myBets`, { params });
}

function getFeedBets(params: {
  page: number;
  limit?: number;
  filter?: ShowFeedBetTypes;
  betsFilterType?: OutcomeFilterBetsTypes;
  show?: FilterFeedBetTypes;
  sport?: SportType;
}) {
  return httpClient.get<{
    count: number;
    currentPage: number;
    rows: Array<BetData>;
    totalPages: number;
  }>(betPath`initial-betting-feed`, { params });
}

function getBuddyBets(params: {
  sport: SportType;
  limit: number;
  page: number;
}) {
  return httpClient.get(betPath`buddyBets`, { params });
}

function getBetById(betId: string) {
  return httpClient.get<{
    bet: BetData;
    bettingTipStatus: BettingTipStatusType;
  }>(`/bets/${betId}`);
}

function getUserBetsById(
  params: { page: number; limit?: number },
  userId: string,
) {
  return httpClient.get<PaginatedData<UserBetData>>(
    `bets/betsForUser/${userId}`,
    { params },
  );
}

function createBet<BetDataType>(betData: BetDataType) {
  return httpClient.post<{ bettingTip: UserBetData }>(betPath``, betData);
}

function acceptBet(params: { betId: string; betPayment: number }) {
  return httpClient.post<{ bettingTip: UserBetData }>(betPath`accept`, null, {
    params,
  });
}

function cancelBet(betId: string) {
  return httpClient.post<BetData>(`bets/cancel/${betId}`);
}

function editBet<BetDataType>(betData: BetDataType, betId: string) {
  return httpClient.post<BetData>(`bets/edit/${betId}`, betData);
}

export default {
  acceptBet,
  createBet,
  editBet,
  cancelBet,
  getBetStats,
  getBetDetails,
  getBuddyBets,
  getUserBets,
  getFeedBets,
  getUserBetsById,
  getTickets,
  getBetsForTicket,
  getBetById,
};
