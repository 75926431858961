import React, { useCallback, useMemo, useRef, useState } from 'react';
import Camera from 'assets/svgs/Camera';
import Search from 'assets/svgs/Search';
import InputField from 'components/Fields/InputField';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { Button, Input, Loader } from 'ncoded-component-library';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { required } from 'services/validators';
import Checkbox from 'assets/svgs/Checkbox';
import { FriendshipType } from 'models/Friends';
import { ChatSessionClientData, GroupChatData } from 'models/Chats';
import useFormHelperElements from 'hooks/useFormHelperElements';
import useInfinitePagination from 'hooks/useInfinitePagination';
import InfiniteScroll from 'components/InfiniteScroll';
import credentialsService from 'services/credentialsService';
import Animation from 'components/Animation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import chatService from '../../chatService';
import { OverlayRef } from 'components/Overlay';
import { AxiosError } from 'axios';
import Image from 'components/Image';
import UserImage from 'components/UserImage';
import classNames from 'classnames';
import utils from 'utils';
import api from 'api';

import './GroupChatModal.styles.scss';

type GroupChatFieldData = {
  groupName: string;
  friends: Array<FriendshipType>;
};

type GroupChatModalProps = ModalProps & {
  onGroupChatCreate?: (value: ChatSessionClientData) => void;
};

const GroupChatModal: React.FC<GroupChatModalProps> = (props) => {
  const { open, onGroupChatCreate, ...modalRest } = props;

  const [groupImage, setGroupImage] = useState<File>();
  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const modalRef = useRef<OverlayRef>(null);

  const {
    loading,
    items: friends,
    setSearchString,
    searchString,
    onContainerScrolled: onFriendsScroll,
  } = useInfinitePagination<FriendshipType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.friends
        .getUserFriends({
          searchString,
          page: currentPage,
          limit: 10,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: chatService.mapFriendsData(data.rows),
          };
        });
    },
  });

  const user = useMemo(() => credentialsService.user, []);
  const { setErrorMsg, Submit, ErrorMessage } = useFormHelperElements();

  const imageUploadHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files && event.target.files.length !== 0) {
        setGroupImage(event.target.files[0]);
      }
    },
    [],
  );

  const handleOnCreate = useCallback(
    async (value: GroupChatFieldData) => {
      try {
        setLoadingCreate(true);

        const groupData: GroupChatData = {
          name: value.groupName,
          userIds: value.friends.map((friend) =>
            friend.userId1 === user.Id ? friend.userId2 : friend.userId1,
          ),
        };

        const { data } = await api.chat.createGroupChatSession(groupData);
        await api.chat.uploadGroupImage(groupImage, data.group.Id);
        setGroupImage(null);

        const newGroupData = chatService.mapChatSession(data.session);
        onGroupChatCreate({ ...newGroupData, group: data.group });
        modalRest.onClose();
      } catch (e) {
        setErrorMsg(utils.getStringError(e as AxiosError));
      } finally {
        setLoadingCreate(false);
      }
    },
    [modalRest, groupImage, user, onGroupChatCreate, setErrorMsg],
  );

  const friendsValidation = useCallback((values: Array<FriendshipType>) => {
    return values?.length > 1
      ? undefined
      : 'Please select at least two friends to create Chat Group!';
  }, []);

  return (
    <Animation
      open={open}
      duration={800}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        className="bb-group-chat-modal"
        {...modalRest}
        open
        ref={modalRef}
        onX={modalRest.onClose}
        title={<p>new group chat</p>}
      >
        {(loading || loadingCreate) && <Loader />}
        <Form
          onSubmit={handleOnCreate}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <InfiniteScroll
                onScroll={onFriendsScroll}
                className="bb-group-chat-modal__container"
              >
                <div className="bb-group-chat-modal__header">
                  <div>
                    {groupImage && (
                      <Image src={URL.createObjectURL(groupImage)} alt="" />
                    )}
                    {!groupImage && (
                      <Button icon={Camera}>
                        <label>
                          <input
                            type="file"
                            accept="image/*"
                            hidden
                            onChange={imageUploadHandler}
                          />
                        </label>
                      </Button>
                    )}
                  </div>
                  <div>
                    <p>group name</p>
                    <Field
                      className="bb-gorup-chat-modal__header__input"
                      name="groupName"
                      defaultValue=""
                      component={InputField}
                      placeholder="Enter Group name"
                      validate={required('Please enter group before submit!')}
                    />
                  </div>
                </div>
                <hr />
                <Input
                  prefixNode={<Search />}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search..."
                />
                <FieldArray<FriendshipType>
                  name="friends"
                  validate={friendsValidation}
                >
                  {({ fields: { remove, push, value } }) => {
                    return friends.map(
                      (friend: FriendshipType, index: number) => {
                        return (
                          <div
                            className={classNames(
                              'bb-group-chat-modal__friend',
                              {
                                'bb-group-chat-modal__friend--active':
                                  value?.includes(friend),
                              },
                            )}
                            key={index}
                            onClick={() =>
                              value?.includes(friend)
                                ? remove(value.indexOf(friend))
                                : push(friend)
                            }
                          >
                            <div>
                              <UserImage
                                userId={friend.sender.Id}
                                src={friend.sender.profileImageUrl}
                              />
                              <p>{`${friend.sender.username}`}</p>
                            </div>
                            <div>
                              <Button type="button" icon={Checkbox} />
                            </div>
                          </div>
                        );
                      },
                    );
                  }}
                </FieldArray>
              </InfiniteScroll>
              <div className="bb-group-chat-modal__footer">
                <ErrorMessage />
                <Submit type="submit">Create Group Chat</Submit>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default GroupChatModal;
