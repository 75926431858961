import MultiUserIcon from 'assets/svgs/MultiUser.icon';
import SingleUserIcon from 'assets/svgs/SingleUser.icon';
import classNames from 'classnames';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import SettingsCardField from 'components/Fields/SettingsCardField';
import FormWrapper from 'components/FormWrapper';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import useFormHelperElements from 'hooks/useFormHelperElements';
import { BetType } from 'models/Bet';
import React, { useContext, useEffect, useMemo } from 'react';
import { Field, useField, useFormState } from 'react-final-form';
import { required } from 'services/validators';
import { InitialFixtureData } from '../../providers/NewBet/NewBet.context';

import './BetTypeForm.styles.scss';

type BetTypeFormProps = {
  className?: string;
};

const BetTypeForm: React.FC<BetTypeFormProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-bet-type', className);

  const { nextStep } = useContext(StepsContext);

  const { SubmitBet, ErrorMessage, setErrorMsg, SaveBetEdit } =
    useFormHelperElements();
  const { touched, dirty, errors } = useFormState<InitialFixtureData>();

  const hasError = useMemo(
    () => touched.betType && dirty && errors,
    [dirty, errors, touched],
  );

  useEffect(() => {
    if (hasError) setErrorMsg(errors.betType);
  }, [errors, hasError, setErrorMsg]);

  const {
    input: { value: betType },
  } = useField<BetType>('betType');

  return (
    <FormWrapper onSubmit={nextStep} className={classes} id="betModalForm">
      <div className="bb-bet-type__container">
        <StandardAppearAnimation delay={100}>
          <Field
            name="betType"
            inputValue="Single"
            component={SettingsCardField}
            icon={SingleUserIcon}
            title="One to One"
            active={betType === 'Single'}
            content="only one user can answer this bet"
            validate={required('You need to select bet type!')}
          />
        </StandardAppearAnimation>
        <StandardAppearAnimation delay={100}>
          <Field
            name="betType"
            inputValue="Multi"
            component={SettingsCardField}
            icon={MultiUserIcon}
            title="Party"
            active={betType === 'Multi'}
            content="multi user can answer this bet"
          />
        </StandardAppearAnimation>
      </div>
      <div className="bb-bet-type__footer">
        <ErrorMessage />
        <SaveBetEdit>Save</SaveBetEdit>
        <SubmitBet>Next Step</SubmitBet>
      </div>
    </FormWrapper>
  );
};

export default BetTypeForm;
