import React from 'react';
import redirect from 'router/modules/redirect';
import { EnhancedRouteProps } from 'router/routes/EnhancedRoute/EnhancedRoute.component';
import Chat from './pages/Chat';
import Stadium from './pages/Stadium';
import Bets from './pages/Bets';
import ChatsIcon from 'assets/svgs/navigation/Chats.icon';
import MenuIcon from 'assets/svgs/navigation/Menu.icon';
import BetsIcon from 'assets/svgs/navigation/Bets.icon';
import StadiumIcon from 'assets/svgs/navigation/Stadium.icon';
import FeedIcon from 'assets/svgs/navigation/Feed.icon';
import MenuRouter from './pages/Menu/router/Menu.router';
import MyBets from './pages/MyBets';
import Feed from './pages/Feed';

export type MainRoute = Partial<EnhancedRouteProps> & {
  label: string;
  icon: React.FC;
};

export default [
  {
    path: '/feed',
    component: Feed,
    label: 'Feed',
    icon: FeedIcon,
  },
  {
    path: '/stadium',
    component: Stadium,
    label: 'Stadium',
    icon: StadiumIcon,
    exact: false,
  },
  {
    path: '/my-bets',
    component: MyBets,
    label: 'My Bets',
    icon: BetsIcon,
  },
  {
    path: '/bets',
    component: Bets,
    label: 'Bets',
    icon: BetsIcon,
  },
  {
    path: '/chat',
    component: Chat,
    label: 'Chats',
    icon: ChatsIcon,
    exact: false,
  },
  {
    path: '/menu',
    component: MenuRouter,
    label: 'More',
    icon: MenuIcon,
    exact: false,
  },
  {
    path: '*',
    component: redirect('/stadium'),
  },
] as MainRoute[];
