import httpClient from 'api/httpClient';
import PaginatedData from 'models/PaginatedData';
import {
  CoinObjectType,
  PaymentResponse,
  PaymentType,
  TransactionType,
} from 'models/Transactions';

const transactionPath = (routePath: TemplateStringsArray) =>
  `transactions${routePath}`;

function getUserTransactions(params: { page: number; limit?: number }) {
  return httpClient.get<PaginatedData<TransactionType>>(transactionPath``, {
    params,
  });
}

function makeDeposit(data: PaymentType) {
  return httpClient.post<PaymentResponse>(transactionPath`/deposit`, data);
}

function makeWithdrawal(data: PaymentType) {
  return httpClient.post(transactionPath`/withdrawal`, data);
}

function getPossibleCoins() {
  return httpClient.get<{ coins: CoinObjectType }>(transactionPath`/coins`);
}

export default {
  getUserTransactions,
  getPossibleCoins,
  makeDeposit,
  makeWithdrawal,
};
