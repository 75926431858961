import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import Arrow from 'assets/svgs/Arrow';
import { Button, Loader } from 'ncoded-component-library';
import Slider from 'assets/svgs/Slider';
import TeamCard from 'components/TeamCard';
import { TeamData } from 'models/Team';
import InfiniteScroll from 'components/InfiniteScroll';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import {
  EASE_IN_OUT_CUBIC,
  sportIcons,
} from 'routers/MainRouter/services/constants';
import { TeamFilterData } from 'routers/MainRouter/pages/MainPage/providers/Teams/Teams.provider';
import AppearAnimation from 'components/AppearAnimation';
import { useHistory } from 'react-router';
import TeamsFilterModal from 'components/modals/TeamsFilterModal';
import GlowScroll from 'components/GlowScroll';
import EmptyPlaceholder from 'components/EmptyPlaceholder';

import './FavoriteTeams.styles.scss';

type FavoriteTeamsProps = {
  className?: string;
};

const FavoriteTeams: React.FC<FavoriteTeamsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-favorite-teams', className);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const history = useHistory();

  const {
    favoriteTeams,
    loadingFavorites,
    filterTeamsData,
    setFilterTeamsData,
    onFavoritesScroll,
  } = useContext(MainContext);

  return (
    <>
      {loadingFavorites && <Loader />}

      <TeamsFilterModal
        open={isFilterOpen}
        teamFilterData={filterTeamsData}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={(value: TeamFilterData) => setFilterTeamsData(value)}
      />

      <GlowScroll className={classes}>
        <AppearAnimation
          duration={700}
          animationName="moveRight"
          timingFunction={EASE_IN_OUT_CUBIC}
        >
          <div className="bb-favorite-teams__container">
            <div className="bb-favorite-teams__header">
              <Button
                styleType="secondary"
                icon={Arrow}
                onClick={() => history.goBack()}
              />
              <p>favorite teams</p>
              <Button
                styleType="secondary"
                className="actions-button"
                icon={Slider}
                onClick={() => setIsFilterOpen(true)}
              />
            </div>

            <InfiniteScroll onScroll={onFavoritesScroll}>
              {favoriteTeams.length === 0 && (
                <EmptyPlaceholder
                  title="There aren't any favorite teams marked yet!"
                  description="Teams will be listed here when you mark some team as favorite."
                />
              )}

              {favoriteTeams?.map((team: TeamData, index: number) => {
                return (
                  <TeamCard
                    key={index}
                    data={team}
                    sportIcon={sportIcons[team.sport]}
                  />
                );
              })}
              <Button
                styleType="secondary"
                onClick={() => history.push('/menu/teams')}
              >
                Explore Teams
              </Button>
            </InfiniteScroll>
          </div>
        </AppearAnimation>
      </GlowScroll>
    </>
  );
};

export default FavoriteTeams;
