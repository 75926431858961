import React from 'react';
import classNames from 'classnames';
import { NotificationType, NotificationTypes } from 'models/Notification';
import Flag from 'assets/svgs/flags/Flag';
import Checkbox from 'assets/svgs/Checkbox';
import utils from 'utils';
import NotifCreate from 'assets/svgs/NotifCreate';
import { NavLink, NavLinkProps } from 'react-router-dom';
import UserImage from 'components/UserImage';
import ChatsIcon from 'assets/svgs/navigation/Chats.icon';

import './NotificationCard.styles.scss';

const NOTIFICATION_COLORS = {
  neonBlue: '#e45826',
  primaryGreen: '#26e196',
  redish: '#db2828',
};

export const NOTIFICATION_DATA: Record<
  NotificationTypes,
  { icon: React.ReactNode; label: string; color: string }
> = {
  'Accepted bet': {
    icon: Checkbox,
    label: 'accept your bet',
    color: NOTIFICATION_COLORS.primaryGreen,
  },
  'Created bet': {
    icon: NotifCreate,
    label: 'created the bet',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Lost bet': {
    icon: Flag,
    label: 'los the bet',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Won bet': {
    icon: Flag,
    label: 'won the bet',
    color: NOTIFICATION_COLORS.primaryGreen,
  },
  'Refunded money': {
    icon: Flag,
    label: 'refunded money',
    color: NOTIFICATION_COLORS.primaryGreen,
  },
  'Message sent': {
    icon: ChatsIcon,
    label: 'new message',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Created friend request': {
    icon: Flag,
    label: 'new friend request',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Accepted friend request': {
    icon: Flag,
    label: 'friend request accepted',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  Deposit: {
    icon: Flag,
    label: 'deposit',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  Withdrawal: {
    icon: Flag,
    label: 'withdrawal',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Withdrawal enabled': {
    icon: Flag,
    label: 'You can now make a Withdraw',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Fixture started': {
    icon: Flag,
    label: 'Game on which you placed a bet is started',
    color: NOTIFICATION_COLORS.neonBlue,
  },
  'Fixture results data not available': {
    icon: Flag,
    label: 'Game on which you placed a bet is started',
    color: NOTIFICATION_COLORS.neonBlue,
  },
};

type NotificationCardProps = {
  className?: string;
  notification: NotificationType;
  onClick?: () => void;
} & React.PropsWithoutRef<NavLinkProps> &
  React.RefAttributes<HTMLAnchorElement>;

const NotificationCard: React.FC<NotificationCardProps> = (props) => {
  const {
    className,
    notification,
    notification: { content, isRead, type, createdAt, fromUser },
    onClick,
    ...rest
  } = props;
  const classes = classNames(
    'bb-notification-card',
    { 'bb-notification-card--active': !isRead },
    className,
  );

  return (
    <NavLink {...rest} className={classes} onClick={onClick}>
      {fromUser && (
        <div className="bb-notification-card__user">
          <UserImage
            src={fromUser.profileImageUrl}
            active={fromUser.isOnline}
          />

          <div
            className="bb-notification-card__user__flag"
            style={{ color: NOTIFICATION_DATA[type]?.color }}
          >
            {utils.renderIcon(NOTIFICATION_DATA[type].icon)}
          </div>
        </div>
      )}

      <div className="bb-notification-card__content">
        <p className="bb-notification-card__user-info">
          <span>{fromUser ? `${fromUser?.username}` : `Notification`}</span>
          <span>{NOTIFICATION_DATA[type].label}</span>
          <span>
            {utils.calculatePastTime(
              createdAt?.toString() || new Date().toString(),
            )}
          </span>
        </p>

        <div className="bb-notification-card__notification-message">
          <p className="bb-notification-card__notification-message__text">
            {content.message}
          </p>
          <div className="active-dot" />
        </div>
      </div>
    </NavLink>
  );
};

export default NotificationCard;
