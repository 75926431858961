import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import FormWrapper from 'components/FormWrapper';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import useFormHelperElements from 'hooks/useFormHelperElements';
import {
  OutcomeType,
  OutcomeData,
} from '../../../../../../../../models/Outcome';
import api from 'api';
import utils, { mapOutcomesData } from 'utils';
import OutcomeCard from '../../components/OutcomeCard';
import { FieldArray } from 'react-final-form-arrays';
import { OutcomeDataFiledProps } from '../../components/OutcomeCard/OutcomeCard.component';
import { FixtureType } from 'models/Fixture';
import InfiniteScroll from 'components/InfiniteScroll';
import { OutcomeCardRef } from '../../components/OutcomeCard/OutcomeCard.component';
import { Loader } from 'ncoded-component-library';
import { AxiosError } from 'axios';
import { isEmpty } from 'lodash';
import NewBetContext from '../../providers/NewBet/NewBet.context';

import './OutcomesForm.styles.scss';

type OutcomesFormProps = {
  className?: string;
};

const OutcomesForm: React.FC<OutcomesFormProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-outcomes-form', className);

  const outcomeCardsSet = useRef<Set<OutcomeCardRef>>(new Set());

  const { change } = useForm();
  const { errors, submitFailed, valid, dirtyFieldsSinceLastSubmit } =
    useFormState();

  const { isBetEditing } = useContext(NewBetContext);
  const { nextStep, previousStep } = useContext(StepsContext);

  const {
    input: { value: selectedGame },
  } = useField<FixtureType>('selectedGame');

  const {
    input: { value: outcomes, onChange: onOutcomesChange },
  } = useField<Array<OutcomeDataFiledProps>>('outcomes');

  const [loading, setLoading] = useState<boolean>(false);
  const { setErrorMsg, ErrorMessage, SubmitBet, SaveBetEdit } =
    useFormHelperElements();

  const validateOutcomes = useCallback(
    (values: Array<OutcomeDataFiledProps>) => {
      const isArrayValid =
        values?.filter((data: OutcomeDataFiledProps) => data.active).length > 0;
      return isArrayValid
        ? undefined
        : 'You need to select one or more outcome!';
    },
    [],
  );

  const onTeamSelect = useCallback(
    (team: number, outcomeType: OutcomeType) => {
      onOutcomesChange(
        outcomes.map((outcome) => {
          if (outcome.type === outcomeType) {
            return {
              ...outcome,
              active: true,
              data: { ...outcome.data, team },
            };
          } else {
            return {
              ...outcome,
              active: false,
              data: { ...outcome.data, team: null },
            };
          }
        }),
      );

      Array.from(outcomeCardsSet.current)
        .filter((oc) => oc.collapsible?.open && oc.type !== outcomeType)
        .forEach((oc) => oc.collapsible?.setOpen(false));
    },
    [onOutcomesChange, outcomes],
  );

  useEffect(() => {
    const errorMessage = errors?.outcomes?.find(
      (err: { data: OutcomeData }) => !isEmpty(err?.data),
    )?.data as Record<string, any>;

    if (errorMessage) setErrorMsg(Object.values(errorMessage)[0]);
    else if (
      errors.outcomes?.length > 0 &&
      submitFailed &&
      !valid &&
      dirtyFieldsSinceLastSubmit
    )
      setErrorMsg('You need to select outcome before submit!');
    else setErrorMsg('');
  }, [dirtyFieldsSinceLastSubmit, errors, submitFailed, valid, setErrorMsg]);

  const loadFixtureOutcomes = useCallback(async () => {
    try {
      setLoading(true);
      const {
        data: { outcomes: outcomesData },
      } = await api.fixture.getFixtureOutcomes({
        fixtureId: selectedGame.Id,
      });
      change('outcomes', mapOutcomesData(outcomesData));
    } catch (e) {
      setErrorMsg(utils.getStringError(e as AxiosError));
    } finally {
      setLoading(false);
    }
  }, [selectedGame.Id, change, setErrorMsg]);

  useEffect(() => {
    if (isBetEditing.current) return;

    if (!window.isNewBetModalClose && !window.prevStep) {
      if (!previousStep || previousStep <= 1) loadFixtureOutcomes();
    }
  }, [isBetEditing, previousStep, selectedGame, loadFixtureOutcomes]);

  return (
    <FormWrapper onSubmit={nextStep} className={classes} id="betModalForm">
      {loading && <Loader />}
      <div className="bb-outcomes-form__container">
        {!loading && (
          <FieldArray name="outcomes" validate={validateOutcomes}>
            {({ fields }) => {
              return (
                <>
                  <InfiniteScroll
                    onScroll={() => {}}
                    className="bb-outcomes-form__content"
                  >
                    {fields?.map((name, index) => {
                      return (
                        <Field
                          ref={(el: OutcomeCardRef) =>
                            el && outcomeCardsSet.current.add(el)
                          }
                          component={OutcomeCard}
                          key={name}
                          active={fields.value[index].active}
                          name={`outcomes[${index}]`}
                          fixture={selectedGame}
                          onTeamSelect={(teamInd: number) =>
                            onTeamSelect(teamInd, fields.value[index].type)
                          }
                        />
                      );
                    })}
                  </InfiniteScroll>
                </>
              );
            }}
          </FieldArray>
        )}
      </div>
      <div className="bb-outcomes-form__footer">
        <ErrorMessage />
        <SaveBetEdit>Save</SaveBetEdit>
        <SubmitBet>Next Step</SubmitBet>
      </div>
    </FormWrapper>
  );
};

export default OutcomesForm;
