import React from 'react';
import classNames from 'classnames';
import { Field, useField } from 'react-final-form';

import { Button } from 'ncoded-component-library';
import FormWrapper from 'components/FormWrapper';
import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';

import './ReferralCodeForm.styles.scss';

type ReferralCodeFormProps = {
  className?: string;
};

const ReferralCodeForm: React.FC<ReferralCodeFormProps> = ({ className }) => {
  const {
    input: { value: referralCode },
  } = useField<string>('referralCode');

  return (
    <FormWrapper className={classNames('bb-referral-code-form', className)}>
      <div className="bb-referral-code-form__content">
        <StandardAppearAnimation delay={500} duration={400}>
          <p>
            Enter your referral code below and after referred deposit of $20 you
            and your friend who shared a referral code will get $10.
          </p>
        </StandardAppearAnimation>
        <Field
          name="referralCode"
          label="referral code"
          placeholder="Code"
          component={AnimatedField}
          type="text"
          delay={600}
          duration={400}
        />

        {!!referralCode && (
          <StandardAppearAnimation delay={700} duration={400}>
            <p className="bb-referral-code-form__embedded">
              Referral code detected from URL entry. Welcome!
            </p>
          </StandardAppearAnimation>
        )}
      </div>
      <div className="bb-referral-code-form__footer">
        <Button type="submit">Next Step</Button>
      </div>
    </FormWrapper>
  );
};

export default ReferralCodeForm;
