import AppearAnimation from 'components/AppearAnimation';
import React from 'react';
import './StandardAppearAnimation.styles.scss';

type StandardAppearAnimationProps = { delay?: number; duration?: number };

const StandardAppearAnimation: React.FC<StandardAppearAnimationProps> = ({
  duration = 800,
  delay = 0,
  children,
}) => (
  <AppearAnimation
    animations={{
      moveIn: {
        duration,
        delay,
      },
      appear: {
        duration,
        delay,
      },
    }}
  >
    {children}
  </AppearAnimation>
);

export default StandardAppearAnimation;
