import React, { ReactElement, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Property } from 'csstype';
import { Without } from 'types';
import uniqid from 'uniqid';

import './AppearAnimation.styles.scss';

type AnimationProps = {
  className?: string;
};

export type AnimationName =
  | 'moveIn'
  | 'moveLeft'
  | 'moveRight'
  | 'moveDown'
  | 'appear';

type SingleAnimation = {
  duration: number;
  animationName: AnimationName;
  delay?: number;
  timingFunction?: Property.TransitionTimingFunction;
  fillMode?: Property.AnimationFillMode;
};

type SimpleAnimationProps = AnimationProps & SingleAnimation;

type Animations = Partial<
  Record<AnimationName, Without<SingleAnimation, 'animationName'>>
>;

type EnhancedAnimationProps = AnimationProps & {
  animations: Animations;
};

type Unitable = keyof Without<
  SingleAnimation,
  'timingFunction' | 'animationName' | 'fillMode'
>;

type AnimationProp = keyof SingleAnimation;

const PropertyOrder: Record<AnimationProp, number> = {
  animationName: 0,
  duration: 1,
  delay: 2,
  timingFunction: 3,
  fillMode: 4,
};

const UnitMap: Record<Unitable, string> = {
  delay: 'ms',
  duration: 'ms',
};

const AppearAnimation: React.FC<
  SimpleAnimationProps | EnhancedAnimationProps
> = (props) => {
  let animations: Animations = {};
  const { className, children, ...rest } = props;

  if ('animations' in props) {
    animations = props.animations;
  } else {
    const { animationName, ...animationProps } = rest as SingleAnimation;
    animations = {
      [animationName]: {
        delay: 0,
        timingFunction: 'ease-in-out',
        ...animationProps,
      },
    };
  }

  const { current: uniqClassName } = useRef(`bb-animation--${uniqid()}`);

  const classes = classNames(
    'bb-appear-animation',
    uniqClassName,
    className,
    {
      'bb-appear-animation--appear': Object.keys(animations).includes('appear'),
      'bb-appear-animation--move-in':
        Object.keys(animations).includes('moveIn'),
      'bb-appear-animation--move-down':
        Object.keys(animations).includes('moveDown'),
      'bb-appear-animation--move-left':
        Object.keys(animations).includes('moveLeft'),
      'bb-appear-animation--move-right':
        Object.keys(animations).includes('moveRight'),
    },
    (children as ReactElement).props.className,
  );

  const extendedChildren = useMemo(
    () =>
      React.cloneElement(children as ReactElement, {
        className: classes,
      }),
    [children, classes],
  );

  const animation = useMemo(
    () =>
      Object.entries(animations)
        .map(
          ([animationName, value]) =>
            `${animationName}${Object.entries({
              timingFunction: 'ease-in-out',
              fillMode: 'forwards',
              ...value,
            })
              .sort(
                ([key1], [key2]) =>
                  PropertyOrder[key1 as AnimationProp] -
                  PropertyOrder[key2 as AnimationProp],
              )
              .reduce(
                (styleString, [key, val]) =>
                  `${styleString} ${val}${UnitMap[key as Unitable] || ''}`,
                '',
              )}`,
        )
        .join(', '),
    [animations],
  );

  return (
    <>
      <style>
        {`
        .${uniqClassName} { 
          --bb-animation: ${animation};
        }
      `}
      </style>
      {extendedChildren}
    </>
  );
};

export default AppearAnimation;
