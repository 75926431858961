import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import {
  FixtureResultsType,
  FixtureStatusType,
  FixtureType,
} from 'models/Fixture';
import { LEAGUES, SportType } from 'models/Sport';
import utils from 'utils';
import Image from 'components/Image';

import './FixtureCard.styles.scss';

type FixtureCardProps = {
  className?: string;
  game?: FixtureType;
  sport?: SportType;
  fixtureStatus?: FixtureStatusType;
  fixtureResults?: FixtureResultsType;
  fixtureDate?: Date;
  sportIcon?: React.ReactNode;
  active?: boolean;
  input?: any;
  meta?: any;
  onClick?: () => void;
};

const FixtureCard: React.FC<FixtureCardProps> = (props) => {
  const { className, game, input, active, fixtureDate, onClick } = props;

  const { firstTeam, secondTeam } = game;

  const classes = classNames([
    'bb-fixture',
    { 'bb-fixture--active': active },
    className,
  ]);

  const handleOnGame = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!input) onClick();
      else {
        input.onChange(game);
        onClick();
      }
    },
    [game, input, onClick],
  );

  const firstTeamLogo = useMemo(
    () => (firstTeam.logoUrl ? firstTeam.logoUrl : firstTeam.publicTeamLogoUrl),
    [firstTeam],
  );

  const secondTeamLogo = useMemo(
    () =>
      secondTeam.logoUrl ? secondTeam.logoUrl : secondTeam.publicTeamLogoUrl,
    [secondTeam],
  );

  return (
    <div className={classes} onClick={handleOnGame}>
      <div className="bb-fixture__game-type">
        <label>{game.sport}</label>
        <label>{LEAGUES[game.league]}</label>
      </div>
      <div className="bb-fixture__game-details">
        <div className="bb-fixture__game-details__team">
          <Image src={firstTeamLogo} />
          <label>{game.firstTeam.teamName}</label>
        </div>

        <div className="bb-fixture__game-details__details">
          <p>{utils.toHourOneDigitMinFormat(fixtureDate?.toString())}</p>
          <p>{utils.toMonthFormat(fixtureDate?.toString())}</p>
        </div>

        <div className="bb-fixture__game-details__team">
          <Image src={secondTeamLogo} />
          <label>{game.secondTeam.teamName}</label>
        </div>
      </div>
    </div>
  );
};

export default FixtureCard;
