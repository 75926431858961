import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-416 -160) translate(80 160) translate(336)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M15.001 4.582C17.932 5.769 20 8.643 20 12c0 4.418-3.582 8-8 8s-8-3.582-8-8c0-3.357 2.068-6.232 5-7.418v2.22C7.207 7.84 6 9.78 6 12c0 3.314 2.686 6 6 6s6-2.686 6-6c0-2.22-1.206-4.16-2.999-5.197V4.582zM12.5 3c.276 0 .5.224.5.5v7c0 .276-.224.5-.5.5h-1c-.276 0-.5-.224-.5-.5v-7c0-.276.224-.5.5-.5h1z"
              transform="translate(-416 -160) translate(80 160) translate(336)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
