import React from 'react';

export default () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_504_1002)">
      <path
        d="M5.00001 10C5.92048 10 6.66668 9.25381 6.66668 8.33334C6.66668 7.41286 5.92048 6.66667 5.00001 6.66667C4.07954 6.66667 3.33334 7.41286 3.33334 8.33334C3.33334 9.25381 4.07954 10 5.00001 10Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 3.33333V6.66666"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 10V16.6667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15C10.9205 15 11.6667 14.2538 11.6667 13.3333C11.6667 12.4129 10.9205 11.6667 10 11.6667C9.07954 11.6667 8.33334 12.4129 8.33334 13.3333C8.33334 14.2538 9.07954 15 10 15Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 3.33333V11.6667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 15V16.6667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.50001C15.9205 7.50001 16.6667 6.75381 16.6667 5.83334C16.6667 4.91286 15.9205 4.16667 15 4.16667C14.0795 4.16667 13.3333 4.91286 13.3333 5.83334C13.3333 6.75381 14.0795 7.50001 15 7.50001Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 3.33333V4.16666"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 7.5V16.6667"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_504_1002">
        <rect width="20" height="20" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
);
