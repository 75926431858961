export default {
  SERVER_ENDPOINT: process.env.REACT_APP_SERVER_ENDPOINT,
  UI_ENDPOINT: process.env.REACT_APP_UI_ENDPOINT,
  FIREBASE_API_KEY: process.env.REACT_APP_FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  FIREBASE_STORAGE_BUCKET: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  FIREBASE_APP_ID: process.env.REACT_APP_FIREBASE_APP_ID,
  FIRE_BASE_VAPID_KEY: process.env.REACT_APP_FIREBASE_VAPID_KEY,
  FIREBASE_MESSAGING_SENDER_ID:
    process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  FACEBOOK_APP_ID: process.env.REACT_APP_FACEBOOK_APP_ID,
};
