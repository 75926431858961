import React, { useMemo } from 'react';
import classNames from 'classnames';
import Flag from 'assets/svgs/flags/Flag';
import { TeamType } from 'models/Team';
import Image from 'components/Image';
import { FixtureStatusType } from 'models/Fixture';

import './BetTeam.styles.scss';

type BetTeamProps = {
  className?: string;
  active?: boolean;
  hasBookmark?: boolean;
  bettingOdds?: number;
  bettingOddsActive?: boolean;
  fixtureStatus?: FixtureStatusType;
  teamScore?: number;
  onClick?: () => void;
} & TeamType;

const BetTeam: React.FC<BetTeamProps> = (props) => {
  const {
    className,
    active,
    logoUrl,
    publicTeamLogoUrl,
    teamName,
    hasBookmark,
    bettingOdds,
    bettingOddsActive,
    fixtureStatus,
    teamScore,
    onClick,
  } = props;
  const classes = classNames(
    'bb-bet-team',
    { 'bb-bet-team--active': active },
    { 'bb-bet-team--betting-odd-active': bettingOddsActive },
    className,
  );
  const bettingOddClasses = classNames('bb-bet-team__betting-odd', {
    'bb-bet-team__betting-odd--active': bettingOddsActive,
  });

  const logoImage = useMemo(
    () => (logoUrl ? logoUrl : publicTeamLogoUrl),
    [logoUrl, publicTeamLogoUrl],
  );

  return (
    <div className={classes} onClick={onClick}>
      <Image src={logoImage} alt={teamName} />
      <label className="bb-bet-team__name">{teamName}</label>
      {fixtureStatus && <span className="bb-bet-team__score">{teamScore}</span>}
      {hasBookmark && active && <Flag />}
      {bettingOdds && (
        <div className={bettingOddClasses}>{bettingOdds.toFixed(2)}</div>
      )}
    </div>
  );
};

export default BetTeam;
