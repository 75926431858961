import React, { useContext } from 'react';
import classNames from 'classnames';
import { FixtureType } from 'models/Fixture';
import { useHistory } from 'react-router-dom';
import TeamsContext from 'routers/MainRouter/pages/MainPage/providers/Teams/Teams.context';
import FixtureCard from 'routers/MainRouter/pages/Stadium/components/FixtureCard';
import { sportIcons } from 'routers/MainRouter/services/constants';

import './TeamUpcomingGames.styles.scss';

type TeamUpcomingGamesProps = {
  className?: string;
};

const TeamUpcomingGames: React.FC<TeamUpcomingGamesProps> = (props) => {
  const { className } = props;

  const history = useHistory();
  const classes = classNames('bb-team-upcoming-games', className);

  const { upcomingFixtures } = useContext(TeamsContext);

  return (
    <div className={classes}>
      {upcomingFixtures?.map((fixture: FixtureType, index: number) => {
        return (
          <FixtureCard
            key={index}
            fixtureResults={fixture.fixtureResults}
            fixtureDate={fixture.startDate}
            fixtureStatus={fixture.fixtureStatus}
            game={fixture}
            sportIcon={sportIcons[fixture.sport]}
            onClick={() => history.push(`/stadium?fixtureId=${fixture.Id}`)}
          />
        );
      })}
    </div>
  );
};

export default TeamUpcomingGames;
