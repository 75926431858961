import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        s
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-128 -160) translate(80 160) translate(48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M13.086 12l-3.293 3.293c-.39.39-.39 1.024 0 1.414.39.39 1.024.39 1.414 0L15.914 12l-4.707-4.707c-.39-.39-1.024-.39-1.414 0-.39.39-.39 1.024 0 1.414L13.086 12z"
              transform="translate(-128 -160) translate(80 160) translate(48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
