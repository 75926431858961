import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';

import useFormHelperElements from 'hooks/useFormHelperElements';
import {
  composeValidators,
  emailFormatValid,
  required,
} from 'services/validators';
import InputField from 'components/Fields/InputField';
import Modal from 'components/Modal';
import { ReferralCodeData } from 'models/ConfirmationModals';
import { ConfirmModalProps } from '../ConfirmModal/ConfirmModal.component';
import CopyToClipboard from './CopyToClipboard';

import './ReferralCodeModal.styles.scss';

type ReferralCodeModalProps = Omit<
  ConfirmModalProps,
  'onClose' | 'onConfirm' | 'description'
> & {
  code: string;
  onClose?: (data: ReferralCodeData) => void;
  onConfirm?: (data: ReferralCodeData) => void;
};

const ReferralCodeModal: React.FC<ReferralCodeModalProps> = (props) => {
  const {
    title,
    okText = 'Send',
    cancelText = 'Cancel',
    code,
    onConfirm,
    onClose,
    ...modalProps
  } = props;

  const { Cancel, Submit } = useFormHelperElements();

  const handleSubmit = useCallback(
    ({ email }: ReferralCodeData) =>
      onConfirm({ email, confirmed: true, code }),
    [code, onConfirm],
  );

  return (
    <Modal
      open
      renderAsPortal={false}
      className="bb-referral-code"
      title={<p>{title}</p>}
      {...modalProps}
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="bb-referral-code__container">
              <CopyToClipboard code={code} />
              <Field
                name="email"
                component={InputField}
                label="friend's email address"
                placeholder="name@example.com"
                validate={composeValidators(
                  required('This field is required'),
                  emailFormatValid('Email address is not valid'),
                )}
              />
              <p className="bb-referral-code__container__info">
                Send referral code invitation to your friends to let them know
                they can join our app with some benefits. Earn rewards everytime
                someone use your invitation code.
              </p>
            </div>

            <div className="bb-referral-code__form-actions">
              <Cancel onClick={() => onClose({ confirmed: false, code })}>
                {cancelText}
              </Cancel>
              <Submit type="submit">{okText}</Submit>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default ReferralCodeModal;
