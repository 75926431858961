import TOrange from 'assets/svgs/TOrange';
import classNames from 'classnames';
import React from 'react';
import utils from 'utils';

import './EmptyPlaceholder.styles.scss';

type EmptyPlaceholderProps = {
  className?: string;
  title?: string;
  description?: string;
  icon?: React.ReactNode;
};

const EmptyPlaceholder: React.FC<EmptyPlaceholderProps> = (props) => {
  const {
    className,
    title = 'There is no content yet!',
    description,
    icon = TOrange,
  } = props;

  const classes = classNames('bb-empty-placeholder', className);

  return (
    <div className={classes}>
      {icon && utils.renderIcon(icon)}
      <p className="bb-empty-placeholder__title">{title}</p>
      <p className="bb-empty-placeholder__description">{description}</p>
    </div>
  );
};

export default EmptyPlaceholder;
