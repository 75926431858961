import React from 'react';
import ReactDOM from 'react-dom';

import CoinConfirmation from './modals/CoinConfirmation';
import ReferralCodeModal from './modals/ReferralCodeModal';
import ConfirmBetModal from './modals/ConfirmBetModal';
import ConfirmModal from './modals/ConfirmModal';
import {
  ConfirmationCoinData,
  ConfirmBetData,
  ReferralCodeData,
} from 'models/ConfirmationModals';
import { ConfirmModalProps } from './modals/ConfirmModal/ConfirmModal.component';

type ConfirmationTypes = {
  simpleConfirmation: (data: Config) => Promise<boolean>;
  betConfirmation: (data: BetConfig) => Promise<ConfirmBetData>;
  coinConfirmation: (data: CoinConfig) => Promise<ConfirmationCoinData>;
  referralCode: (data: ReferralCodeConfig) => Promise<ReferralCodeData>;
};

type Config = Omit<ConfirmModalProps, 'onClose' | 'onConfirm'>;
type BetConfig = Omit<ConfirmModalProps, 'onClose' | 'onConfirm'> & {
  maxPayment: number;
  outcomeMessage?: string;
};
type CoinConfig = Omit<ConfirmModalProps, 'onClose' | 'onConfirm'> & {
  address: string;
};
type ReferralCodeConfig = Omit<
  ConfirmModalProps,
  'onClose' | 'onConfirm' | 'description'
> & {
  code: string;
  email: string;
};

type RenderConfirmationModalProps<G> = {
  config?: G;
  Component: React.FC<G>;
  simpleConfirmation: boolean;
};

function renderConfirmationModal<T, G>(
  values: RenderConfirmationModalProps<G>,
): Promise<boolean | T> {
  const { config, Component, simpleConfirmation } = values;

  const div = document.createElement('div');
  document.body.appendChild(div);

  const cleanup = () => {
    ReactDOM.unmountComponentAtNode(div);
    document.body.removeChild(div);
  };

  return new Promise((resolve) => {
    ReactDOM.render(
      <Component
        onClose={(data: T) => {
          cleanup();
          resolve(simpleConfirmation ? false : data);
        }}
        onConfirm={(data: T) => {
          cleanup();
          resolve(simpleConfirmation ? true : data);
        }}
        {...config}
      />,
      div,
    );
  });
}

export default {
  simpleConfirmation: (data: Config) =>
    renderConfirmationModal<boolean, Config>({
      config: data,
      Component: ConfirmModal,
      simpleConfirmation: true,
    }),
  betConfirmation: (data: BetConfig) =>
    renderConfirmationModal<ConfirmBetData, BetConfig>({
      config: data,
      Component: ConfirmBetModal,
      simpleConfirmation: false,
    }),
  coinConfirmation: (data: CoinConfig) =>
    renderConfirmationModal<ConfirmationCoinData, CoinConfig>({
      config: data,
      Component: CoinConfirmation,
      simpleConfirmation: false,
    }),
  referralCode: (data: ReferralCodeConfig) =>
    renderConfirmationModal<ReferralCodeData, ReferralCodeConfig>({
      config: data,
      Component: ReferralCodeModal,
      simpleConfirmation: false,
    }),
} as ConfirmationTypes;
