import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import { OverlayProps, OverlayRef } from 'components/Overlay/Overlay.component';
import useCallbackRef from 'hooks/useCallbackRef';
import classNames from 'classnames';
import { Overlay } from 'ncoded-component-library';
import Close from '../../assets/svgs/Close';

import './Modal.styles.scss';

export type ModalProps = {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  size?: 'medium' | 'small';
  onX?: () => void;
} & OverlayProps;

export type ModalRef = OverlayRef;

const Modal: React.ForwardRefRenderFunction<ModalRef, ModalProps> = (
  props,
  ref,
) => {
  const {
    children,
    title,
    background = 'transparent',
    footer,
    className,
    size,
    close,
    onX,
    ...rest
  } = props;

  const [overlay, overlayRef] = useCallbackRef<OverlayRef>();

  const classes = classNames([
    'modal',
    { [`modal--${size}`]: Boolean(size) },
    className,
  ]);

  useImperativeHandle(ref, () => overlay, [overlay]);
  const onXClick = useMemo(() => onX || overlay?.close, [onX, overlay]);

  return (
    <Overlay
      className={classes}
      ref={overlayRef}
      background={background}
      overlayCloses={close !== undefined ? close : true}
      fullContent={false}
      {...rest}
    >
      {title && (
        <header>
          {title}
          <button className="modal__close" onClick={onXClick}>
            <Close />
          </button>
        </header>
      )}
      <main>{children}</main>
      {footer && <footer>{footer}</footer>}
    </Overlay>
  );
};

export default forwardRef(Modal);
