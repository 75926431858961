import { Property } from 'csstype';
import { useCallback, useMemo } from 'react';
import { Without } from 'types';

export type AnimationNames =
  | 'moveUp'
  | 'moveDown'
  | 'moveLeft'
  | 'moveRight'
  | 'moveOutRight'
  | 'backgroundColor'
  | 'backgroundColorReverse';

export type Animation = {
  animationName: AnimationNames;
  duration?: number;
  delay?: number;
  timingFunction?: Property.TransitionTimingFunction;
  iterationCount?: Property.AnimationIterationCount;
  direction?: Property.AnimationDirection;
  fillMode?: Property.AnimationFillMode;
};

type Unitable = keyof Without<
  Animation,
  | 'iterationCount'
  | 'direction'
  | 'animationName'
  | 'fillMode'
  | 'timingFunction'
>;

export type AnimationProps = {
  appearAnimation?: AnimationNames;
  disappearAnimation?: AnimationNames;
} & Without<Animation, 'animationName'>;

type AnimationKeys = keyof Animation;

const PropertyOrder: Record<AnimationKeys, number> = {
  animationName: 0,
  duration: 1,
  timingFunction: 2,
  delay: 3,
  iterationCount: 4,
  direction: 5,
  fillMode: 6,
};

const UnitMap: Record<Unitable, string> = {
  delay: 'ms',
  duration: 'ms',
};

type Animations = Partial<
  Record<AnimationNames, Without<Animation, 'animationName'>>
>;

export type EnhancedAnimationProps = AnimationProps & {
  appearAnimations?: Animations;
  disappearAnimations?: Animations;
};

export default function useAnimation(
  props: EnhancedAnimationProps | AnimationProps,
) {
  const { ...animationRest } = props;

  let appearAnimations: Animations;
  let disappearAnimations: Animations;

  if ('appearAnimations' in props || 'disappearAnimations' in props) {
    appearAnimations = props.appearAnimations;
    disappearAnimations = props.disappearAnimations;
  } else {
    const { appearAnimation, disappearAnimation, ...animationProps } =
      animationRest as AnimationProps;

    appearAnimations = {
      [appearAnimation]: {
        ...animationProps,
      },
    };

    disappearAnimations = {
      [disappearAnimation]: {
        ...animationProps,
      },
    };
  }

  const generateAnimationString = useCallback((animations: Animations) => {
    return Object.entries(animations)
      .map(
        ([animationName, value]) =>
          `${animationName}${Object.entries({
            timingFunction: 'ease-in-out',
            fillMode: 'forwards',
            duration: 600,
            ...value,
          })
            .sort(
              ([key1], [key2]) =>
                PropertyOrder[key1 as AnimationKeys] -
                PropertyOrder[key2 as AnimationKeys],
            )
            .reduce(
              (styleString, [key, val]) =>
                `${styleString} ${val}${UnitMap[key as Unitable] || ''}`,
              '',
            )}`,
      )
      .join(', ');
  }, []);

  const appearAnimationText = useMemo(
    () => !!appearAnimations && generateAnimationString(appearAnimations),
    [appearAnimations, generateAnimationString],
  );

  const disappearAnimationText = useMemo(
    () => !!disappearAnimations && generateAnimationString(disappearAnimations),
    [disappearAnimations, generateAnimationString],
  );

  return {
    appearAnimations,
    disappearAnimations,
    appearAnimationText,
    disappearAnimationText,
  };
}
