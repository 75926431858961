import React, { useContext } from 'react';
import classNames from 'classnames';
import { Pickers } from '../../utils';
import { Button } from 'ncoded-component-library';
import DatepickerContext from '../../provider/Datepicker.context';

import './ShowMenu.styles.scss';
import './ShowMenu.styles.responsive.scss';

type ShowMenuProps = {
  className?: string;
};

const ShowMenu: React.FC<ShowMenuProps> = (props) => {
  const { className } = props;
  const { setShowInMenu, showInMenu } = useContext(DatepickerContext);

  const classes = classNames('bb-show-menu', className);

  return (
    <div className={classes}>
      {Object.values(Pickers).map((picker) => (
        <Button
          styleType="secondary"
          key={picker}
          className={classNames({
            'bb-show-menu__active': picker === showInMenu,
          })}
          onClick={() => setShowInMenu(picker)}
        >
          {picker}
        </Button>
      ))}
    </div>
  );
};

export default ShowMenu;
