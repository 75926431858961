import httpClient from 'api/httpClient';
import PaginatedData from 'models/PaginatedData';
import { UserProfileType, UserSettingsType, UserType } from 'models/User';

const userPath = (routePath: TemplateStringsArray) => `users${routePath}`;

function getCurrentBalance() {
  return httpClient.post<{ currentBalance: number }>(userPath`/currentBalance`);
}

function getUsers(params: {
  page: number;
  limit?: number;
  searchString?: string;
}) {
  return httpClient.get<PaginatedData<UserType>>(userPath``, { params });
}

function getUser() {
  return httpClient.get<UserType>(userPath`/user`);
}

function getUserById(userId: string) {
  return httpClient.get<UserProfileType>(`users/${userId}`);
}

function getUserSettings() {
  return httpClient.get<UserSettingsType>(userPath`/user/settings`);
}

function uploadUserImage(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return httpClient.post<{ imageUrl: string }>(
    userPath`/upload/profileImage`,
    formData,
  );
}

function setUserMuteSetting(muted: boolean) {
  return httpClient.post(userPath`/settings/notifications`, {
    isNotificationsMuted: muted,
  });
}

function setUserRememberMe(remember: boolean) {
  return httpClient.post(userPath`/settings/rememberMe`, {
    isRememberMe: remember,
  });
}

function setFCMRegistartionToken(token: string) {
  return httpClient.post(userPath`/set-fcm-registration-token`, {
    registrationToken: token,
  });
}

function finishOnboarding() {
  return httpClient.post(userPath`/finish-onboarding`);
}

function updateUser(userData: UserType) {
  return httpClient.patch(userPath``, userData);
}

export default {
  getCurrentBalance,
  getUsers,
  getUser,
  updateUser,
  getUserById,
  getUserSettings,
  uploadUserImage,
  setUserMuteSetting,
  setUserRememberMe,
  setFCMRegistartionToken,
  finishOnboarding,
};
