import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import './Toastr.styles.scss';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import usePopup from 'hooks/usePopup';
import Close from 'assets/svgs/Close';
import useCSSVariables from 'hooks/useCSSVariables';

type PointerEv =
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | React.TouchEvent<HTMLDivElement>;

export type ToastrProps = {
  icon?: ReactNode;
  text?: string;
  description?: string;
  type?: 'Success' | 'Error' | 'Info';
  profileImage?: React.ReactNode;
  onClose?: () => void;
  onExit?: () => void;
  onClick?: () => void;
};

export type ToastrRef = {
  open: () => void;
  close: () => void;
};

const Toastr: React.ForwardRefRenderFunction<ToastrRef, ToastrProps> = (
  props,
  ref,
) => {
  const { text, description, type, profileImage, onClose, onExit, onClick } =
    props;
  const [open, setOpen] = usePopup({ defaultOpen: true, onClose });

  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [trackingOffset, setTrackingOffset] = useState<number>(0);
  const translateX = useRef<number>(0);
  const rootContainerRef = useRef<HTMLDivElement>(null);

  const toastrClasses = classNames(
    'bb-toast',
    { 'bb-toast--error': type === 'Error' },
    { 'bb-toast--success': type === 'Success' },
  );

  const openToastr = useCallback(() => setOpen(true), [setOpen]);
  const closeToastr = useCallback(() => setOpen(false), [setOpen]);

  const calculateXStart = useCallback((event: PointerEv) => {
    // touch event
    if (
      (event as React.TouchEvent).targetTouches &&
      (event as React.TouchEvent).targetTouches.length >= 1
    ) {
      return (event as React.TouchEvent).targetTouches[0].clientX;
    }

    // mouse event
    else return (event as React.MouseEvent).clientX;
  }, []);

  const tap = useCallback(
    (event: PointerEv) => {
      event.stopPropagation();
      translateX.current = calculateXStart(event);
      setIsDragging(true);
    },
    [calculateXStart],
  );

  const drag = useCallback(
    (event: PointerEv) => {
      if (isDragging) {
        const currentX = calculateXStart(event);
        const difference = currentX - translateX.current;
        setTrackingOffset(difference);
      }
    },
    [calculateXStart, isDragging],
  );

  const release = useCallback(
    (event: PointerEv) => {
      setIsDragging(false);
      if (
        (Math.abs(trackingOffset) / rootContainerRef.current.clientWidth) *
          100 >
        50
      )
        setOpen(false);
      else setTrackingOffset(0);
    },
    [setOpen, trackingOffset],
  );

  const toastrStyle = useCSSVariables({
    xOffset: `${trackingOffset}px`,
  });

  useImperativeHandle(
    ref,
    () => ({
      open: openToastr,
      close: closeToastr,
    }),
    [closeToastr, openToastr],
  );

  return (
    <CSSTransition
      appear
      classNames="bb-toast"
      in={open}
      unmountOnExit
      timeout={300}
      onExited={onExit}
    >
      <div
        className={toastrClasses}
        onMouseDown={tap}
        onMouseMove={drag}
        onMouseUp={release}
        onTouchStart={tap}
        onTouchMove={drag}
        onTouchEnd={release}
        ref={rootContainerRef}
        onClick={onClick}
      >
        {toastrStyle}
        <div>
          {profileImage}
          <div className="bb-toast__text">
            <p className="bb-toast__text__heading">{text}</p>
            <p className="bb-toast__text__description">{description}</p>
          </div>
        </div>
        <button
          className="bb-toast__close"
          onClick={(e) => {
            e.stopPropagation();
            setOpen(false);
          }}
        >
          <Close />
        </button>
      </div>
    </CSSTransition>
  );
};

const ForwardedToastr = forwardRef(Toastr);

export default ForwardedToastr;
