import Search from 'assets/svgs/Search';
import classNames from 'classnames';
import InfiniteScroll from 'components/InfiniteScroll';
import { UserType } from 'models/User';
import confirm from 'modules/confirm';
import { Input, Loader } from 'ncoded-component-library';
import React, { useCallback, useContext, useState } from 'react';
import FriendsContext from '../../providers/Friends/Friends.context';
import FriendShipCard from '../FriendShipCard';
import './FindUsers.styles.scss';

type FindUsersProps = {
  children?: React.ReactNode;
  className?: string;
};

const FindUsers: React.FC<FindUsersProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-find-users', className);

  const [loadingIndex, setLoadingIndex] = useState<number>(0);

  const {
    loading,
    loadingGlobalUsers,
    globalUsers,
    globalUsersSearchString,
    setGlobalUsersSearchString,
    onGlobalUsersScroll,
    sendFriendRequest,
    deleteFriendship,
    deleteFriendRequest,
  } = useContext(FriendsContext);

  const handleOnAddFriend = useCallback(
    async (user: UserType, index: number) => {
      setLoadingIndex(index);
      sendFriendRequest(user.Id);
    },
    [sendFriendRequest],
  );

  const handleOnUnfriend = useCallback(
    async (user: UserType, index: number) => {
      const { username } = user;

      if (
        await confirm.simpleConfirmation({
          title: 'Unfriend user',
          description: `Are you sure you want to unfriend ${username}?`,
        })
      ) {
        setLoadingIndex(index);
        deleteFriendship(user.friends[0]?.Id);
      }
    },
    [deleteFriendship],
  );

  const handleOnDeleteRequest = useCallback(
    async (user: UserType, index: number) => {
      setLoadingIndex(index);
      deleteFriendRequest(user.friendsRequests[0]?.Id);
    },
    [deleteFriendRequest],
  );

  return (
    <InfiniteScroll className={classes} onScroll={onGlobalUsersScroll}>
      <Input
        prefixNode={<Search />}
        placeholder="Search..."
        value={globalUsersSearchString}
        onChange={(e) => setGlobalUsersSearchString(e.target.value)}
      />
      {globalUsers.map((user, index) => {
        return (
          <div key={index}>
            {user.friendshipStatus === 'FRIEND' && (
              <FriendShipCard
                key={index}
                loading={loading && loadingIndex === index}
                to={`profile/${user.Id}`}
                text="unfriend"
                buttonStyle="secondary"
                user={user}
                onAction={() => handleOnUnfriend(user, index)}
              />
            )}

            {user.friendshipStatus === 'NOT_FRIEND' && (
              <FriendShipCard
                key={index}
                loading={loading && loadingIndex === index}
                to={`profile/${user.Id}`}
                text="add friend"
                buttonStyle="primary"
                user={user}
                onAction={() => handleOnAddFriend(user, index)}
              />
            )}

            {user.friendshipStatus === 'PENDING' && (
              <FriendShipCard
                key={index}
                loading={loading && loadingIndex === index}
                to={`profile/${user.Id}`}
                text="pending"
                buttonStyle="secondary"
                user={user}
                onAction={() => handleOnDeleteRequest(user, index)}
              />
            )}
          </div>
        );
      })}
      {loadingGlobalUsers && <Loader />}
    </InfiniteScroll>
  );
};

export default FindUsers;
