import auth from './auth';
import fixture from './fixture';
import bets from './bets';
import outcomes from './outcomes';
import transactions from './transactions';
import user from './user';
import teams from './teams';
import chat from './chat';
import friends from './friends';
import notification from './notification';
import coins from './coins';
import referralCode from './referralCode';
import geolocation from './geolocation';

export default {
  auth,
  fixture,
  bets,
  outcomes,
  transactions,
  user,
  teams,
  chat,
  friends,
  notification,
  coins,
  referralCode,
  geolocation,
};
