import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { composeValidators, required } from 'services/validators';
import { Button } from 'ncoded-component-library';
import CalendarField from 'components/Fields/CalendarField';
import AnimatedField from 'components/animated/AnimatedField';
import FormWrapper from 'components/FormWrapper';

import './PersonalDetailsForm.styles.scss';

type PersonalDetailsFormProps = {};

const PersonalDetailsForm: React.FC<PersonalDetailsFormProps> = (props) => {
  const validateObject = useMemo(
    () => ({
      firstname: required('Firstname is required'),
      lastname: required('Lastname is required'),
      birthdate: composeValidators(required('Birthdate is required')),
    }),
    [],
  );

  return (
    <FormWrapper className="bb-personal-details">
      <div className="bb-personal-details__content">
        <Field
          label="firstname"
          name="firstName"
          component={AnimatedField}
          type="text"
          delay={500}
          duration={400}
          placeholder="Enter first name"
          validate={validateObject.firstname}
        />
        <Field
          label="lastname"
          placeholder="Enter last name"
          name="lastName"
          component={AnimatedField}
          type="text"
          delay={600}
          duration={400}
          validate={validateObject.lastname}
        />
        <Field
          name="birthdate"
          className="birthdate"
          label="Birth date"
          onlyPast
          component={AnimatedField}
          as={CalendarField}
          delay={700}
          duration={800}
          validate={validateObject.birthdate}
        />
      </div>
      <div className="bb-personal-details__footer">
        <Button type="submit">Next Step</Button>
      </div>
    </FormWrapper>
  );
};

export default PersonalDetailsForm;
