import React from 'react';
import NewBetProvider from './components/NewBetModal/providers/NewBet';
import MainPage from './MainPage.page';
import MainProvider from './providers/Main';
import NotificationProvider from './providers/Notification';
import TeamsProvider from './providers/Teams';

const MainPageWrapper = () => (
  <MainProvider>
    <NotificationProvider>
      <NewBetProvider>
        <TeamsProvider>
          <MainPage></MainPage>
        </TeamsProvider>
      </NewBetProvider>
    </NotificationProvider>
  </MainProvider>
);

export default MainPageWrapper;
