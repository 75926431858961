import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { TeamStatsType } from 'models/Team';
import { Loader } from 'ncoded-component-library';
import React, { useContext, useImperativeHandle } from 'react';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import StadiumContext from 'routers/MainRouter/pages/Stadium/providers/Stadium/Stadium.context';
import { ScrollableListFCRef } from '../../models/ScrollableList';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import classNames from 'classnames';
import utils from 'utils';

import './TeamStats.styles.scss';

type TeamStatsProps = {
  className?: string;
};

const TeamStats: React.ForwardRefRenderFunction<
  ScrollableListFCRef,
  TeamStatsProps
> = (props, ref) => {
  const { className } = props;
  const { selectedFixture } = useContext(StadiumContext);

  const {
    loading,
    items: currentTeamStats,
    onContainerScrolled,
  } = useInfinitePagination<TeamStatsType>({
    makeRequest: (currentPage: number) => {
      return api.teams
        .getH2hStatistics({
          firstTeamId: selectedFixture?.firstTeamId,
          secondTeamId: selectedFixture?.secondTeamId,
          page: currentPage,
          limit: 5,
        })
        .then(({ data }) => data);
    },
    mainDep: selectedFixture,
  });

  useImperativeHandle(ref, () => ({ onContainerScrolled }), [
    onContainerScrolled,
  ]);

  return (
    <div className={classNames('bb-team-stats', className)}>
      {loading && <Loader />}

      {currentTeamStats.length === 0 && (
        <EmptyPlaceholder title="There is no H2H statistic for this game!" />
      )}

      {currentTeamStats.map((teamStats, index) => {
        const results = teamStats.finalResult.split(' ');
        const teamWinner = parseInt(results[0]) > parseInt(results[2]) ? 1 : 2;
        return (
          <div key={index} className="bb-team-stats__data">
            <div className="bb-team-stats__fixture">
              <BetTeam
                {...teamStats.firstTeam}
                active={teamWinner === 1}
                key="first-team"
              />
              <div className="bb-team-stats__results">
                <p>result</p>
                <p>{`${teamStats.finalResult}`}</p>
              </div>
              <BetTeam
                {...teamStats.secondTeam}
                active={teamWinner === 2}
                key="second-team"
              />
            </div>
            <div>
              <p>event date</p>
              <p>{utils.toDateFormat(teamStats.eventDate.toString())}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default React.forwardRef(TeamStats);
