import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import RadioSlider from 'components/RadioSlider';
import { sportsRadioSlides } from '../MainPage/constants';
import { FixtureType } from 'models/Fixture';
import FixtureCard from './components/FixtureCard';
import ImageSlider from './components/ImageSlider';
import { SportType } from 'models/Sport';
import InfiniteScroll from 'components/InfiniteScroll';
import AppearAnimation from 'components/AppearAnimation';
import StadiumContext from './providers/Stadium/Stadium.context';
import FixtureDetails from './components/FixtureDetails';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button, Input, Loader } from 'ncoded-component-library';
import { slideImages } from './mockData';
import TimeFilterModal from './components/TimeFilterModal';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import GlowScroll from 'components/GlowScroll';
import Slider from 'assets/svgs/Slider';
import Search from 'assets/svgs/Search';
import {
  EASE_IN_OUT_CUBIC,
  sportIcons,
} from 'routers/MainRouter/services/constants';

import './Stadium.styles.scss';

type StadiumProps = {
  className?: string;
};

const Stadium: React.FC<StadiumProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-stadium', className);
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [rootContainer, rootContainerRef] =
    useCallbackRef<HTMLDivElement>(null);
  const [sliderContainer, sliderContainerRef] =
    useCallbackRef<HTMLDivElement>(null);

  const {
    loadingFixtures,
    fixtures,
    fixturesSearchString,
    selectedSport,
    selectedFixture,
    fixtureFilter,
    setFixtureFilter,
    setFixtureSearchString,
    setSelectedSport,
    setSelectedFixture,
    onFixturesScroll,
  } = useContext(StadiumContext);

  useEffect(() => {
    if (!rootContainer || !sliderContainer) return;

    selectedSport === 'all'
      ? rootContainer.scrollIntoView()
      : sliderContainer.scrollIntoView();
  }, [rootContainer, selectedSport, sliderContainer]);

  return (
    <GlowScroll className={classes}>
      {!selectedFixture && (
        <AppearAnimation duration={500} animationName="appear">
          <InfiniteScroll onScroll={onFixturesScroll}>
            <div className="bb-stadium__content" ref={rootContainerRef}>
              {loadingFixtures && <Loader />}
              <ImageSlider
                className="bb-stadium__content__image-slider"
                images={slideImages}
              />
              <p className="bb-stadium__content__heading">Upcoming games</p>
              <div
                className="bb-stadium__content__radio-slider"
                ref={sliderContainerRef}
              >
                <RadioSlider
                  slideSteps={sportsRadioSlides}
                  showValueOfRadioSlide
                  name="sports"
                  onChange={(value) => setSelectedSport(value as SportType)}
                />
              </div>
              {selectedSport !== 'all' && (
                <div className="bb-stadium__content__search">
                  <Input
                    prefixNode={<Search />}
                    placeholder="Search..."
                    value={fixturesSearchString}
                    onChange={(e) => setFixtureSearchString(e.target.value)}
                  />
                  <Button
                    styleType="secondary"
                    icon={Slider}
                    onClick={() => setIsFilterOpen(true)}
                  />
                </div>
              )}
              {fixtures.length === 0 && (
                <EmptyPlaceholder title="There are no games" />
              )}
              {fixtures.map((fixture: FixtureType, index: number) => {
                return (
                  <FixtureCard
                    key={index}
                    game={fixture}
                    sportIcon={sportIcons[fixture.sport]}
                    fixtureDate={fixture.startDate}
                    fixtureStatus={fixture.fixtureStatus}
                    fixtureResults={fixture.fixtureResults}
                    onClick={() => setSelectedFixture(fixture)}
                  />
                );
              })}
            </div>
          </InfiniteScroll>
        </AppearAnimation>
      )}

      {selectedFixture && (
        <AppearAnimation
          duration={600}
          animationName="moveRight"
          timingFunction={EASE_IN_OUT_CUBIC}
        >
          <FixtureDetails />
        </AppearAnimation>
      )}

      <TimeFilterModal
        open={isFilterOpen}
        filterData={fixtureFilter}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={(value) => setFixtureFilter(value)}
      />
    </GlowScroll>
  );
};

export default Stadium;
