import { Button } from 'ncoded-component-library';
import AnimatedField from 'components/animated/AnimatedField';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import {
  composeValidators,
  emailFormatValid,
  minLength,
  required,
} from 'services/validators';
import FormWrapper from 'components/FormWrapper';
import './UserDetailsForm.styles.scss';

type UserDetailsFormProps = {};

const UserDetailsForm: React.FC<UserDetailsFormProps> = () => {
  const validateObject = useMemo(
    () => ({
      username: composeValidators(
        required('Username is required'),
        minLength('Username must have more than 6 characters', 6),
      ),
      email: composeValidators(
        required('Email is required'),
        emailFormatValid('Email address is not valid'),
      ),
    }),
    [],
  );

  return (
    <FormWrapper className="bb-user-details">
      <div className="bb-user-details__content">
        <Field
          label="username"
          name="username"
          component={AnimatedField}
          delay={500}
          duration={400}
          placeholder="Enter username"
          validate={validateObject.username}
        />
        <Field
          label="email"
          name="email"
          component={AnimatedField}
          delay={600}
          duration={400}
          type="text"
          placeholder="Enter email address"
          validate={validateObject.email}
        />
      </div>
      <div className="bb-user-details__footer">
        <Button type="submit">Next Step</Button>
      </div>
    </FormWrapper>
  );
};

export default UserDetailsForm;
