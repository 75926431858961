import React, { useCallback } from 'react';
import { Form, Field } from 'react-final-form';

import useFormHelperElements from 'hooks/useFormHelperElements';
import { composeValidators, isEthAddress, required } from 'services/validators';
import InputField from 'components/Fields/InputField';
import Modal from 'components/Modal';
import { ConfirmationCoinData } from 'models/ConfirmationModals';
import { ConfirmModalProps } from '../ConfirmModal/ConfirmModal.component';

import './CoinConfirmation.styles.scss';

type ConfirmCoinModalProps = Omit<
  ConfirmModalProps,
  'onClose' | 'onConfirm'
> & {
  children?: React.ReactNode;
  content?: React.ReactNode;
  address?: string;
  onClose?: (data: ConfirmationCoinData) => void;
  onConfirm?: (data: ConfirmationCoinData) => void;
};

const CoinConfirmation: React.FC<ConfirmCoinModalProps> = (props) => {
  const {
    children,
    title,
    description,
    address,
    content,
    okText = 'OK',
    cancelText = 'Cancel',
    onConfirm,
    onClose,
    ...modalProps
  } = props;

  const { Cancel, Submit } = useFormHelperElements();

  const handleSubmit = useCallback(
    ({ address }: ConfirmationCoinData) => {
      onConfirm({ address, confirmed: true });
    },
    [onConfirm],
  );

  return (
    <Modal
      open
      renderAsPortal={false}
      className="bb-coin-confirm-popup"
      {...modalProps}
      title={<p>{title}</p>}
    >
      <Form
        onSubmit={handleSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <div className="bb-coin-confirm-popup__container">
              <Field
                name="address"
                defaultValue={address}
                component={InputField}
                label="wallet address"
                placeholder="3J98t1WpEZ73CNmQviecrnyiWrnqRhWNLy"
                validate={composeValidators(
                  required('Wallet address is required'),
                  isEthAddress('Address must be eth address'),
                )}
              />

              <div className="bb-coin-confirm-popup__data">
                For your security, if changed are made to a withdrawal address,
                withdrawals of that coin will be unavailable for 24 hours.
              </div>
            </div>

            <div className="bb-coin-confirm-popup__form-actions">
              <Cancel onClick={() => onClose({ confirmed: false, address })}>
                {cancelText}
              </Cancel>
              <Submit type="submit">{okText}</Submit>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default CoinConfirmation;
