import React, { useCallback, useMemo } from 'react';
import { Field, withTypes } from 'react-final-form';
import { Button } from 'ncoded-component-library';

import { FilterFeedBetData, FilterFeedBetTypes } from 'models/Bet';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import { sportsRadioSlides } from 'routers/MainRouter/pages/MainPage/constants';
import RadioSliderField from 'components/Fields/RadioSliderField';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import Animation from 'components/Animation';
import { filterSlides, sortSlides } from './filterSlides';
import ConditionalField from 'components/Fields/ConditionalField';
import useCallbackRef from 'hooks/useCallbackRef';
import useClickOutside from 'hooks/useClickOutside';

import './FeedFilterModal.styles.scss';

type FeedFilterFormType = Omit<FilterFeedBetData, 'filter'>;

type FeedFilterModalProps = {
  betData: FilterFeedBetData;
  onSubmit: (data: FilterFeedBetData) => void;
  onClear: () => void;
} & ModalProps;

const FeedFilterModal: React.FC<FeedFilterModalProps> = (props) => {
  const {
    betData: { show, sport, betsFilterType },
    open,
    onSubmit,
    onClear,
    ...modalRest
  } = props;

  const { Form } = withTypes<FeedFilterFormType>();
  const [container, containerRef] = useCallbackRef<HTMLFormElement>(null);

  const initialValues = useMemo(
    () =>
      ({
        sport,
        show,
        ...(show === 'PREVIOUS' && { betsFilterType }),
      } as FilterFeedBetData),
    [betsFilterType, show, sport],
  );

  const onFormSubmit = useCallback(
    (values: FilterFeedBetData) => {
      onSubmit?.(values);
      modalRest.onClose();
    },
    [onSubmit, modalRest],
  );

  useClickOutside({
    element: container,
    callback: () => modalRest.onClose(),
  });

  return (
    <Animation
      open={open}
      duration={600}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        className="bb-feed-filter-modal"
        {...modalRest}
        open
        onX={modalRest.onClose}
        title={<p>filters</p>}
      >
        <Form
          onSubmit={onFormSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, form, values }) => (
            <form onSubmit={handleSubmit} ref={containerRef}>
              <div className="bb-feed-filter-modal__container">
                <p className="bb-feed-filter-modal__container__title">sport</p>
                <Field
                  component={RadioSliderField}
                  slideSteps={sportsRadioSlides}
                  name="sport"
                  inputName="sport"
                  value={sport}
                  showValueOfRadioSlide
                />
                <p className="bb-feed-filter-modal__container__title">filter</p>
                <Field<FilterFeedBetTypes>
                  component={RadioSliderField}
                  slideSteps={filterSlides}
                  name="show"
                  inputName="show"
                  value={show}
                />
                {values.show === 'PREVIOUS' && (
                  <>
                    <p className="bb-feed-filter-modal__container__title">
                      status
                    </p>
                    <ConditionalField
                      shouldClear={values.show !== 'PREVIOUS'}
                      component={RadioSliderField}
                      slideSteps={sortSlides}
                      name="betsFilterType"
                      inputName="betsFilterType"
                      {...(!values.betsFilterType && { initialValue: 'WINS' })}
                    />
                  </>
                )}
              </div>
              <div className="bb-feed-filter-modal__footer">
                <Button
                  styleType="secondary"
                  onClick={() => {
                    form.reset();
                    onClear();
                    modalRest.onClose();
                  }}
                >
                  Clear Filter
                </Button>
                <Button styleType="primary" type="submit">
                  Apply Filter
                </Button>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default FeedFilterModal;
