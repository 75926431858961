import React, { useCallback } from 'react';
import classNames from 'classnames';

import api from 'api';
import utils from 'utils';
import Star from 'assets/svgs/Star';
import confirm from 'modules/confirm';
import { Button } from 'ncoded-component-library';
import { SendReferralCodeType } from 'models/ReferalCodes';

import './ReferralCodeOption.styles.scss';

type ReferralCodeOptionProps = {
  className?: string;
};

const ReferralCodeOption: React.FC<ReferralCodeOptionProps> = ({
  className,
}) => {
  const sendToFriendEmail = useCallback(async (data: SendReferralCodeType) => {
    try {
      await api.referralCode.sendReferralCode(data);

      utils.toastSuccess(
        `Successfully send referral code to friend ${data.email}.`,
      );
    } catch (e) {
      utils.toastError(e);
    }
  }, []);

  const handleOnClick = useCallback(async () => {
    try {
      const {
        data: { code, Id },
      } = await api.referralCode.getReferralCode();

      const { email, confirmed } = await confirm.referralCode({
        email: '',
        title: `Send referral code`,
        code: code,
      });

      if (confirmed) sendToFriendEmail({ email, referralCodeId: Id });
    } catch (e) {
      utils.toastError(e);
    }
  }, [sendToFriendEmail]);

  return (
    <Button
      className={classNames('bb-referral-code-option', className)}
      styleType="secondary"
      onClick={handleOnClick}
      icon={<Star />}
      iconPosition="right"
    >
      Refer Your Friends
    </Button>
  );
};

export default ReferralCodeOption;
