import { TeamData } from 'models/Team';

export const mockTeams = [
  {
    team: {
      logoUrl:
        'https://s.yimg.com/cv/apiv2/default/nba/20181211/500x500/hawks_wbg.png',
      teamName: 'Atlanta Hawks',
    },
    teamId: '0',
    userId: '1',
    wins: 3,
    loses: 4,
    favorites: true,
    winLosePercentage: 42.9,
  },
  {
    team: {
      logoUrl:
        'https://s.yimg.com/cv/apiv2/default/nba/20181211/500x500/hawks_wbg.png',
      teamName: 'Atlanta Hawks',
    },
    teamId: '0',
    userId: '1',
    wins: 3,
    loses: 4,
    favorites: true,
    winLosePercentage: 42.9,
  },
  {
    team: {
      logoUrl:
        'https://s.yimg.com/cv/apiv2/default/nba/20181211/500x500/hawks_wbg.png',
      teamName: 'Atlanta Hawks',
    },
    teamId: '0',
    userId: '1',
    wins: 3,
    loses: 4,
    favorites: true,
    winLosePercentage: 42.9,
  },
] as Array<TeamData>;

export const mockPlayers = [
  {
    playerName: 'Vince Carter',
    playerUrl:
      'https://www.pngfind.com/pngs/m/625-6257528_vince-carter-magic-vince-carter-transparent-background-hd.png',
    playerNumber: 15,
    playerPosition: 'Guard',
  },
  {
    playerName: 'Vince Carter',
    playerUrl:
      'https://www.pngfind.com/pngs/m/625-6257528_vince-carter-magic-vince-carter-transparent-background-hd.png',
    playerNumber: 15,
    playerPosition: 'Guard',
  },
  {
    playerName: 'Vince Carter',
    playerUrl:
      'https://www.pngfind.com/pngs/m/625-6257528_vince-carter-magic-vince-carter-transparent-background-hd.png',
    playerNumber: 15,
    playerPosition: 'Guard',
  },
  {
    playerName: 'Vince Carter',
    playerUrl:
      'https://www.pngfind.com/pngs/m/625-6257528_vince-carter-magic-vince-carter-transparent-background-hd.png',
    playerNumber: 15,
    playerPosition: 'Guard',
  },
  {
    playerName: 'Vince Carter',
    playerUrl:
      'https://www.pngfind.com/pngs/m/625-6257528_vince-carter-magic-vince-carter-transparent-background-hd.png',
    playerNumber: 15,
    playerPosition: 'Guard',
  },
];
