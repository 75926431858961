import { BetData } from 'models/Bet';
import { SportType } from 'models/Sport';
import { createContext } from 'react';
import utils from 'utils';

type BetContextProps = {
  loadingPublicBets: boolean;
  loadingBuddyBets: boolean;
  buddyBets: Array<BetData>;
  publicBets: Array<BetData>;
  selectedSport: SportType | 'all';
  activeRadioIndex: number;
  setPublicBets: React.Dispatch<React.SetStateAction<BetData[]>>;
  setBuddyBets: React.Dispatch<React.SetStateAction<BetData[]>>;
  setActiveSource: (value: number) => void;
  setSelectedSport: (value: SportType) => void;
  onBuddyBetsScroll: () => void;
  onPublicBetsScroll: () => void;
};

export default createContext<Partial<BetContextProps>>({
  loadingBuddyBets: false,
  loadingPublicBets: false,
  buddyBets: [],
  publicBets: [],
  activeRadioIndex: 0,
  selectedSport: null,
  setBuddyBets: utils.noop,
  setPublicBets: utils.noop,
  setActiveSource: utils.noop,
  setSelectedSport: utils.noop,
  onBuddyBetsScroll: utils.noop,
  onPublicBetsScroll: utils.noop,
});
