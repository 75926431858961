import React, { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { MainRoute } from 'routers/MainRouter/routes';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import utils from 'utils';
import NotificationContext from 'routers/MainRouter/pages/MainPage/providers/Notification/Notification.context';
import './NavigationTab.styles.scss';

type NavigationTabProps = {
  className?: string;
} & MainRoute;

const NavigationTab: React.FC<NavigationTabProps> = (props) => {
  const { className, label, path, icon } = props;

  const classes = classNames(['bb-navigation-tab', className]);

  const { userData } = useContext(MainContext);
  const { unreadMessages } = useContext(NotificationContext);

  const isMenuTab = useMemo(() => path === '/menu', [path]);
  const isChatTab = useMemo(() => path === '/chat', [path]);

  return (
    <NavLink
      activeClassName="bb-navigation-tab--active"
      className={classes}
      to={path as string}
    >
      <div>
        {utils.renderIcon(icon)}
        {isMenuTab && userData?.numberOfUnreadNotifications > 0 && (
          <div className="bb-navigation-tab__dot" />
        )}
        {isChatTab && unreadMessages > 0 && (
          <div className="bb-navigation-tab__dot" />
        )}
      </div>
      <span>{label}</span>
    </NavLink>
  );
};

export default NavigationTab;
