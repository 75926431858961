import React from 'react';
import classNames from 'classnames';

import utils from 'utils';
import { LEAGUES, LeagueType } from 'models/Sport';

import './GameDetails.styles.scss';

type GameDetailsProps = {
  className?: string;
  sport: string;
  league: LeagueType;
  gameDate: Date;
};

const GameDetails: React.FC<GameDetailsProps> = (props) => {
  const { className, sport, league, gameDate } = props;

  return (
    <div className={classNames('bb-game-details', className)}>
      <p className="bb-game-details__sport">{sport}</p>
      <div className="bb-game-details__time">
        <p className="bb-game-details__time__hour">
          {utils.toHourOneDigitMinFormat(gameDate?.toString())}
        </p>
        <p className="bb-game-details__time__date">
          {utils.toMonthFormat(gameDate?.toString())}
        </p>
      </div>
      <p className="bb-game-details__league">{LEAGUES[league]}</p>
    </div>
  );
};

export default GameDetails;
