import { createContext } from 'react';

import utils from 'utils';
import { FixtureType } from 'models/Fixture';
import { SportType } from 'models/Sport';
import { OutcomeDataFiledProps } from '../../components/OutcomeCard/OutcomeCard.component';
import { BetPrivacy, BetType } from 'models/Bet';

export type InitialFixtureData = {
  sportCategory: SportType;
  selectedGame: FixtureType;
  outcomes?: Array<OutcomeDataFiledProps>;
  betAmount?: number;
  betOdd?: number;
  publishSettings?: BetPrivacy;
  shareWithFriend?: boolean;
  betType?: BetType;
};

type NewBetContextProps = {
  initialFixtureData: InitialFixtureData;
  setInitialFixtureData: React.Dispatch<
    React.SetStateAction<InitialFixtureData>
  >;
  isBetEditing: React.MutableRefObject<boolean>;
  editingBetId: React.MutableRefObject<string>;
};

export default createContext<Partial<NewBetContextProps>>({
  initialFixtureData: null,
  setInitialFixtureData: utils.noop,
  isBetEditing: null,
  editingBetId: null,
});
