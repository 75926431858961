import React, { useCallback, useContext, useMemo } from 'react';
import { useFormState } from 'react-final-form';
import classNames from 'classnames';
import BetTeam from '../../components/BetTeam';
import { OutcomeData } from 'models/Outcome';
import FormWrapper from 'components/FormWrapper';
import useFormHelperElements from 'hooks/useFormHelperElements';
import utils from 'utils';
import { OutcomeDataFiledProps } from '../../components/OutcomeCard/OutcomeCard.component';
import UserList from 'components/UserList';
import ShowOutcomeCard from 'components/ShowOutcomes/components/ShowOutcomeCard';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import { FixtureType } from 'models/Fixture';
import { LEAGUES } from 'models/Sport';
import NewBetContext from '../../providers/NewBet/NewBet.context';

import './ConfirmStep.styles.scss';

const ConfirmStep: React.FC = () => {
  const {
    values: { betAmount, betOdd, outcomes, selectedGame, sessions },
  } = useFormState();

  const {
    firstTeam,
    secondTeam,
    startDate: fixtureStartDate,
    league,
  } = selectedGame as FixtureType;

  const classes = classNames('bb-confirm-bet', classNames);

  const { Submit, ErrorMessage } = useFormHelperElements();

  const { isBetEditing } = useContext(NewBetContext);

  const mapOutcomesData = useCallback(
    (outcomes: Array<OutcomeDataFiledProps>) => {
      return outcomes
        .filter((outcome: OutcomeDataFiledProps) => outcome.active)
        .map((outcome: OutcomeDataFiledProps) => {
          return outcome.data;
        }) as Array<OutcomeData>;
    },
    [],
  );

  const outcomeData = useMemo(
    () => mapOutcomesData(outcomes)[0],
    [mapOutcomesData, outcomes],
  );

  const startDate = useMemo(
    () => (
      <>
        <span>{`${utils.toDateFormat(fixtureStartDate.toString())}`}</span>
        <span>{`${utils.toHourMinFormat(fixtureStartDate.toString())}`}</span>
      </>
    ),
    [fixtureStartDate],
  );

  return (
    <FormWrapper className={classes} id="betModalForm">
      <div className="bb-confirm-bet__container">
        <StandardAppearAnimation delay={100}>
          <div className="bb-confirm-bet__teams">
            <BetTeam
              {...firstTeam}
              key="first-team"
              active={outcomeData.team === 1}
            />
            <div className="bb-confirm-bet__teams__details">
              <p className="bb-confirm-bet__teams__details--date">
                {startDate}
              </p>
              <p className="bb-confirm-bet__teams__details--vs">VS</p>
              <p className="bb-confirm-bet__teams__details--info">
                {LEAGUES[league]}
              </p>
            </div>
            <BetTeam
              {...secondTeam}
              key="second-team"
              active={outcomeData.team === 2}
            />
          </div>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={200}>
          <hr />
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={300}>
          <ShowOutcomeCard
            className="bb-confirm-bet__show-outcomes"
            outcomeData={outcomeData}
            showTeamLogo={false}
            defaultOpen={false}
            fixture={selectedGame}
          />
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={400}>
          <div className="bb-confirm-bet__bet">
            <p>your bet</p>
            <p>{`$${betAmount.toFixed(2)}`}</p>
          </div>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={500}>
          <hr />
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={600}>
          <div className="bb-confirm-bet__bet">
            <p>to return</p>
            <p>{`$${(betAmount * betOdd).toFixed(2)}`}</p>
          </div>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={700}>
          <hr />
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={800}>
          <div className="bb-confirm-bet__bet">
            <p>bet odds</p>
            <p>{`${Number(betOdd)?.toFixed(2)}`}</p>
          </div>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={900}>
          <hr />
        </StandardAppearAnimation>

        {sessions?.length > 0 && (
          <StandardAppearAnimation delay={1000}>
            <UserList
              type="sessions"
              sessions={sessions}
              info={sessions?.length}
            />
          </StandardAppearAnimation>
        )}
      </div>
      <div className="bb-confirm-bet__footer">
        <Submit>{isBetEditing.current ? 'Save' : 'Publish Bet'}</Submit>
        <ErrorMessage />
      </div>
    </FormWrapper>
  );
};

export default ConfirmStep;
