import React, { useMemo } from 'react';
import classNames from 'classnames';
import { BetData } from 'models/Bet';
import utils from 'utils';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import UserImage from 'components/UserImage';
import { useHistory } from 'react-router';
import { LEAGUES } from 'models/Sport';
import { FixtureType, FIXTURE_STATUSES } from 'models/Fixture';

import './BetChatCard.styles.scss';

type BetChatCardProps = {
  className?: string;
  active?: boolean;
  bet?: BetData;
  game?: FixtureType;
  fixtureDate?: Date;
};

const BetChatCard: React.FC<BetChatCardProps> = (props) => {
  const { className, active, bet } = props;

  const history = useHistory();

  const {
    Id: betId,
    user,
    playersCount,
    bettingAmount,
    totalBetAmount,
    createdAt,
    fixture: {
      firstTeam,
      secondTeam,
      startDate: fixtureStartDate,
      league,
      fixtureStatus,
      sport,
    },
  } = bet ?? {};

  const classes = classNames(
    'bb-betchat-card',
    { 'bb-betchat-card--active': active },
    className,
  );

  const firstTeamLogo = useMemo(
    () => (firstTeam.logoUrl ? firstTeam.logoUrl : firstTeam.publicTeamLogoUrl),
    [firstTeam],
  );

  const secondTeamLogo = useMemo(
    () =>
      secondTeam.logoUrl ? secondTeam.logoUrl : secondTeam.publicTeamLogoUrl,
    [secondTeam],
  );

  const bettingTicketInfo = useMemo(
    () =>
      fixtureStatus === FIXTURE_STATUSES.FINISHED ? (
        `${FIXTURE_STATUSES.FINISHED}`
      ) : fixtureStatus === FIXTURE_STATUSES.NOT_STARTED_YET ? (
        <>
          <span>{`${utils.toDateFormat(fixtureStartDate.toString())} `}</span>
          <span>{`${utils.toHourMinFormat(fixtureStartDate.toString())}`}</span>
        </>
      ) : fixtureStatus === FIXTURE_STATUSES.IN_PROGRESS ? (
        `Game In Progress`
      ) : null,
    [fixtureStartDate, fixtureStatus],
  );

  return (
    <div className={classes}>
      <div className="bb-betchat-card__header">
        <div className="bb-betchat-card__header__user">
          <UserImage userId={user?.Id} src={user?.profileImageUrl} />
          <div className="bb-betchat-card__header__user-info">
            <p>{`${user?.username}`}</p>
            <p>{`${utils.toHourMinFormat(createdAt.toString())}`}</p>
          </div>
        </div>
        <p className="bb-betchat-card__header__betting-ticket-info">
          {bettingTicketInfo}
        </p>
      </div>

      <div
        className="bb-betchat-card__content"
        onClick={() => history.push(`/bets?source=public-bets&betId=${betId}`)}
      >
        <div className="bb-betchat-card__content__details">
          <p className="bb-betchat-card__content__details--sport">{sport}</p>
          <div className="bb-betchat-card__content__details--time">
            <p>{utils.toHourOneDigitMinFormat(fixtureStartDate?.toString())}</p>
            <p>{utils.toMonthFormat(fixtureStartDate?.toString())}</p>
          </div>
          <p className="bb-betchat-card__content__details--league">
            {LEAGUES[league]}
          </p>
        </div>
        <div className="bb-betchat-card__content__teams">
          <BetTeam
            key="first-team"
            teamName={firstTeam.teamName}
            logoUrl={firstTeamLogo}
          />
          <BetTeam
            key="second-team"
            teamName={secondTeam.teamName}
            logoUrl={secondTeamLogo}
          />
        </div>
      </div>

      <div className="bb-betchat-card__footer">
        <div>
          <p>{playersCount}</p>
          <p>players</p>
        </div>
        <div>
          <p>{`$${totalBetAmount.toFixed(0)}`}</p>
          <p>total</p>
        </div>
        <div>
          <p>{`$${bettingAmount.toFixed(0)}`}</p>
          <p>user bet</p>
        </div>
      </div>
    </div>
  );
};

export default BetChatCard;
