import socketService, {
  socketEventNames,
  SocketService,
} from './socketService';
import io from 'socket.io-client';
import env from '../../env';
import credentialsService from 'services/credentialsService';

function connect(this: SocketService) {
  return new Promise((resolve, reject) => {
    this.socket = io.connect(`${env.SERVER_ENDPOINT}`);

    Object.values(socketEventNames).forEach((event) => {
      this.socket.on(event, (data: any) => {
        this.consumers[event]?.forEach((cb) => cb(data));
      });
    });

    this.socket.on('connect', () => {
      resolve(this.socket);
      this.sendEvent(socketEventNames.AUTH, credentialsService.token);
    });

    this.socket.on('disconnect', () => {
      console.warn('Client disconnected');
      reject({
        message: 'Not authorized to send messages',
      });
    });
  });
}

export default socketService(connect);
