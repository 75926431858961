import BaseBall from 'assets/svgs/sports/BaseBall';
import BaskettBall from 'assets/svgs/sports/BaskettBall';
import Boxing from 'assets/svgs/sports/Boxing';
import Hockey from 'assets/svgs/sports/Hockey';
import PingPong from 'assets/svgs/sports/PingPong';
import Rugby from 'assets/svgs/sports/Rugby';
import Soccer from 'assets/svgs/sports/Soccer';
import Tennis from 'assets/svgs/sports/Tennis';
import VolleyBall from 'assets/svgs/sports/VolleyBall';
import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';
import { Sport } from 'models/Sport';

export const sportsRadioSlides = [
  {
    element: 'All',
    value: 'all',
    label: 'All',
  },
  {
    element: BaskettBall,
    value: 'BASKETBALL',
    label: 'Basketball',
  },
  {
    element: Hockey,
    value: 'ICE_HOCKEY',
    label: 'Ice hockey',
  },
  {
    element: Rugby,
    value: 'AMERICAN_FOOTBALL',
    label: 'American football',
  },
  {
    element: Soccer,
    value: 'SOCCER',
    label: 'Soccer',
  },
  {
    element: BaseBall,
    value: 'BASEBALL',
    label: 'Baseball',
  },
  {
    element: Tennis,
    value: 'TENNIS',
    label: 'Tennis',
  },
  {
    element: VolleyBall,
    value: 'VOLLEYBALL',
    label: 'Volleyball',
  },
  {
    element: Boxing,
    value: 'BOXING',
    label: 'Boxing',
  },
  {
    element: PingPong,
    value: 'PING_PONG',
    label: 'Ping pong',
  },
] as Array<RadioSlideType<string>>;

export const betAmountSlides = [
  {
    element: '$5',
    value: 5,
  },
  {
    element: '$10',
    value: 10,
  },
  {
    element: '$25',
    value: 25,
  },
  {
    element: '$50',
    value: 50,
  },
  {
    element: 'Other',
    value: 1,
  },
] as Array<RadioSlideType<number>>;

export const outcomeSlides = [
  {
    element: '1 Outcome',
    value: 1,
  },
  {
    element: '3 Outcome',
    value: 3,
  },
  {
    element: '5 Outcome',
    value: 5,
  },
] as Array<RadioSlideType<number>>;

export const sportCategories = [
  {
    sportIcon: BaskettBall,
    value: 'Basketball',
    sport: 'BASKETBALL',
  },
  {
    sportIcon: Soccer,
    value: 'Soccer',
    sport: 'SOCCER',
  },
  {
    sportIcon: BaseBall,
    value: 'Baseball',
    sport: 'BASEBALL',
  },
  {
    sportIcon: Hockey,
    value: 'Ice Hockey',
    sport: 'ICE_HOCKEY',
  },
  {
    sportIcon: Rugby,
    value: 'American Football',
    sport: 'AMERICAN_FOOTBALL',
  },
  // {
  //   sportIcon: Tennis,
  //   value: 'Tennis',
  //   sport: 'TENNIS',
  // // },
  // {
  //   sportIcon: VolleyBall,
  //   value: 'Volleyball',
  //   sport: 'VOLLEYBALL',
  // },
  // {
  //   sportIcon: Boxing,
  //   value: 'Boxing',
  //   sport: 'BOXING',
  // },
  // {
  //   sportIcon: PingPong,
  //   value: 'Pingpong',
  //   sport: 'PINGPONG',
  // },
] as Array<Sport>;
