import Axios, { AxiosRequestConfig } from 'axios';
import credentialsService from 'services/credentialsService';
import env from 'env';
import refreshTokenService from 'services/refreshTokenService';
import globalLogout from 'modules/globalLogout';
import bus, { AuthEventTypes } from 'modules/bus';

let refreshTimeout: NodeJS.Timeout | number;

const httpClient = Axios.create({
  baseURL: `${env.SERVER_ENDPOINT}`,
});

const setToken = (config: AxiosRequestConfig) => {
  const { token, registartionToken } = credentialsService;
  if ((token || registartionToken) && config.method !== 'OPTIONS') {
    config.headers.Authorization = `Bearer ${token || registartionToken}`;
  }
};

const refresh = async (config: AxiosRequestConfig) => {
  await new Promise<void>((resolve, reject) => {
    function onRefreshTokenSuccess() {
      setToken(config);
      removeHandlers();
      resolve();
    }

    function onRefreshTokenFail() {
      reject(Error('Could not refresh token'));
      removeHandlers();
    }

    const removeSuccessListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_SUCCESS,
      onRefreshTokenSuccess,
    );
    const removeFailListener = bus.addEventListener(
      AuthEventTypes.REFRESH_TOKEN_FAIL,
      onRefreshTokenFail,
    );

    function removeHandlers() {
      removeSuccessListener();
      removeFailListener();
    }

    if (!refreshTimeout) {
      refreshTimeout = setTimeout(() => {
        reject(Error('Could not refresh token'));
        removeHandlers();
      }, refreshTokenService.checkRefreshingTreshold);
    }
  });
};

httpClient.interceptors.request.use(
  async function (config) {
    // Do something before request is sent
    //If the header does not contain the token and the url not public, redirect to login

    setToken(config);
    if (refreshTokenService.refreshingAtm && !(config as any).isRefresh) {
      await refresh(config);
    }

    return config;
  },

  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(null, async (error) => {
  if (error.response && [401].includes(error.response.status)) {
    const { config } = error;

    if (config.skipRefresh || config.isRefresh) return;

    if (refreshTokenService.refreshingAtm) {
      await refresh(config);
      return httpClient(config);
    }

    try {
      await refreshTokenService.refreshToken();
      return httpClient(config);
    } catch (err) {
      error.preventDefault = true;
      console.log(err);
      globalLogout();
      clearTimeout(refreshTimeout as number);
    }
  }
  return Promise.reject(error);
});

export default httpClient;
