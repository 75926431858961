import React, { ReactNode, useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import { useForm, useFormState } from 'react-final-form';
import StepsContext from './providers/Steps/Steps.context';
import './FormWrapper.styles.scss';

type FormWrapperProps = {
  children?: ReactNode;
  className?: string;
  id?: string;
  onSubmit?: (values: Record<string, any>) => void;
};

const FormWrapper: React.FC<FormWrapperProps> = (props) => {
  const { submit } = useForm();

  const { children, className, id } = props;

  const { valid } = useFormState();
  const { setSubmitting } = useContext(StepsContext);

  const classes = useMemo(
    () => classNames(['bb-form-wrapper', className]),
    [className],
  );

  const handleSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      if (event) {
        // sometimes not true, e.g. React Native
        if (typeof event.preventDefault === 'function') {
          event.preventDefault();
        }
        if (typeof event.stopPropagation === 'function') {
          // prevent any outer forms from receiving the event too
          event.stopPropagation();
        }
      }

      if (valid) {
        setSubmitting(true);
      }

      submit();
    },
    [setSubmitting, submit, valid],
  );

  return (
    <form className={classes} onSubmit={handleSubmit} id={id}>
      {children}
    </form>
  );
};

export default FormWrapper;
