import React from 'react';
import { AxiosError } from 'axios';

import toastr from 'modules/toastr';
import { OutcomeData, OutcomeModel } from 'models/Outcome';
import { OutcomeDataFiledProps } from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/OutcomeCard/OutcomeCard.component';
import { BetData } from 'models/Bet';

function isTouchDevice(): boolean {
  const prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
  let mq = function (query: string) {
    return window.matchMedia(query).matches;
  };

  if ('ontouchstart' in window || 'DocumentTouch' in window) {
    return true;
  }

  const query = ['(', prefixes.join('touch-enabled),('), 'ncoded', ')'].join(
    '',
  );
  return mq(query);
}

export const parseStringValue = (value: any) =>
  /^\d+$/.test(value)
    ? +value
    : /^(true|false)$/.test(value)
    ? JSON.parse(value)
    : value;

export function convertFormDataToJSONObject(formData: FormData) {
  const obj: any = {};
  formData.forEach((val, key) => {
    const isArray = key.includes('[]') || key.includes('files');

    if (isArray) {
      const newKey = key.split('[]')[0];
      if (!obj[newKey]) obj[newKey] = [];
      obj[newKey].push(parseStringValue(val));
    } else {
      obj[key] = parseStringValue(val);
    }
  });
  return obj;
}

export const convertObjToFormData = (
  obj: Record<string, any>,
  formData = new FormData(),
  path = '',
) => {
  if (obj === undefined) return;

  for (const prop in obj) {
    const newPath = path ? `${path}[${prop}]` : prop;
    if (typeof obj[prop] !== 'object') {
      if (obj[prop] instanceof File) {
        const file: File = obj[prop];
        formData.append(newPath, file, file.name);
      } else {
        formData.append(newPath, obj[prop]);
      }
    } else if (obj[prop] === null) {
      formData.append(newPath, obj[prop]);
    } else {
      convertObjToFormData(obj[prop], formData, newPath);
    }
  }

  return formData;
};

export function debounce(func: Function, wait: number, isImmediate = false) {
  let timeout: NodeJS.Timeout | null;
  return function (...args: any[]) {
    const later = () => {
      timeout = null;
      if (!isImmediate) {
        func(...args);
      }
    };
    const callNow = isImmediate && !timeout;
    if (timeout) clearTimeout(timeout);
    timeout = global.setTimeout(later, wait);
    if (callNow) {
      func(...args);
    }
  };
}

function checkForZero(number: number): string {
  return number < 10 ? `0${number}` : `${number}`;
}

export function toHourMinFormat(dateStr: string) {
  const date = new Date(dateStr);

  var hours = date.getHours();
  var minutes = date.getMinutes();
  hours = hours ? hours : 12;
  var ampm = date.getHours() >= 12 ? 'PM' : 'AM';

  return `${checkForZero(((hours % 12) + 12) % 12)}:${checkForZero(
    minutes,
  )}${ampm}`;
}

export function toDateFormat(dateStr: string) {
  const date = new Date(dateStr);
  return `${checkForZero(date.getDate())} ${
    MONTHS[date.getMonth()]
  } ${date.getFullYear()}`;
}

export function toMonthFormat(dateStr: string) {
  const date = new Date(dateStr);
  return `${MONTHS[date.getMonth()]} ${checkForZero(
    date.getDate(),
  )}, ${date.getFullYear()}`;
}

export function toHourOneDigitMinFormat(dateStr: string) {
  const date = new Date(dateStr);
  return date.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });
}

export function calculatePastTime(dateStr: string) {
  const date = new Date(dateStr);
  const currentDate = new Date();

  const timeDiff = Math.abs((date.getTime() - currentDate.getTime()) / 1000);

  return timeDiff > 60 * 60 * 24 * 30
    ? `${(timeDiff / (60 * 60 * 24 * 30)).toFixed(0)} month`
    : timeDiff > 60 * 60 * 24
    ? `${(timeDiff / (60 * 60 * 24)).toFixed(0)} day`
    : timeDiff > 60 * 60
    ? `${(timeDiff / (60 * 60)).toFixed(0)} hour`
    : timeDiff > 60
    ? `${(timeDiff / 60).toFixed(0)} min`
    : `${timeDiff.toFixed(0)} sec`;
}

export function getUTCDateFormat() {
  const date = new Date();
  let year = date.getUTCFullYear();
  let month = (1 + date.getUTCMonth()).toString().padStart(2, '0');
  let day = date.getUTCDate().toString().padStart(2, '0');

  return month + '/' + day + '/' + year;
}

function toastStringError(e: string) {
  toastr.showError({
    text: 'Error occured',
    description: e,
    type: 'Error',
  });
}

function toastError(e: AxiosError | any) {
  if ((e as any).preventDefault) return;
  toastr.showError({
    text: 'Error occured',
    description: getStringError(e),
    type: 'Error',
  });
}

function toastSuccess(description: string) {
  toastr.show({
    text: 'Success',
    type: 'Success',
    description,
    disappearAfter: 5000,
  });
}

function noop(): any {}

function camelCaseToDashCase(camelCase: string) {
  return camelCase.replace(/([A-Z]+)/g, (text) => `-${text.toLowerCase()}`);
}

function jsonToCssVariables(object: Object) {
  return Object.entries(object).reduce(
    (styleString, [key, value]) =>
      `${styleString} --${camelCaseToDashCase(key)}: ${value};`,
    '',
  );
}

function getStringError(error: AxiosError) {
  if (!error?.response?.data) return "Couldn't parse error";

  let {
    response: {
      data: { message },
    },
  } = error;
  message =
    typeof message === 'string'
      ? message
      : typeof message.errors[0].message === 'string'
      ? message.errors[0].message
      : 'Invalid data';
  return message;
}

function renderIcon(item: React.ReactNode | string) {
  if (typeof item === 'string') return item;
  else {
    const IconToRender = item as React.FC;
    return <IconToRender />;
  }
}

function getDotedNumber(value: string) {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function copyToClipboard(element: HTMLInputElement) {
  element.select();
  document.execCommand('copy');
}

export function mapOutcomesData(
  outcomes: Array<OutcomeModel>,
  activeBet?: OutcomeData,
) {
  return outcomes.map(
    (outcome: OutcomeModel) =>
      ({
        type: outcome.type,
        subtypes: outcome.subtypes,
        periods: outcome.periods,
        ...(activeBet && outcome.type === activeBet.type
          ? {
              active: true,
              data: {
                handicap: activeBet.handicap,
                type: activeBet.type,
                subtype: activeBet.subtype,
                period: activeBet.period,
                limit: activeBet.limit,
                team: activeBet.team,
                bettingTip: activeBet.bettingTip,
              } as OutcomeData,
            }
          : {
              active: false,
              data: {
                handicap: 0,
                type: outcome.type,
                subtype: outcome.subtypes[0],
                period: outcome.periods[0],
              } as OutcomeData,
            }),
      } as OutcomeDataFiledProps),
  );
}

export const convertXToYFilterFeedBetTypes = (data: any): BetData => ({
  Id: data.Id || data.bets[0].Id,
  fixture: data.fixture || data.bets[0].fixture,
  user: data.user || data.bets[0].user,
  userId: data.userId || data.bets[0].userId,
  betOdd: data.betOdd || data.bets[0].betOdd,
  betOutcomes: data.betOutcomes || data.bets[0].betOutcomes,
  bettingAmount: data.bettingAmount || data.bets[0].bettingAmount,
  createdAt: data.createdAt,
  totalBetAmount: data.totalBetAmount || data.bets[0].totalBetAmount,
  betPrivacy: data.betPrivacy || data.bets[0].betPrivacy,
  betTips: data.betTips || data.bets[0].betTips,
});

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export default {
  noop,
  getStringError,
  toastError,
  toastSuccess,
  camelCaseToDashCase,
  isTouchDevice,
  parseStringValue,
  convertObjToFormData,
  convertFormDataToJSONObject,
  debounce,
  jsonToCssVariables,
  toHourMinFormat,
  toDateFormat,
  toMonthFormat,
  calculatePastTime,
  getUTCDateFormat,
  getDotedNumber,
  checkForZero,
  renderIcon,
  copyToClipboard,
  toHourOneDigitMinFormat,
  toastStringError,
  convertXToYFilterFeedBetTypes,
};
