import { FriendshipType } from 'models/Friends';
import { UserGlobalType } from 'models/User';
import { createContext } from 'react';
import utils from 'utils';

type FriendsContextProps = {
  loading: boolean;
  loadingMyFriends: boolean;
  loadingFriendRequests: boolean;
  loadingGlobalUsers: boolean;

  myFriendSearchString: string;
  friendRequestSearchString: string;
  globalUsersSearchString: string;
  myFriends: Array<FriendshipType>;
  friendRequests: Array<FriendshipType>;
  globalUsers: Array<UserGlobalType>;

  setLoading: (value: boolean) => void;
  setMyFriendSearchString: (value: string) => void;
  setFriendRequestSearchString: (value: string) => void;
  setGlobalUsersSearchString: (value: string) => void;

  setMyFriends: (values: Array<FriendshipType>) => void;
  setFriendRequests: (values: Array<FriendshipType>) => void;
  setGlobalUsers: (values: Array<UserGlobalType>) => void;

  sendFriendRequest: (value: string) => Promise<FriendshipType>;
  acceptFriendRequest: (value: FriendshipType) => Promise<FriendshipType>;
  deleteFriendship: (value: string) => void;
  deleteFriendRequest: (value: string) => void;

  onMyFriendsScroll: () => void;
  onFriendRequestsScroll: () => void;
  onGlobalUsersScroll: () => void;
};

export default createContext<Partial<FriendsContextProps>>({
  loading: false,
  loadingMyFriends: false,
  loadingFriendRequests: false,
  myFriendSearchString: '',
  friendRequestSearchString: '',
  globalUsersSearchString: '',
  myFriends: [],
  friendRequests: [],

  setLoading: utils.noop,
  setMyFriendSearchString: utils.noop,
  setFriendRequestSearchString: utils.noop,
  setGlobalUsersSearchString: utils.noop,

  setMyFriends: utils.noop,
  setFriendRequests: utils.noop,
  setGlobalUsers: utils.noop,

  sendFriendRequest: utils.noop,
  deleteFriendship: utils.noop,
  acceptFriendRequest: utils.noop,

  onMyFriendsScroll: utils.noop,
  onFriendRequestsScroll: utils.noop,
  onGlobalUsersScroll: utils.noop,
});
