import React, { useContext, useState } from 'react';
import classNames from 'classnames';
import { myBetsSlides } from 'routers/MainRouter/pages/MainPage/components/BetFilterModal/filterSlides';
import { Button, Loader } from 'ncoded-component-library';
import InfiniteScroll from 'components/InfiniteScroll';
import { FilterBetData, ShowBetTypesEnhanced, UserBetData } from 'models/Bet';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import ShareModal from 'routers/MainRouter/pages/MainPage/components/ShareModal';
import AppearAnimation from 'components/AppearAnimation';
import BetFilterModal from 'routers/MainRouter/pages/MainPage/components/BetFilterModal';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import UserBetCard from 'components/UserBetCard';
import RadioSlider from 'components/RadioSlider';
import GlowScroll from 'components/GlowScroll';
import Slider from 'assets/svgs/Slider';

import './MyBets.styles.scss';

type MyBetsProps = {
  className?: string;
};

const MyBets: React.FC<MyBetsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-mybets', className);

  const {
    loadingBets,
    userBets,
    filterBetsData,
    setFilterBetsData,
    filterShowBetsData,
    setFilterShowBetsData,
    onUserBetsScroll,
  } = useContext(MainContext);

  const [shareBetId, setShareBetId] = useState<string>();
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);

  return (
    <>
      <BetFilterModal
        open={isFilterOpen}
        betData={filterBetsData}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={(value: FilterBetData) => setFilterBetsData({ ...value })}
      />

      <ShareModal
        open={isShareOpen}
        shareContentId={shareBetId}
        onClose={() => setIsShareOpen(false)}
      />

      <GlowScroll className={classes}>
        <AppearAnimation duration={150} animationName="appear">
          <div className="bb-mybets__container">
            {loadingBets && <Loader />}

            <div className="bb-mybets__header">
              <p className="bb-mybets__header__title">my bets</p>
              <Button
                styleType="secondary"
                icon={Slider}
                onClick={() => setIsFilterOpen(true)}
              />
            </div>

            <RadioSlider
              className="bb-mybets__slider"
              slideSteps={myBetsSlides}
              name="bettype"
              value={filterShowBetsData}
              onChange={(value) => {
                setFilterShowBetsData(value as ShowBetTypesEnhanced);
              }}
            />

            <InfiniteScroll onScroll={onUserBetsScroll}>
              {userBets.length === 0 && (
                <EmptyPlaceholder
                  title="There aren't any bets yet!"
                  description="Bets will be listed here after you create or accept someone else's bet."
                />
              )}

              {userBets?.map((bet: UserBetData) => (
                <UserBetCard
                  key={bet.Id}
                  betData={bet}
                  onShare={() => {
                    setShareBetId(bet.betId);
                    setIsShareOpen(true);
                  }}
                />
              ))}
            </InfiniteScroll>
          </div>
        </AppearAnimation>
      </GlowScroll>
    </>
  );
};

export default MyBets;
