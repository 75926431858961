import React, {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useFormState } from 'react-final-form';
import StepsContext, { Step, StepsContextProps } from './Steps.context';

type StepsProps = {
  steps: Step[];
  children?: ReactNode;
};

export type StepProviderRef = StepsContextProps;

declare global {
  interface Window {
    submittingForm?: boolean;
    nextStep?: boolean;
    prevStep?: boolean;
  }
}

const Steps: React.ForwardRefRenderFunction<StepProviderRef, StepsProps> = (
  props,
  ref,
) => {
  const { steps, children } = props;
  const [currentStep, setCurrentStep] = useState(0);

  const previousStep = useRef<number>();

  const { values } = useFormState();

  const validateStepFunction = useMemo(
    () => steps[currentStep].validateFn,
    [currentStep, steps],
  );

  const updateCurrentStep = useCallback((value: number) => {
    setCurrentStep((old) => {
      previousStep.current = old;
      return value;
    });
  }, []);

  const nextStep = useCallback(async () => {
    window.nextStep = true;

    setCurrentStep((old) => {
      previousStep.current = old;
      return Math.min(old + 1, steps.length - 1);
    });
  }, [steps.length]);

  const prevStep = useCallback(() => {
    window.prevStep = true;

    setCurrentStep((old) => {
      previousStep.current = old;
      return Math.max(0, old - 1);
    });
  }, []);

  const setSubmitting = useCallback((value: boolean) => {
    window.submittingForm = value;
  }, []);

  useEffect(() => {
    if (!validateStepFunction) return;

    const invalidCurrentStep = !validateStepFunction(values);
    if (!invalidCurrentStep) return;

    previousStep.current < currentStep ? nextStep() : prevStep();
  }, [currentStep, nextStep, prevStep, validateStepFunction, values]);

  const value = useMemo(
    () => ({
      steps,
      activeStep: steps[currentStep],
      currentStep,
      previousStep: previousStep.current,
      setCurrentStep: updateCurrentStep,
      setSubmitting,
      nextStep,
      prevStep,
    }),
    [currentStep, nextStep, prevStep, updateCurrentStep, setSubmitting, steps],
  );

  useImperativeHandle(ref, () => value, [value]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  }, [currentStep, setSubmitting]);

  return (
    <StepsContext.Provider value={value}>{children}</StepsContext.Provider>
  );
};

export default forwardRef(Steps);
