import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g fill="#FFF" fillOpacity="0">
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-26 -350) translate(8 56) translate(0 276) translate(18 18)"
                />
              </g>
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M14 19c0 1.105-.895 2-2 2s-2-.895-2-2h4zM12 3c.552 0 1 .448 1 1v.083c2.838.477 5 2.944 5 5.917v5c0 .553.447 1 1 1.001h.001c.552.001.999.449.999 1 0 .552-.447.999-.999.999H5C4.447 18 4 17.553 4 17.001c0-.551.447-.999.999-1H5c.553 0 1-.448 1-1.001v-5c0-2.973 2.162-5.441 5-5.917V4c0-.552.448-1 1-1z"
                transform="translate(-26 -350) translate(8 56) translate(0 276) translate(18 18)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
