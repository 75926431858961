import classNames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import './GlowScroll.styles.scss';

type GlowScrollProps = {
  treshold?: number;
  elementId?: string;
  className?: string;
} & Partial<CSSStyleDeclaration>;

const GlowScroll: React.FC<GlowScrollProps> = (props) => {
  const {
    children,
    treshold = 0.99,
    elementId = 'bb-footer',
    className,
  } = props;

  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const elementRef = useRef<HTMLElement>(null);
  const divRef = useRef<HTMLDivElement>(null);

  const classes = classNames(
    { 'bb-glow-scroll--active': isScrollable },
    { 'bb-glow-scroll--inactive': !isScrollable },
  );

  const calculateGlow = useCallback(
    (element: HTMLDivElement) => {
      const scrollBoundary = element.scrollHeight * treshold;
      const scrollOffset = element.scrollTop + element.clientHeight;

      setIsScrollable(scrollOffset < scrollBoundary);
    },
    [treshold],
  );

  const handleOnScroll = useCallback(
    (event: Event) => {
      if (!elementRef.current) return;
      const scrollElement = event.target as HTMLDivElement;

      calculateGlow(scrollElement);
    },
    [calculateGlow],
  );

  const handleOnResize = useCallback(
    (event: Event) => {
      calculateGlow(event.target as HTMLDivElement);
    },
    [calculateGlow],
  );

  useEffect(() => {
    if (!divRef.current) return;
    calculateGlow(divRef.current);
  }, [calculateGlow]);

  useEffect(() => {
    const targetElement = document.getElementById(elementId);
    elementRef.current = targetElement;
  }, [elementId]);

  useEffect(() => {
    if (!elementRef.current) return;
    elementRef.current.classList.add(classes);
    elementRef.current.classList.add('bb-glow-scroll');
    return () => {
      elementRef.current.classList.remove(classes);
      elementRef.current.classList.remove('bb-glow-scroll');
    };
  }, [classes]);

  useEffect(() => {
    window.addEventListener('resize', handleOnResize);
    window.addEventListener('scroll', handleOnScroll, true);
    return () => {
      window.removeEventListener('scroll', handleOnScroll);
      window.removeEventListener('resize', handleOnResize, true);
    };
  });

  return (
    <div className={className} ref={divRef}>
      {children}
    </div>
  );
};

export default GlowScroll;
