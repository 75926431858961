import httpClient from 'api/httpClient';

function sendGeoLocation(latitude: number, longitude: number) {
  return httpClient.post(`geofence`, {
    latitude,
    longitude,
  });
}

export default {
  sendGeoLocation,
};
