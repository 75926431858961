import React, { useContext, useMemo } from 'react';
import RadioSlider from 'components/RadioSlider';
import { SportType } from 'models/Sport';
import { sportsRadioSlides } from '../MainPage/constants';
import { betSlides } from 'routers/MainRouter/services/radioSlides';
import AppearAnimation from 'components/AppearAnimation';
import BetContext from './providers/Bet/Bet.context';
import PublicBets from './components/PublicBets';
import BuddyBets from './components/BuddyBets';
import AnimatedSlider from 'components/AnimatedSlider';
import { Loader } from 'ncoded-component-library';
import GlowScroll from 'components/GlowScroll';

import './Bets.styles.scss';

const Bets: React.FC = () => {
  const {
    activeRadioIndex,
    loadingBuddyBets,
    loadingPublicBets,
    setActiveSource,
    setSelectedSport,
  } = useContext(BetContext);

  const animatedSlides = useMemo(
    () => [BuddyBets, PublicBets] as Array<React.ReactNode>,
    [],
  );

  return (
    <GlowScroll className="bb-bets">
      <AppearAnimation duration={150} animationName="appear">
        <div className="bb-bets__container">
          {(loadingBuddyBets || loadingPublicBets) && <Loader />}
          <RadioSlider
            className="bb-bets__radio-slider bb-radio-slider--sports-list"
            slideSteps={sportsRadioSlides}
            name="sports"
            showValueOfRadioSlide
            onChange={(value) => setSelectedSport(value as SportType)}
          />

          <AnimatedSlider
            name="bet-type"
            elements={animatedSlides}
            radioSlides={betSlides}
            activeIndex={activeRadioIndex}
            onRadioChange={(value) => setActiveSource(value)}
          />
        </div>
      </AppearAnimation>
    </GlowScroll>
  );
};

export default Bets;
