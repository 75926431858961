import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-944 -160) translate(80 160) translate(864)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M18.5 7c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5V18h10c.552 0 1-.448 1-1V7h.5zm-2-2c.276 0 .5.224.5.5v11c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-11c0-.276.224-.5.5-.5h11z"
              transform="translate(-944 -160) translate(80 160) translate(864)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
