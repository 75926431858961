import credentialsService from 'services/credentialsService';
import refreshTokenService from 'services/refreshTokenService';
import eventSocketService from 'services/socket/eventSocketService';
import history from './history';

export default async function () {
  refreshTokenService.reinit();
  credentialsService.removeAuthBody();
  eventSocketService.disconnect();
  history.push('/start');
}
