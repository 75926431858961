export const socketEventNames = {
  AUTH: 'auth',
  LOGOUT: 'logout',
  MESSAGE_CREATED: 'message-created',
  DISCONNECT_ALL: 'disconnect-all',
  FRIEND_REQUEST_CREATED: 'friend-request-created',
  FRIEND_REQUEST_ACCEPTED: 'friend-request-accepted',
  BET_CREATED: 'bet-created',
  BET_ACCEPTED: 'bet-accepted',
  BET_WON: 'bet-won',
  BET_LOST: 'bet-lost',
  NEW_MESSAGE: 'new-message',
  MONEY_REFUNDED: 'money-refunded',
  USER_CONNECTED: 'user-connected',
  USER_DISCONNECTED: 'user-disconnected',
  CHECK_CONNECTION: 'check-connection',
  CONFIRM_CONNECTION: 'confirm-connection',
  DEPOSIT: 'deposit',
  WITHDRAWAL: 'withdrawal',
  NOT_AVAILABLE_DATA: 'not-available-data',
  WITHDRAWAL_ENABLED: 'withdrawal-enabled',
} as const;

export type SocketEvents =
  typeof socketEventNames[keyof typeof socketEventNames];

export type SocketService<T extends string = SocketEvents> = {
  socket: SocketIOClient.Socket;
  consumers: Partial<Record<T, Array<Function>>>;
  connect: Function;
  disconnect: Function;
  addListener: <P = any>(event: T, callback: (data: P) => void) => void;
  removeListener: (event: T, callback: Function) => void;
  sendEvent: (event: T, payload: any) => void;
  removeAllListener: Function;
};

export default <T extends string = SocketEvents>(connect: Function) =>
  ({
    socket: null,
    consumers: {},
    connect,
    disconnect() {
      this.removeAllListener();
      if (this.socket) {
        this.socket.disconnect();
      }
    },
    addListener<P = any>(event: T, callback: (data: P) => void) {
      if (!this.consumers[event]) {
        this.consumers[event] = [callback];
      } else this.consumers[event].push(callback);
    },
    removeListener(event: T, callback: Function) {
      const callbackArray = this.consumers[event];
      if (!callbackArray) return;
      this.consumers[event] = callbackArray.filter((cb) => cb !== callback);
    },
    removeAllListener() {
      this.consumers = {};
    },
    sendEvent(eventName: string, eventPayload: any) {
      if (!this.socket)
        throw new Error('Socket has to be initializes before events are sent');
      this.socket.emit(eventName, eventPayload);
    },
  } as SocketService<T>);
