import { TicketData } from './Bet';
import { LeagueType, SportType } from './Sport';
import { TeamType } from './Team';

export const FIXTURE_STATUSES = {
  NOT_STARTED_YET: 'NOT_STARTED_YET',
  FINISHED: 'FINISHED',
  IN_PROGRESS: 'IN_PROGRESS',
} as const;

export type FixtureStatusType =
  typeof FIXTURE_STATUSES[keyof typeof FIXTURE_STATUSES];

export type FixtureType = {
  Id: string;
  apiFixutreId?: string;
  apiId?: string;
  createdAt?: Date;
  firstTeam: TeamType;
  firstTeamId: string;
  secondTeam: TeamType;
  secondTeamId: string;
  fixtureResults?: FixtureResultsType;
  fixtureStatus: FixtureStatusType;
  lastUpdated: Date;
  startDate: Date;
  sport: SportType;
  league: LeagueType;
  updatedAt: Date;
  ticket?: TicketData;
};

export type FixtureResultsType = {
  Id: string;
  createdAt: Date;
  firstTeamId: string;
  firstTeamScore: number;
  fixtureId: string;
  resultsFirstTeam: any; // TODO - TYPES FOR PERIOS FOR MATCH
  secondTeamId: string;
  secondTeamScore: number;
  sport: SportType;
  updatedAt: Date;
};
