import { DateTime } from 'luxon';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import utils from 'utils';
import { CalendarProviderValues, CalendarProviderProps } from '../models';
import CalendarContext from './Calendar.context';

const CalendarProvider: React.FC<CalendarProviderProps> = (props) => {
  const { children, onChange: onChangeValue, value } = props;

  const [currentDate, setCurrentDate] = useState<DateTime>(DateTime.now());
  const firstDateSelect = useRef<boolean>(false);

  const onChange = useCallback(
    (date: Date) => {
      const newCurrentDate = DateTime.fromJSDate(date);
      setCurrentDate(newCurrentDate);

      if (!firstDateSelect.current) firstDateSelect.current = true;
      const newDateFormat = `${utils.checkForZero(
        newCurrentDate.month,
      )}-${utils.checkForZero(newCurrentDate.day)}-${utils.checkForZero(
        newCurrentDate.year,
      )}`;

      onChangeValue(newDateFormat);
    },
    [onChangeValue],
  );

  useEffect(() => {
    if (value) {
      const date = DateTime.fromFormat(value, 'MM-dd-yyyy');
      setCurrentDate(date);
    }
  }, [value]);

  const providerValues: CalendarProviderValues = useMemo(
    () => ({
      firstDateSelect,
      currentDate,
      setCurrentDate,
      onChange,
    }),
    [currentDate, onChange],
  );

  return (
    <CalendarContext.Provider value={providerValues}>
      {children}
    </CalendarContext.Provider>
  );
};

export default CalendarProvider;
