import BaseBall from 'assets/svgs/sports/BaseBall';
import BaskettBall from 'assets/svgs/sports/BaskettBall';
import Boxing from 'assets/svgs/sports/Boxing';
import Hockey from 'assets/svgs/sports/Hockey';
import PingPong from 'assets/svgs/sports/PingPong';
import Rugby from 'assets/svgs/sports/Rugby';
import Soccer from 'assets/svgs/sports/Soccer';
import Tennis from 'assets/svgs/sports/Tennis';
import VolleyBall from 'assets/svgs/sports/VolleyBall';
import React from 'react';

interface DataMap {
  [key: string]: string;
}

export const sportIcons: Record<string, React.ReactNode> = {
  BASKETBALL: BaskettBall,
  ICE_HOCKEY: Hockey,
  AMERICAN_FOOTBALL: Rugby,
  SOCCER: Soccer,
  BASEBALL: BaseBall,
  TENNIS: Tennis,
  VOLLEYBALL: VolleyBall,
  BOXING: Boxing,
  PINGPONG: PingPong,
};

export const sportTypes = {
  BASKETBALL: 'BaskettBall',
  ICE_HOCKEY: 'Ice Hockey',
  AMERICAN_FOOTBALL: 'American Football',
  SOCCER: 'Soccer',
  BASEBALL: 'BaseBall',
  TENNIS: 'Tennis',
  VOLLEYBALL: 'VolleyBall',
  BOXING: 'Boxing',
  PINGPONG: 'PingPong',
} as DataMap;

export const EASE_IN_OUT_CUBIC = 'cubic-bezier(0.645, 0.045, 0.355, 1)';
