import React, { useCallback, useContext, useMemo } from 'react';
import classNames from 'classnames';
import Arrow from 'assets/svgs/Arrow';
import { Button } from 'ncoded-component-library';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import NewBetContext from 'routers/MainRouter/pages/MainPage/components/NewBetModal/providers/NewBet/NewBet.context';
import StadiumContext from '../../providers/Stadium/Stadium.context';
import { gameSlides } from 'routers/MainRouter/services/radioSlides';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import TeamStats from './components/TeamStats';
import InfiniteScroll from 'components/InfiniteScroll';
import { ScrollableListFCRef } from './models/ScrollableList';
import AnimatedSlider from 'components/AnimatedSlider';
import useCallbackRef from 'hooks/useCallbackRef';
import BetList from './components/BetList';
import { LEAGUES } from 'models/Sport';
import utils from 'utils';

import './FixtureDetails.styles.scss';

type FixtureDetailsProps = {
  className?: string;
};

const FixtureDetails: React.FC<FixtureDetailsProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-fixture-details', className);

  const { setNewBetModal } = useContext(MainContext);
  const { setInitialFixtureData, isBetEditing } = useContext(NewBetContext);

  const [activeScrollList, activeScrollListRef] =
    useCallbackRef<ScrollableListFCRef>(null);

  const {
    selectedFixture,
    activeRadioIndex,
    setActiveSource,
    setSelectedFixture,
  } = useContext(StadiumContext);

  const {
    sport,
    league,
    startDate,
    fixtureStatus,
    fixtureResults,
    firstTeam,
    secondTeam,
    ticket: { playersCount, averageBet, totalAmount },
  } = selectedFixture;

  const statusForFixture = useMemo(() => {
    switch (fixtureStatus) {
      case 'IN_PROGRESS': {
        return 'Game In Progress';
      }
      case 'NOT_STARTED_YET': {
        return `${utils.toDateFormat(startDate.toString())}`;
      }
      case 'FINISHED': {
        return `${fixtureResults.firstTeamScore} : ${fixtureResults.secondTeamScore}`;
      }
    }
  }, [fixtureResults, fixtureStatus, startDate]);

  const handleOnMakeBet = useCallback(() => {
    setNewBetModal(true);
    isBetEditing.current = false;

    setInitialFixtureData((oldData) => {
      return {
        ...oldData,
        selectedGame: selectedFixture,
        sportCategory: sport,
      };
    });
  }, [
    isBetEditing,
    selectedFixture,
    setInitialFixtureData,
    setNewBetModal,
    sport,
  ]);

  const animatedSlides = useMemo(
    () =>
      [
        <BetList ref={activeScrollListRef} />,
        <TeamStats ref={activeScrollListRef} />,
      ] as Array<React.ReactNode>,
    [activeScrollListRef],
  );

  return (
    <InfiniteScroll
      onScroll={activeScrollList?.onContainerScrolled}
      className={classes}
    >
      <div className="bb-fixture-details__content">
        <div className="bb-fixture-details__content__header">
          <Button icon={Arrow} onClick={() => setSelectedFixture(null)} />
          <p>game details</p>
        </div>

        <div className="bb-fixture-details__content__data">
          <div>
            <BetTeam {...firstTeam} key="first-team" />
            <div className="bb-fixture-details__content__info">
              <p>{`${utils.toHourMinFormat(startDate.toString())}`} </p>
              <p>VS</p>
              <p>{statusForFixture}</p>
              <p>{LEAGUES[league]}</p>
            </div>
            <BetTeam {...secondTeam} key="second-team" />
          </div>

          <div className="bb-fixture-details__footer">
            <div className="bb-fixture-details__footer__content">
              <div>
                <p>{playersCount}</p>
                <p>players</p>
              </div>
              <div>
                <p>{averageBet ? `$${averageBet.toFixed(2)}` : '$0.00'}</p>
                <p>average bet</p>
              </div>
              <div>
                <p>{`$${totalAmount?.toFixed(2)}`}</p>
                <p>total</p>
              </div>
            </div>

            {fixtureStatus === 'NOT_STARTED_YET' && (
              <Button
                ripple
                onClick={() => {
                  window.isNewBetModalClose = false;
                  handleOnMakeBet();
                }}
              >
                Make a Bet
              </Button>
            )}
          </div>
        </div>
      </div>

      <AnimatedSlider
        name="fixture-details"
        elements={animatedSlides}
        radioSlides={gameSlides}
        activeIndex={activeRadioIndex}
        onRadioChange={(value) => setActiveSource(value)}
      />
    </InfiniteScroll>
  );
};

export default FixtureDetails;
