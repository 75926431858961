import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import './Toggle.styles.scss';

type ToggleProps = {
  className?: string;
  value?: boolean;
  defualtValue?: boolean;
  input?: any;
  onChange?: (value: boolean) => void;
};

const Toggle: React.FC<ToggleProps> = (props: ToggleProps) => {
  const { className, value, defualtValue, input, onChange } = props;
  const classes = classNames('bb-toggle', className);

  const [checked, setChecked] = useState<boolean>(value || false);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (input !== undefined) input.onChange(event.target.checked);
      else onChange(event.target.checked);

      setChecked(event.target.checked);
    },
    [input, onChange],
  );

  return (
    <div className={classes}>
      <label
        className={classNames('bb-toggle__slide', {
          'bb-toggle__slide--active': checked,
        })}
      >
        <input
          type="checkbox"
          hidden
          checked={input !== undefined ? input.value : value || defualtValue}
          onChange={handleOnChange}
        />
        <div className="bb-toggle__circle" />
      </label>
    </div>
  );
};

export default Toggle;
