import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-752 -160) translate(80 160) translate(672)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M18.999 7.465l1.63.436c.19.051.331.209.363.4l.007.083v3.118c0 .246-.177.45-.41.492l-.09.008H19V20.5c0 .245-.177.45-.41.492L18.5 21h-10c-.245 0-.45-.177-.492-.41L8 20.5V19h7.5c.78 0 1.42-.595 1.493-1.356L17 17.5v-7.498h.5c.779 0 1.42-.595 1.492-1.355L19 8.502V7.465zM12.5 3.527L17.628 4.9c.192.051.332.209.364.4l.007.083v3.118c0 .246-.177.45-.41.492l-.09.008H16V17.5c0 .245-.177.45-.41.492L15.5 18h-10c-.245 0-.45-.177-.492-.41L5 17.5V9.002H3.5c-.245 0-.45-.177-.492-.41L3 8.502V5.384c0-.198.116-.375.292-.455l.079-.028L8.5 3.527c.431.51 1.1.765 2.006.765.906 0 1.57-.255 1.994-.765zM10.998 8.5H9v1h1.998l.09.008c.235.043.412.247.412.494 0 .238-.165.437-.387.488l-.086.013-.029-.003H9.5v1.003h1.498l.029-.002c.264.015.473.233.473.5 0 .247-.177.452-.412.494l-.09.008H9v1h1.998c.83 0 1.502-.672 1.502-1.501 0-.385-.145-.736-.382-1 .238-.265.382-.616.382-1 0-.83-.672-1.502-1.502-1.502z"
              transform="translate(-752 -160) translate(80 160) translate(672)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
