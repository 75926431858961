import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import './PinField.styles.scss';

type ExtractPinFieldProps = FieldRenderProps<string, HTMLElement>;

interface BackspaceKeyEvent extends React.KeyboardEvent<HTMLInputElement> {
  key: 'Backspace';
}

type PinFieldProps = {
  className?: string;
  numOfPins: number;
  name?: string;
  onChange?: (value: string) => void;
} & ExtractPinFieldProps;

const PinFiled: React.FC<PinFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    className,
    numOfPins,
    name,
    onChange,
    ...rest
  } = props;

  const classes = classNames('bb-pin-field', className);

  const digitArray = Array.from({ length: numOfPins }).map((_) => '');

  const [numbers, setNumbers] = useState<Array<string>>(digitArray);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const hasError = useMemo(() => touched && error, [error, touched]);

  const validatePinField = useCallback(() => {
    if (numbers.length < numOfPins) return false;
    else return true;
  }, [numOfPins, numbers]);

  const handleOnKeyDown = useCallback((event: BackspaceKeyEvent) => {
    const { key } = event;
    if (key !== 'Backspace') return;
    setActiveIndex((oldIndex) => oldIndex - 1);
  }, []);

  const handleOnChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, numIndex: number) => {
      const {
        target: { value },
      } = event;

      if (/[^0-9]/.test(value)) return;

      if (!validatePinField) return;
      setActiveIndex((oldIndex) => oldIndex + 1);

      setNumbers((oldNumbers) => {
        return oldNumbers.map((number, index) => {
          if (index === numIndex) return value.charAt(0);
          else return number;
        });
      });
    },
    [validatePinField],
  );

  useEffect(() => {
    (input ? input.onChange : onChange)?.(numbers.join(''));
  }, [numbers, input, onChange]);

  useEffect(() => {
    if (!inputRef.current) return;
    inputRef.current.focus();
  }, [numbers, activeIndex]);

  return (
    <div className={classes}>
      <div className="bb-pin-field__content" {...rest}>
        {Array.from({ length: numOfPins }).map((_, index) => {
          return (
            <input
              ref={index === activeIndex ? inputRef : null}
              autoComplete="new-password"
              key={index}
              inputMode="numeric"
              type="new-password"
              name={`pin-${index}`}
              value={numbers[index]}
              min={0}
              max={9}
              onChange={(event) => handleOnChange(event, index)}
              onKeyDown={(event: BackspaceKeyEvent) => handleOnKeyDown(event)}
            />
          );
        })}
      </div>
      {hasError && <p>{error}</p>}
    </div>
  );
};

export default PinFiled;
