import { Step } from 'components/FormWrapper/providers/Steps/Steps.context';
import { InitialFixtureData } from './providers/NewBet/NewBet.context';
import ConfirmStep from './steps/ConfirmStep';
import BetTypeForm from './steps/BetTypeForm';
import ShareWithFriendForm from './steps/ShareWithFriendForm';
import PublishSettingsForm from './steps/PublishSettingsForm';
import SportCategoryForm from './steps/SportCategoryForm';
import FixturesForm from './steps/FixturesForm';
import OutcomesForm from './steps/OutcomesForm';
import BetAmountForm from './steps/BetAmountForm';
import OddsForm from './steps/OddsForm';

export default [
  {
    component: SportCategoryForm,
    title: 'choose sport category',
  },
  {
    component: FixturesForm,
    title: 'choose game for bet',
  },
  {
    component: OutcomesForm,
    title: 'pick outcomes',
  },
  {
    component: BetAmountForm,
    title: 'choose your bet',
  },
  {
    component: OddsForm,
    title: 'choose your odds',
  },
  {
    component: PublishSettingsForm,
    title: 'publish settings',
  },
  {
    component: ShareWithFriendForm,
    title: 'share bet with friends',
    validateFn: (data) => data.shareWithFriend,
  },
  {
    component: BetTypeForm,
    title: 'choose bet type',
    validateFn: (data) => data.publishSettings === 'Private',
  },
  {
    component: ConfirmStep,
    title: 'confirm your bet',
  },
] as Step<InitialFixtureData>[];
