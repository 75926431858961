import React from 'react';

export default () => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 931.96 1000"
  >
    <defs>
      <style>{`.cls-1{fill:none;}.cls-2{fill:#f0a500;}`} </style>
    </defs>
    <path
      className="cls-2"
      d="M424.29,507.02h351.22l58.43-252.88h-280.93c-31.57,0-55.93-25.79-55.93-55.79,0-4.17,.47-8.43,1.46-12.7l26.37-114.15H243.94l-40.49,175.23c-3.76,16.25-5.55,32.42-5.55,48.27,0,88.28,55.51,166.95,136.27,197.97H146.56l-40.49,175.23c-3.76,16.25-5.55,32.42-5.55,48.27,0,114.02,92.58,212.02,212.55,212.02h365.05l58.43-252.88H455.63c-31.57,0-55.93-25.79-55.93-55.79,0-4.17,.47-8.43,1.46-12.7l23.13-100.1Z"
    />
    <rect className="cls-1" width="931.96" height="1000" />
  </svg>
);
