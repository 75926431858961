import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-195 -158) translate(12 68) translate(183 90)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm5.5 11h-8c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h8c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5zm-10 0h-1c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h1c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5zm10-3h-8c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h8c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5zm-10 0h-1c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h1c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5zm10-3h-8c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h8c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5zm-10 0h-1c-.276 0-.5.224-.5.5v1c0 .276.224.5.5.5h1c.276 0 .5-.224.5-.5v-1c0-.276-.224-.5-.5-.5z"
              transform="translate(-195 -158) translate(12 68) translate(183 90)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
