import { NotificationType } from 'models/Notification';
import { createContext } from 'react';
import utils from 'utils';

type NotificationContextProps = {
  loadingNotifications: boolean;
  unreadMessages: number;
  nUnreadNotifications: number;
  notifications: Array<NotificationType>;
  setNotifications: (value: React.SetStateAction<NotificationType[]>) => void;
  setNotificationRead: (value: NotificationType) => void;
  setnUnreadNotifications: (value: number) => void;
  readAllSessionMessageNotification: (value: string) => void;
  readAllNotifications: () => void;
  onNotificationScroll: () => void;
};

export default createContext<Partial<NotificationContextProps>>({
  loadingNotifications: false,
  notifications: [],
  nUnreadNotifications: 0,
  unreadMessages: 0,
  setNotifications: utils.noop,
  setNotificationRead: utils.noop,
  setnUnreadNotifications: utils.noop,
  readAllSessionMessageNotification: utils.noop,
  readAllNotifications: utils.noop,
  onNotificationScroll: utils.noop,
});
