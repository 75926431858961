import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useQueryParams from 'hooks/useQueryParams';
import { BetData, TicketData } from 'models/Bet';
import { SportType } from 'models/Sport';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BetContext from './Bet.context';

type BetProps = {
  children?: React.ReactNode;
};

const NAVIGATION_SUBTYPES = ['buddy-bets', 'public-bets'] as const;
type BetNavigationKeys = typeof NAVIGATION_SUBTYPES[number];
const QUERY_BETS_TABS: Record<BetNavigationKeys, number> = {
  'buddy-bets': 0,
  'public-bets': 1,
};

const Bet: React.FC<BetProps> = (props) => {
  const { children } = props;

  const [selectedSport, setSelectedSport] = useState<SportType | 'all'>();

  const {
    params: { source },
    setQueryParam,
  } = useQueryParams<{
    source: BetNavigationKeys;
  }>();

  const activeRadioIndex = useMemo(() => {
    if (source === 'buddy-bets' || source === 'public-bets')
      return QUERY_BETS_TABS[source];
    else return QUERY_BETS_TABS['buddy-bets'];
  }, [source]);

  const mapTicketsData = useCallback((tickets: Array<TicketData>) => {
    return tickets.length > 0
      ? tickets
          .map((ticket: TicketData) => ticket.bets)
          ?.reduce((pre, cur) => pre.concat(cur))
      : [];
  }, []);

  const {
    loading: loadingPublicBets,
    items: publicBets,
    setItems: setPublicBets,
    onContainerScrolled: onPublicBetsScroll,
  } = useInfinitePagination<BetData>({
    makeRequest: (currentPage: number) => {
      return api.bets
        .getTickets({
          sport: selectedSport !== 'all' ? selectedSport : null,
          page: currentPage,
          limit: 5,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: mapTicketsData(data.rows),
          };
        });
    },
    dependencies: [selectedSport, activeRadioIndex],
    resetDeps: [selectedSport, activeRadioIndex],
  });

  const {
    loading: loadingBuddyBets,
    items: buddyBets,
    setItems: setBuddyBets,
    onContainerScrolled: onBuddyBetsScroll,
  } = useInfinitePagination<BetData>({
    makeRequest: (currentPage: number) => {
      return api.bets
        .getBuddyBets({
          sport: selectedSport !== 'all' ? selectedSport : null,
          page: currentPage,
          limit: 5,
        })
        .then(({ data }) => data);
    },
    dependencies: [selectedSport, activeRadioIndex],
    resetDeps: [selectedSport, activeRadioIndex],
  });

  const setActiveSource = useCallback(
    (value: number) => {
      setQueryParam('source', NAVIGATION_SUBTYPES[value]);
    },
    [setQueryParam],
  );

  useEffect(() => {
    if (source === undefined) setActiveSource(0);
  }, [setActiveSource, source]);

  return (
    <BetContext.Provider
      value={{
        loadingBuddyBets,
        loadingPublicBets,
        publicBets,
        buddyBets,
        selectedSport,
        activeRadioIndex,
        setPublicBets,
        setBuddyBets,
        setActiveSource,
        setSelectedSport,
        onPublicBetsScroll,
        onBuddyBetsScroll,
      }}
    >
      {children}
    </BetContext.Provider>
  );
};

export default Bet;
