import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { BetData, BETTING_STATUS, UserBetData } from 'models/Bet';
import BetCard from '../BetCard';
import InfiniteScroll from 'components/InfiniteScroll';
import BetContext from '../../providers/Bet/Bet.context';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import useQueryParams from 'hooks/useQueryParams';
import BetOverview from '../BetOverview';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import utils from 'utils';
import api from 'api';

import './PublicBets.styles.scss';

type PublicBetsProps = {
  className?: string;
};

const PublicBets: React.FC<PublicBetsProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-public-bets', className);

  const { publicBets, loadingPublicBets, setPublicBets, onPublicBetsScroll } =
    useContext(BetContext);

  const { userData } = useContext(MainContext);

  const {
    params: { betId },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{
    betId: string;
  }>();

  const [currentSelectedBet, setCurrentSelectedBet] = useState<BetData>(null);
  const [isBetOpen, setIsBetOpen] = useState<boolean>(false);

  const acceptBetHandler = useCallback(
    (paymentAmount: number, newBetData: UserBetData) => {
      const {
        bet: { currentMaxPayment: newMaxPayment, betStatus, Id: betId },
        bettingTipAmount,
      } = newBetData;

      const betData = publicBets.find((bet) => bet.Id === betId);

      if (
        betData.currentMaxPayment === +paymentAmount ||
        betStatus === BETTING_STATUS.Close
      )
        setPublicBets((oldBets) => oldBets.filter((bet) => bet.Id !== betId));
      else
        setPublicBets((oldBets) =>
          oldBets.map((bet) => {
            if (bet.Id === betId) {
              return {
                ...bet,
                currentMaxPayment: newMaxPayment,
                betTips: !bet.betTips.find((d) => d.userId === userData.Id)
                  ? [...bet.betTips, newBetData as any]
                  : bet.betTips.map((betTip) => {
                      if (betTip.userId === userData.Id) {
                        return {
                          ...betTip,
                          bettingTipAmount: bettingTipAmount,
                        };
                      } else return betTip;
                    }),
              };
            } else return bet;
          }),
        );
    },
    [publicBets, setPublicBets, userData.Id],
  );

  useEffect(() => {
    isBetOpen
      ? setQueryParam('betId', currentSelectedBet.Id)
      : removeQueryParam('betId');
  }, [currentSelectedBet, isBetOpen, removeQueryParam, setQueryParam]);

  useEffect(() => {
    const loadCurrentBet = async () => {
      try {
        const { data } = await api.bets.getBetById(betId);
        setCurrentSelectedBet({
          ...data.bet,
          bettingTipStatus: data.bettingTipStatus,
        });
        setIsBetOpen(true);
      } catch (e) {
        utils.toastError(e);
      }
    };
    if (betId && !currentSelectedBet) {
      loadCurrentBet();
    }
  }, [betId, currentSelectedBet]);

  useEffect(() => {
    const onPopState = (ev: PopStateEvent) => {
      if (currentSelectedBet) {
        setIsBetOpen(false);
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [currentSelectedBet, removeQueryParam]);

  return (
    <div className={classes}>
      <BetOverview
        open={isBetOpen}
        betData={currentSelectedBet}
        onBetChange={(value: BetData) => setCurrentSelectedBet(value)}
        onClose={() => {
          setIsBetOpen(false);
        }}
      />

      <InfiniteScroll
        onScroll={onPublicBetsScroll}
        className="bb-public-bets__content"
      >
        {publicBets.length === 0 && !loadingPublicBets && (
          <EmptyPlaceholder
            title="There aren't any public bets yet!"
            description="Bets will be listed here when someone create bet fist."
          />
        )}

        {publicBets?.map((bet: BetData, index: number) => {
          return (
            <BetCard
              key={index}
              betData={bet}
              showOutcomes={true}
              showStatus={false}
              onAccept={(amount, betData) => acceptBetHandler(amount, betData)}
              onClick={() => {
                setIsBetOpen(true);
                setQueryParam('betId', bet.Id);
                setCurrentSelectedBet(bet);
              }}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

export default PublicBets;
