import httpClient from 'api/httpClient';
import { NotificationType } from 'models/Notification';
import PaginatedData from 'models/PaginatedData';

const notificationPath = (routePath: TemplateStringsArray) =>
  `notifications/${routePath}`;

function getUserNotifications(params: { limit?: number; page: number }) {
  return httpClient.get<PaginatedData<NotificationType>>(notificationPath``, {
    params,
  });
}

function setNotificationRead(params: { notificationId: string }) {
  return httpClient.post(notificationPath`read`, null, { params });
}

function readAllNotifications() {
  return httpClient.post(notificationPath`read/all`);
}

export default {
  getUserNotifications,
  setNotificationRead,
  readAllNotifications,
};
