import { FieldState, FieldValidator } from 'final-form';
import toastr from 'modules/toastr';

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 100;

export const REQUIRE_NUMBER = RegExp('(?=.*?[0-9])');
export const REQUIRE_MIN_LENGHT = RegExp('^(?=.{6,})');
export const REQUIRE_LETTER = RegExp('(?=.*?[a-z, A-Z])');

const isNonEmptyString = (value: any) => {
  return value.trim().length > 0;
};

// Final Form expects  undefined value for a successful validation
const VALID: any = undefined;

export const required = (errorMessage: string) => (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return errorMessage;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : errorMessage;
  }

  return VALID;
};

export const requiredToastr = (errorMessage: string) => (value: any) => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return errorMessage;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value)
      ? VALID
      : toastr.showError({ text: errorMessage, type: 'Error' });
  }

  return VALID;
};

export const requiredStringNoTrim = (errorMessage: string) => (value: any) => {
  return typeof value === 'string' && value.length > 0 ? VALID : errorMessage;
};

const SPECIAL_CHARS_AND_NUMBERS_RE =
  /[0-9`~!@#$%^&*()_\-=+[\]\\'/,.?":;{}|<>]+/;

export const onlyLetters = (errorMessage: string) => (value: any) => {
  return typeof value === 'string' && SPECIAL_CHARS_AND_NUMBERS_RE.test(value)
    ? errorMessage
    : VALID;
};

const SPECIAL_CHARS_RE = /[`~!@#$%^&*()_\-=+[\]\\'/,.?":;{}|<>]+/;

export const hasSpecialChars = (errorMessage: string) => (value: any) => {
  return typeof value === 'string' && SPECIAL_CHARS_RE.test(value)
    ? VALID
    : errorMessage;
};

export const minLength =
  (errorMessage: string, minimumLength: number) => (value: any) => {
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length >= minimumLength ? VALID : errorMessage;
  };

export const mustContainerNumber = (errorMessage: string) => (value: any) => {
  if (/(?=.*?[0-9])/.test(value)) return VALID;
  else return errorMessage;
};

export const mustContainerLetter = (errorMessage: string) => (value: any) => {
  if (/(?=.*?[a-z, A-Z])/.test(value)) return VALID;
  else return errorMessage;
};

export const maxLength =
  (errorMessage: string, maximumLength: number) => (value: any) => {
    if (!value) {
      return VALID;
    }
    const hasLength = value && typeof value.length === 'number';
    return hasLength && value.length <= maximumLength ? VALID : errorMessage;
  };

export const isNumber = (errorMessage: string) => (value: any) => {
  if (/^([a-z]*)$/.test(value)) return errorMessage;
  else return VALID;
};

export const isEthAddress = (errorMessage: string) => (value: any) => {
  if (!/^0x[a-fA-F0-9]{40}$/.test(value)) return errorMessage;
  else return VALID;
};

export const mustBeGreaterThan =
  (errorMessage: string, numValue: number | string) => (value: any) => {
    if (!value) {
      return VALID;
    }
    const numberValue = parseInt(value);
    const isGreaterThan = numberValue > numValue;
    return isGreaterThan ? VALID : errorMessage;
  };

export const formPasswordValidation =
  (errorMessage: string) => (value: any) => {
    const { password, confirmPassword } = value;

    return password === confirmPassword
      ? VALID
      : { confirmPassword: errorMessage };
  };

const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

export const emailFormatValid = (errorMessage: string) => (value: any) => {
  return value && EMAIL_RE.test(value) ? VALID : errorMessage;
};

const DATE_FORMAT_RE =
  /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;

export const dateFormatValid = (errorMessage: string) => (value: any) => {
  return value && DATE_FORMAT_RE.test(value) ? VALID : errorMessage;
};

const TIMEZONE_FORMAT_RE = /^[+-][0-9]{2}:[0-9]{2}/;

export const timezoneFormatValid = (errorMessage: string) => {
  return (value: any) => {
    return value && TIMEZONE_FORMAT_RE.test(value) ? VALID : errorMessage;
  };
};

export const is18YearOld =
  (errorMessage: string, date: string) => (value: any) => {
    const birthDate = new Date(date);
    const todayDate = new Date();

    const birthObject = {
      year: birthDate.getFullYear(),
      month: birthDate.getMonth(),
      day: birthDate.getDate(),
    };

    const todayObject = {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth(),
      day: todayDate.getDate(),
    };

    if (
      todayObject.year - birthObject.year >= 18 &&
      todayObject.month - birthObject.month >= 0 &&
      todayObject.day - birthObject.day >= 0
    )
      return VALID;
    else return errorMessage;
  };

export const equalValues =
  (errorMessage: string, filedName: string) =>
  (value: any, allValues: Record<string, any>) => {
    if (!value || value === allValues[filedName]) return VALID;
    else return errorMessage;
  };

export const isHalfInteger = (errorMessage?: string) => (value: any) => {
  const absValue = Math.abs(value);
  if (absValue === 0 || absValue % 1 === 0.5) return VALID;
  else return errorMessage;
};

export const minValue =
  (errorMessage: string, minValue: number) => (value: any) => {
    return value >= minValue ? VALID : errorMessage;
  };
export const maxValue =
  (errorMessage: string, maxValue: number) => (value: any) => {
    return value <= maxValue ? VALID : errorMessage;
  };

export const composeValidators =
  (...validators: FieldValidator<any>[]) =>
  (value: any, allValues: object, meta?: FieldState<any>) =>
    validators.reduce(
      (error, validator) => error || validator?.(value, allValues, meta),
      VALID,
    );
