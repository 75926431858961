import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { FixtureType } from 'models/Fixture';
import { SportType } from 'models/Sport';
import { TeamData } from 'models/Team';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import utils from 'utils';
import MainContext from '../Main/Main.context';
import TeamsContext from './Teams.context';

export type TeamFilterData = {
  limitWins?: number;
  limitLoses?: number;
};

type TeamsProps = {
  children?: React.ReactNode;
};

const Teams: React.FC<TeamsProps> = (props) => {
  const { children } = props;

  const [loadingAddToFavorites, setLoadingAddToFavorites] =
    useState<boolean>(false);
  const [currenSelectedTeam, setCurrentSelectedTeam] = useState<TeamData>(null);
  const [currentSelectedSport, setCurrentSelectedSport] = useState<
    SportType | 'all'
  >('all');
  const [filterTeamsData, setFilterTeamsData] = useState<TeamFilterData>({
    limitWins: 0,
    limitLoses: 10,
  });

  const { addTeamToFavoritesList, removeTeamFromFavoritesList } =
    useContext(MainContext);

  const {
    loading: loadingTeams,
    items: teams,
    setItems: setTeams,
    onContainerScrolled: onTeamScroll,
    searchString: teamSearchString,
    setSearchString: setTeamSearchString,
  } = useInfinitePagination<TeamData>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.teams
        .getTeamsStats({
          ...filterTeamsData,
          page: currentPage,
          limit: 15,
          searchString,
          sport: currentSelectedSport === 'all' ? null : currentSelectedSport,
        })
        .then(({ data }) => data);
    },
    dependencies: [currentSelectedSport, filterTeamsData],
    resetDeps: [currentSelectedSport, filterTeamsData],
  });

  const {
    loading: loadingUpcomingFixtures,
    items: upcomingFixtures,
    setItems: setUpcomingFixtures,
    onContainerScrolled: onUpcomingFixturesScroll,
  } = useInfinitePagination<FixtureType>({
    makeRequest: (currentPage: number) => {
      return api.fixture
        .getUpcomingFixtures({
          teamId: currenSelectedTeam?.teamId,
          page: currentPage,
          limit: 10,
        })
        .then(({ data }) => data);
    },
    dependencies: [currenSelectedTeam],
    resetDeps: [currenSelectedTeam],
    mainDep: currenSelectedTeam,
  });

  const [searchTeams, setSearchTeams] = useState<Array<TeamData>>(teams);

  const addTeamToFavorites = useCallback(async () => {
    try {
      setLoadingAddToFavorites(true);

      await api.teams.addtTeamToFavorites({
        teamId: currenSelectedTeam.teamId,
      });

      addTeamToFavoritesList(currenSelectedTeam);

      setTeams((oldTeams) =>
        oldTeams.map((team) => {
          if (team.teamId === currenSelectedTeam.teamId)
            return {
              ...team,
              favorites: true,
            };
          else return team;
        }),
      );

      setCurrentSelectedTeam((oldTeam) => {
        return {
          ...oldTeam,
          favorites: true,
        };
      });
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoadingAddToFavorites(false);
    }
  }, [addTeamToFavoritesList, currenSelectedTeam, setTeams]);

  const removeFromFavorites = useCallback(async () => {
    try {
      setLoadingAddToFavorites(true);

      await api.teams.removeTeamFromFavorites({
        teamId: currenSelectedTeam.teamId,
      });

      removeTeamFromFavoritesList(currenSelectedTeam.teamId);

      setTeams((oldTeams) =>
        oldTeams.map((team) => {
          if (team.teamId === currenSelectedTeam.teamId)
            return {
              ...team,
              favorites: false,
            };
          else return team;
        }),
      );

      setCurrentSelectedTeam((oldTeam) => {
        return {
          ...oldTeam,
          favorites: false,
        };
      });
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoadingAddToFavorites(false);
    }
  }, [currenSelectedTeam, removeTeamFromFavoritesList, setTeams]);

  useEffect(() => {
    setSearchTeams(teams);
  }, [teams]);

  return (
    <TeamsContext.Provider
      value={{
        loadingUpcomingFixtures,
        loadingAddToFavorites,
        loadingTeams,
        teamSearchString,
        teams,
        upcomingFixtures,
        filterTeamsData,
        currenSelectedTeam,
        currentSelectedSport,
        searchTeams,
        setLoadingAddToFavorites,
        setSearchTeams,
        setTeams,
        setUpcomingFixtures,
        setTeamSearchString,
        setCurrentSelectedTeam,
        setCurrentSelectedSport,
        setFilterTeamsData,
        addTeamToFavorites,
        removeFromFavorites,
        onTeamScroll,
        onUpcomingFixturesScroll,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export default Teams;
