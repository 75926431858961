import React, { useCallback, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useForm } from 'react-final-form';
import FormWrapper from 'components/FormWrapper';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import useFormHelperElements from 'hooks/useFormHelperElements';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { ChatSessionClientData, ChatSessionType } from 'models/Chats';
import chatService from 'routers/MainRouter/pages/Chat/chatService';
import InfiniteScroll from 'components/InfiniteScroll';
import RadioSlider from 'components/RadioSlider';
import { shareFriendSlides } from 'routers/MainRouter/services/radioSlides';
import api from 'api';
import { FieldArray } from 'react-final-form-arrays';
import { Button, Loader } from 'ncoded-component-library';
import Checkbox from 'assets/svgs/Checkbox';
import AppearAnimation from 'components/AppearAnimation';
import UserImage from 'components/UserImage';
import './ShareWithFriendForm.styles.scss';

type ShareWithFriendStepProps = {
  className?: string;
};

const ShareWithFriendStep: React.FC<ShareWithFriendStepProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-share-with-friend', className);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);

  const { change } = useForm();

  const {
    loading,
    items: sessions,

    onContainerScrolled: onSessionScroll,
  } = useInfinitePagination<ChatSessionType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.chat
        .getChatSessions({
          page: currentPage,
          limit: 15,
          searchString,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: chatService.mapChatSessions(data.rows),
          };
        });
    },
  });

  const { nextStep } = useContext(StepsContext);

  const { SubmitBet, ErrorMessage, SaveBetEdit } = useFormHelperElements();

  const getImageUrl = useCallback((session: ChatSessionClientData) => {
    return session.group
      ? session.group.image?.publicUrl
      : session.sender.participant.profileImageUrl;
  }, []);

  const getSessionName = useCallback((session: ChatSessionClientData) => {
    return session.chatableType === 'Friendship'
      ? `${session.sender.participant.username}`
      : session.chatableType === 'Group'
      ? `${session.group.name}`
      : '';
  }, []);

  const renderUserList = useCallback(
    (sessions: Array<ChatSessionClientData>): JSX.Element => {
      return (
        <FieldArray<ChatSessionClientData> name="sessions">
          {({ fields: { remove, push, value } }) => {
            return sessions.map(
              (session: ChatSessionClientData, index: number) => {
                const imageUrl = getImageUrl(session);
                const sessionName = getSessionName(session);
                return (
                  <div
                    className={classNames('bb-share-with-friend__session', {
                      'bb-share-with-friend__session--active': !!value?.find(
                        (s) => s.Id === session.Id,
                      ),
                    })}
                    key={index}
                    onClick={() =>
                      !!value?.find((s) => s.Id === session.Id)
                        ? remove(value?.findIndex((s) => s.Id === session.Id))
                        : push(session)
                    }
                  >
                    <div>
                      <UserImage
                        src={imageUrl || ''}
                        alt={'user'}
                        active={session.sender?.participant.isOnline || false}
                      />
                      <p>{sessionName}</p>
                    </div>
                    <div>
                      <Button type="button" icon={Checkbox} />
                    </div>
                  </div>
                );
              },
            );
          }}
        </FieldArray>
      );
    },
    [getImageUrl, getSessionName],
  );

  //reset share with friend flag validation
  useEffect(
    () => () => {
      change('shareWithFriend', undefined);
    },
    [change],
  );

  return (
    <FormWrapper onSubmit={nextStep} className={classes} id="betModalForm">
      {loading && <Loader />}
      <InfiniteScroll
        className="bb-share-with-friend__container"
        onScroll={onSessionScroll}
      >
        <RadioSlider
          className="bb-share-with-friend__select"
          name="friends"
          slideSteps={shareFriendSlides}
          defaultIndex={0}
          onChange={(value) => setActiveSlideIndex(value)}
        />

        {activeSlideIndex === 0 && (
          <AppearAnimation duration={600} animationName="moveRight">
            <div>{renderUserList(sessions)}</div>
          </AppearAnimation>
        )}

        {activeSlideIndex === 1 && (
          <AppearAnimation duration={600} animationName="moveLeft">
            <div>
              {sessions &&
                renderUserList(
                  sessions.filter(
                    (session) => session.sender?.participant.isOnline,
                  ),
                )}
            </div>
          </AppearAnimation>
        )}
      </InfiniteScroll>
      <div className="bb-share-with-friend__footer">
        <ErrorMessage />
        <SaveBetEdit>Save</SaveBetEdit>
        <SubmitBet>Next Step</SubmitBet>
      </div>
    </FormWrapper>
  );
};

export default ShareWithFriendStep;
