import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <defs>
      <filter id="jvu3ntjuta">
        <feColorMatrix
          in="SourceGraphic"
          values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
        />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="currentColor"
          d="M0 0H1200V800H0z"
          transform="translate(-848 -272)"
        />
        <g
          filter="url(#jvu3ntjuta)"
          transform="translate(-848 -272) translate(80 272)"
        >
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(768)"
            />
            <path
              fill="#9FAEC1"
              fillRule="nonzero"
              d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm0 4c-.552 0-1 .448-1 1v2.999L8 11c-.552 0-1 .448-1 1s.448 1 1 1l3-.001V16c0 .552.448 1 1 1s1-.448 1-1v-3h3c.552 0 1-.448 1-1s-.448-1-1-1h-3V8c0-.552-.448-1-1-1z"
              transform="translate(768)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
