import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';
import useCollapsibleHeight from './hooks/useCollapsibleHeight';
import { useSpring, animated } from 'react-spring';
import useCallbackRef from 'hooks/useCallbackRef';
import classNames from 'classnames';

import './Collapsible.styles.scss';

export type CollapsibleRef = {
  contentElement: HTMLDivElement;
  open: boolean;
  setOpen: (value: boolean) => void;
};

type CollapsibleProps = {
  defaultOpen?: boolean;
  children?: React.ReactNode;
  className?: string;
  trigger?: React.ReactNode;
};

const Collapsible: React.ForwardRefRenderFunction<
  CollapsibleRef,
  CollapsibleProps
> = (props, ref) => {
  const { trigger, children, className, defaultOpen = true } = props;
  const [heightRef, height] = useCollapsibleHeight<HTMLDivElement>();

  const [open, setOpen] = useState<boolean>(defaultOpen);

  const [mainElement, mainRef] = useCallbackRef<HTMLDivElement>(null);

  const classes = useMemo(
    () =>
      classNames('bb-collapsible', { 'bb-collapsible--open': open }, className),
    [className, open],
  );

  const contentStyles = useSpring({
    from: { height: 0 },
    to: { height: open ? height : 0 },
    onStart: () => onAnimationStart(),
    onRest: () => onAnimationEnd(),
  });

  const onAnimationStart = useCallback(() => {
    if (!mainElement) return;
    mainElement.classList.add('bb-collapsible__animation-start');
    mainElement.classList.remove('bb-collapsible__animation-end');
  }, [mainElement]);

  const onAnimationEnd = useCallback(() => {
    if (!mainElement) return;
    mainElement.classList.add('bb-collapsible__animation-end');
    mainElement.classList.remove('bb-collapsible__animation-start');
  }, [mainElement]);

  useImperativeHandle(
    ref,
    () =>
      ({ contentElement: heightRef.current, open, setOpen } as CollapsibleRef),
    [heightRef, open],
  );

  return (
    <section className={classes}>
      <header
        className="bb-collapsible__trigger"
        onClick={() => setOpen((old) => !old)}
      >
        {trigger}
      </header>
      <animated.main
        ref={mainRef}
        className="bb-collapsible__animated"
        style={{ ...contentStyles, overflow: 'hidden' }}
      >
        <div className="bb-collapsible__content" ref={heightRef}>
          {children}
        </div>
      </animated.main>
    </section>
  );
};

export default forwardRef(Collapsible);
