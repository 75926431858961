import React, { useContext } from 'react';
import classNames from 'classnames';
import Datepicker from './components/Datepicker';
import CalendarContext from './provider/Calendar.context';
import { CalendarProps } from './models';

import './Calendar.styles.scss';
import './Calendar.styles.responsive.scss';

const Calendar: React.FC<CalendarProps> = (props: CalendarProps) => {
  const { className, ...rest } = props;

  const { onChange: onDateChange, currentDate } = useContext(CalendarContext);
  const classes = classNames('bb-calendar', className);

  return (
    <main className={classes}>
      <Datepicker
        value={currentDate.toJSDate()}
        onChange={(val: any) => onDateChange(val as Date)}
        {...rest}
      />
    </main>
  );
};

export default Calendar;
