import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  OutcomeData,
  outcomeSubtypes,
  outcomeTypes,
  overunderTypes,
  periodTypes,
} from 'models/Outcome';
import { Button } from 'ncoded-component-library';
import Arrow from 'assets/svgs/Arrow';
import Collapsible from 'components/Collapsible';
import useCallbackRef from 'hooks/useCallbackRef';
import Image from 'components/Image';
import { FixtureType } from 'models/Fixture';
import { TEAM_WINNER_OUTCOME_TYPES } from 'hooks/useBetInformation';
import { CollapsibleRef } from 'components/Collapsible/Collapsible.component';

import './ShowOutcomeCard.styles.scss';

type ShowOutcomeCardProps = {
  className?: string;
  outcomeData: OutcomeData;
  betTeamUrl?: string;
  showTeamLogo?: boolean;
  defaultOpen?: boolean;
  fixture: FixtureType;
};

const ShowOutcomeCard: React.FC<ShowOutcomeCardProps> = (props) => {
  const {
    className,
    outcomeData,
    betTeamUrl,
    showTeamLogo = true,
    defaultOpen = true,
    fixture: { firstTeam, secondTeam },
  } = props;

  const { subtype, handicap, team: teamIndex } = outcomeData ?? {};

  const [collapsible, collapsibleRef] = useCallbackRef<CollapsibleRef>(null);

  const classes = useMemo(
    () =>
      classNames(
        'bb-outcome-details-card',
        { 'bb-outcome-details-card--open': collapsible?.open },
        className,
      ),
    [className, collapsible],
  );

  const isAdvanced = useMemo(
    () => outcomeData.handicap !== 0 || outcomeData.limit !== undefined,
    [outcomeData.handicap, outcomeData.limit],
  );

  const isSubtypeTotal = useMemo(
    () => outcomeData.subtype.startsWith('TOTAL'),
    [outcomeData],
  );

  const outcomeTypeInfo = useMemo(
    () =>
      outcomeData && TEAM_WINNER_OUTCOME_TYPES.includes(subtype)
        ? teamIndex === 1
          ? firstTeam?.teamName
          : secondTeam?.teamName
        : outcomeTypes[outcomeData.type],
    [firstTeam.teamName, outcomeData, secondTeam.teamName, subtype, teamIndex],
  );

  return (
    <div className={classes}>
      <Collapsible
        defaultOpen={defaultOpen}
        ref={collapsibleRef}
        className="bb-outcome-details-card__collapsible"
        trigger={
          <div className="bb-outcome-details-card__content">
            <div>
              {!isSubtypeTotal && showTeamLogo && (
                <Image
                  className="bb-outcome-details-card__team"
                  src={betTeamUrl}
                  alt="team"
                />
              )}
              <p>{outcomeTypeInfo}</p>
            </div>
            <Button
              className={classNames({
                'bb-outcome-details-card__content__advanced': isAdvanced,
              })}
              styleType="secondary"
              type="button"
              icon={Arrow}
              iconPosition="right"
            >
              {isAdvanced ? `advanced` : ``}
            </Button>
          </div>
        }
      >
        <div className="bb-outcome-details-card__data">
          <div>
            <p>Subtype</p>
            <p>{outcomeSubtypes[outcomeData.subtype]}</p>
          </div>
          <div>
            <p>Period</p>
            <p>{`${periodTypes[outcomeData.period]}`}</p>
          </div>

          {outcomeData.handicap !== undefined && (
            <div>
              <p>Handicap</p>
              <p>{`${handicap > 0 ? '+' : ''}${handicap}`}</p>
            </div>
          )}

          {outcomeData.limit && (
            <div>
              <p>{`${overunderTypes[outcomeData.bettingTip]}`}</p>
              <p>{outcomeData.limit}</p>
            </div>
          )}
        </div>
      </Collapsible>
    </div>
  );
};

export default ShowOutcomeCard;
