import React, { useContext, useEffect, useState } from 'react';
import RadioSlider from 'components/RadioSlider';
import { sportsRadioSlides } from '../../../../MainPage/constants';
import { Button, Input, Loader } from 'ncoded-component-library';
import Search from 'assets/svgs/Search';
import Slider from 'assets/svgs/Slider';
import TeamCard from 'components/TeamCard';
import { TeamData } from 'models/Team';
import { SportType } from 'models/Sport';
import InfiniteScroll from 'components/InfiniteScroll';
import TeamsContext from '../../../../MainPage/providers/Teams/Teams.context';
import {
  EASE_IN_OUT_CUBIC,
  sportIcons,
} from 'routers/MainRouter/services/constants';
import { TeamFilterData } from '../../../../MainPage/providers/Teams/Teams.provider';
import TeamContent from './components/TeamContent';
import TeamsFilterModal from 'components/modals/TeamsFilterModal';
import AppearAnimation from 'components/AppearAnimation';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import useQueryParams from 'hooks/useQueryParams';
import Arrow from 'assets/svgs/Arrow';
import { useHistory } from 'react-router';
import GlowScroll from 'components/GlowScroll';
import api from 'api';
import utils from 'utils';

import './Teams.styles.scss';

type TeamsProps = {};

const Teams: React.FC<TeamsProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const {
    params: { teamId },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{
    teamId: string;
  }>();

  const history = useHistory();

  const {
    loadingTeams,
    loadingAddToFavorites,
    currenSelectedTeam,
    teamSearchString,
    searchTeams,
    filterTeamsData,
    setFilterTeamsData,
    setTeamSearchString,
    setCurrentSelectedTeam,
    setCurrentSelectedSport,
    onTeamScroll,
  } = useContext(TeamsContext);

  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  useEffect(() => {
    const loadCurrentTeam = async () => {
      try {
        setLoading(true);
        const { data } = await api.teams.getTeamById(teamId);
        setCurrentSelectedTeam(data);
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    };
    if (teamId) loadCurrentTeam();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currenSelectedTeam) setQueryParam('teamId', currenSelectedTeam.teamId);
    else removeQueryParam('teamId');
  }, [currenSelectedTeam, removeQueryParam, setQueryParam]);

  useEffect(() => {
    const onPopState = (ev: PopStateEvent) => {
      ev.preventDefault();
      if (currenSelectedTeam) {
        setCurrentSelectedTeam(null);
      }
    };

    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [currenSelectedTeam, setCurrentSelectedTeam]);

  return (
    <>
      {(loadingTeams || loadingAddToFavorites || loading) && <Loader />}

      <TeamsFilterModal
        open={isFilterOpen}
        teamFilterData={filterTeamsData}
        onClose={() => setIsFilterOpen(false)}
        onSubmit={(value: TeamFilterData) => setFilterTeamsData(value)}
      />

      {!currenSelectedTeam && (
        <GlowScroll className="bb-teams">
          <AppearAnimation
            duration={700}
            animationName="moveRight"
            timingFunction={EASE_IN_OUT_CUBIC}
          >
            <div className="bb-teams__container">
              <div className="bb-teams__header">
                <Button
                  styleType="secondary"
                  icon={Arrow}
                  onClick={() => history.push('/menu')}
                />
                <p>Teams</p>
              </div>

              <RadioSlider
                className="bb-teams__select"
                slideSteps={sportsRadioSlides}
                name="sports"
                showValueOfRadioSlide
                onChange={(value) =>
                  setCurrentSelectedSport(value as SportType)
                }
              />

              <div className="bb-teams__content">
                <Input
                  value={teamSearchString}
                  onChange={(e) => setTeamSearchString(e.target.value)}
                  prefixNode={<Search />}
                  placeholder="Search..."
                />
                <Button
                  styleType="secondary"
                  className="actions-button"
                  icon={Slider}
                  onClick={() => setIsFilterOpen(true)}
                />
              </div>

              <InfiniteScroll onScroll={onTeamScroll}>
                {searchTeams.length === 0 && (
                  <EmptyPlaceholder title="There aren't any teams yet" />
                )}

                {searchTeams.map((team: TeamData, index: number) => {
                  return (
                    <TeamCard
                      sportIcon={sportIcons[team.sport]}
                      key={index}
                      data={team}
                      onClick={() => setCurrentSelectedTeam(team)}
                    />
                  );
                })}
              </InfiniteScroll>
            </div>
          </AppearAnimation>
        </GlowScroll>
      )}

      {currenSelectedTeam && (
        <AppearAnimation
          duration={600}
          animationName="moveRight"
          timingFunction={EASE_IN_OUT_CUBIC}
        >
          <TeamContent />
        </AppearAnimation>
      )}
    </>
  );
};

export default Teams;
