import React, { useCallback } from 'react';
import classNames from 'classnames';
import './SettingsCard.styles.scss';
import utils from 'utils';

type SettingsCardProps = {
  className?: string;
  active?: boolean;
  icon?: React.ReactNode;
  title?: string;
  content?: string;
  name: string;
  inputValue: string;
  onChange: (value: string) => void;
};

const SettingsCard: React.FC<SettingsCardProps> = (props) => {
  const {
    className,
    active,
    icon,
    title,
    content,
    name,
    inputValue,
    onChange,
  } = props;

  const classes = classNames(
    'bb-settings-card',
    { 'bb-settings-card--active': active },
    className,
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(event.target.value);
    },
    [onChange],
  );

  return (
    <label className={classes}>
      <input
        name={name}
        value={inputValue}
        type="radio"
        hidden
        onChange={handleOnChange}
      />
      <div className="bb-settings-card__circle">
        {icon && utils.renderIcon(icon)}
      </div>
      <p>{title}</p>
      <p>{content}</p>
    </label>
  );
};

export default SettingsCard;
