import React, { useEffect, useMemo, useState } from 'react';
import { Field, useFormState, useField } from 'react-final-form';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';

import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import FormWrapper from 'components/FormWrapper';
import Close from 'assets/svgs/Close';
import Checkbox from 'assets/svgs/Checkbox';
import {
  composeValidators,
  required,
  minLength,
  mustContainerNumber,
  mustContainerLetter,
  equalValues,
  REQUIRE_MIN_LENGHT,
  REQUIRE_NUMBER,
  REQUIRE_LETTER,
} from 'services/validators';

import './PasswordForm.styles.scss';

type PasswordFormProps = {};

const PasswordForm: React.FC<PasswordFormProps> = () => {
  const validateObject = useMemo(
    () => ({
      password: composeValidators(
        required('required'),
        minLength('minLength', 6),
        mustContainerNumber('number'),
        mustContainerLetter('letter'),
      ),
      confirmPassword: composeValidators(
        required('required'),
        minLength('minLength', 6),
        mustContainerNumber('number'),
        mustContainerLetter('letter'),
        equalValues('Password must match', 'password'),
      ),
    }),
    [],
  );

  const { errors } = useFormState();

  const [validationIcon, setValidationIcon] = useState({
    number: false,
    lenght: false,
    letter: false,
  });

  const {
    input: { value: password },
  } = useField<string>('password');

  const validationMessage = useMemo(
    () => [
      {
        valid: validationIcon.lenght,
        message: 'At leaset 6 characters long',
      },
      {
        valid: validationIcon.letter,
        message: 'Must contain a letter',
      },
      {
        valid: validationIcon.number,
        message: 'Must container a number',
      },
    ],
    [validationIcon],
  );

  useEffect(
    () =>
      setValidationIcon({
        lenght: REQUIRE_MIN_LENGHT.test(password),
        number: REQUIRE_NUMBER.test(password),
        letter: REQUIRE_LETTER.test(password),
      }),
    [password],
  );

  return (
    <FormWrapper className="bb-password-setup">
      <div className="bb-password-setup__content">
        <Field
          label="password"
          name="password"
          component={AnimatedField}
          delay={500}
          duration={400}
          type="password"
          placeholder="Enter password"
          showError={false}
          validate={validateObject.password}
        />
        <Field
          label="confirm password"
          name="confirmPassword"
          component={AnimatedField}
          delay={600}
          duration={400}
          type="password"
          placeholder="Confirm password"
          showError={false}
          validate={validateObject.confirmPassword}
        />

        <StandardAppearAnimation delay={700} duration={400}>
          <div>
            {validationMessage.map(({ valid, message }) => {
              return (
                <div
                  key={message}
                  className={classNames(
                    'bb-password-setup__content__validation',
                    {
                      valid: valid,
                    },
                  )}
                >
                  {valid ? <Checkbox /> : <Close />}
                  <p className="bb-password-setup__content__validation__text">
                    {message}
                  </p>
                </div>
              );
            })}
          </div>
        </StandardAppearAnimation>

        {errors.confirmPassword === 'Password must match' && (
          <p className="bb-password-setup__content__match">
            Passwords do not match
          </p>
        )}
      </div>

      <div className="bb-password-setup__footer">
        <Button type="submit">Next Step</Button>
      </div>
    </FormWrapper>
  );
};

export default PasswordForm;
