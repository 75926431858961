import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-224 -208) translate(80 160) translate(144 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M17.218 18.451v1.929c0 .355-.306.62-.66.62h-9c-.355 0-.625-.265-.625-.62l-.001-1.929h10.286zM6.276 17.165c-1.775-.003-3.213-1.439-3.216-3.214 0-1.396.896-2.572 2.147-3.016.04-.014.076-.031.111-.052.3-.096.62-.146.954-.146h3.214c.71.001 1.284.576 1.286 1.286-.002.71-.576 1.284-1.286 1.285H6.272c-.355 0-.643.288-.643.643 0 .355.288.643.643.643h3.214c1.42 0 2.571-1.15 2.571-2.571h3.215c1.27 0 2.415-.526 3.233-1.371l-.001 5.87c0 .355-.297.643-.652.643H6.276zM15.29 3v.022c1.928.004 3.214 1.44 3.214 3.215v1.14c-.012.047-.017.096-.017.146-.004 1.774-1.44 3.21-3.215 3.214h-3.57c-.446-.765-1.266-1.286-2.216-1.286H6.272c-.212 0-.42.015-.626.043V6.237C5.647 4.462 7.069 3 8.844 3h6.447z"
              transform="translate(-224 -208) translate(80 160) translate(144 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
