import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import credentialsService from 'services/credentialsService';
import { Loader } from 'ncoded-component-library';
import { AxiosResponse } from 'axios';
import api from 'api';

import './GeoChecker.styles.scss';

type GeoCheckerProps = {
  children?: React.ReactNode;
};

const ACCESS_DENIED = 403;

const GeoChecker: React.FC<GeoCheckerProps> = (props) => {
  const { children } = props;

  const isChrome = useRef<boolean>(!!(window as any).chrome);

  const [loading, setLoading] = useState<boolean>(false);

  const [allowedAccess, setAllowedAccess] = useState<boolean>(false);
  const [locationError, setLocationError] = useState<boolean>(false);
  const [coordinates, setCoordinates] = useState<GeolocationCoordinates>(null);

  const sendGeoLocation = useCallback(
    async (latitude: number, longitude: number) => {
      try {
        await api.geolocation.sendGeoLocation(latitude, longitude);
        setAllowedAccess(true);
      } catch (e) {
        if ((e as AxiosResponse).request?.status === ACCESS_DENIED) {
          setAllowedAccess(false);
          credentialsService.removeAuthBody();
        }
      } finally {
        setLoading(false);
      }
    },
    [],
  );

  useEffect(() => {
    if ('geolocation' in navigator) {
      setLoading(true);

      navigator.geolocation.getCurrentPosition(
        (position: GeolocationPosition) => {
          setCoordinates(position.coords);
        },
        () => {
          setLoading(false);
          setAllowedAccess(false);
          setLocationError(true);
        },
      );
    }
  }, []);

  useEffect(() => {
    if (!coordinates) return;

    sendGeoLocation(coordinates.latitude, coordinates.longitude);
  }, [coordinates, sendGeoLocation]);

  if (loading) return <Loader />;
  else if (!allowedAccess)
    return (
      <Fragment>
        <style>{`html,body { max-width: unset;}`} </style>
        {locationError ? (
          <div className="denied-title">
            You need to have your location enabled to use
            <a href="https://www.topcheez.com"> TopCheez.</a>
            <br />
            {isChrome.current ? (
              <>
                <ol className="settings-list" type="1">
                  <li>Click the icon on top left.</li>
                  <li>Click permissions.</li>
                  <li>Enable location.</li>
                </ol>
              </>
            ) : (
              'Go to system settings to enable location.'
            )}
          </div>
        ) : (
          <p className="denied-title">
            Do not have permission to access
            <a href="https://www.topcheez.com"> TopCheez </a> in your current
            region. We would love to keep you updated when we roll out to new
            areas, join the wait-list here
            <a href="https://www.topcheez.com/#join-section"> TopCheez.</a>
          </p>
        )}
      </Fragment>
    );
  else return <Fragment>{children}</Fragment>;
};

export default GeoChecker;
