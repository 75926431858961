import React from 'react';
import classNames from 'classnames';
import './PlayerCard.styles.scss';

type PlayerCardProps = {
  className?: string;
  playerUrl?: string;
  playerName?: string;
  playerPosition?: string;
  playerNumber?: number;
};

const PlayerCard: React.FC<PlayerCardProps> = (props) => {
  const { className, playerUrl, playerName, playerNumber, playerPosition } =
    props;

  const classes = classNames('bb-player-card', className);

  return (
    <div className={classes}>
      <div className="bb-player-card__player-info">
        <p>{playerName}</p>
        <p className="bb-player-card__player-info--player-number">{`#${playerNumber}`}</p>
        <p>{playerPosition}</p>
      </div>
      <img src={playerUrl} alt="" />
    </div>
  );
};

export default PlayerCard;
