export type BaseballOutcome = 'RUNS' | 'GOALS';

//Runs outcome
export type BaseballRunsOutcome =
  | 'TOTAL_RUNS'
  | 'TEAM_TOTAL_RUNS'
  | 'RUNS_WINNER';

//Goals outcome
export type BaseballGoalsOutcome =
  | 'TOTAL_GOALS'
  | 'TEAM_TOTAL_GOALS'
  | 'GOALS_WINNER';

//PERIODS
export type BaseballPeriodTypes =
  | 'MATCH'
  | 'FIRST_INNING'
  | 'FIRST_THREE_INNINGS'
  | 'FIRST_FIVE_INNINGS'
  | 'FIRST_SEVEN_INNINGS';

export type BaseballOutcomeSubType = BaseballRunsOutcome | BaseballGoalsOutcome;

export const BASEBALL_OUTCOMES = {
  RUNS: 'Runs',
  GOALS: 'Golas',
} as const;

export const BASEBALL_SUBOUTCOMES = {
  RUNS_WINNER: 'Match Winner',
  TOTAL_RUNS: 'Total Runs',
  TEAM_TOTAL_RUNS: 'Team Total Runs',
  TOTAL_GOALS: 'Total Goals',
  TEAM_TOTAL_GOALS: 'Team Total Goals',
  GOALS_WINNER: 'Goals Winner',
} as const;

export const BASEBALL_PERIODS = {
  MATCH: 'Match',
  FIRST_INNING: 'First Inning',
  FIRST_THREE_INNINGS: 'First Three Innings',
  FIRST_FIVE_INNINGS: 'First Five Inning',
  FIRST_SEVEN_INNINGS: 'First Seven Innings',
} as const;
