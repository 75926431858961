import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-368 -208) translate(80 160) translate(288 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M17.182 12.167c1.425.67 2.535 1.996 2.802 3.67.01.068.033.132.063.19l-.07.146-.182.328C18.13 19.386 15.097 21 11.986 21c-.15 0-.3-.004-.45-.011 1.885-.586 3.563-1.855 4.628-3.698.737-1.278 1.09-2.677 1.09-4.056 0-.359-.025-.715-.072-1.068zm-2.116-.478c.256 0 .51.023.76.063.108.487.177.982.177 1.483 0 1.167-.296 2.346-.922 3.43-1.274 2.208-3.585 3.44-5.962 3.44-.184 0-.369-.007-.553-.022-.13-.01-.255.02-.36.08-.239-.11-.475-.233-.707-.368-.625-.36-1.19-.785-1.692-1.26.2.018.399.028.596.028 1.626-.001 3.177-.648 4.329-1.746.083-.08.159-.166.237-.25.012-.012.025-.022.036-.036.993-1.075 1.631-2.523 1.63-4.172.791-.453 1.618-.67 2.43-.67zM4.246 8.904c0 1.378.352 2.778 1.09 4.056.93 1.611 2.335 2.776 3.932 3.436-.833.582-1.835.917-2.865.917-.57 0-1.15-.1-1.717-.317-.065-.025-.132-.038-.198-.04C3.516 15.48 3 13.745 3 11.985c0-1.526.389-3.074 1.205-4.487.078-.136.16-.27.245-.4-.135.59-.204 1.195-.204 1.805zm4.039-1.841c-.005 2.068 1.072 4.13 3.1 5.298-.002 1.206-.418 2.254-1.09 3.082-1.58-.501-2.982-1.56-3.876-3.108-.626-1.085-.922-2.264-.922-3.431-.001-1.394.424-2.768 1.22-3.92.074-.106.11-.228.11-.349.8-.562 1.684-.985 2.611-1.26-.779 1.094-1.155 2.4-1.153 3.688zm6.215-.064c2.746 0 5.113 1.608 6.217 3.933.055.117.142.208.244.27.027.268.039.54.039.812 0 .696-.08 1.396-.246 2.087-.809-1.79-2.433-3.063-4.293-3.496-.014-.002-.025-.007-.04-.008-.441-.1-.895-.159-1.355-.159-1.03 0-2.087.266-3.071.834-1.04-.603-1.75-1.479-2.129-2.466C11.091 7.686 12.71 7 14.5 7zM12.014 3c1.526 0 3.074.388 4.486 1.205 1.562.901 2.751 2.203 3.514 3.701C18.566 6.567 16.628 5.75 14.5 5.75c-1.859 0-3.57.633-4.94 1.685-.01-.124-.025-.247-.025-.371.001-1.465.587-2.91 1.795-3.895.054-.044.1-.096.134-.151l.249-.012z"
              transform="translate(-368 -208) translate(80 160) translate(288 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
