import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Button, Loader } from 'ncoded-component-library';
import { Field, withTypes } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import classNames from 'classnames';

import api from 'api';
import utils from 'utils';
import Edit from 'assets/svgs/Edit';
import { UserType } from 'models/User';
import UserImage from 'components/UserImage';
import InputField from 'components/Fields/InputField';
import credentialsService from 'services/credentialsService';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';

import './ProfileSettings.styles.scss';

type ProfileSettingsFormType = {
  username: string;
  firstName: string;
  lastName: string;
  address: string;
};

type ProfileSettingsProps = {
  className?: string;
};

const ProfileSettings: React.FC<ProfileSettingsProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-profile-settings', className);

  const history = useHistory();
  const { Form } = withTypes<ProfileSettingsFormType>();

  const { userData, setUserData } = useContext(MainContext);

  const [userImage, setUserImage] = useState<File>();
  const [loading, setLoading] = useState<boolean>(false);

  const userProfileURL = useMemo(
    () =>
      userImage ? URL.createObjectURL(userImage) : userData?.profileImageUrl,
    [userData, userImage],
  );

  const initialData = useMemo(
    () =>
      ({
        username: userData.username,
        firstName: userData.firstName,
        lastName: userData.lastName,
      } as ProfileSettingsFormType),
    [userData],
  );

  const imageUploadHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (event.target.files && event.target.files.length !== 0) {
          setUserImage(event.target.files[0]);
          setLoading(true);

          const {
            data: { imageUrl },
          } = await api.user.uploadUserImage(event.target.files[0]);

          const updatedUser = {
            ...userData,
            profileImageUrl: imageUrl,
          } as UserType;

          setUserData(updatedUser);
          credentialsService.updateUserData(updatedUser);
          utils.toastSuccess('User has been successfully updated');
        }
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [setUserData, userData],
  );

  const handleOnSubmit = useCallback(
    async (values: ProfileSettingsFormType) => {
      try {
        setLoading(true);

        await api.user.updateUser(values as any);
        const updatedUser = { ...userData, ...values } as UserType;

        setUserData(updatedUser);
        credentialsService.updateUserData(updatedUser);
        utils.toastSuccess('User has been successfully updated');

        history.push(`/menu/profile/${userData.Id}`);
      } catch (e) {
        utils.toastError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    },
    [userData, history, setUserData],
  );

  return (
    <Form initialValues={initialData} onSubmit={handleOnSubmit}>
      {({ handleSubmit, dirty }) => {
        return (
          <form className={classes} onSubmit={handleSubmit}>
            {loading && <Loader />}
            <h1 className="bb-profile-settings__title">Profile settings</h1>

            <div className="bb-profile-settings__content">
              <label>
                <UserImage
                  className="bb-profile-settings__user-image"
                  src={userProfileURL}
                  alt=""
                >
                  <Edit />
                </UserImage>

                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={imageUploadHandler}
                />
              </label>

              <Field
                name="username"
                component={InputField}
                label="Username"
                placeholder="Username"
                validate={null}
              />
              <Field
                name="firstName"
                component={InputField}
                label="First name"
                placeholder="First name"
                validate={null}
              />
              <Field
                name="lastName"
                component={InputField}
                label="Last name"
                placeholder="Last name"
                validate={null}
              />

              <Button disabled={!dirty} type="submit">
                Save changes
              </Button>
            </div>
          </form>
        );
      }}
    </Form>
  );
};

export default ProfileSettings;
