import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import './Card.styles.scss';
import Checkbox from 'assets/svgs/Checkbox';

type CardProps = {
  className?: string;
  name?: string;
  value?: boolean;
  title?: string;
  active?: boolean;
  onChange?: (value: boolean) => void;
};

const Card: React.FC<CardProps> = (props) => {
  const { className, name, value, title, active, onChange } = props;

  const [activeCard, setActiveCard] = useState<boolean>(active || false);

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.checked);
      setActiveCard(event.target.checked);
    },
    [onChange],
  );

  const classes = classNames(
    'bb-card',
    { 'bb-card--active': active },
    className,
  );

  return (
    <label className={classes}>
      <input
        type="checkbox"
        name={name}
        hidden
        checked={value || activeCard}
        onChange={handleOnChange}
      />
      {title}
      {active && <Checkbox />}
    </label>
  );
};

export default Card;
