import React, { useMemo } from 'react';
import classNames from 'classnames';
import './StepProgress.styles.scss';

type StepProgressProps = {
  className?: string;
  totalSteps: number;
  value: number;
};

const StepProgress: React.FC<StepProgressProps> = (props, ref) => {
  const { className, value, totalSteps } = props;

  const classes = classNames([
    'betbears-progress__progress__line-container',
    className,
  ]);

  const progressLineStyle = useMemo<React.CSSProperties>(() => {
    return {
      width: `${((value + 1) / totalSteps) * 100}%`,
      transition: `width 0.4s ease-in`,
    };
  }, [value, totalSteps]);

  return (
    <div className="betbears-progress">
      <div className={classes} style={progressLineStyle}></div>
    </div>
  );
};

export default StepProgress;
