import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import React, { useCallback, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import NumericField from 'components/Fields/NumericField';
import { TeamFilterData } from 'routers/MainRouter/pages/MainPage/providers/Teams/Teams.provider';
import { OverlayRef } from 'components/Overlay';
import Animation from 'components/Animation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';

import './TeamsFilterModal.styles.scss';

type TeamsFilterModalProps = {
  teamFilterData: TeamFilterData;
  onSubmit: (data: TeamFilterData) => void;
} & ModalProps;

const TeamsFilterModal: React.FC<TeamsFilterModalProps> = (props) => {
  const { teamFilterData, open, onSubmit, ...modalRest } = props;

  const teamFilterModalRef = useRef<OverlayRef>(null);

  const { limitWins, limitLoses } = teamFilterData;

  const onFormSubmit = useCallback(
    (values: TeamFilterData) => {
      if (onSubmit) onSubmit(values);
      modalRest.onClose();
    },
    [onSubmit, modalRest],
  );

  return (
    <Animation
      open={open}
      duration={600}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        className="bb-team-filter-modal"
        {...modalRest}
        open={open}
        onX={modalRest.onClose}
        ref={teamFilterModalRef}
        title={<p>filters</p>}
      >
        <Form
          initialValues={{ limitWins, limitLoses }}
          onSubmit={onFormSubmit}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="bb-team-filter-modal__container">
                <p>wins - greater than</p>
                <Field
                  component={NumericField}
                  name="limitWins"
                  value={limitWins}
                  min={0}
                  max={1000}
                />
                <p>loss - less than</p>
                <Field
                  component={NumericField}
                  name="limitLoses"
                  value={limitLoses}
                  min={0}
                  max={1000}
                />
              </div>
              <div className="bb-team-filter-modal__footer">
                <Button styleType="secondary" onClick={() => form.reset()}>
                  Clear Filter
                </Button>
                <Button styleType="primary" type="submit">
                  Apply Filter
                </Button>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default TeamsFilterModal;
