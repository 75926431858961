import api from 'api';
import classNames from 'classnames';
import Card from 'components/Card';
import InputField from 'components/Fields/InputField';
import {
  CoinTypes,
  PaymentType,
  TRANSACTION_NAMES,
  TRANSACTION_TYPES,
} from 'models/Transactions';
import { Button, Loader } from 'ncoded-component-library';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import { composeValidators, isNumber, required } from 'services/validators';
import { PaymentMethodType } from '../../depositsteps/DepositAmountStep/paymentMethod';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import utils from 'utils';

import './Withdraw.styles.scss';

export type WithdrawFormType = {
  transactionValue: string;
  coinType: CoinTypes;
};

type WithdrawProps = {
  className?: string;
};

const Withdraw: React.FC<WithdrawProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-withdraw', className);
  const [activeIndexMethod, setActiveIndexMethod] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const { coinsWithAddresses } = useContext(MainContext);

  const paymentMethods = useMemo(() => {
    return coinsWithAddresses
      .filter((coin) => coin.isWithdrawalEnabled)
      .map((coin) => {
        return {
          name: coin.coinType,
        } as PaymentMethodType;
      });
  }, [coinsWithAddresses]);

  const isWalletAddressSetted = useMemo(
    () => paymentMethods.length > 0,
    [paymentMethods],
  );

  const handleOnPayment = useCallback(
    (input: any, paymentMethod: PaymentMethodType) => {
      input.onChange(paymentMethod.name);
      setActiveIndexMethod(paymentMethods.indexOf(paymentMethod));
    },
    [paymentMethods],
  );

  const onWithdrawlSubmit = useCallback(async (values: WithdrawFormType) => {
    try {
      setLoading(true);
      const { transactionValue, coinType } = values;

      const transactionData = {
        transactionType: TRANSACTION_TYPES.INCOME,
        transactionName: TRANSACTION_NAMES.WITHDRAW,
        transactionValue: parseFloat(transactionValue.slice(1)),
        coinType,
      } as PaymentType;

      await api.transactions.makeWithdrawal(transactionData);
      await utils.toastSuccess('Successfully made withdrawal');
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Form
      onSubmit={onWithdrawlSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} className={classes}>
          {loading && <Loader />}
          <div className="bb-withdraw__content">
            <StandardAppearAnimation delay={100}>
              <p>Withdraw Details</p>
            </StandardAppearAnimation>

            <StandardAppearAnimation delay={200}>
              <Field
                disabled={!isWalletAddressSetted}
                name="transactionValue"
                label="Enter Withdraw Amount"
                component={InputField}
                type="text"
                isNumeric
                placeholder="$0.00"
                inputMode="numeric"
                validate={composeValidators(
                  required('Please enter withdraw amount!'),
                  isNumber('Value must be number'),
                )}
              />
            </StandardAppearAnimation>

            <StandardAppearAnimation delay={300}>
              <hr />
            </StandardAppearAnimation>

            <Field<CoinTypes>
              name="coinType"
              defaultValue={paymentMethods[0]?.name}
            >
              {({ input }) => {
                if (paymentMethods.length === 0)
                  return (
                    <EmptyPlaceholder
                      title="There aren't any wallet addreses added yet!"
                      description="Go to the settings page and add your wallet addresses."
                    />
                  );
                return paymentMethods.map(
                  (item: PaymentMethodType, index: number) => {
                    return (
                      <StandardAppearAnimation delay={400 + index * 100}>
                        <Card
                          key={index}
                          active={activeIndexMethod === index}
                          name="payment"
                          title={item.name}
                          onChange={() => handleOnPayment(input, item)}
                        />
                      </StandardAppearAnimation>
                    );
                  },
                );
              }}
            </Field>
          </div>
          <div className="bb-withdraw__footer">
            <Button disabled={!isWalletAddressSetted} type="submit">
              Withdraw Funds
            </Button>
          </div>
        </form>
      )}
    ></Form>
  );
};

export default Withdraw;
