import { UserType } from 'models/User';
import { AuthBody } from '../models/AuthBody';
import storageService, { STORAGE_KEYS } from './storageService';

export default {
  get authBody() {
    return storageService.getItem<AuthBody>(STORAGE_KEYS.AUTH);
  },

  get user() {
    return storageService.getItem<UserType>(STORAGE_KEYS.USER);
  },

  get token(): string {
    return storageService.getItem<string>(STORAGE_KEYS.TOKEN);
  },

  get registartionToken(): string {
    return storageService.getItem<string>(STORAGE_KEYS.REGISTRATION_TOKEN);
  },

  get refreshToken(): string {
    return storageService.getItem<string>(STORAGE_KEYS.REFRESH_TOKEN);
  },

  updateUserData(data: UserType) {
    storageService.setItem(STORAGE_KEYS.USER, data);
  },

  saveAuthBody(authBody: AuthBody) {
    storageService.setItem(STORAGE_KEYS.AUTH, authBody);
  },

  removeAuthBody() {
    storageService.removeItem(STORAGE_KEYS.USER);
    storageService.removeItem(STORAGE_KEYS.TOKEN);
    storageService.removeItem(STORAGE_KEYS.REFRESH_TOKEN);
  },
};
