import React, { useRef, useMemo, ReactElement } from 'react';
import useAnimation, {
  AnimationProps as SimpleAnimationProps,
  EnhancedAnimationProps,
} from 'hooks/useAnimation';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import uniqid from 'uniqid';

import './Animation.styles.scss';

type AnimationProps = {
  className?: string;
  children?: React.ReactNode;
  open: boolean;
  onExited?: () => void;
} & (EnhancedAnimationProps | SimpleAnimationProps);

const Animation: React.FC<AnimationProps> = (props) => {
  const { className, children, open, onExited, ...animationRest } = props;

  const { duration = 600 } = animationRest;
  const { current: uniqClassName } = useRef(`bb-animation--${uniqid()}`);

  const containerRef = useRef<HTMLDivElement>(null);

  const {
    appearAnimations = {},
    disappearAnimations = {},
    appearAnimationText,
    disappearAnimationText,
  } = useAnimation({
    duration,
    ...animationRest,
  });

  const classes = classNames('bb-animation', uniqClassName, {
    'bb-animation--appear':
      Object.keys(appearAnimations).includes('appear') ||
      Object.keys(disappearAnimations).includes('appear'),
    'bb-animation--move-up':
      Object.keys(appearAnimations).includes('moveUp') ||
      Object.keys(disappearAnimations).includes('moveUp'),
    'bb-animation--move-down':
      Object.keys(appearAnimations).includes('moveDown') ||
      Object.keys(disappearAnimations).includes('moveDown'),
    'bb-animation--move-left':
      Object.keys(appearAnimations).includes('moveLeft') ||
      Object.keys(disappearAnimations).includes('moveLeft'),
    'bb-animation--move-right':
      Object.keys(appearAnimations).includes('moveRight') ||
      Object.keys(disappearAnimations).includes('moveRight'),
  });

  const extendedChildren = useMemo(() => {
    const test = (el: any) => {
      if (el && typeof el === 'object') {
        containerRef.current = el.container;
      }
    };

    return React.cloneElement(children as ReactElement, {
      ref: test,
      className: `${(children as any).props.className} ${classes}`,
    });
  }, [children, classes]);

  return (
    <>
      <style>
        {`
      .${uniqClassName} { 
        --bb-appear-animation: ${appearAnimationText};
        --bb-disappear-animation: ${disappearAnimationText};
      }
    `}
      </style>
      <CSSTransition
        nodeRef={containerRef}
        classNames={{
          enter: 'bb-animation-enter',
          enterActive: 'bb-animation-enter-active',
          enterDone: 'bb-animation-enter-done',
          exit: 'bb-animation-ext',
          exitActive: 'bb-animation-exit-active',
          exitDone: 'bb-animation-exit-done',
        }}
        timeout={duration}
        unmountOnExit
        exit={true}
        in={open}
        onExited={onExited}
      >
        {extendedChildren}
      </CSSTransition>
    </>
  );
};

export default Animation;
