import React, { useMemo } from 'react';
import classNames from 'classnames';

import { BetData } from 'models/Bet';
import { convertXToYFilterFeedBetTypes } from 'utils';
import { useBetInformation } from 'hooks/useBetInformation';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import GameDetails from 'components/GameDetails';
import UserBetInfo from 'components/UserBetInfo';
import BetPlayersList from 'components/BetPlayersList';
import OutcomeMoney from '../OutcomeMoney';

import './FeedCard.styles.scss';

type FeedCardProps = {
  className?: string;
  betData?: BetData;
};

const FeedCard: React.FC<FeedCardProps> = ({ className, betData }) => {
  const convertedBetData = convertXToYFilterFeedBetTypes(betData);

  const { fixture, user, betOdd, bettingAmount, createdAt, totalBetAmount } =
    convertedBetData;

  const {
    firstTeam,
    secondTeam,
    league,
    sport,
    startDate: fixtureStartData,
    fixtureStatus,
    fixtureResults,
  } = fixture ?? {};

  const { teamName: firstTeamName } = firstTeam ?? {};
  const { teamName: secondTeamName } = secondTeam ?? {};
  const { firstTeamScore, secondTeamScore } = fixtureResults ?? {};

  const { bettingTeamName } = useBetInformation(convertedBetData);

  const playersBetOdd = useMemo(
    () => (betOdd > 1 ? +(1 + 1 / (betOdd - 1)).toFixed(2) : null),
    [betOdd],
  );

  return (
    <div className={classNames('bb-feed-card', className)}>
      <UserBetInfo
        user={user}
        betCreatedAt={createdAt}
        betStatus={fixtureStatus}
        betStatusStyleType="outline"
      />

      <div className="bb-feed-card__content">
        <GameDetails
          sport={sport}
          league={league}
          gameDate={fixtureStartData}
        />
        <div className="bb-feed-card__content__teams">
          <BetTeam
            {...firstTeam}
            key="first-team"
            bettingOdds={betOdd}
            bettingOddsActive={firstTeamName === bettingTeamName}
            fixtureStatus={fixtureStatus}
            teamScore={firstTeamScore}
            className={classNames('team', 'first-team')}
          />
          <BetTeam
            {...secondTeam}
            key="second-team"
            bettingOdds={playersBetOdd}
            bettingOddsActive={secondTeamName === bettingTeamName}
            fixtureStatus={fixtureStatus}
            teamScore={secondTeamScore}
            className={classNames('team', 'second-team')}
          />
        </div>

        <BetPlayersList bet={convertedBetData} />
      </div>

      <div className="bb-feed-card__footer">
        <OutcomeMoney
          styleType={'outline'}
          label={'total'}
          amount={totalBetAmount}
        />
        <OutcomeMoney label={'your bet'} amount={bettingAmount} />
      </div>
    </div>
  );
};

export default FeedCard;
