import React, { useEffect } from 'react';
import {
  Field,
  FieldProps,
  FieldRenderProps,
  useField,
  useForm,
} from 'react-final-form';

type ConditionalFieldProps<T> = FieldProps<
  T,
  FieldRenderProps<T>,
  HTMLElement
> & {
  shouldClear?: boolean;
  name: string;
};

function ConditionalField<T = any>(props: ConditionalFieldProps<T>) {
  const { shouldClear, name, ...rest } = props;

  const { change } = useForm();

  const {
    input: { value },
  } = useField(name);

  useEffect(() => {
    if (shouldClear && value) {
      change(name, undefined);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [change, shouldClear]);

  useEffect(() => {
    return () => {
      change(name, undefined);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Field<T> name={name} {...rest} />;
}

export default ConditionalField as <T extends unknown>(
  props: ConditionalFieldProps<T>,
) => React.ReactElement;
