import React from 'react';
import './Image.styles.scss';

type ImageProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  fallback?: string;
};

const Image: React.FC<ImageProps> = (props) => {
  const {
    src,
    alt,
    fallback = '/assets/images/logo.png',
    className,
    ...rest
  } = props;

  return (
    <img
      {...rest}
      src={src || ''}
      alt={alt}
      className={className}
      onError={(e) => {
        (e.target as HTMLImageElement).className = 'bb-image--error';
        (e.target as HTMLImageElement).onerror = null;
        (e.target as HTMLImageElement).src = fallback;
      }}
    />
  );
};

export default Image;
