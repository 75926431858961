import api from 'api';
import classNames from 'classnames';
import React, { useContext, useCallback } from 'react';
import Toggle from 'components/Toggle';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import utils from 'utils';

import './SoundOption.styles.scss';

type SoundOptionProps = {
  className?: string;
};

const SoundOption: React.FC<SoundOptionProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-sound-option', className);

  const { settingsData, setSettingsData } = useContext(MainContext);

  const handleOnMuteSound = useCallback(
    async (value: boolean) => {
      try {
        setSettingsData((oldData) => {
          return {
            ...oldData,
            isNotificationsMuted: value,
          };
        });

        await api.user.setUserMuteSetting(value);
      } catch (e) {
        utils.toastError(e);
      }
    },
    [setSettingsData],
  );

  return (
    <div className={classes}>
      <p>Mute Sound</p>
      <Toggle
        value={settingsData?.isNotificationsMuted}
        onChange={(value) => handleOnMuteSound(value)}
      />
    </div>
  );
};

export default SoundOption;
