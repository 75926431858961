import React from 'react';
import { useHistory } from 'react-router';
import classNames from 'classnames';

import { Button } from 'ncoded-component-library';
import WalletOption from './components/WalletOption';
import AppearAnimation from 'components/AppearAnimation';
import SoundOption from './components/SoundOption';
import Arrow from 'assets/svgs/Arrow';
import ReferralCodeOption from './components/ReferralCodeOption';
import OnboardingTutorialOption from './components/OnboardingTutorialOption';

import './Settings.styles.scss';

type SettingsProps = {
  className?: string;
};

const Settings: React.FC<SettingsProps> = ({ className }) => {
  const history = useHistory();

  return (
    <div className={classNames('bb-settings', className)}>
      <AppearAnimation duration={600} animationName="moveRight">
        <div className="bb-settings__container">
          <div className="bb-settings__header">
            <Button
              styleType="secondary"
              icon={Arrow}
              onClick={() => history.goBack()}
            />
            <p>settings</p>
          </div>

          <div className="bb-settings__options">
            <SoundOption />
            <WalletOption />
            <ReferralCodeOption />
            <OnboardingTutorialOption />
          </div>
        </div>
      </AppearAnimation>
    </div>
  );
};

export default Settings;
