import React, { useCallback, useContext, useMemo, useRef } from 'react';
import classNames from 'classnames';
import { Field, useField, useForm, useFormState } from 'react-final-form';
import FormWrapper from 'components/FormWrapper';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import { Button } from 'ncoded-component-library';
import { BetPrivacy } from '../../../../../../../../models/Bet';
import SettingsCardField from 'components/Fields/SettingsCardField';
import Private from 'assets/svgs/Private';
import Public from 'assets/svgs/Public';
import { required } from 'services/validators';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import NewBetContext from '../../providers/NewBet/NewBet.context';

import './PublishSettingsForm.styles.scss';
import useFormHelperElements from 'hooks/useFormHelperElements';

type PublishSettingsFormProps = {
  className?: string;
};

const PublishSettingsForm: React.FC<PublishSettingsFormProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-publish-settings', className);

  const { nextStep, setCurrentStep } = useContext(StepsContext);
  const { isBetEditing } = useContext(NewBetContext);

  const { SaveBetEdit } = useFormHelperElements();

  const { errors } = useFormState();
  const { change } = useForm();

  const hasError = useMemo(() => !(Object.keys(errors).length === 0), [errors]);
  const publishSettingsRef = useRef<HTMLDivElement>(null);

  const {
    input: { value: privacy },
  } = useField<BetPrivacy>('publishSettings');

  const handleOnFormSubmit = useCallback(() => {
    if (isBetEditing.current) {
      setCurrentStep(6);
      nextStep();
      return;
    }
    if (!hasError) setCurrentStep(7);
    else {
      const submitButton = document.createElement('button');
      publishSettingsRef.current.appendChild(submitButton);
      submitButton.style.display = 'none';
      submitButton.click();
    }
  }, [hasError, isBetEditing, nextStep, setCurrentStep]);

  return (
    <FormWrapper onSubmit={nextStep} className={classes} id="betModalForm">
      <div className="bb-publish-settings__container">
        <StandardAppearAnimation delay={100}>
          <Field
            name="publishSettings"
            inputValue="Private"
            component={SettingsCardField}
            icon={Private}
            title="Private"
            active={privacy === 'Private'}
            content="only your friend can answer this bet"
            validate={required('You need to choose bet privacy')}
          />
        </StandardAppearAnimation>
        <StandardAppearAnimation delay={100}>
          <Field
            name="publishSettings"
            inputValue="Public"
            component={SettingsCardField}
            icon={Public}
            title="Public"
            active={privacy === 'Public'}
            content="all users can view and answer this bet"
            validate={required('You need to choose bet privacy')}
          />
        </StandardAppearAnimation>

        {privacy === 'Private' && (
          <div>
            <Button
              styleType="secondary"
              type="button"
              onClick={() => {
                change('shareWithFriend', true);
                nextStep();
              }}
            >
              Share With Friends
            </Button>
          </div>
        )}
      </div>
      <div className="bb-publish-settings__footer" ref={publishSettingsRef}>
        <SaveBetEdit>Save</SaveBetEdit>
        <Button
          styleType={isBetEditing.current ? 'secondary' : 'primary'}
          onClick={handleOnFormSubmit}
        >
          Next Step
        </Button>
      </div>
    </FormWrapper>
  );
};

export default PublishSettingsForm;
