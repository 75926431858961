import React, { useContext, useState, useMemo } from 'react';
import classNames from 'classnames';
import Close from 'assets/svgs/Close';
import TeamCard from 'components/TeamCard';
import { Button } from 'ncoded-component-library';
import { teamSlides } from 'routers/MainRouter/services/radioSlides';
import TeamsContext from 'routers/MainRouter/pages/MainPage/providers/Teams/Teams.context';
import TeamPlayers from './components/TeamPlayers';
import TeamUpcomingGames from './components/TeamUpcomingGames';
import AnimatedSlider from 'components/AnimatedSlider';

import './TeamContent.styles.scss';

type TeamContentProps = {
  className?: string;
};

const TeamContent: React.FC<TeamContentProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-team', className);

  const [activeRadioIndex, setActiveRadioIndex] = useState<number>(0);

  const {
    currenSelectedTeam,
    setCurrentSelectedTeam,
    removeFromFavorites,
    addTeamToFavorites,
  } = useContext(TeamsContext);

  const animatedSlides = useMemo(
    () => [TeamPlayers, TeamUpcomingGames] as Array<React.ReactNode>,
    [],
  );

  return (
    <div className={classes}>
      <div className="bb-team__team">
        <Button
          icon={Close}
          styleType="secondary"
          onClick={() => setCurrentSelectedTeam(null)}
        />
        <TeamCard data={currenSelectedTeam} />

        {currenSelectedTeam.favorites ? (
          <Button
            styleType="secondary"
            ripple
            onClick={() => removeFromFavorites()}
          >
            Remove from Favorites
          </Button>
        ) : (
          <Button
            styleType="primary"
            ripple
            onClick={() => addTeamToFavorites()}
          >
            Add To Favorites
          </Button>
        )}
      </div>

      <AnimatedSlider
        className="bb-team__animated-slider"
        name="teams"
        elements={animatedSlides}
        radioSlides={teamSlides}
        activeIndex={activeRadioIndex}
        onRadioChange={(value) => setActiveRadioIndex(value)}
      />
    </div>
  );
};

export default TeamContent;
