import React, { useState } from 'react';
import Animation from 'components/Animation';
import { Button } from 'ncoded-component-library';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import Calendar from 'components/Calendar';
import './Catalog.styles.scss';

type CatalogProps = {};

const Home: React.FC<CatalogProps> = (props) => {
  const [open, setOpen] = useState<boolean>(false);

  const [date, setDate] = useState<string>();

  return (
    <div className="bb-catalog">
      <Button onClick={() => setOpen(true)}>Start Animation</Button>
      <Animation
        open={open}
        duration={800}
        appearAnimation="moveUp"
        disappearAnimation="moveDown"
        timingFunction={EASE_IN_OUT_CUBIC}
      >
        <div className="test">
          <Button onClick={() => setOpen(false)}>Close Animation</Button>
        </div>
      </Animation>

      <Calendar
        onlyPast
        value={date}
        onChange={(val) => {
          console.log(val);
          setDate(val);
        }}
      />
    </div>
  );
};

export default Home;
