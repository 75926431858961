import React, { useCallback, useContext, useState } from 'react';
import MainContext from '../MainPage/providers/Main/Main.context';
import { NavLink } from 'react-router-dom';
import Logout from 'assets/svgs/Logout';
import Flag from 'assets/svgs/flags/Flag';
import { Button } from 'ncoded-component-library';
import Arrow from 'assets/svgs/Arrow';
import AppearAnimation from 'components/AppearAnimation';
import routes from './router/routes';
import UserImage from 'components/UserImage';
import globalLogout from 'modules/globalLogout';
import utils from 'utils';
import api from 'api';

import './Menu.styles.scss';

const Menu: React.FC = () => {
  const navigationItems = routes.filter(
    (routes) =>
      routes.path !== '/' &&
      routes.path !== '/profile/:id' &&
      routes.path !== '/profile-settings',
  );
  const [userImage, setUserImage] = useState<File>();
  const { userData, setUserData } = useContext(MainContext);

  const imageUploadHandler = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      try {
        if (event.target.files && event.target.files.length !== 0) {
          setUserImage(event.target.files[0]);

          const { data } = await api.user.uploadUserImage(
            event.target.files[0],
          );

          setUserData((oldData) => {
            return {
              ...oldData,
              profileImageUrl: data.imageUrl,
            };
          });
        }
      } catch (e) {
        utils.toastError(e);
      }
    },
    [setUserData],
  );

  const logOut = useCallback(async () => {
    globalLogout();
  }, []);

  return (
    <AppearAnimation duration={150} animationName="appear">
      <div className="bb-menu">
        <div className="bb-menu__profile">
          <Button>
            <label>
              <UserImage
                src={
                  userImage
                    ? URL.createObjectURL(userImage)
                    : userData?.profileImageUrl
                }
              />
              <input
                type="file"
                accept="image/*"
                hidden
                onChange={imageUploadHandler}
              />
            </label>
          </Button>

          <div className="bb-menu__profile__data">
            <p>{`${userData?.username}`}</p>
            <div>
              <Flag />
              <p>{`${userData?.wins} wins`}</p>
              <Flag />
              <p>{`${userData?.loses} loss`}</p>
            </div>
            <NavLink to={`menu/profile/${userData.Id}`}>
              <Arrow />
            </NavLink>
          </div>
        </div>

        {navigationItems.map(({ icon, label, path }, index) => {
          return (
            <NavLink
              key={index}
              className="bb-menu__item"
              to={`menu${path as string}`}
            >
              {icon && utils.renderIcon(icon)}
              <p>{label}</p>
              {utils.renderIcon(Arrow)}
              {path === '/notifications' &&
                userData?.numberOfUnreadNotifications > 0 && (
                  <div>{userData?.numberOfUnreadNotifications}</div>
                )}
            </NavLink>
          );
        })}
        <div className="bb-menu__logout" onClick={() => logOut()}>
          <Logout />
          <p>Log Out</p>
        </div>
      </div>
    </AppearAnimation>
  );
};

export default Menu;
