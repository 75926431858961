import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-176 -208) translate(80 160) translate(96 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M11.989 12.884l2.51 2.51c-1.35 1.527-2.246 3.465-2.46 5.605l-.033.001c-2.296 0-4.361-.83-5.933-2.2l5.916-5.916zm3.393 3.393l2.536 2.536c-1.264 1.098-2.845 1.847-4.61 2.096.219-1.759.967-3.355 2.075-4.632zm5.523-2.959c-.252 1.751-1 3.339-2.103 4.61l-2.536-2.535c1.23-1.066 2.756-1.8 4.438-2.049zM8.596 9.491L11.105 12 5.19 17.915C3.819 16.333 3 14.263 2.999 12v-.052l-.32.028c2.263-.155 4.315-1.071 5.917-2.485zm10.185-3.4C20.161 7.674 20.998 9.742 21 12v.05l.3-.026c-2.263.155-4.315 1.071-5.917 2.485L12.873 12zM11.994 3c2.256 0 4.32.834 5.902 2.209l-5.907 5.907-2.509-2.51c1.35-1.527 2.245-3.465 2.46-5.604zM5.205 6.1l2.507 2.507c-1.273 1.104-2.863 1.85-4.615 2.073.255-1.738 1.008-3.314 2.108-4.58zm3.39 1.623L6.09 5.215c1.265-1.104 2.842-1.86 4.58-2.118-.22 1.757-.967 3.35-2.073 4.626z"
              transform="translate(-176 -208) translate(80 160) translate(96 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
