import React, { useContext, useCallback, useMemo } from 'react';
import Collapsible from 'components/Collapsible';
import Arrow from 'assets/svgs/Arrow';
import classNames from 'classnames';
import CoinAddressCard from 'components/CoinAddressCard';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import { CollapsibleRef } from 'components/Collapsible/Collapsible.component';
import { WalletType } from 'models/Wallet';
import useCallbackRef from 'hooks/useCallbackRef';

import './WalletOption.styles.scss';

type WalletOptionProps = {
  className?: string;
};

const WalletOption: React.FC<WalletOptionProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-wallet-option', className);

  const { coinsWithAddresses, coinsWithoutAddress, updateCoinsList } =
    useContext(MainContext);

  const [collapsible, collapsibleRef] = useCallbackRef<CollapsibleRef>();

  const handleOnEnterWalletAddress = useCallback(
    (data: WalletType) => {
      updateCoinsList(data);
    },
    [updateCoinsList],
  );

  const triggerClasses = useMemo(
    () =>
      classNames('bb-wallet-option__trigger', {
        'bb-wallet-option__trigger--open': collapsible?.open,
      }),
    [collapsible],
  );

  return (
    <Collapsible
      className={classes}
      defaultOpen={false}
      ref={collapsibleRef}
      trigger={
        <div className={triggerClasses}>
          <p>Wallet Addresses</p>
          <Arrow />
        </div>
      }
    >
      <div className="bb-wallet-option__wallet-content">
        {coinsWithoutAddress?.map((coin) => (
          <CoinAddressCard
            key={coin}
            isAddressSet={false}
            coinType={coin}
            onChange={handleOnEnterWalletAddress}
          />
        ))}

        {coinsWithAddresses?.map((coin) => {
          return (
            <CoinAddressCard
              {...coin}
              key={coin.Id}
              onChange={handleOnEnterWalletAddress}
            />
          );
        })}
      </div>
    </Collapsible>
  );
};

export default WalletOption;
