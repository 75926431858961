import { createContext } from 'react';
import utils from 'utils';

export type Step<ValidateRecord extends Record<string, any> = any> = {
  title: string;
  component: React.FC;
  validateFn?: (data: ValidateRecord) => boolean;
};

export type StepsContextProps = {
  activeStep: Step;
  steps: Step[];
  currentStep: number;
  previousStep: number;
  setCurrentStep: (value: number) => void;
  setSubmitting: (value: boolean) => void;
  nextStep: (values?: object) => void;
  prevStep: () => void;
};

export default createContext<StepsContextProps>({
  activeStep: null,
  steps: [],
  currentStep: 0,
  previousStep: 0,
  setCurrentStep: utils.noop,
  setSubmitting: utils.noop,
  nextStep: utils.noop,
  prevStep: utils.noop,
});
