import React from 'react';

export default () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_466)">
      <path
        d="M21.6 15L18.6 12H11.6C11.3348 12 11.0804 11.8946 10.8929 11.7071C10.7053 11.5196 10.6 11.2652 10.6 11V5C10.6 4.73478 10.7053 4.48043 10.8929 4.29289C11.0804 4.10536 11.3348 4 11.6 4H20.6C20.8652 4 21.1196 4.10536 21.3071 4.29289C21.4946 4.48043 21.6 4.73478 21.6 5V15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.6 15V17C15.6 17.2652 15.4946 17.5196 15.3071 17.7071C15.1196 17.8946 14.8652 18 14.6 18H7.59999L4.59999 21V11C4.59999 10.7348 4.70535 10.4804 4.89288 10.2929C5.08042 10.1054 5.33477 10 5.59999 10H7.59999"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_466">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0.599991)"
        />
      </clipPath>
    </defs>
  </svg>
);
