import React from 'react';
import classNames from 'classnames';

import './OnboardingSlide.styles.scss';

type OnBoardingSlideProps = {
  className?: string;
  text?: string;
  heading?: string;
};

const OnboardingSlide: React.FC<OnBoardingSlideProps> = (props) => {
  const { className, text, heading } = props;

  return (
    <div className={classNames('bb-onboarding-slide', className)}>
      <p className="bb-onboarding-slide__heading">{heading}</p>
      <p className="bb-onboarding-slide__text">{text}</p>
    </div>
  );
};

export default OnboardingSlide;
