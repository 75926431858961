import React, { useRef, useState } from 'react';

import NewBetContext from './NewBet.context';
import { InitialFixtureData } from './NewBet.context';

type NewBetProps = {
  children: React.ReactNode;
};

const NewBet: React.FC<NewBetProps> = (props) => {
  const { children } = props;

  const editingBetId = useRef<string>('');
  const isBetEditing = useRef<boolean>(false);

  const [initialFixtureData, setInitialFixtureData] =
    useState<InitialFixtureData>(null);

  return (
    <NewBetContext.Provider
      value={{
        initialFixtureData,
        setInitialFixtureData,
        isBetEditing,
        editingBetId,
      }}
    >
      {children}
    </NewBetContext.Provider>
  );
};

export default NewBet;
