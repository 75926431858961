import Checkbox from 'assets/svgs/Checkbox';
import Search from 'assets/svgs/Search';
import classNames from 'classnames';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import InfiniteScroll from 'components/InfiniteScroll';
import { FriendshipType } from 'models/Friends';
import confirm from 'modules/confirm';
import { Input, Loader } from 'ncoded-component-library';
import React, { useCallback, useContext } from 'react';
import FriendsContext from '../../providers/Friends/Friends.context';
import FriendShipCard from '../FriendShipCard';

import './MyFriends.styles.scss';

type MyFriendsProps = {
  className?: string;
};

const MyFriends: React.FC<MyFriendsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-my-friends', className);

  const {
    loadingMyFriends,
    myFriends,
    myFriendSearchString,
    deleteFriendship,
    setMyFriendSearchString,
    onMyFriendsScroll,
  } = useContext(FriendsContext);

  const handleOnDeleteFriendship = useCallback(
    async (friendship: FriendshipType) => {
      if (
        await confirm.simpleConfirmation({
          title: `confirm unfirned`,
          description: `Are you sure you want to unfriend ${friendship.sender.username}`,
        })
      )
        deleteFriendship(friendship.Id);
    },
    [deleteFriendship],
  );

  return (
    <InfiniteScroll className={classes} onScroll={onMyFriendsScroll}>
      <Input
        prefixNode={<Search />}
        placeholder="Search..."
        value={myFriendSearchString}
        onChange={(e) => setMyFriendSearchString(e.target.value)}
      />

      {myFriends.length === 0 && (
        <EmptyPlaceholder
          title="You haven't made any friendships!"
          description="Your friends will be listed here when you accept or send friend request to someone."
        />
      )}

      {myFriends.map((friendship, index) => {
        return (
          <FriendShipCard
            to={`profile/${friendship?.sender.Id}`}
            key={index}
            text="Friend"
            buttonStyle="secondary"
            icon={Checkbox}
            user={friendship.sender}
            onAction={() => handleOnDeleteFriendship(friendship)}
          />
        );
      })}

      {loadingMyFriends && <Loader />}
    </InfiniteScroll>
  );
};

export default MyFriends;
