import { AuthBody } from 'models/AuthBody';
import { CreateAccountFormType } from 'pages/Auth/components/CreateAcountModal/CreateAcountModal.component';
import httpClient from '../httpClient';

const authPath = (routePath: TemplateStringsArray) => `auth/${routePath}`;
const driverLicencePath = (driverLicencePath: TemplateStringsArray) =>
  `users/${driverLicencePath}`;

function sendAuthenticationEmail(username: string, email: string) {
  return httpClient.post(authPath``, { username, email });
}

function verifyUserEmail(verificationKey: string, email: string) {
  return httpClient.post(authPath`email/verify`, { verificationKey, email });
}

function register(userData: CreateAccountFormType) {
  return httpClient.post<AuthBody>(authPath`signup`, userData);
}

function login(userData: { username: string; password: string }) {
  return httpClient.post<AuthBody>(authPath`login`, userData);
}

function logout() {
  return httpClient.post(authPath`logout`, null, { skipRefresh: true } as any);
}

function sendRefreshToken(refreshToken: string) {
  return httpClient.post<AuthBody>(authPath`refreshToken`, { refreshToken }, {
    isRefresh: true,
  } as any);
}

function forgotPassword(email: string) {
  return httpClient.post(authPath`forgot-password`, { email });
}

function resetPassword(token: string, password: string) {
  return httpClient.post(`/auth/${token}/reset-password`, { password });
}

function driverLicenceUpload(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return httpClient.post(driverLicencePath`upload`, formData);
}

export default {
  sendAuthenticationEmail,
  verifyUserEmail,
  register,
  login,
  logout,
  forgotPassword,
  resetPassword,
  sendRefreshToken,
  driverLicenceUpload,
};
