import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-140 -74) translate(12 68) translate(128 6)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M5.563 16.46l5.52 5.517-3.469.001c-1.666 0-3.02-1.294-3.11-2.936l-.006-.178c0-.963.393-1.828 1.065-2.405zm4.021-.732l6 6c-.27.13-.563.212-.866.24l-.229.01-1.638-.001-6.13-6.129c.227-.062.467-.102.72-.115l.19-.005h1.953zm10.871-6.229l-3.594 10.78c-.078.233-.187.447-.32.638l-5.19-5.189h1.753l2.078-6.229h5.273zm-15.961 0c1.38.002 2.503 1.12 2.504 2.5-.001 1.382-1.241 2.5-2.491 2.5S2 13.382 2 12c.001-1.38 1.113-2.498 2.494-2.5zM19.488 2c.262 0 .53.041.791.128 1.048.35 1.71 1.323 1.71 2.37 0 .198-.023.397-.072.594l-.057.195-.988 2.962H15.6l1.518-4.55v.011c.35-1.047 1.323-1.711 2.371-1.71z"
              transform="translate(-140 -74) translate(12 68) translate(128 6)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
