import InputField from 'components/Fields/InputField';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { ExtractPropTypes } from 'types';
import AnimatedComponent from '../AnimatedComponent';
import './AnimatedField.styles.scss';

type AnimatedFieldProps<T = any> = {
  as?: React.FC<T>;
} & FieldRenderProps<string, HTMLElement> &
  Pick<ExtractPropTypes<typeof AnimatedComponent>, 'delay' | 'duration'>;

const AnimatedField: React.FC<AnimatedFieldProps> = (props) => {
  const { as = InputField, children, ...rest } = props;

  return <AnimatedComponent component={as} {...rest} />;
};

export default AnimatedField;
