import React from 'react';
import classNames from 'classnames';
import { ModalProps } from 'components/Modal/Modal.component';
import { BetData } from 'models/Bet';
import Modal from 'components/Modal';
import { Button, Loader } from 'ncoded-component-library';
import Arrow from 'assets/svgs/Arrow';
import InfiniteScroll from 'components/InfiniteScroll';
import useInfinitePagination from 'hooks/useInfinitePagination';
import Animation from 'components/Animation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import UserImage from 'components/UserImage';
import BetCard from '../BetCard';
import api from 'api';

import './BetOverview.styles.scss';

type BetOverviewProps = {
  className?: string;
  betData: BetData;
  onBack?: () => void;
  onBetChange?: (value: BetData) => void;
} & ModalProps;

const BetOverview: React.FC<BetOverviewProps> = (props) => {
  const { className, betData, open, onBack, onBetChange, ...rest } = props;

  const classes = classNames('bb-bet-overview', className);

  const {
    loading: loadingTicketBets,
    items: ticketBets,
    onContainerScrolled: onTicketBetScroll,
  } = useInfinitePagination<BetData>({
    makeRequest: (currentPage: number) => {
      return api.bets
        .getBetsForTicket(
          {
            page: currentPage,
            limit: 5,
          },
          betData.ticketId,
        )
        .then(({ data }) => data);
    },
    skipFirst: true,
    dependencies: [betData],
    resetDeps: [betData],
  });

  return (
    <Animation
      open={open}
      appearAnimations={{
        moveRight: {
          duration: 400,
          timingFunction: EASE_IN_OUT_CUBIC,
        },
      }}
      disappearAnimations={{
        moveOutRight: {
          duration: 600,
          timingFunction: EASE_IN_OUT_CUBIC,
        },
      }}
    >
      <Modal
        className={classes}
        {...rest}
        open
        onX={() => {
          rest.onClose();
          onBack?.();
        }}
      >
        <div className="bb-bet-overview__container">
          {loadingTicketBets && <Loader />}

          <div className="bb-bet-overview__header">
            <Button
              ripple
              styleType="secondary"
              icon={Arrow}
              onClick={() => {
                rest.onClose();
                onBack?.();
              }}
            />
            <p>Bet Details</p>
          </div>

          <InfiniteScroll
            onScroll={onTicketBetScroll}
            className="bb-bet-overview__content"
          >
            {betData && (
              <BetCard betData={betData} onAccept={() => rest.onClose()} />
            )}

            {loadingTicketBets && <Loader />}
            {!loadingTicketBets &&
              ticketBets?.map((bet, index) => {
                return (
                  <div
                    key={index}
                    className={classNames('bb-bet-overview__user-card', {
                      'bb-bet-overview__user-card--active':
                        bet.Id === betData?.Id,
                    })}
                    onClick={() => onBetChange(bet)}
                  >
                    <UserImage
                      userId={bet.user.Id}
                      active={bet.user.isOnline}
                      src={bet.user.profileImageUrl}
                    />

                    <div className="bb-bet-overview__user-card__content">
                      <div>
                        <p>{`${bet.user.username}`}</p>
                        <p>{`$${bet.bettingAmount.toFixed(2)}`}</p>
                      </div>
                      <div>
                        <p>{`${bet.betOutcomes.length} OUTCOME`}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
          </InfiniteScroll>
        </div>
      </Modal>
    </Animation>
  );
};

export default BetOverview;
