import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import './UserImage.styles.scss';

type UserImageProps = {
  className?: string;
  active?: boolean;
  fallback?: string;
  userId?: string;
  onClick?: () => void;
} & React.ImgHTMLAttributes<HTMLImageElement>;

const UserImage: React.FC<UserImageProps> = (props) => {
  const {
    children,
    className,
    active,
    fallback = '/assets/images/avatar.svg',
    src,
    alt,
    userId,
    onClick,
    ...rest
  } = props;

  const history = useHistory();
  const classes = classNames('bb-user-image', className);

  return (
    <div
      className={classes}
      onClick={() =>
        userId !== undefined ? history.push(`/menu/profile/${userId}`) : onClick
      }
    >
      {active && <span className="bb-user-image--active" />}
      <img
        {...rest}
        src={src || ''}
        alt={'' || alt}
        onError={(e) => {
          (e.target as HTMLImageElement).className = classNames(
            'bb-user-image--error',
            className,
          );
          (e.target as HTMLImageElement).src = fallback;
          (e.target as HTMLImageElement).onerror = null;
        }}
      />
      {children}
    </div>
  );
};

export default UserImage;
