import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g fill="#FFF" fillOpacity="0">
                <path
                  d="M0 0H24V24H0z"
                  transform="translate(-26 -290) translate(8 56) translate(0 216) translate(18 18)"
                />
              </g>
              <path
                fill="currentColor"
                fillRule="nonzero"
                d="M12 14c2.142 0 3.891 1.684 3.995 3.8L16 18H2c0-2.21 1.79-4 4-4l.08.06C6.9 14.65 7.91 15 9 15c1.126 0 2.165-.372 3-1zm9.5 1c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-4c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h4zM9 6c2.21 0 4 1.79 4 4s-1.79 4-4 4-4-1.79-4-4 1.79-4 4-4zm12.5 5c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-7c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h7zm0-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-7c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h7z"
                transform="translate(-26 -290) translate(8 56) translate(0 216) translate(18 18)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
