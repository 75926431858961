import React, { useMemo } from 'react';
import classNames from 'classnames';
import FormWrapper from 'components/FormWrapper';
import RangeSliderField from 'components/Fields/RangeSliderField';
import useFormHelperElements from 'hooks/useFormHelperElements';
import { Field, useFormState } from 'react-final-form';
import InputField from 'components/Fields/InputField';
import {
  composeValidators,
  maxValue,
  minValue,
  required,
} from 'services/validators';

import './OddsForm.styles.scss';

type OddsFormProps = {
  className?: string;
};

const OddsForm: React.FC<OddsFormProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-odds', className);

  const { ErrorMessage, SubmitBet, SaveBetEdit } = useFormHelperElements();

  const validatorsObject = useMemo(
    () => ({
      betOdd: composeValidators(
        required('Bet Amount is Required'),
        minValue('Minimum value for odds is 1.01', 1.01),
        maxValue('Maximum value for odds is 20.00', 20.0),
      ),
    }),
    [],
  );

  const {
    values: { betAmount, betOdd },
  } = useFormState<{ betAmount: number; betOdd: number }>();

  const minContent = useMemo(
    () =>
      ({ minValue }: { minValue: number }) => {
        return `${minValue.toFixed(2)}`;
      },
    [],
  );

  const maximumWinAmount = useMemo(
    () => Number(betAmount * betOdd - betAmount).toFixed(2) || betAmount,
    [betAmount, betOdd],
  );

  const winAmountInfo = useMemo(
    () => (
      <p className="bb-odds__help__a">{`${
        Number(betOdd)?.toFixed(2) || 1
      } means that for your bet of $${betAmount} you will win $${
        Number(betAmount * betOdd - betAmount).toFixed(2) || 1
      }`}</p>
    ),
    [betAmount, betOdd],
  );

  return (
    <FormWrapper className={classes} id="betModalForm">
      <div className="bb-odds__container">
        <label className="bb-odds__container__label">
          drag for select bet odds
        </label>
        <Field
          type="number"
          className="bb-odds__value"
          name="betOdd"
          min={1.01}
          max={5.0}
          step="0.01"
          component={InputField}
          validate={validatorsObject.betOdd}
        />
        <Field
          name="betOdd"
          min={1}
          max={5}
          className="bb-odds__range-slider"
          variant="single"
          line
          minContent={minContent}
          component={RangeSliderField}
        />
        <p className="bb-odds__maximum">{`You will win a maximum of $${
          isNaN(maximumWinAmount as number) ? 0 : maximumWinAmount
        }`}</p>
        <div className="bb-odds__help">
          <p className="bb-odds__help__q">What is this?</p>
          {winAmountInfo}
        </div>
      </div>
      <div className="bb-outcomes-form__footer">
        <ErrorMessage />
        <SaveBetEdit>Save</SaveBetEdit>
        <SubmitBet>Next Step</SubmitBet>
      </div>
    </FormWrapper>
  );
};

export default OddsForm;
