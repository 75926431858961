import InfiniteScroll from 'components/InfiniteScroll';
import React, { useCallback, useContext } from 'react';
import BetContext from '../../providers/Bet/Bet.context';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import { BetData, BETTING_STATUS, UserBetData } from 'models/Bet';
import classNames from 'classnames';
import BetCard from '../BetCard';

import './BuddyBets.styles.scss';

type BuddyBetsProps = {
  className?: string;
};

const BuddyBets: React.FC<BuddyBetsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-buddy-bets', className);

  const { userData } = useContext(MainContext);

  const { buddyBets, loadingBuddyBets, onBuddyBetsScroll, setBuddyBets } =
    useContext(BetContext);

  const acceptBetHandler = useCallback(
    (paymentAmount: number, newBetData: UserBetData) => {
      const {
        bet: { currentMaxPayment: newMaxPayment, betStatus, Id: betId },
        bettingTipAmount,
      } = newBetData;

      const betData = buddyBets.find((bet) => bet.Id === betId);
      if (
        betData.currentMaxPayment === +paymentAmount ||
        betStatus === BETTING_STATUS.Close
      )
        setBuddyBets((oldBets) => oldBets.filter((bet) => bet.Id !== betId));
      else
        setBuddyBets((oldBets) =>
          oldBets.map((bet) => {
            if (bet.Id === betId) {
              return {
                ...bet,
                currentMaxPayment: newMaxPayment,
                betTips: !bet.betTips.find((d) => d.userId === userData.Id)
                  ? [...bet.betTips, newBetData as any]
                  : bet.betTips.map((betTip) => {
                      if (betTip.userId === userData.Id) {
                        return {
                          ...betTip,
                          bettingTipAmount: bettingTipAmount,
                        };
                      } else return betTip;
                    }),
              };
            } else return bet;
          }),
        );
    },
    [buddyBets, setBuddyBets, userData.Id],
  );

  return (
    <InfiniteScroll className={classes} onScroll={onBuddyBetsScroll}>
      {buddyBets.length === 0 && !loadingBuddyBets && (
        <EmptyPlaceholder
          title="There aren't any buddy bets yet!"
          description="Bets will be listed here after someone of your friends create the bet."
        />
      )}

      {buddyBets?.map((bet: BetData, index: number) => {
        return (
          <BetCard
            key={index}
            betData={bet}
            showStatus={false}
            onAccept={(amount, betData) => acceptBetHandler(amount, betData)}
          />
        );
      })}
    </InfiniteScroll>
  );
};

export default BuddyBets;
