import React from 'react';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import { FieldRenderProps } from 'react-final-form';
import './AnimatedComponent.styles.scss';

type AnimatedButtonProps<T = {}> = {
  delay?: number;
  duration?: number;
  component: React.FC<T & Partial<FieldRenderProps<string, HTMLElement>>>;
} & T;

function AnimatedButton<T extends {}>(props: AnimatedButtonProps<T>) {
  const { delay, duration, component: Component, ...rest } = props;

  return (
    <StandardAppearAnimation delay={delay} duration={duration}>
      <Component {...(rest as any as T)} />
    </StandardAppearAnimation>
  );
}

export default AnimatedButton;
