import React, { useMemo } from 'react';
import { ExtractPropTypes } from 'types';
import { FieldRenderProps } from 'react-final-form';
import RadioSlider from 'components/RadioSlider';
import classNames from 'classnames';
import './RadioSliderField.styles.scss';

type RadioSliderProps = ExtractPropTypes<typeof RadioSlider>;

type RadioSliderFieldProps = RadioSliderProps &
  FieldRenderProps<any, HTMLElement>;

const RadioSliderField: React.FC<RadioSliderFieldProps> = (props) => {
  const {
    input,
    meta: { submitFailed, error },
    className,
    ...rest
  } = props;

  const hasError = useMemo(() => submitFailed && error, [error, submitFailed]);

  const classes = classNames('bb-radio-slider-field', {
    'bb-radio-slider-field--error': hasError,
  });

  return <RadioSlider className={classes} {...rest} {...input} />;
};

export default RadioSliderField;
