import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import Menus from 'assets/svgs/Menus';
import Add from 'assets/svgs/Add';
import { Button } from 'ncoded-component-library';
import './Numeric.styles.scss';
import utils from 'utils';

type NumericType = 'add' | 'sub';

type NumericProps = {
  hasDecimals?: boolean;
  className?: string;
  styleType?: 'primary' | 'secondary';
  disabled?: boolean;
  min?: number;
  max?: number;
  step?: number;
  value?: number;
  readOnly?: boolean;
  customIcons?: Array<React.ReactNode>;
  onChange?: (value: any) => void;
} & React.InputHTMLAttributes<HTMLInputElement>;

const Numeric: React.FC<NumericProps> = (props) => {
  const {
    className,
    styleType = 'primary',
    customIcons,
    disabled,
    value = 0,
    min,
    max,
    onChange,
    hasDecimals,
    ...rest
  } = props;
  const classes = classNames(
    'bb-numeric',
    `bb-numeric--${styleType}-style-type`,
    {
      'bb-numeric--disabled': disabled,
    },
    className,
  );

  const [numValue, setNumValue] = useState<number>(0);

  const renderIcons = useMemo(() => {
    if (customIcons) {
      return {
        plus: customIcons[0] as React.ReactNode,
        minus: customIcons[1] as React.ReactNode,
      };
    } else {
      return {
        plus: Add as React.ReactNode,
        minus: Menus as React.ReactNode,
      };
    }
  }, [customIcons]);

  const validate = useCallback(
    (numValue: number, type: NumericType) => {
      if (type === 'add') {
        if (numValue > max) return false;
        else return true;
      } else if ((type = 'sub')) {
        if (numValue < min) return false;
        else return true;
      }
    },
    [max, min],
  );

  const handleOnPlus = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (disabled) return;
      setNumValue((old) => {
        if (!validate(old + 1, 'add')) return old;
        else {
          onChange(old + 1);
          return old + 1;
        }
      });
    },
    [disabled, onChange, validate],
  );

  const handleOnMinus = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (disabled) return;
      setNumValue((old) => {
        if (!validate(old - 1, 'sub')) return old;
        else {
          onChange(old - 1);
          return old - 1;
        }
      });
    },
    [disabled, onChange, validate],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value.slice(1).replace(/,/g, '');

      if (/^[a-zA-Z]*$/.test(value)) {
        setNumValue(0);
        onChange(0);
      } else {
        setNumValue(parseInt(value));
        onChange(parseInt(value));
      }
    },
    [onChange],
  );

  useEffect(() => {
    if (value) {
      setNumValue(value);
    }
  }, [value]);

  return (
    <div className={classes}>
      <Button
        styleType="secondary"
        icon={renderIcons.minus}
        onClick={handleOnMinus}
      />
      <input
        type="text"
        disabled={disabled}
        value={
          hasDecimals
            ? `$${utils.getDotedNumber(numValue.toFixed(0))}`
            : numValue
        }
        onChange={handleOnChange}
        {...rest}
      />
      <Button
        styleType="secondary"
        icon={renderIcons.plus}
        onClick={handleOnPlus}
      />
    </div>
  );
};

export default Numeric;
