import React, { useState, useMemo, HTMLAttributes, useContext } from 'react';
import { Button, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import NewBetContext from 'routers/MainRouter/pages/MainPage/components/NewBetModal/providers/NewBet/NewBet.context';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';

type ButtonProps = ExtractPropTypes<typeof Button>;

export default function (): {
  Submit: React.FC<ButtonProps>;
  Cancel: React.FC<ButtonProps>;
  SubmitBet: React.FC<ButtonProps>;
  SaveBetEdit: React.FC<ButtonProps>;
  ErrorMessage: React.FC<HTMLAttributes<HTMLParagraphElement>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setErrorMsg: React.Dispatch<React.SetStateAction<string>>;
} {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const { isBetEditing } = useContext(NewBetContext);
  const { nextStep } = useContext(StepsContext);

  const Submit = useMemo(
    () =>
      ({ children, styleType = 'primary', ...rest }: ButtonProps) =>
        (
          <Button ripple type="submit" styleType={styleType} {...rest}>
            {loading ? <Loader inline overlay={false} /> : children}
          </Button>
        ),
    [loading],
  );

  const Cancel = useMemo(
    () =>
      ({ children, ...rest }: ButtonProps) =>
        (
          <Button ripple type="button" styleType="secondary" {...rest}>
            {children}
          </Button>
        ),
    [],
  );

  const ErrorMessage = useMemo(
    () =>
      ({ className, ...rest }: HTMLAttributes<HTMLParagraphElement>) =>
        errorMsg ? (
          <p className={classNames(className, 'bb-form__error-msg')} {...rest}>
            {errorMsg}
          </p>
        ) : null,
    [errorMsg],
  );

  const SubmitBet = useMemo(
    () =>
      ({ children, styleType, ...rest }: ButtonProps) =>
        (
          <Submit
            {...rest}
            styleType={isBetEditing.current ? 'secondary' : 'primary'}
            onClick={(event) => {
              if (isBetEditing.current) {
                event.preventDefault();
                nextStep();
              }
            }}
          >
            {children}
          </Submit>
        ),
    [loading],
  );

  const SaveBetEdit = useMemo(
    () =>
      ({ children, ...rest }: ButtonProps) =>
        isBetEditing.current && (
          <Button
            ripple
            type="submit"
            styleType="primary"
            form="betModalForm"
            className="save-edit-bet-button"
            {...rest}
          >
            {loading ? <Loader inline overlay={false} /> : children}
          </Button>
        ),
    [loading],
  );

  return {
    Submit,
    SubmitBet,
    Cancel,
    SaveBetEdit,
    ErrorMessage,
    setLoading,
    setErrorMsg,
  };
}
