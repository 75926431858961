import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';
import { LEAGUES, LeagueType, SportType } from 'models/Sport';

export const showSlides = [
  {
    element: 'All Bets',
    value: 'ALL_BETS',
  },
  {
    element: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    element: 'Previous',
    value: 'PREVIOUS',
  },
] as Array<RadioSlideType>;

export const filterSlides = [
  {
    element: 'All Bets',
    value: 'ALL_BETS',
  },
  {
    element: 'My Bets',
    value: 'MY_BETS',
  },
  {
    element: 'Accepted',
    value: 'ACCEPTED',
  },
] as Array<RadioSlideType>;

export const sortSlides = [
  {
    element: 'Price',
    value: 'PRICE',
  },
  {
    element: 'Most Recent',
    value: 'MOST_RECENT',
  },
  {
    element: 'Popularity',
    value: 'POPULARITY',
  },
] as Array<RadioSlideType>;

export const timeSlides = [
  {
    element: 'Time Started',
    value: 'TIME_STARTED',
  },
  {
    element: 'Popularity',
    value: 'POPULARITY',
  },
] as Array<RadioSlideType>;

export const timeSlides2: Array<RadioSlideType<number>> = [
  {
    element: 'Time Started',
    value: 2,
  },
  {
    element: 'Popularity',
    value: 3,
  },
];

export const myBetsSlides = [
  {
    element: 'Upcoming',
    value: 'UPCOMING',
  },
  {
    element: 'Completed',
    value: 'PREVIOUS',
  },
  {
    element: 'In progress',
    value: 'IN_PROGRESS',
  },
] as Array<RadioSlideType<string>>;

export const LEAGUES_SLIDES = {
  BASKETBALL: [
    { element: LEAGUES.NBA, value: 'NBA' },
    { element: LEAGUES.NCAA, value: 'NCAA' },
  ],
  SOCCER: [
    { element: LEAGUES.PREMIER_LEAGUE, value: 'PREMIER_LEAGUE' },
    { element: LEAGUES.CHAMPIONS_LEAGUE, value: 'CHAMPIONS_LEAGUE' },
  ],
  AMERICAN_FOOTBALL: [{ element: LEAGUES.NFL, value: 'NFL' }],
  BASEBALL: [{ element: LEAGUES.MLB, value: 'MLB' }],
  ICE_HOCKEY: [{ element: LEAGUES.NHL, value: 'NHL' }],
} as Record<SportType, Array<RadioSlideType<LeagueType>>>;
