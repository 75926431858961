import React, { useCallback, useMemo, useState } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { Button, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import Checkbox from 'assets/svgs/Checkbox';
import ClipboardIcon from 'assets/svgs/Clipboard.icon';
import InputField from 'components/Fields/InputField';
import FormWrapper from 'components/FormWrapper';
import { COIN_TYPES } from 'models/Transactions';
import { DepositFormType } from '../../WalletModal.component';
import CreditCardForm from '../DepositAmountStep/components/CreditCardForm';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';

import api from 'api';
import env from 'env';
import utils from 'utils';

import './WalletCheckoutDetails.styles.scss';

const stripePromise = loadStripe(env.STRIPE_KEY);

const CHECKOUT_TYPES: Record<string, 'ADDRESS' | 'AMOUNT'> = {
  ADDRESS: 'ADDRESS',
  AMOUNT: 'AMOUNT',
} as const;
type WalletCheckoutTypes = typeof CHECKOUT_TYPES[number];

const WalletCheckoutDetails: React.FC = () => {
  const {
    values: { address, amount, coinType, checkoutUrl },
  } = useFormState<DepositFormType>();

  const { values } = useFormState<DepositFormType>();
  const { change } = useForm<DepositFormType>();

  const { clientSecret, stripeTransactionId } = values;

  const [isAddressCopied, setIsAddressCopied] = useState<boolean>(false);
  const [isAmountCopied, setIsAmountCopied] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const showStripeElements = useMemo(
    () => values.paymentMethod === COIN_TYPES.CREDIT_CARD,
    [values],
  );

  const copyAddresIcon = useMemo(
    () => (isAddressCopied ? Checkbox : ClipboardIcon),
    [isAddressCopied],
  );

  const copyAmountIcon = useMemo(
    () => (isAmountCopied ? Checkbox : ClipboardIcon),
    [isAmountCopied],
  );

  const copyToClipboard = useCallback(
    (copiedValue: WalletCheckoutTypes, text: string) => {
      navigator.clipboard.writeText(text);

      if (copiedValue === CHECKOUT_TYPES.ADDRESS) {
        setIsAddressCopied(true);
        setTimeout(() => {
          setIsAddressCopied(false);
        }, 1500);
      } else if (copiedValue === CHECKOUT_TYPES.AMOUNT) {
        setIsAmountCopied(true);
        setTimeout(() => {
          setIsAmountCopied(false);
        }, 1500);
      }
    },
    [],
  );

  const checkoutDetails = useMemo(
    () =>
      showStripeElements && clientSecret ? (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret,
            appearance: {
              theme: 'stripe',
            },
          }}
        >
          <CreditCardForm
            stripeTransactionId={stripeTransactionId}
            transactionValue={values.transactionValue}
          />
        </Elements>
      ) : (
        <>
          <div className="bb-credit-card__value">
            <Field
              name="address"
              component={InputField}
              label="Wallet Address"
              placeholder="Wallet Address"
            />
            <Button
              className={classNames({
                'bb-credit-card__active-button': isAddressCopied,
              })}
              type="button"
              icon={copyAddresIcon}
              onClick={() => copyToClipboard(CHECKOUT_TYPES.ADDRESS, address)}
            />
          </div>
          <div className="bb-credit-card__value">
            <Field
              name="amount"
              component={InputField}
              label={`${coinType} coins amount`}
              placeholder="Wallet Address"
            />
            <Button
              className={classNames({
                'bb-credit-card__active-button': isAmountCopied,
              })}
              type="button"
              icon={copyAmountIcon}
              onClick={() =>
                copyToClipboard(CHECKOUT_TYPES.AMOUNT, amount.toString())
              }
            />
          </div>
          <Button
            type="button"
            onClick={() => window.open(checkoutUrl, '_blank')}
          >
            View Status
          </Button>
        </>
      ),
    [
      showStripeElements,
      clientSecret,
      values,
      isAddressCopied,
      coinType,
      isAmountCopied,
      address,
      amount,
      checkoutUrl,
      stripeTransactionId,
      copyAddresIcon,
      copyAmountIcon,
      copyToClipboard,
    ],
  );

  useEffectOnceWhen(() => {
    (async () => {
      try {
        setLoading(true);

        const { data } = await api.coins.createPaymentIntent(
          parseInt(values.transactionValue.replace('$', '')),
        );

        change('clientSecret', data.clientSecret);
        change('stripeTransactionId', data.stripePaymentTransactionId);
      } catch (e) {
        console.error(e);
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    })();
  }, showStripeElements && !window.activeTransactionView);

  return (
    <FormWrapper className="bb-credit-card">
      <div className="bb-credit-card__container">
        <p>Transaction Details</p>
        {loading && showStripeElements ? <Loader /> : checkoutDetails}
      </div>
    </FormWrapper>
  );
};

export default WalletCheckoutDetails;
