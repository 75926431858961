import { ToastrProps } from 'components/UI/Toastr/Toastr.component';
import Toastr from 'components/UI/Toastr/Toastr.component';
import React from 'react';
import ReactDOM from 'react-dom';

type Config = {
  disappearAfter?: number;
} & ToastrProps;

type ToastrWrapperProps = Config & {
  onDisappear: () => void;
};

const ToastrWrapper: React.FC<ToastrWrapperProps> = (props) => {
  const { onExit, onDisappear, ...rest } = props;

  return <Toastr onExit={onDisappear} {...rest} />;
};

export default {
  show: (config: Config) => {
    const { disappearAfter = 2000, ...rest } = config;

    const div = document.createElement('div');
    document.body.appendChild(div);

    let timeout: NodeJS.Timeout | number;

    const cleanup = () => {
      clearTimeout(timeout as number);
      ReactDOM.unmountComponentAtNode(div);
      document.body.removeChild(div);
    };

    timeout = setTimeout(cleanup, disappearAfter);

    requestAnimationFrame(() =>
      ReactDOM.render(
        <ToastrWrapper
          disappearAfter={disappearAfter}
          onDisappear={cleanup}
          {...rest}
        />,
        div,
      ),
    );
  },
  showError(config: Config) {
    this.show({
      text: 'Error occured',
      type: 'Error',
      disappearAfter: 5000,
      ...config,
    });
  },
};
