import React from 'react';
import classNames from 'classnames';

import './OutcomeMoney.styles.scss';

type OutcomeMoneyProps = {
  className?: string;
  styleType?: 'outline' | 'solid';
  label: string;
  amount: number;
};

const OutcomeMoney: React.FC<OutcomeMoneyProps> = (props) => {
  const { className, styleType = 'solid', label, amount } = props;

  return (
    <div
      className={classNames(
        'bb-outcome-money',
        `bb-outcome-money--${styleType}`,
        className,
      )}
    >
      <p className="bb-outcome-money__amount">{`$${amount.toFixed(2)}`}</p>
      <p className="bb-outcome-money__text">{label}</p>
    </div>
  );
};

export default OutcomeMoney;
