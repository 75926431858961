import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(-824 -616) translate(824 616)">
          <rect width="24" height="24" fill="currentColor" rx="12" />
          <path
            stroke="#FFF"
            strokeLinecap="round"
            strokeWidth="2"
            d="M8 12L11 15 17 9"
          />
        </g>
      </g>
    </g>
  </svg>
);
