import TeamsIcon from 'assets/svgs/navigation/Teams.icon';
import NotificationIcon from 'assets/svgs/navigation/Notification.icon';
import FriendIcon from 'assets/svgs/Friends';
import SettingsIcon from 'assets/svgs/Settings';
import Menu from '../Menu.page';
import FavoriteTeams from './pages/FavoriteTeams';
import Friends from './pages/Friends';
import Profile from './pages/Profile';
import ProfileSettings from './pages/ProfileSettings';
import Settings from './pages/Settings';
import { EnhancedRouteProps } from 'router/routes/EnhancedRoute/EnhancedRoute.component';
import Notifications from './pages/Notifications';
import Teams from './pages/Teams';

export type MenuRouteType = {
  icon?: React.ReactNode;
  value?: number;
  label?: string;
} & Partial<EnhancedRouteProps>;

export default [
  // {
  //   path: '/mybets',
  //   component: MyBets,
  //   exact: false,
  //   icon: BetsIcon,
  //   value: 1,
  //   label: 'MY BETS',
  // },
  {
    path: '/teams',
    component: Teams,
    label: 'TEAMS',
    icon: TeamsIcon,
  },
  {
    path: '/favorite-teams',
    component: FavoriteTeams,
    exact: false,
    icon: TeamsIcon,
    value: 2,
    label: 'FAVORITE TEAMS',
  },
  {
    path: '/friends',
    component: Friends,
    exact: false,
    icon: FriendIcon,
    value: 3,
    label: 'MY FRIENDS',
  },
  {
    path: '/notifications',
    component: Notifications,
    exact: false,
    icon: NotificationIcon,
    value: 4,
    label: 'NOTIFICATIONS',
  },
  {
    path: '/settings',
    component: Settings,
    exact: false,
    icon: SettingsIcon,
    value: 5,
    label: 'SETTINGS',
  },
  {
    path: '/profile/:id',
    component: Profile,
    exact: false,
  },
  {
    path: '/profile-settings',
    component: ProfileSettings,
    exact: false,
  },
  {
    path: '/',
    component: Menu,
    exact: false,
  },
] as MenuRouteType[];
