import { Button } from 'ncoded-component-library';
import React, { useContext, useEffect, useState } from 'react';
import NavigationTabs from 'components/NavigationTabs';
import NewBetModal from './components/NewBetModal';
import WalletModal from './components/WalletModal';
import WelcomeWizard from 'components/WelcomeWizard';
import Routes from 'router/components/Routes';
import routes from 'routers/MainRouter/routes';
import { EnhancedRouteProps } from 'router/routes/EnhancedRoute/EnhancedRoute.component';
import MainContext from './providers/Main/Main.context';
import eventSocketService from 'services/socket/eventSocketService';
import NewBetContext from './components/NewBetModal/providers/NewBet/NewBet.context';
import WalletIcon from 'assets/svgs/Wallet.icon';
import Add from 'assets/svgs/Add';
import utils from 'utils';

import './MainPage.styles.scss';

declare global {
  interface Window {
    isNewBetModalClose?: boolean;
  }
}

const navigationItems = routes.filter((r) => r.path !== '*');

const MainPage: React.FC = () => {
  const [walletModal, setWalletModal] = useState<boolean>(false);

  const { initialFixtureData, setInitialFixtureData, isBetEditing } =
    useContext(NewBetContext);

  const {
    currentBalance,
    newBetModal,
    welcomeWizardModal,
    setNewBetModal,
    setWelcomeWizardModal,
  } = useContext(MainContext);

  useEffect(() => {
    eventSocketService.connect();
  }, []);

  return (
    <div className="bb-main">
      <div className="bb-main__header">
        <Button
          ripple
          styleType="secondary"
          variant="outline"
          icon={WalletIcon}
          onClick={() => setWalletModal(true)}
        >
          {`$${utils.getDotedNumber(currentBalance.toFixed(2).toString())}`}
        </Button>
        <Button
          className="ncoded-button--primary--unique"
          ripple
          icon={Add}
          onClick={() => {
            window.isNewBetModalClose = false;
            isBetEditing.current = false;
            setNewBetModal(true);
          }}
        />
      </div>

      <Routes routes={routes as EnhancedRouteProps[]} />

      <div className="bb-main__footer" id="bb-footer">
        <NavigationTabs items={navigationItems} />
      </div>

      <WelcomeWizard
        open={welcomeWizardModal}
        onClose={() => setWelcomeWizardModal(false)}
      />

      <NewBetModal
        open={newBetModal}
        initialValue={initialFixtureData}
        onClose={() => {
          setNewBetModal(false);
          setTimeout(() => {
            if (isBetEditing) isBetEditing.current = false;
            setInitialFixtureData(null);
          }, 800);
        }}
      />

      <WalletModal open={walletModal} onClose={() => setWalletModal(false)} />
    </div>
  );
};

export default MainPage;
