import Search from 'assets/svgs/Search';
import classNames from 'classnames';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import InfiniteScroll from 'components/InfiniteScroll';
import { FriendshipType } from 'models/Friends';
import confirm from 'modules/confirm';
import { Input, Loader } from 'ncoded-component-library';
import React, { useCallback, useContext } from 'react';
import FriendsContext from '../../providers/Friends/Friends.context';
import FriendShipCard from '../FriendShipCard';

import './FriendRequests.styles.scss';

type FriendRequestsProps = {
  className?: string;
};

const FriendRequests: React.FC<FriendRequestsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-friend-requests', className);

  const {
    loadingFriendRequests,
    friendRequests,
    friendRequestSearchString,
    setFriendRequestSearchString,
    acceptFriendRequest,
    onFriendRequestsScroll,
  } = useContext(FriendsContext);

  const handleOnAcceptFriendRequest = useCallback(
    async (friendship: FriendshipType) => {
      if (
        await confirm.simpleConfirmation({
          title: 'Accept Friend Request',
          description: 'Click OK to accept friend requst!',
        })
      )
        acceptFriendRequest(friendship);
    },
    [acceptFriendRequest],
  );

  return (
    <InfiniteScroll className={classes} onScroll={onFriendRequestsScroll}>
      <Input
        prefixNode={<Search />}
        placeholder="Search..."
        value={friendRequestSearchString}
        onChange={(e) => setFriendRequestSearchString(e.target.value)}
      />

      {friendRequests.length === 0 && (
        <EmptyPlaceholder
          title="You don't have any friend requests yet!"
          description="Friend requests will be listed here."
        />
      )}

      {friendRequests.map((friendship, index) => {
        return (
          <FriendShipCard
            key={index}
            to={`profile/${friendship.sender.Id}`}
            text="Accept"
            buttonStyle="primary"
            user={friendship.sender}
            onAction={() => handleOnAcceptFriendRequest(friendship)}
          />
        );
      })}

      {loadingFriendRequests && <Loader />}
    </InfiniteScroll>
  );
};

export default FriendRequests;
