import { ImageType } from './components/ImageSlider/ImageSlider.component';

export const slideImages = [
  {
    imageUrl:
      'https://a.espncdn.com/photo/2020/0813/nba_mega_playoff_orange_HT_16x9.jpg',
    index: 0,
  },
  {
    imageUrl:
      'https://hoopshype.com/wp-content/uploads/sites/92/2022/09/top_76.png?w=1024&h=576&crop=1',
    index: 1,
  },
  {
    imageUrl:
      'https://static01.nyt.com/images/2019/10/18/sports/18NBA-ZION-LEDE/merlin_162561834_f6d87b94-769c-410a-b10f-b845a66826f6-superJumbo.jpg',
    index: 2,
  },
  {
    imageUrl: 'https://i.ytimg.com/vi/F3isOGU7H1A/maxresdefault.jpg',
    index: 3,
  },
] as Array<ImageType>;
