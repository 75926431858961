import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-128 -208) translate(80 160) translate(48 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M3.873 16.49c.21-.001.42.038.62.11l.15.06.003-.005 2.699 2.698c.083.201.14.408.16.617l.006.157c0 .477-.184.96-.548 1.324-.364.365-.848.55-1.324.549-.425 0-.853-.145-1.2-.434l-.125-.115-1.765-1.765c-.364-.364-.55-.846-.549-1.324 0-.477.184-.96.549-1.325.364-.364.847-.549 1.324-.548zm3.214-2.625l3.048 3.048-1.846 1.614-2.818-2.815 1.616-1.847zM18.88 2c.797 0 1.6.305 2.207.913.188.188.347.395.478.614.29.49.435 1.04.435 1.59 0 .73-.255 1.467-.767 2.054l-.146.156-.023.021-.187.17-.007.007-9.794 8.564-3.166-3.166 8.566-9.793.007-.007.169-.187.02-.023C17.282 2.305 18.085 2 18.88 2zm-14.29.195c1.378.001 2.5 1.118 2.501 2.497-.001 1.379-1.24 2.495-2.488 2.497-1.248-.002-2.502-1.118-2.504-2.497.002-1.379 1.112-2.496 2.49-2.497z"
              transform="translate(-128 -208) translate(80 160) translate(48 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
