import React, { useCallback, useMemo } from 'react';
import { OutcomeData } from 'models/Outcome';
import { FixtureType } from 'models/Fixture';
import ShowOutcomeCard from './components/ShowOutcomeCard';
import classNames from 'classnames';

import './ShowOutcomes.styles.scss';

type ShowOutcomesProps = {
  className?: string;
  fixture: FixtureType;
  betOutcomes: Array<OutcomeData>;
};

const ShowOutcomes: React.FC<ShowOutcomesProps> = (props) => {
  const { className, betOutcomes, fixture } = props;
  const { firstTeam, secondTeam } = fixture;

  const classes = classNames('bb-outcome-details', className);

  const firstTeamLogo = useMemo(
    () => (firstTeam.logoUrl ? firstTeam.logoUrl : firstTeam.publicTeamLogoUrl),
    [firstTeam],
  );

  const secondTeamLogo = useMemo(
    () =>
      secondTeam.logoUrl ? secondTeam.logoUrl : secondTeam.publicTeamLogoUrl,
    [secondTeam],
  );

  const getTeamLogo = useCallback(
    (teamIndex: number) => {
      return teamIndex === 1 ? firstTeamLogo : secondTeamLogo;
    },
    [firstTeamLogo, secondTeamLogo],
  );

  return (
    <div className={classes}>
      {betOutcomes?.map((outcomeData, index) => {
        return (
          <ShowOutcomeCard
            key={index}
            fixture={fixture}
            outcomeData={outcomeData}
            betTeamUrl={getTeamLogo(outcomeData.team)}
          />
        );
      })}
    </div>
  );
};

export default ShowOutcomes;
