import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';

export const moveUpWithBackground = {
  moveUp: {
    duration: 800,
    timingFunction: EASE_IN_OUT_CUBIC,
  },
  backgroundColor: {
    delay: 600,
    duration: 400,
    timingFunction: EASE_IN_OUT_CUBIC,
  },
};

export const moveDownWithBackground = {
  moveDown: {},
  backgroundColorReverse: { duration: 50 },
};
