import { ChatSessionClientData, ChatSessionType } from 'models/Chats';
import { FriendshipType } from 'models/Friends';
import credentialsService from 'services/credentialsService';

type ChatService = {
  mapFriendsData: (values: Array<FriendshipType>) => Array<FriendshipType>;
  mapChatSessions: (
    values: Array<ChatSessionType>,
  ) => Array<ChatSessionClientData>;
  mapChatSession: (value: ChatSessionType) => ChatSessionClientData;
};

export default {
  mapFriendsData(values: Array<FriendshipType>) {
    return values.map((friendship: FriendshipType) => {
      if (friendship.userId1 === credentialsService.user.Id) {
        return { ...friendship, sender: friendship.user2 };
      } else {
        return { ...friendship, sender: friendship.user1 };
      }
    });
  },
  mapChatSessions(sessions: Array<ChatSessionType>) {
    return sessions.map((session: ChatSessionType) => {
      return this.mapChatSession(session);
    });
  },
  mapChatSession(session: ChatSessionType) {
    return {
      ...session,
      sender: session.sessionParticipants.filter(
        (participant) =>
          participant.participantId !== credentialsService.user.Id,
      )[0],
      senders: session.sessionParticipants.filter(
        (participant) =>
          participant.participantId !== credentialsService.user.Id,
      ),
      lastMessage: session.messages ? session.messages[0] : null,
      isLastMessageViewed:
        session.sessionParticipants.filter(
          (participant) =>
            participant.participantId === credentialsService.user.Id,
        )[0].unreadMessages === 0,
    } as ChatSessionClientData;
  },
} as ChatService;
