import React, { useMemo } from 'react';
import classNames from 'classnames';

import { BetData } from 'models/Bet';
import UserImage from 'components/UserImage';
import UserList from 'components/UserList';
import CheckboxIcon from 'assets/svgs/Checkbox';

import './BetPlayersList.styles.scss';

type BetPlayersListProps = {
  className?: string;
  bet: BetData;
};

const BetPlayersList: React.FC<BetPlayersListProps> = ({ className, bet }) => {
  const { betPrivacy, betTips } = bet;

  const privateUserList = useMemo(
    () =>
      betPrivacy === 'Private'
        ? betTips.filter((betTip) => betTip.bettingType !== 'Created')
        : null,
    [betPrivacy, betTips],
  );

  const publicUserList = useMemo(
    () =>
      betPrivacy === 'Public'
        ? betTips
            .filter((bet) => bet.bettingType !== 'Created')
            .map((bet) => bet.user)
        : null,
    [betPrivacy, betTips],
  );

  return (
    <div className={classNames('bb-bet-players-list', className)}>
      {publicUserList?.length > 0 && (
        <UserList
          type="users"
          users={publicUserList}
          info={`${publicUserList.length}`}
        />
      )}

      {privateUserList?.length > 0 &&
        privateUserList.map((userBet) => (
          <div className="bb-bet-players-list__private" key={userBet.Id}>
            <UserImage
              userId={userBet.user.Id}
              src={userBet.user.profileImageUrl}
              active={userBet.user.isOnline}
            />
            <div className="bb-bet-players-list__private__accepted">
              <CheckboxIcon />
              <span>{`${
                userBet.bettingType
              } with $${userBet.bettingTipAmount.toFixed(2)}`}</span>
            </div>
          </div>
        ))}
    </div>
  );
};

export default BetPlayersList;
