import Dots from 'assets/svgs/Dots';
import Edit from 'assets/svgs/Edit';
import Group from 'assets/svgs/Group';
import Plus from 'assets/svgs/Plus';
import Search from 'assets/svgs/Search';
import { Button, Dropdown, Input, Loader } from 'ncoded-component-library';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import ChatCard from './components/ChatCard';
import ChatModal from './components/ChatModal';
import NewChatModal from './components/NewChatModal';
import GroupChatModal from './components/GroupChatModal';
import {
  ChatSessionClientData,
  ChatSessionExistType,
  MessageType,
} from 'models/Chats';
import api from 'api';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import useInfinitePagination from 'hooks/useInfinitePagination';
import InfiniteScroll from 'components/InfiniteScroll';
import credentialsService from 'services/credentialsService';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import chatService from './chatService';
import useQueryParams from 'hooks/useQueryParams';
import utils from 'utils';
import GlowScroll from 'components/GlowScroll';

import './Chat.styles.scss';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';

const USER_STATUS_TYPES: Record<string, 'online' | 'offline'> = {
  ONLINE: 'online',
  OFFLINE: 'offline',
} as const;

type UserStatusType = typeof USER_STATUS_TYPES[number];

const Chat: React.FC = () => {
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isNewChatOpen, setIsNewChatOpen] = useState<boolean>(false);
  const [isGroupChatOpen, setIsGroupChatOpen] = useState<boolean>(false);
  const [currentOpenSession, setCurrentOpenSession] =
    useState<ChatSessionClientData>(null);

  const user = useMemo(() => credentialsService.user, []);

  const dropdownRef = useRef<DropdownRef>(null);

  const {
    params: { session },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{
    session: string;
  }>();

  const {
    loading: loadingSessions,
    items: sessions,
    setItems: setSessions,
    searchString,
    setSearchString,
    onContainerScrolled: onSessionsScroll,
  } = useInfinitePagination<ChatSessionClientData>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.chat
        .getChatSessions({
          searchString,
          page: currentPage,
          limit: 15,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: chatService.mapChatSessions(data.rows),
          };
        });
    },
    resetPageDep: true,
  });

  const setViewedInSessions = useCallback(
    (sessionId: string, isViewed: boolean) => {
      setSessions((oldSessions) => {
        return oldSessions.map((session: ChatSessionClientData) => {
          if (session.Id === sessionId) {
            return {
              ...session,
              isLastMessageViewed: isViewed,
            };
          } else return session;
        });
      });
    },
    [setSessions],
  );

  const setLastMessage = useCallback(
    (sessionId: string, message: MessageType) => {
      setSessions((oldSessions) => {
        return oldSessions.map((session: ChatSessionClientData) => {
          if (session.Id === sessionId) {
            return {
              ...session,
              lastMessage: message,
            };
          } else return session;
        });
      });
    },
    [setSessions],
  );

  const handleOnNewSession = useCallback(
    (value: ChatSessionClientData) => {
      setSessions((oldSessions) => [value, ...oldSessions]);
      setCurrentOpenSession(value);
      setIsChatOpen(true);
    },
    [setSessions],
  );

  const handleOnSessionExist = useCallback(
    (value: ChatSessionExistType) => {
      const existSession = sessions.find(
        (session) => session.Id === value.sessionId,
      );
      setCurrentOpenSession(existSession);
      setIsChatOpen(true);
    },
    [sessions],
  );

  const getSessionName = useCallback((session: ChatSessionClientData) => {
    return session.chatableType === 'Friendship'
      ? `${session.sender.participant.username}`
      : session.chatableType === 'Group'
      ? `${session.group.name}`
      : '';
  }, []);

  const setUserOnlineStatus = useCallback(
    (userId: string, onlineStatus: UserStatusType) => {
      setSessions((oldSession) =>
        oldSession.map((session) => {
          if (session.sender.participant.Id === userId) {
            return {
              ...session,
              sender: {
                ...session.sender,
                participant: {
                  ...session.sender.participant,
                  isOnline: onlineStatus === 'online' ? true : false,
                },
              },
            };
          } else return session;
        }),
      );
    },
    [setSessions],
  );

  useEffect(() => {
    const loadActiveSession = async () => {
      try {
        const { data } = await api.chat.getChatSessionById(session);

        const sessionData = chatService.mapChatSession(data);
        setCurrentOpenSession(sessionData);
        setIsChatOpen(true);
      } catch (e) {
        utils.toastError(e);
      }
    };
    if (session) {
      loadActiveSession();
    }
  }, [session, user]);

  useEffect(() => {
    if (currentOpenSession) setQueryParam('session', currentOpenSession.Id);
    else removeQueryParam('session');
  }, [currentOpenSession, removeQueryParam, setQueryParam]);

  useEffect(() => {
    if (currentOpenSession) setViewedInSessions(currentOpenSession.Id, true);
  }, [currentOpenSession, setViewedInSessions]);

  useEffect(() => {
    const onNewMessage = (data: { message: MessageType }) => {
      if (
        data.message?.sessionId !== currentOpenSession?.Id ||
        !currentOpenSession
      ) {
        //if session from incoming message is different from current open session id
        //or if chat modal is not open - set incoming message not viewed
        setViewedInSessions(data.message?.sessionId, false);
        setLastMessage(data.message?.sessionId, data.message);
      }
    };

    const onUserConnected = async (data: { userId: string }) => {
      setUserOnlineStatus(data.userId, USER_STATUS_TYPES.ONLINE);
    };

    const onUserDisconected = async (data: { userId: string }) => {
      setUserOnlineStatus(data.userId, USER_STATUS_TYPES.OFFLINE);
    };

    eventSocketService.addListener<{ message: MessageType }>(
      socketEventNames.MESSAGE_CREATED,
      onNewMessage,
    );

    eventSocketService.addListener<{ userId: string }>(
      socketEventNames.USER_CONNECTED,
      onUserConnected,
    );

    eventSocketService.addListener<{ userId: string }>(
      socketEventNames.USER_DISCONNECTED,
      onUserDisconected,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.MESSAGE_CREATED,
        onNewMessage,
      );

      eventSocketService.removeListener(
        socketEventNames.USER_CONNECTED,
        onUserConnected,
      );

      eventSocketService.removeListener(
        socketEventNames.USER_DISCONNECTED,
        onUserDisconected,
      );
    };
  }, [
    currentOpenSession,
    setLastMessage,
    setSessions,
    setUserOnlineStatus,
    setViewedInSessions,
  ]);

  return (
    <>
      {loadingSessions && <Loader />}
      <GlowScroll className="bb-chat">
        <div>
          <Input
            className="bb-chat__input"
            onChange={(event) => setSearchString(event.target.value)}
            prefixNode={<Search />}
            value={searchString}
            placeholder="Search..."
          />
          <Dropdown
            ref={dropdownRef}
            className="bb-chat__options"
            renderAsPortal={true}
            defaultOpen={false}
            trigger={<Button styleType="secondary" icon={Dots} />}
          >
            <>
              <Button
                styleType="secondary"
                icon={Plus}
                iconPosition="left"
                onClick={() => {
                  dropdownRef.current.setIsOpen(false);
                  setIsNewChatOpen(true);
                }}
              >
                New Chat
              </Button>
              <Button
                styleType="secondary"
                icon={Group}
                iconPosition="left"
                onClick={() => {
                  dropdownRef.current.setIsOpen(false);
                  setIsGroupChatOpen(true);
                }}
              >
                New Group Chat
              </Button>
              <Button
                styleType="secondary"
                icon={Edit}
                iconPosition="left"
                onClick={() => {
                  dropdownRef.current.setIsOpen(false);
                }}
              >
                Edit Chats
              </Button>
            </>
          </Dropdown>
        </div>
        <InfiniteScroll onScroll={onSessionsScroll}>
          {sessions.length === 0 && (
            <EmptyPlaceholder
              title="There aren't any chat rooms created yet"
              description="Chats will be listed here when someone message you or when you create the new chat."
            />
          )}

          {sessions?.map((session: ChatSessionClientData, index: number) => {
            const sessionName = getSessionName(session);
            const imageUrl = session.group
              ? session.group?.image?.publicUrl
              : session.sender.participant.profileImageUrl;

            return (
              <ChatCard
                isUserActive={session.sender?.participant.isOnline || false}
                key={index}
                imageUrl={imageUrl}
                isLastMessageViewed={session.isLastMessageViewed}
                lastMessage={session.lastMessage}
                sender={sessionName}
                onClick={() => {
                  setViewedInSessions(session.Id, true);
                  setCurrentOpenSession(session);
                  setIsChatOpen(true);
                }}
              />
            );
          })}
        </InfiniteScroll>

        {isChatOpen && (
          <ChatModal
            chatSession={currentOpenSession}
            onMessageSent={(sessionId, message) =>
              setLastMessage(sessionId, message)
            }
            onClose={() => {
              setCurrentOpenSession(null);
              setIsChatOpen(false);
            }}
          />
        )}

        <NewChatModal
          open={isNewChatOpen}
          onClose={() => setIsNewChatOpen(false)}
          onSessionCreate={(value) => handleOnNewSession(value)}
          onSessionExist={(value) => handleOnSessionExist(value)}
        />

        <GroupChatModal
          open={isGroupChatOpen}
          onClose={() => setIsGroupChatOpen(false)}
          onGroupChatCreate={(value) => handleOnNewSession(value)}
        />
      </GlowScroll>
    </>
  );
};

export default Chat;
