import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#FFF"
            fillOpacity="0"
            d="M0 0H24V24H0z"
            transform="translate(-329 -629) translate(329 629)"
          />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M4.427 3.792c.13-.244.432-.337.676-.208l15.062 7.974c.089.047.161.12.208.208.13.244.036.547-.208.676L5.103 20.416c-.12.064-.263.076-.392.033-.262-.088-.404-.37-.316-.633l2.3-6.903L14 12l-7.304-.913-2.301-6.903c-.035-.104-.034-.215 0-.317z"
            transform="translate(-329 -629) translate(329 629)"
          />
        </g>
      </g>
    </g>
  </svg>
);
