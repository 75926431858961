import React, { useState, useCallback, useMemo } from 'react';
import RadioSlider from 'components/RadioSlider';
import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';
import { CSSTransition } from 'react-transition-group';
import classNames from 'classnames';

import './AnimatedSlider.styles.scss';

type AnimatedSliderProps = {
  className?: string;
  name?: string;
  radioValue?: string;
  activeIndex?: number;
  radioSlides: Array<RadioSlideType<any>>;
  elements: Array<React.ReactNode> | Array<React.FC<any>>;
  onRadioChange?: (value: any) => void;
};

const AnimatedSlider: React.FC<AnimatedSliderProps> = (props) => {
  const {
    className,
    name = 'animated-radio',
    radioValue,
    activeIndex: pActiveIndex,
    radioSlides,
    elements,
    onRadioChange,
  } = props;

  const classes = classNames('bb-animated-radio', className);

  const [prevActiveIndex, setPrevAcitveIndex] = useState<number>(0);
  const [activeIndex, setAcitveIndex] = useState<number>(pActiveIndex || 0);

  const renderAsComponent = useCallback(
    (element: React.ReactNode | React.FC<any>) => {
      if (typeof element === 'string') return element;
      if (typeof element === 'object') return element;

      const ComponentToRender = element as React.FC;
      return <ComponentToRender />;
    },
    [],
  );

  const componentClasses = useMemo(
    () =>
      classNames('bb-animated-radio__component', {
        'bb-animated-radio__component--right': prevActiveIndex < activeIndex,
        'bb-animated-radio__component--left': prevActiveIndex > activeIndex,
      }),
    [activeIndex, prevActiveIndex],
  );

  return (
    <div className={classes}>
      <RadioSlider
        className="bb-animated-radio__radio-slider"
        name={name}
        value={radioValue}
        slideSteps={radioSlides}
        defaultIndex={activeIndex}
        onChange={(value) => {
          setPrevAcitveIndex(activeIndex);
          setAcitveIndex(value as number);
          onRadioChange(value);
        }}
      />
      <div className="bb-animated-radio__content">
        {elements.map((element, index) => (
          <CSSTransition
            key={index}
            exit={true}
            timeout={400}
            unmountOnExit
            in={activeIndex === index}
            className={componentClasses}
          >
            {renderAsComponent(element)}
          </CSSTransition>
        ))}
      </div>
    </div>
  );
};

export default AnimatedSlider;
