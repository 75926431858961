import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import FormWrapper from 'components/FormWrapper';
import useFormHelperElements from 'hooks/useFormHelperElements';
import PinFiled from 'pages/Auth/components/PinField/PinField.component';
import React from 'react';
import { Field } from 'react-final-form';
import { required } from 'services/validators';
import './SecurityForm.styles.scss';

const SecurityForm: React.FC = () => {
  const { Submit, ErrorMessage } = useFormHelperElements();

  return (
    <FormWrapper className="bb-security">
      <div className="bb-security__container">
        <StandardAppearAnimation delay={500} duration={400}>
          <p>{`Enter the verification code sent to your email`}</p>
        </StandardAppearAnimation>
        <Field
          name="verificationKey"
          type="text"
          component={AnimatedField}
          as={PinFiled}
          numOfPins={4}
          delay={600}
          duration={400}
          validate={required(
            'You must enter security number sent on your email address',
          )}
        />
      </div>
      <div className="bb-security__footer">
        <Submit type="submit">Next Step</Submit>
        <ErrorMessage />
      </div>
    </FormWrapper>
  );
};

export default SecurityForm;
