import React, { useCallback, useContext, useMemo } from 'react';
import './DayPicker.scss';
import classNames from 'classnames';
import { DateTime } from 'luxon';
import { convertToDate, ObjectDate, prepareMonth, WEEK } from '../../utils';
import DatepickerContext from '../../provider/Datepicker.context';
import CalendarContext from 'components/Calendar/provider/Calendar.context';
import * as utils from '../../../utils';

import '../../Datepicker.styles.scss';

type DayPickerProps = {
  className?: string;
};

const classBase = (suffix: TemplateStringsArray | string = '') =>
  `datepicker-menu-container__main__field${suffix}`;

const DayPicker: React.FC<DayPickerProps> = ({ className }) => {
  const { firstDateSelect } = useContext(CalendarContext);
  const { currentDate, minDate, maxDate, date, onChange } =
    useContext(DatepickerContext);

  const array = useMemo(() => prepareMonth(currentDate!), [currentDate]);

  const dayPickerClassNames = classNames(
    'datepicker-menu-container__main',
    'day-picker',
    className,
  );

  const handleDayChange = useCallback(
    (currentDate: ObjectDate) => {
      onChange?.(
        DateTime.fromObject({
          day: currentDate.day,
          month: currentDate.month,
          year: currentDate.year,
        }),
      );
    },
    [onChange],
  );

  const isCurrentDate = useCallback((dateObject: ObjectDate) => {
    const today = DateTime.now();
    return (
      dateObject.day === today.day &&
      dateObject.month === today.month &&
      dateObject.year === today.year
    );
  }, []);

  const isDateSelected = useCallback(
    (dateObj: ObjectDate) => {
      if (firstDateSelect.current || !isCurrentDate(dateObj))
        return +convertToDate(dateObj) === +date.startOf('day');
    },
    [date, firstDateSelect, isCurrentDate],
  );

  const isDateValid = useCallback(
    (dateObject: ObjectDate) =>
      utils.isDateValid(convertToDate(dateObject), minDate, maxDate),
    [maxDate, minDate],
  );

  return (
    <main className={dayPickerClassNames}>
      {WEEK.map((day, index) => (
        <div
          className="datepicker-menu-container__main__field day-picker__week-days"
          key={currentDate.toISO() + day + index}
        >
          <div className="datepicker-menu-container__main__field__content">
            {day}
          </div>
        </div>
      ))}
      {array.map((dateObject, index) => {
        return (
          <div
            className={classNames(classBase(), {
              [classBase`--current-date`]: isCurrentDate(dateObject),
              [classBase`--selected`]: isDateSelected(dateObject),
              [classBase`--valid`]: isDateValid(dateObject),
              [classBase`--invalid`]: !isDateValid(dateObject),
              [classBase`--different-month`]:
                dateObject.month !== currentDate.month,
            })}
            key={currentDate.toISO() + dateObject.day + index}
            onClick={() =>
              isDateValid(dateObject) && handleDayChange(dateObject)
            }
          >
            <div className="datepicker-menu-container__main__field__content">
              {dateObject.day}
            </div>
          </div>
        );
      })}
    </main>
  );
};

export default DayPicker;
