import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';
import { HandicapSlides } from 'models/Bet';
import { BettingTips } from 'models/Outcome';

export const bettingTypeSlides = [
  {
    element: 'Under',
    value: 'UNDER',
  },
  {
    element: 'Over',
    value: 'OVER',
  },
] as Array<RadioSlideType<BettingTips>>;

export const handicapSlides = [
  {
    element: 'Straight Up',
    value: 'STRAIGHT_UP',
  },
  {
    element: 'Handicap',
    value: 'HANDICAP',
  },
] as Array<RadioSlideType<HandicapSlides>>;
