import React, { useCallback, useContext, useEffect, useState } from 'react';
import { composeValidators, isNumber, required } from 'services/validators';
import InputField from 'components/Fields/InputField';
import { PaymentMethodType } from './paymentMethod';
import { CoinTypes, COIN_TYPES } from 'models/Transactions';
import { Field, useFormState } from 'react-final-form';
import FormWrapper from 'components/FormWrapper';
import { Button, Loader } from 'ncoded-component-library';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import { DepositFormType } from '../../WalletModal.component';
import { DEPOSIT_STEPS } from '../../formSteps';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import Card from 'components/Card';
import utils from 'utils';
import api from 'api';

import './DepositAmountStep.styles.scss';

export const COINS_TRANSLATIONS = {
  [COIN_TYPES.USDC]: 'USDC',
  [COIN_TYPES.USDT]: 'USDT ERC20',
  [COIN_TYPES.CREDIT_CARD]: 'Credit card',
  [COIN_TYPES['USDC.BEP20']]: 'USDC BEP20',
} as Record<CoinTypes, string>;

const DepositAmountStep: React.FC = () => {
  const {
    values: { paymentMethod, clientSecret },
  } = useFormState<DepositFormType>();

  const { setCurrentStep } = useContext(StepsContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [possibleCoins, setPossibleCoins] = useState<Array<PaymentMethodType>>(
    [],
  );

  const handleOnPayment = useCallback(
    (input: any, paymentMethod: PaymentMethodType) => {
      input.onChange(paymentMethod.name);
    },
    [],
  );

  useEffect(() => {
    const loadPossibleCoins = async () => {
      try {
        setLoading(true);

        const {
          data: { coins },
        } = await api.transactions.getPossibleCoins();

        const possibleCoins: Array<PaymentMethodType> = Object.values(
          coins,
        ).map((coin) => {
          return {
            name: coin,
          } as PaymentMethodType;
        });

        setPossibleCoins([...possibleCoins, { name: COIN_TYPES.CREDIT_CARD }]);
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    };

    loadPossibleCoins();
  }, []);

  useEffect(() => {
    if (
      !window.activeTransactionView ||
      paymentMethod !== COIN_TYPES.CREDIT_CARD
    )
      return;

    if (clientSecret) setCurrentStep(DEPOSIT_STEPS.CHECKOUT_DETAILS);
  }, [clientSecret, paymentMethod, setCurrentStep]);

  return (
    <FormWrapper className="bb-deposit-amount">
      {loading && <Loader />}
      <div className="bb-deposit-amount__content">
        <StandardAppearAnimation delay={100}>
          <p>Deposit Money</p>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={200}>
          <div className="bb-deposit-amount__content__input-amount">
            <Field
              name="transactionValue"
              label="Enter Deposit Amount"
              component={InputField}
              type="text"
              isNumeric
              placeholder="$0.00"
              validate={composeValidators(
                required('Please enter deposit amount!'),
                isNumber('Value must be number'),
              )}
              inputMode="numeric"
            />
          </div>
        </StandardAppearAnimation>

        <StandardAppearAnimation delay={300}>
          <hr />
        </StandardAppearAnimation>

        <div className="bb-deposit-amount__content__cards">
          <Field<CoinTypes>
            name="paymentMethod"
            defaultValue={possibleCoins[0]?.name}
          >
            {({ input }) => {
              return possibleCoins.map(
                (item: PaymentMethodType, index: number) => {
                  return (
                    <StandardAppearAnimation
                      key={index}
                      delay={400 + index * 100}
                    >
                      <Card
                        key={index}
                        name="payment"
                        active={input.value === item.name}
                        title={COINS_TRANSLATIONS[item.name]}
                        onChange={() => handleOnPayment(input, item)}
                      />
                    </StandardAppearAnimation>
                  );
                },
              );
            }}
          </Field>
        </div>
      </div>
      <div className="bb-deposit-amount__footer">
        <Button type="submit">Make Deposit</Button>
      </div>
    </FormWrapper>
  );
};

export default DepositAmountStep;
