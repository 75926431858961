import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="20"
    viewBox="0 0 28 20"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor" fillRule="nonzero">
        <g>
          <g>
            <g>
              <path
                d="M32 22c6.342 0 11.7 4.217 13.42 10C43.7 37.783 38.342 42 32 42s-11.699-4.217-13.42-10C20.3 26.218 25.658 22 32 22zm0 3c-3.866 0-7 3.134-7 7s3.134 7 7 7 7-3.134 7-7-3.134-7-7-7zm0 2c.657 0 1.285.127 1.86.358-.52.36-.86.961-.86 1.642 0 1.105.895 2 2 2 .68 0 1.282-.34 1.644-.86.23.575.356 1.203.356 1.86 0 2.761-2.239 5-5 5s-5-2.239-5-5 2.239-5 5-5z"
                transform="translate(-258 -182) translate(8 56) translate(184 72) translate(48 32)"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
