import AnimatedField from 'components/animated/AnimatedField';
import React, { useMemo } from 'react';
import { Field } from 'react-final-form';
import { required } from 'services/validators';
import SelectField from 'components/Fields/SelectField';
import FormWrapper from 'components/FormWrapper';
import useFormHelperElements from 'hooks/useFormHelperElements';
import timezones from 'services/timezone';

import './AddressForm.styles.scss';

type AddressFormProps = {};

const AddressForm: React.FC<AddressFormProps> = () => {
  const { Submit, ErrorMessage } = useFormHelperElements();

  const validateObject = useMemo(
    () => ({
      address: required('Address is required'),
      city: required('City is required'),
      timezone: required('Timezone is required'),
    }),
    [],
  );

  return (
    <FormWrapper className="bb-address-details">
      <div className="bb-address-details__content">
        <Field
          label="address"
          name="address"
          component={AnimatedField}
          delay={500}
          duration={400}
          type="text"
          placeholder="Enter address"
          validate={validateObject.address}
        />
        <Field
          label="city"
          name="city"
          component={AnimatedField}
          delay={600}
          duration={400}
          type="text"
          placeholder="Enter city"
          validate={validateObject.city}
        />
        <Field
          label="timezone"
          name="timezone"
          component={AnimatedField}
          as={SelectField}
          searchable
          options={timezones}
          delay={700}
          duration={400}
          placeholder="Enter time zone"
          validate={validateObject.timezone}
        />
      </div>
      <div className="bb-address-details__footer">
        <Submit type="submit">Next Step</Submit>
        <ErrorMessage />
      </div>
    </FormWrapper>
  );
};

export default AddressForm;
