import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { ModalProps } from 'components/Modal/Modal.component';
import Animation from 'components/Animation';
import Modal from 'components/Modal';
import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import { Field, withTypes } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { Button, Loader } from 'ncoded-component-library';
import { AxiosError } from 'axios';
import toastr from 'modules/toastr';
import utils from 'utils';
import api from 'api';

import './ForgotPasswordModal.styles.scss';

type ForgotPasswordModalProps = {
  className?: string;
} & ModalProps;

const ForgotPasswordModal: React.FC<ForgotPasswordModalProps> = (props) => {
  const { className, open, ...rest } = props;

  const classes = classNames('bb-forgot-password-modal', className);

  const history = useHistory();
  const { Form } = withTypes<{ email: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const handleOnForgotPassword = useCallback(
    async (values: { email: string }) => {
      try {
        setLoading(true);

        await api.auth.forgotPassword(values.email);

        toastr.show({
          type: 'Success',
          text: 'Success',
          description: 'Reset link sent successfully',
          disappearAfter: 5000,
        });

        rest.onClose();
      } catch (e) {
        utils.toastError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    },
    [rest],
  );

  return (
    <Animation
      open={open}
      appearAnimation="moveUp"
      appearAnimations={{
        moveUp: { duration: 800, timingFunction: EASE_IN_OUT_CUBIC },
      }}
      disappearAnimations={{
        moveDown: { duration: 400, timingFunction: EASE_IN_OUT_CUBIC },
      }}
    >
      <Modal
        {...rest}
        open
        className={classes}
        title={<p>forgot password</p>}
        onX={() => rest.onClose()}
      >
        {loading && <Loader />}

        <Form
          onSubmit={handleOnForgotPassword}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <StandardAppearAnimation delay={100}>
                <p className="bb-forgot-password-modal__text">
                  Enter your email and we will send you a reset link
                </p>
              </StandardAppearAnimation>

              <Field
                name="email"
                label="email"
                placeholder="Enter your email"
                delay={200}
                component={AnimatedField}
              />

              <StandardAppearAnimation delay={300}>
                <Button ripple styleType="primary" type="submit">
                  Send
                </Button>
              </StandardAppearAnimation>
              <StandardAppearAnimation delay={400}>
                <Button
                  variant="link"
                  styleType="secondary"
                  type="button"
                  onClick={() => {
                    history.push('/start');
                  }}
                >
                  I remembered it
                </Button>
              </StandardAppearAnimation>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default ForgotPasswordModal;
