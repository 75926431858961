import Search from 'assets/svgs/Search';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { Input, Loader } from 'ncoded-component-library';
import React, { useCallback, useRef, useState } from 'react';
import classNames from 'classnames';
import { FriendshipType } from 'models/Friends';
import useFormHelperElements from 'hooks/useFormHelperElements';
import utils from 'utils';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { required } from 'services/validators';
import useInfinitePagination from 'hooks/useInfinitePagination';
import InfiniteScroll from 'components/InfiniteScroll';
import { ChatSessionClientData, ChatSessionExistType } from 'models/Chats';
import UserImage from 'components/UserImage';
import chatService from '../../chatService';
import { OverlayRef } from 'components/Overlay';
import Animation from 'components/Animation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import { AxiosError } from 'axios';
import api from 'api';

import './NewChatModal.styles.scss';

type NewChatModalProps = ModalProps & {
  onSessionCreate?: (value: ChatSessionClientData) => void;
  onSessionExist?: (value: ChatSessionExistType) => void;
};

const NewChatModal: React.FC<NewChatModalProps> = (props) => {
  const { open, onSessionCreate, onSessionExist, ...modalRest } = props;

  const [loadingCreate, setLoadingCreate] = useState<boolean>(false);
  const [activeIndex, setActiveIndex] = useState<number>();
  const modalRef = useRef<OverlayRef>(null);

  const {
    loading,
    items: friends,
    searchString,
    setSearchString,
    onContainerScrolled: onFriendsScroll,
  } = useInfinitePagination<FriendshipType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.friends
        .getUserFriends({
          searchString,
          page: currentPage,
          limit: 15,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: chatService.mapFriendsData(data.rows),
          };
        });
    },
  });

  const { setErrorMsg, Submit, ErrorMessage } = useFormHelperElements();

  const handleOnStartChat = useCallback(
    async ({ friendship }: { friendship: FriendshipType }) => {
      try {
        setLoadingCreate(true);

        const { data } = await api.chat.createChatSession(friendship.Id);

        if (data.sessionId) {
          onSessionExist(data as ChatSessionExistType);
        } else {
          onSessionCreate(
            chatService.mapChatSession(data as ChatSessionClientData),
          );
        }

        modalRest.onClose();
      } catch (e) {
        setErrorMsg(utils.getStringError(e as AxiosError));
      } finally {
        setLoadingCreate(false);
      }
    },
    [modalRest, onSessionCreate, onSessionExist, setErrorMsg],
  );

  return (
    <Animation
      open={open}
      duration={800}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        {...modalRest}
        open
        ref={modalRef}
        onX={modalRest.onClose}
        className="bb-newchat-modal"
        title={<p>new chat</p>}
      >
        {(loading || loadingCreate) && <Loader />}
        <Form
          onSubmit={handleOnStartChat}
          mutators={{ ...arrayMutators }}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <InfiniteScroll
                onScroll={onFriendsScroll}
                className="bb-newchat-modal__container"
              >
                <Input
                  prefixNode={<Search />}
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  placeholder="Search..."
                />
                <Field<FriendshipType>
                  name="friendship"
                  validate={required(
                    'You need to select friend to start chatting!',
                  )}
                >
                  {({ input }) => {
                    return friends.map(
                      (friend: FriendshipType, index: number) => {
                        return (
                          <div
                            className={classNames('bb-newchat-modal__friend', {
                              'bb-newchat-modal__friend--active':
                                index === activeIndex,
                            })}
                            key={index}
                            onClick={() => {
                              setActiveIndex(index);
                              input.onChange(friend);
                            }}
                          >
                            <UserImage
                              userId={friend.sender.Id}
                              src={friend.sender.profileImageUrl}
                            />
                            <p>{`${friend.sender.username}`}</p>
                          </div>
                        );
                      },
                    );
                  }}
                </Field>
              </InfiniteScroll>
              <div className="bb-newchat-modal__footer">
                <ErrorMessage />
                <Submit type="submit"> Start New Chat</Submit>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default NewChatModal;
