export type BasektballOutcome =
  | 'POINTS'
  | 'FREE_THROWS_MADE'
  | 'TWO_POINTS_MADE'
  | 'THREE_POINTS_MADE'
  | 'OFFENSIVE_REBOUNDS'
  | 'DEFENSIVE_REBOUNDS'
  | 'TOTAL_REBOUNDS'
  | 'ASSISTS'
  | 'BLOCKS'
  | 'STEALS'
  | 'TURNOVERS';

//Points Outcome
export type BasketballPointsOutcome =
  | 'TOTAL_POINTS'
  | 'TEAM_TOTAL_POINTS'
  | 'POINTS_WINNER';

//Free throws outcome
export type BasketballFreeThrowsOutcome =
  | 'TOTAL_FREE_THROWS_MADE'
  | 'TEAM_TOTAL_FREE_THROWS_MADE'
  | 'FREE_THROWS_MADE_WINNER';

//Two points outcome
export type BasketballTwoPointsOutcome =
  | 'TOTAL_TWO_POINTS_MADE'
  | 'TEAM_TOTAL_TWO_POINTS_MADE'
  | 'TWO_POINTS_MADE_WINNER';

//Three points outcome
export type BasketballThreePointsOutcome =
  | 'TOTAL_THREE_POINTS_MADE'
  | 'TEAM_TOTAL_THREE_POINTS_MADE'
  | 'THREE_POINTS_MADE_WINNER';

//Offensive rebounds outcome
export type BasketballOffensiveReboundsOutcome =
  | 'TOTAL_OFFENSIVE_REBOUNDS'
  | 'TEAM_TOTAL_OFFENSIVE_REBOUNDS'
  | 'OFFENSIVE_REBOUNDS_WINNER';

////Defensive rebounds outcome
export type BasketballDefensiveReboundsOutcome =
  | 'TOTAL_DEFENSIVE_REBOUNDS'
  | 'TEAM_TOTAL_DEFENSIVE_REBOUNDS'
  | 'DEFENSIVE_REBOUNDS_WINNER';

//Rebounds outcome
export type BasketballReboundsOutcome =
  | 'TOTAL_TOTAL_REBOUNDS'
  | 'TEAM_TOTAL_TOTAL_REBOUNDS'
  | 'TOTAL_REBOUNDS_WINNER';

//Assist outcome
export type BasketballAssistsOutcome =
  | 'TOTAL_ASSISTS'
  | 'TEAM_TOTAL_ASSISTS'
  | 'ASSISTS_WINNER';

//Blocks outcome
export type BasketballBlocksOutcome =
  | 'TOTAL_BLOCKS'
  | 'TEAM_TOTAL_BLOCKS'
  | 'BLOCKS_WINNER';

//Steal outcome
export type BasketballStealsOutcome =
  | 'TOTAL_STEALS'
  | 'TEAM_TOTAL_STEALS'
  | 'STEALS_WINNER';

export type BasketballTurnOversOutcome =
  | 'TURNOVERS_WINNER'
  | 'TOTAL_TURNOVERS'
  | 'TEAM_TOTAL_TRUNOVERS';

//PERIODS
export type BasketballPeriodTypes =
  | 'MATCH'
  | 'FIRST_HALF'
  | 'SECOND_HALF'
  | 'FIRST_QUARTER'
  | 'SECOND_QUARTER'
  | 'THIRD_QUARTER'
  | 'FOURTH_QUARTER';

export type BasketballOutcomeSubType =
  | BasketballPointsOutcome
  | BasketballFreeThrowsOutcome
  | BasketballTwoPointsOutcome
  | BasketballThreePointsOutcome
  | BasketballOffensiveReboundsOutcome
  | BasketballDefensiveReboundsOutcome
  | BasketballReboundsOutcome
  | BasketballAssistsOutcome
  | BasketballBlocksOutcome
  | BasketballStealsOutcome
  | BasketballTurnOversOutcome;

export const BASKETBALL_OUTCOMES = {
  POINTS: 'Points',
  FREE_THROWS_MADE: 'Free Throws',
  TWO_POINTS_MADE: 'Two Points',
  THREE_POINTS_MADE: 'Three Points',
  OFFENSIVE_REBOUNDS: 'Offensive Rebounds',
  DEFENSIVE_REBOUNDS: 'Defensive Rebounds',
  TOTAL_REBOUNDS: 'Total Rebounds',
  ASSISTS: 'Assists',
  BLOCKS: 'Blocks',
  STEALS: 'Steals',
  TURNOVERS: 'Turnovers',
} as const;

export const BASKETBALL_SUBOUTCOMES = {
  POINTS_WINNER: 'Match Winner',
  TOTAL_POINTS: 'Total Points',
  TEAM_TOTAL_POINTS: 'Team Total Points',
  FREE_THROWS_MADE_WINNER: 'Free Throws Winner',
  TOTAL_FREE_THROWS_MADE: 'Total Free Throws',
  TEAM_TOTAL_FREE_THROWS_MADE: 'Team Total Free Throws',
  TWO_POINTS_MADE_WINNER: 'Two Points Winner',
  TOTAL_TWO_POINTS_MADE: 'Total Two Points',
  TEAM_TOTAL_TWO_POINTS_MADE: 'Team Total Two Points',
  THREE_POINTS_MADE_WINNER: 'Three Points Winner',
  TOTAL_THREE_POINTS_MADE: 'Total Three Points',
  TEAM_TOTAL_THREE_POINTS_MADE: 'Team Total Three Points',
  TOTAL_OFFENSIVE_REBOUNDS: 'Total Offensive Rebounds',
  TEAM_TOTAL_OFFENSIVE_REBOUNDS: 'Team Total Offensive Rebounds',
  OFFENSIVE_REBOUNDS_WINNER: 'Offensive Rebounds Winner',
  TOTAL_DEFENSIVE_REBOUNDS: 'Total Defensive Rebounds',
  TEAM_TOTAL_DEFENSIVE_REBOUNDS: 'Team Total Defensive',
  DEFENSIVE_REBOUNDS_WINNER: 'Defensive Rebounds Winner',
  TOTAL_TOTAL_REBOUNDS: 'Total Rebounds',
  TEAM_TOTAL_TOTAL_REBOUNDS: 'Team Total Rebounds',
  TOTAL_REBOUNDS_WINNER: 'Total Rebounds Winner',
  TOTAL_ASSISTS: 'Total Assists',
  TEAM_TOTAL_ASSISTS: 'Team Total Assists',
  ASSISTS_WINNER: 'Assists Winner',
  TOTAL_BLOCKS: 'Total Blocks',
  TEAM_TOTAL_BLOCKS: 'Team Total Blocks',
  BLOCKS_WINNER: 'Blocks Winner',
  TOTAL_STEALS: 'Total Steals',
  TEAM_TOTAL_STEALS: 'Team Total Steals',
  STEALS_WINNER: 'Steals Winner',
  TURNOVERS_WINNER: 'Turnovers Winner',
  TOTAL_TURNOVERS: 'Total Turnovers',
  TEAM_TOTAL_TRUNOVERS: 'Team Total Turnovers',
} as const;

export const BASKETBALL_PERIODS = {
  MATCH: 'Match',
  FIRST_HALF: 'First Half',
  SECOND_HALF: 'Second Half',
  FIRST_QUARTER: 'First Quarter',
  SECOND_QUARTER: 'Second Quarter',
  THIRD_QUARTER: 'Third Quarter',
  FOURTH_QUARTER: 'Fourth Quarter',
} as const;
