import httpClient from 'api/httpClient';
import { SendReferralCodeType } from 'models/ReferalCodes';

const referralCodePath = (routePath: TemplateStringsArray) =>
  `users${routePath}`;

function getReferralCode() {
  return httpClient.post(referralCodePath`/referral-code-generate`);
}

function sendReferralCode(data: SendReferralCodeType) {
  return httpClient.post(referralCodePath`/referral-code-send`, data);
}

export default { getReferralCode, sendReferralCode };
