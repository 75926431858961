import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Routes from 'router/components/Routes';
import { EnhancedRouteProps } from 'router/routes/EnhancedRoute/EnhancedRoute.component';
import FriendsProvider from './pages/Friends/providers/Friends';
import routes from './routes';

//here we add possible provider wrapper for
const MenuRouter: React.FC<RouteComponentProps> = () => {
  return (
    <FriendsProvider>
      <Routes routes={routes as EnhancedRouteProps[]} />
    </FriendsProvider>
  );
};

export default MenuRouter;
