import React from 'react';
import classNames from 'classnames';
import { MessageType } from 'models/Chats';
import utils from 'utils';
import './ChatCard.styles.scss';
import UserImage from 'components/UserImage';

type ChatCardProps = {
  className?: string;
  name?: string;
  imageUrl?: string;
  isUserActive?: boolean;
  sender?: string;
  lastMessage?: MessageType; // possible message types to define
  isLastMessageViewed?: boolean;
  onClick?: () => void;
};

const ChatCard: React.FC<ChatCardProps> = (props) => {
  const {
    className,
    imageUrl,
    isUserActive,
    lastMessage,
    isLastMessageViewed = true,
    sender,
    onClick,
  } = props;

  const classes = classNames(
    'bb-chat-card',
    { 'bb-chat-card--is-viewed': !isLastMessageViewed },
    className,
  );

  return (
    <div className={classes} onClick={onClick}>
      <UserImage src={imageUrl} alt={sender} active={isUserActive} />
      <div className="bb-chat-card__content">
        <div>
          <p>{sender}</p>
          <p>
            {lastMessage?.text
              ? lastMessage?.text
              : lastMessage?.betId
              ? 'User Bet'
              : 'No Messages'}
          </p>
        </div>
        <div>
          <p>{`${
            lastMessage
              ? utils.toHourMinFormat(lastMessage.createdAt.toString())
              : ''
          }`}</p>
        </div>
      </div>
    </div>
  );
};

export default ChatCard;
