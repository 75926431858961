import httpClient from 'api/httpClient';
import PaginatedData from 'models/PaginatedData';
import { SportType } from 'models/Sport';
import { TeamData, TeamStatsType } from 'models/Team';

const teamPath = (routePath: TemplateStringsArray) => `teams/${routePath}`;

function getTeamsStats(params: {
  page: number;
  limit?: number;
  sport: SportType;
  limitWins?: number;
  limitLoses?: number;
  searchString?: string;
}) {
  return httpClient.get<PaginatedData<TeamData>>(teamPath`bettingStats`, {
    params,
  });
}

function getFavoriteTeamsForUser(params: {
  sport?: SportType;
  limitWins?: number;
  limitLoses?: number;
  page: number;
  limit?: number;
}) {
  return httpClient.get<PaginatedData<TeamData>>(teamPath`favorites`, {
    params,
  });
}

function getTeamById(teamId: string) {
  return httpClient.get<TeamData>(`/teams/team/${teamId}`);
}

function getH2hStatistics(params: {
  page: number;
  limit?: number;
  firstTeamId: string;
  secondTeamId: string;
}) {
  return httpClient.get<PaginatedData<TeamStatsType>>(teamPath`h2h`, {
    params,
  });
}

function addtTeamToFavorites(params: { teamId: string }) {
  return httpClient.post(teamPath`favorites`, null, { params });
}

function removeTeamFromFavorites(params: { teamId: string }) {
  return httpClient.post(teamPath`deleteFavorites`, null, { params });
}

export default {
  getTeamsStats,
  getTeamById,
  getFavoriteTeamsForUser,
  getH2hStatistics,
  addtTeamToFavorites,
  removeTeamFromFavorites,
};
