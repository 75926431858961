import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import StepsProvider from 'components/FormWrapper/providers/Steps';
import useCallbackRef from 'hooks/useCallbackRef';
import { StepProviderRef } from 'components/FormWrapper/providers/Steps/Steps.provider';
import formSteps from './formSteps';
import StepProgress from 'components/StepProgress';
import { Button, Loader } from 'ncoded-component-library';
import Arrow from 'assets/svgs/Arrow';
import AppearAnimation from 'components/AppearAnimation';
import useReplayAnimation from 'hooks/useReplayAnimation';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { withTypes } from 'react-final-form';
import { CSSTransition } from 'react-transition-group';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import { useHistory } from 'react-router';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import { TimeZoneTypes } from 'services/timezone';
import useQueryParams from 'hooks/useQueryParams';
import Animation from 'components/Animation';
import utils from 'utils';
import api from 'api';

import './CreateAcountModal.styles.scss';

export type CreateAccountFormType = {
  username: string;
  email: string;
  verificationKey: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  birthdate: string;
  address: string;
  city: string;
  timezone: TimeZoneTypes;
  driverlicence: File;
  referralCode?: string;
};

type CreateAcountModalProps = ModalProps & {
  className?: string;
};

const CreateAcountModal: React.FC<CreateAcountModalProps> = (
  props: CreateAcountModalProps,
) => {
  const { children, className, open, ...rest } = props;
  const classes = classNames(['betbears-createaccount-modal', className]);

  const [loading, setLoading] = useState<boolean>(false);
  const [formModifiedValues, setFormModifiedValues] = useState(null);
  const history = useHistory();

  const { params } = useQueryParams<{ referral_code: string }>();

  const [
    {
      activeStep,
      currentStep,
      steps,
      nextStep,
      prevStep,
    } = {} as StepProviderRef,
    stepsProviderRef,
  ] = useCallbackRef<StepProviderRef>();

  const isLastStep = useMemo(
    () => currentStep === steps?.length - 1,
    [currentStep, steps],
  );

  const initialValues = useMemo(
    () => ({
      ...(params.referral_code && {
        referralCode: params.referral_code,
      }),
    }),
    [params.referral_code],
  );

  const { Form } = withTypes<CreateAccountFormType>();

  const animatedStep = useMemo(() => {
    return steps?.map((step, index) => (
      <CSSTransition
        key={step.title}
        in={currentStep === index}
        unmountOnExit
        timeout={700}
        classNames="form-step"
      >
        <div className="form-step">
          <step.component />
        </div>
      </CSSTransition>
    ));
  }, [steps, currentStep]);

  const replayTitleAnimation = useReplayAnimation(
    'betbears-createaccount__title',
  );

  const registerUser = useCallback(
    async (values: CreateAccountFormType) => {
      try {
        setLoading(true);

        const driverLicence = values['driverlicence'];
        delete values['driverlicence'];

        const { data } = await api.auth.register(values);

        storageService.setItem(STORAGE_KEYS.TOKEN, data.token);
        storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
        storageService.setItem(STORAGE_KEYS.USER, data.user);

        await api.auth.driverLicenceUpload(driverLicence);
        history.push('/betbears');
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [history],
  );

  const verifyEmail = useCallback(
    async (values: CreateAccountFormType) => {
      try {
        setLoading(true);
        const { verificationKey, email } = values;
        await api.auth.verifyUserEmail(verificationKey, email);
        nextStep();
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [nextStep],
  );

  const sendAutheticateEmail = useCallback(
    async (values: CreateAccountFormType) => {
      try {
        setLoading(true);
        const { username, email } = values;
        await api.auth.sendAuthenticationEmail(username, email);
        nextStep();
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [nextStep],
  );

  const onFormSubmit = useCallback(
    (values: CreateAccountFormType) => {
      const { username, email } = formModifiedValues;
      const isFormChanged = email || username;

      if (currentStep === 0 && isFormChanged) sendAutheticateEmail(values);
      else if (currentStep === 1) verifyEmail(values);
      else if (isLastStep) registerUser(values);
      else nextStep();
    },
    [
      currentStep,
      formModifiedValues,
      isLastStep,
      nextStep,
      registerUser,
      sendAutheticateEmail,
      verifyEmail,
    ],
  );

  useEffectSkipFirst(() => {
    replayTitleAnimation();
  }, [replayTitleAnimation, activeStep]);

  return (
    <Animation
      open={open}
      appearAnimation="moveUp"
      appearAnimations={{
        moveUp: { duration: 800, timingFunction: EASE_IN_OUT_CUBIC },
      }}
      disappearAnimations={{
        moveDown: { duration: 400, timingFunction: EASE_IN_OUT_CUBIC },
      }}
    >
      <Modal
        className={classes}
        {...rest}
        open
        onX={() => rest.onClose()}
        title={
          <>
            {currentStep > 0 && <Button onClick={prevStep} icon={Arrow} />}
            <p>CREATE ACCOUNT</p>
          </>
        }
      >
        <Form initialValues={initialValues} onSubmit={onFormSubmit}>
          {({ modified }) => {
            setFormModifiedValues(modified);
            return (
              <StepsProvider ref={stepsProviderRef} steps={formSteps}>
                <StepProgress value={currentStep} totalSteps={steps?.length} />
                <div className="bb-createaccount-modal-container">
                  {loading && <Loader />}
                  <div className="betbears-createaccount-modal__details">
                    <AppearAnimation duration={300} animationName="appear">
                      <div className="betbears-createaccount__title">
                        <p>{activeStep?.title}</p>
                        <p>{`STEP 0${currentStep + 1}`}</p>
                      </div>
                    </AppearAnimation>
                  </div>

                  <div className="form-step-container">{animatedStep}</div>
                </div>
              </StepsProvider>
            );
          }}
        </Form>
      </Modal>
    </Animation>
  );
};

export default CreateAcountModal;
