import React from 'react';
import { Router } from 'react-router-dom';
import { routes } from 'router/routes';
import Routes from 'router/components/Routes';
import GeoChecker from 'components/GeoChecker';
import history from 'modules/history';
import Body from 'components/Body';

function App() {
  return (
    <Router history={history}>
      <Body className="top-cheese" />
      <GeoChecker>
        <Routes routes={routes} />
      </GeoChecker>
    </Router>
  );
}

export default App;
