import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { UserType } from 'models/User';
import UserImage from 'components/UserImage';
import { ChatSessionClientData } from 'models/Chats';

import './UserList.styles.scss';

type UserListProps = {
  users?: Array<UserType>;
  sessions?: Array<ChatSessionClientData>;
  className?: string;
  title?: string;
  info?: string;
  type: 'sessions' | 'users';
};

const UserList: React.FC<UserListProps> = (props) => {
  const { className, sessions, users, title = 'players', info, type } = props;

  const classes = classNames('bb-user-list', className);

  const getImageUrl = useCallback((session: ChatSessionClientData) => {
    return session.group
      ? session.group.image?.publicUrl
      : session.sender.participant.profileImageUrl;
  }, []);

  const userList = useMemo(() => {
    if (type === 'sessions') {
      return sessions.map((session, index) => {
        return (
          <UserImage
            userId={session?.sender?.participant?.Id}
            key={index}
            src={getImageUrl(session)}
          />
        );
      });
    } else if (type === 'users') {
      return users.map((user, index) => {
        return (
          <UserImage key={index} userId={user.Id} src={user.profileImageUrl} />
        );
      });
    }
  }, [getImageUrl, sessions, type, users]);

  return (
    <div className={classes}>
      <div>
        <p>{`${title} (${info})`}</p>
      </div>
      <div>{userList}</div>
    </div>
  );
};

export default UserList;
