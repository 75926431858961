import api from 'api';
import UserImage from 'components/UserImage';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { NotificationType } from 'models/Notification';
import toastr from 'modules/toastr';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import utils from 'utils';
import MainContext from '../Main/Main.context';
import NotificationContext from './Notification.context';
import {
  generateToken,
  onMessageListener,
} from 'services/pushNotifcationService';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';

type NotificationProps = {
  children?: React.ReactNode;
};

const notifSound = new Audio('/assets/sound/notif.wav');

const Notification: React.FC<NotificationProps> = (props) => {
  const { children } = props;

  const history = useHistory();

  const { setCurrentBalance, setUserData, userData, settingsData } =
    useContext(MainContext);

  const [nUnreadNotifications, setnUnreadNotifications] = useState<number>(
    userData.numberOfUnreadNotifications,
  );

  const {
    loading: loadingNotifications,
    items: notifications,
    setItems: setNotifications,
    onContainerScrolled: onNotificationScroll,
  } = useInfinitePagination<NotificationType>({
    makeRequest: (currentPage: number) => {
      return api.notification
        .getUserNotifications({
          page: currentPage,
          limit: 20,
        })
        .then(({ data }) => {
          return data;
        });
    },
  });

  const unreadMessages = useMemo(() => {
    return notifications.filter(
      (notification) =>
        !notification.isRead && notification.type === 'Message sent',
    ).length;
  }, [notifications]);

  const notifySound = useCallback(() => {
    if (settingsData.isNotificationsMuted) return;

    notifSound.volume = 0.7;
    notifSound.currentTime = 0;
    notifSound.play();
  }, [settingsData]);

  const readAllSessionMessageNotification = useCallback(
    (sessionId: string) => {
      setNotifications((oldNotifications) =>
        oldNotifications.map((notification) => {
          if (notification.content.sessionId === sessionId) {
            return {
              ...notification,
              isRead: true,
            };
          } else return notification;
        }),
      );
    },
    [setNotifications],
  );

  const readAllNotifications = useCallback(async () => {
    try {
      await api.notification.readAllNotifications();

      setNotifications((oldNotifications) =>
        oldNotifications.map((notification) => {
          return {
            ...notification,
            isRead: true,
          };
        }),
      );

      setUserData((oldUserData) => {
        return {
          ...oldUserData,
          numberOfUnreadNotifications: 0,
        };
      });
    } catch (e) {
      utils.toastError(e);
    }
  }, [setNotifications, setUserData]);

  const checkIfNotifExist = useCallback(
    (notificationData: NotificationType) => {
      const isNotifExists = notifications.find(
        (notification: NotificationType) => {
          return notification.Id === notificationData.Id;
        },
      );
      return isNotifExists ? true : false;
    },
    [notifications],
  );

  const setNotificationRead = useCallback(
    async (notificationData: NotificationType) => {
      try {
        await api.notification.setNotificationRead({
          notificationId: notificationData.Id,
        });

        setNotifications((oldNotifications) =>
          oldNotifications.map((notification) => {
            if (notification.Id === notificationData.Id)
              return { ...notification, isRead: true };
            else return notification;
          }),
        );

        if (!notificationData.isRead)
          setUserData((oldData) => {
            return {
              ...oldData,
              numberOfUnreadNotifications:
                oldData.numberOfUnreadNotifications - 1,
            };
          });
      } catch (e) {
        utils.toastError(e);
      }
    },
    [setNotifications, setUserData],
  );

  useEffect(() => {
    const onBetWonNotification = (data: NotificationType) => {
      setCurrentBalance((oldBalance) => oldBalance + data.content.payout);

      if (!checkIfNotifExist(data)) {
        setNotifications((oldNotifications) => [data, ...oldNotifications]);

        const {
          content: { message },
          toUser: { profileImageUrl, isOnline },
        } = data;

        notifySound();

        toastr.show({
          text: `you won the bet`,
          description: `${message}`,
          disappearAfter: 10000,
          profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
        });
      }
    };

    const onBetCreatedNotification = (data: NotificationType) => {
      if (!checkIfNotifExist(data)) {
        setNotifications((oldNotifications) => [data, ...oldNotifications]);

        notifySound();

        const {
          content: { message },
          fromUser: { username, profileImageUrl, isOnline },
        } = data;

        toastr.show({
          text: `${username} create a bet`,
          description: `${message}`,
          disappearAfter: 10000,
          profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
          onClick: () =>
            history.push(
              `/bets?source=public-bets&betId=${data.content.betId}`,
            ),
        });
      }
    };

    const onBetAccepted = (data: NotificationType) => {
      if (!checkIfNotifExist(data)) {
        setNotifications((oldNotifications) => [data, ...oldNotifications]);

        notifySound();

        const {
          content: { message },
          fromUser: { username, profileImageUrl, isOnline },
        } = data;

        toastr.show({
          text: `${username} accept your bet`,
          description: message,
          disappearAfter: 10000,
          profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
          onClick: () =>
            history.push(
              `/bets?source=public-bets&betId=${data.content.betId}`,
            ),
        });
      }
    };

    const onNewMessage = (data: NotificationType) => {
      if (window.location.pathname === '/chat') return;

      if (!checkIfNotifExist(data)) {
        setNotifications((oldNotifications) => [data, ...oldNotifications]);

        notifySound();

        const {
          content: { message },
          fromUser: { profileImageUrl, isOnline, username },
        } = data;

        toastr.show({
          text: `New Message from ${username}`,
          description: `${message}`,
          disappearAfter: 10000,
          profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
          onClick: () =>
            history.push(`/chat?session=${data.content.sessionId}`),
        });
      }
    };

    const onMoneyRefund = (data: NotificationType) => {
      setCurrentBalance((oldBalance) => oldBalance + data.content.payout);

      if (!checkIfNotifExist(data)) {
        setNotifications((oldNotifications) => [data, ...oldNotifications]);

        notifySound();

        const {
          content: { message },
          toUser: { profileImageUrl, isOnline },
        } = data;

        toastr.show({
          text: `Refun money`,
          description: message,
          disappearAfter: 10000,
          profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
        });
      }
    };

    const friendRequestAccepted = (data: NotificationType) => {
      setNotifications((oldNotifications) => [data, ...oldNotifications]);

      const {
        fromUser: { profileImageUrl, isOnline },
      } = data;

      notifySound();

      toastr.show({
        type: 'Info',
        text: `Friend Request Accepted`,
        description: `${data.fromUser.username} accepted your friend request!`,
        disappearAfter: 10000,
        profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
        onClick: () => history.push('/menu/friends?source=my-friends'),
      });
    };

    const friendRequestCreated = (data: NotificationType) => {
      setNotifications((oldNotifications) => [data, ...oldNotifications]);

      const {
        fromUser: { profileImageUrl, isOnline },
      } = data;

      notifySound();

      toastr.show({
        type: 'Info',
        text: `New Friend Request`,
        description: `${data.fromUser.username} sent you a friend request!`,
        disappearAfter: 10000,
        profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
        onClick: () => history.push('/menu/friends?source=friend-request'),
      });
    };

    const onDeposit = (data: NotificationType) => {
      const {
        fromUser: { profileImageUrl, isOnline },
        content: { transaction },
      } = data;

      setNotifications((oldNotifications) => [data, ...oldNotifications]);
      setCurrentBalance(
        (oldBalance) => oldBalance + transaction.transactionValue,
      );

      notifySound();

      toastr.show({
        type: 'Info',
        text: `Deposit is made`,
        description: `You have successfluy made deposit`,
        disappearAfter: 10000,
        profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
      });
    };

    const onWithdrawl = (data: NotificationType) => {
      const {
        fromUser: { profileImageUrl, isOnline },
        content: { transaction },
      } = data;

      setNotifications((oldNotifications) => [data, ...oldNotifications]);
      setCurrentBalance(
        (oldBalance) => oldBalance - transaction.transactionValue,
      );

      notifySound();

      toastr.show({
        type: 'Info',
        text: `Withdraw is made`,
        description: `You have successfluy made withdraw`,
        disappearAfter: 10000,
        profileImage: <UserImage src={profileImageUrl} active={isOnline} />,
      });
    };

    onMessageListener().then((payload) => {
      //TODO: check if here is logic needed
    });

    eventSocketService.addListener(socketEventNames.DEPOSIT, onDeposit);
    eventSocketService.addListener(socketEventNames.WITHDRAWAL, onWithdrawl);

    eventSocketService.addListener(
      socketEventNames.BET_WON,
      onBetWonNotification,
    );

    eventSocketService.addListener(
      socketEventNames.BET_CREATED,
      onBetCreatedNotification,
    );
    eventSocketService.addListener(
      socketEventNames.BET_ACCEPTED,
      onBetAccepted,
    );
    eventSocketService.addListener(socketEventNames.NEW_MESSAGE, onNewMessage);
    eventSocketService.addListener(
      socketEventNames.MONEY_REFUNDED,
      onMoneyRefund,
    );
    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_ACCEPTED,
      friendRequestAccepted,
    );

    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_CREATED,
      friendRequestCreated,
    );

    return () => {
      eventSocketService.removeListener(socketEventNames.DEPOSIT, onDeposit);

      eventSocketService.removeListener(
        socketEventNames.WITHDRAWAL,
        onWithdrawl,
      );

      eventSocketService.removeListener(
        socketEventNames.BET_WON,
        onBetWonNotification,
      );
      eventSocketService.removeListener(
        socketEventNames.BET_CREATED,
        onBetCreatedNotification,
      );
      eventSocketService.removeListener(
        socketEventNames.BET_ACCEPTED,
        onBetAccepted,
      );
      eventSocketService.removeListener(
        socketEventNames.NEW_MESSAGE,
        onNewMessage,
      );
      eventSocketService.removeListener(
        socketEventNames.MONEY_REFUNDED,
        onMoneyRefund,
      );

      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_ACCEPTED,
        friendRequestAccepted,
      );

      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_CREATED,
        friendRequestCreated,
      );
    };
  }, [
    checkIfNotifExist,
    history,
    notifications,
    notifySound,
    setCurrentBalance,
    setNotifications,
  ]);

  const tokenFound = useRef<boolean>(false);

  useEffectOnceWhen(() => {
    (async () => {
      const token = await generateToken(tokenFound);

      if (tokenFound.current && token) {
        await api.user.setFCMRegistartionToken(token);
      }
    })();
  }, !!tokenFound);

  return (
    <NotificationContext.Provider
      value={{
        loadingNotifications,
        notifications,
        nUnreadNotifications,
        unreadMessages,
        setNotifications,
        setnUnreadNotifications,
        setNotificationRead,
        readAllSessionMessageNotification,
        readAllNotifications,
        onNotificationScroll,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export default Notification;
