import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { FixtureType } from 'models/Fixture';
import RadioSlider, {
  RadioSlideType,
} from 'components/RadioSlider/RadioSlider.component';
import {
  OutcomeData,
  OutcomeSubType,
  outcomeSubtypes,
  OutcomeType,
  outcomeTypes,
  PeriodTypes,
  periodTypes,
} from 'models/Outcome';
import {
  Field,
  FieldInputProps,
  FieldRenderProps,
  useForm,
} from 'react-final-form';
import {
  composeValidators,
  isHalfInteger,
  required,
  requiredToastr,
} from 'services/validators';
import { FieldValidator } from 'final-form';
import Arrow from 'assets/svgs/Arrow';
import Checkbox from 'assets/svgs/Checkbox';
import RadioSliderField from 'components/Fields/RadioSliderField';
import SelectField from 'components/Fields/SelectField';
import FieldWrapper from 'components/FieldWrapper';
import { handicapSlides, bettingTypeSlides } from '../../services';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';
import Collapsible from 'components/Collapsible';
import useCallbackRef from 'hooks/useCallbackRef';
import { CollapsibleRef } from 'components/Collapsible/Collapsible.component';
import NewBetContext from '../../providers/NewBet/NewBet.context';
import InputField from 'components/Fields/InputField';
import { HandicapSlides } from 'models/Bet';
import Image from 'components/Image';

import './OutcomeCard.styles.scss';

export type OutcomeDataFiledProps = {
  active: boolean;
  type: OutcomeType;
  subtypes: Array<OutcomeSubType>;
  periods: Array<PeriodTypes>;
  data: OutcomeData;
};

type OutcomeCardProps = {
  className?: string;
  title?: string;
  active?: boolean;
  fixture?: FixtureType;
  onTeamSelect?: (teamInd: number) => void;
  onClick: () => void;
} & FieldRenderProps<OutcomeDataFiledProps, HTMLElement>;

export type OutcomeCardRef = {
  collapsible: CollapsibleRef;
  type: OutcomeType;
};

const OutcomeCard: React.ForwardRefRenderFunction<
  OutcomeCardRef,
  OutcomeCardProps
> = (props, ref) => {
  const {
    input: {
      name,
      value: {
        active: isActive,
        subtypes,
        periods,
        data: { handicap, limit, team, type, subtype, bettingTip } = {},
      },
    },
    meta: { error, touched, dirty },
    className,
    title,
    active,
    fixture,
    onTeamSelect,
    onClick,
  } = props;

  const { firstTeam, secondTeam } = fixture;

  const hasError = useMemo(
    () => (error && touched && dirty ? error : null),
    [dirty, error, touched],
  );

  const [isHandicap, setIsHandicap] = useState<boolean>(handicap === 0);
  const [collapsible, collapsibleRef] = useCallbackRef<CollapsibleRef>(null);

  const previousHandicap = useRef<string | number>(handicap);
  const { isBetEditing } = useContext(NewBetContext);

  const handicapActiveSlide = useMemo(
    () => (handicap === 0 ? 'STRAIGHT_UP' : 'HANDICAP'),
    [handicap],
  );

  const isWinnerTypeOutcome = useMemo(
    () => subtype.includes('WINNER'),
    [subtype],
  );

  const slidesForSubOutcome = useMemo(() => {
    return subtypes?.map((subOutcome: OutcomeSubType) => {
      return {
        element: outcomeSubtypes[subOutcome],
        value: subOutcome,
      } as RadioSlideType;
    });
  }, [subtypes]);

  const periodOptions = useMemo(
    () =>
      periods.map((period) => {
        return {
          label: periodTypes[period],
          value: period,
        } as OptionValue;
      }),
    [periods],
  );

  const classes = useMemo(
    () =>
      classNames(
        'bb-outcome-card',
        {
          'bb-outcome-card--active': active || isActive,
          'bb-outcome-card--advanced-open': collapsible?.open,
          'bb-outcome-card--error': hasError,
        },
        className,
      ),
    [active, className, collapsible, hasError, isActive],
  );

  const { change } = useForm();

  const firstTeamLogo = useMemo(
    () => (firstTeam.logoUrl ? firstTeam.logoUrl : firstTeam.publicTeamLogoUrl),
    [firstTeam],
  );

  const secondTeamLogo = useMemo(
    () =>
      secondTeam.logoUrl ? secondTeam.logoUrl : secondTeam.publicTeamLogoUrl,
    [secondTeam],
  );

  const isWinnerSelected = useMemo(
    () => subtype === slidesForSubOutcome[0].value,
    [subtype, slidesForSubOutcome],
  );

  const validators = useMemo(
    () =>
      !active
        ? {}
        : {
            handicap: composeValidators(
              required('Handicap is requierd!'),
              isHalfInteger('Handicap must be floating point of .5'),
            ),
            limit: composeValidators(
              required('Limit is requierd!'),
              isHalfInteger('Limit must be floating point of .5'),
            ),
            bettingTip: requiredToastr('You need to select betting type!'),
            duration: requiredToastr('You need to select period for bet!'),
          },
    [active],
  );

  const bettingTipSlideIndex = useMemo(
    () =>
      bettingTypeSlides.findIndex((el) => el.value === bettingTip) > 0 ? 1 : 0,
    [bettingTip],
  );

  const handleOnHandicapChange = useCallback(
    (value: HandicapSlides) => {
      if (value === 'HANDICAP') {
        setIsHandicap(false);

        const newHandicap =
          isBetEditing.current && previousHandicap.current
            ? previousHandicap.current
            : 0.5;

        change(`${name}.data.handicap`, newHandicap);
      } else if (value === 'STRAIGHT_UP') {
        setIsHandicap(true);
        change(`${name}.data.handicap`, 0);
      }
    },
    [change, isBetEditing, name],
  );

  useEffect(() => {
    if (isWinnerTypeOutcome) return;

    change(`${name}.data.limit`, limit);
    change(`${name}.data.handicap`, undefined);
  }, [change, isWinnerTypeOutcome, isBetEditing, limit, name]);

  useEffect(() => {
    if (isWinnerTypeOutcome && !handicap) {
      change(`${name}.data.handicap`, 0);
      change(`${name}.data.limit`, undefined);
    }
  }, [change, handicap, isWinnerTypeOutcome, name]);

  useEffect(() => {
    setIsHandicap(handicap === 0);
  }, [handicap, isBetEditing]);

  const outcomeTeamValidator: (errorMessage: string) => FieldValidator<number> =
    useCallback(
      (errorMsg = 'error') =>
        (value, allValues, { name }) => {
          const index = +Array.from(name)
            .filter((c) => /[0-9]+/i.test(c))
            .join('');

          if (
            !(allValues as any)?.outcomes[index]?.active ||
            (allValues as any)?.outcomes[index]?.data.subtype ===
              slidesForSubOutcome[1].value
          ) {
            return undefined;
          }
          return required(errorMsg)(value);
        },
      [slidesForSubOutcome],
    );

  const handleOnTeamSelect = useCallback(
    (teamIndex: number, input: FieldInputProps<number, HTMLElement>) => {
      if (active && team === teamIndex) return;
      onTeamSelect?.(teamIndex);
      input.onChange(teamIndex);
    },
    [active, team, onTeamSelect],
  );

  useImperativeHandle(ref, () => ({ collapsible, type }), [collapsible, type]);

  const collectInfo = useRef(collapsible?.open);

  useEffectSkipFirst(() => {
    if (!active && collectInfo.current && collapsible.open) onTeamSelect(null);
    collectInfo.current = collapsible.open;
  }, [subtype, handicap, bettingTip]);

  return (
    <div className={classes}>
      <Collapsible
        defaultOpen={false}
        ref={collapsibleRef}
        className="bb-outcome-card__collapsible"
        trigger={
          <div className="bb-outcome-card__outcome">
            <Field<number>
              name={`${name}.data.team`}
              validate={outcomeTeamValidator('You need to select bet team!')}
            >
              {({ input }) => {
                return (
                  <>
                    <div
                      className={classNames('bb-outcome-card__outcome__team', {
                        'bb-outcome-card__outcome__team--active': team === 1,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnTeamSelect(1, input);
                      }}
                    >
                      <Image src={firstTeamLogo} />
                    </div>
                    <div
                      className="bb-outcome-card__outcome__content"
                      onClick={onClick}
                    >
                      {hasError && <small>{error.data.team}</small>}
                      <p>{outcomeTypes[type] || title}</p>
                    </div>
                    <div
                      className={classNames('bb-outcome-card__outcome__team', {
                        'bb-outcome-card__outcome__team--active': team === 2,
                      })}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleOnTeamSelect(2, input);
                      }}
                    >
                      <Image src={secondTeamLogo} />
                    </div>
                  </>
                );
              }}
            </Field>
          </div>
        }
      >
        {/* 
      DEVELOPMENT VERSION DO NOT NEED TO HAVE 
      THIS KIND OF INFORMATION
      \{hasWarning && (
        <div className="bb-outcome-card__subtype-warn">
          <small>
            You do not have to select a team for{' '}
            <b>{slidesForSubOutcome[1].element}</b>
          </small>
        </div>
      )} */}

        <div className="bb-outcome-card__options">
          <Field
            component={RadioSliderField}
            slideSteps={slidesForSubOutcome}
            name={`${name}.data.subtype`}
            value={subtype}
            inputName="subtype"
          />

          {isWinnerSelected ? (
            <div className="bb-outcome-card__options__handicap">
              <RadioSlider
                key="handicap-slider"
                slideSteps={handicapSlides}
                name="handicap"
                value={handicapActiveSlide}
                onChange={(value: HandicapSlides) =>
                  handleOnHandicapChange(value)
                }
              />
              <FieldWrapper
                key="handicap"
                component={InputField}
                type="number"
                showError={false}
                name={`${name}.data.handicap`}
                disabled={isHandicap}
                step="1"
                min={-1000.5}
                max={1000.5}
                defaultValue={handicap}
                validate={validators.handicap}
              />
            </div>
          ) : (
            <div className="bb-outcome-card__options__over-under">
              <FieldWrapper
                component={RadioSliderField}
                slideSteps={bettingTypeSlides}
                name={`${name}.data.bettingTip`}
                value={bettingTip}
                inputName="betting-tip"
                defaultValue={bettingTypeSlides[bettingTipSlideIndex].value}
                validate={validators.bettingTip}
              />

              <FieldWrapper
                component={InputField}
                type="number"
                showError={false}
                name={`${name}.data.limit`}
                step="1"
                min={0.5}
                max={1000}
                defaultValue={limit}
                validate={validators.limit}
              />
            </div>
          )}

          <Field
            label="period"
            component={SelectField}
            name={`${name}.data.period`}
            options={periodOptions}
            validate={validators.duration}
          />
        </div>
      </Collapsible>
      <div
        className={classNames('bb-outcome-card__advanced', {
          'bb-outcome-card__advanced--active': active,
          'bb-outcome-card__advanced--open': collapsible?.open,
        })}
        onClick={() => collapsible?.setOpen(!collapsible?.open)}
      >
        {collapsible?.open ? <Checkbox /> : <div />}
        advanced
        <Arrow />
      </div>
    </div>
  );
};

export default forwardRef(OutcomeCard);
