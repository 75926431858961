import React, { useCallback, useEffect, useMemo } from 'react';
import Arrow from 'assets/svgs/Arrow';
import { Button } from 'ncoded-component-library';
import { friendSlides } from 'routers/MainRouter/services/radioSlides';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import AppearAnimation from 'components/AppearAnimation';
import FriendRequests from './components/FriendRequests';
import MyFriends from './components/MyFriends';
import FindUsers from './components/FindUsers';
import { useHistory } from 'react-router';
import useQueryParams from 'hooks/useQueryParams';
import AnimatedSlider from 'components/AnimatedSlider';
import GlowScroll from 'components/GlowScroll';

import './Friends.styles.scss';

const NAVIGATION_SUBTYPES = [
  'my-friends',
  'friend-request',
  'find-users',
] as const;

type FriendNavigationKeys = typeof NAVIGATION_SUBTYPES[number];
const QUERY_BETS_TABS: Record<FriendNavigationKeys, number> = {
  'my-friends': 0,
  'friend-request': 1,
  'find-users': 2,
};

const Friends: React.FC = () => {
  const history = useHistory();

  const {
    params: { source },
    setQueryParam,
  } = useQueryParams<{
    source: FriendNavigationKeys;
  }>();

  const activeRadioIndex = useMemo(() => {
    if (['my-friends', 'friend-request', 'find-users'].includes(source))
      return QUERY_BETS_TABS[source];
    else return QUERY_BETS_TABS['my-friends'];
  }, [source]);

  const setActiveFriendSource = useCallback(
    (value: number, replace = false) => {
      setQueryParam('source', NAVIGATION_SUBTYPES[value], replace);
    },
    [setQueryParam],
  );

  useEffect(() => {
    if (source === undefined) setActiveFriendSource(0, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const animatedSlides = useMemo(
    () => [MyFriends, FriendRequests, FindUsers] as Array<React.ReactNode>,
    [],
  );

  return (
    <GlowScroll className="bb-friends">
      <AppearAnimation
        duration={700}
        animationName="moveRight"
        timingFunction={EASE_IN_OUT_CUBIC}
      >
        <div className="bb-friends__container">
          <div className="bb-friends__header">
            <Button
              styleType="secondary"
              icon={Arrow}
              onClick={() => history.push('/menu')}
            />
            <p>my friends</p>
          </div>

          <AnimatedSlider
            className="bb-friends__radio-slider"
            elements={animatedSlides}
            radioSlides={friendSlides}
            activeIndex={activeRadioIndex}
            onRadioChange={(value) => setActiveFriendSource(value)}
          />
        </div>
      </AppearAnimation>
    </GlowScroll>
  );
};

export default Friends;
