import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import useEffectSkipFirst from './useEffectSkipFirst';
import utils from 'utils';
import { uniqBy } from 'lodash';

type InfiniteProps<T> = {
  makeRequest: (
    currentPage: number,
    searchString: string,
  ) => Promise<{ count: number; rows: T[]; totalPages: number }>;
  defaultItems?: T[];
  customSetItems?: React.Dispatch<React.SetStateAction<T[]>>;
  dependencies?: Array<any>;
  resetDeps?: Array<any>;
  resetPageDep?: boolean;
  debounceTime?: number;
  skipFirst?: boolean;
  mainDep?: any;
};

export default function <T>({
  defaultItems = [],
  dependencies = [],
  resetDeps = [],
  resetPageDep = false,
  debounceTime = null,
  skipFirst = false,
  mainDep = {},
  customSetItems,
  makeRequest,
}: InfiniteProps<T>) {
  const [loading, setLoading] = useState(!skipFirst);
  const [items, setItems] = useState<T[]>(defaultItems);
  const [searchString, setSearchString] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [totalPages, setTotalPages] = useState(1);
  const lastSearchString = useRef(searchString);
  const first = useRef(true);
  const resetDataFlag = useRef(false);
  const skipFirstRef = useRef(skipFirst);

  const onContainerScrolled = useCallback(() => {
    if (loading) return;
    if (currentPage < totalPages) {
      setCurrentPage((old) => old + 1);
    }
  }, [loading, currentPage, totalPages]);

  const setItemsFinal = useMemo(
    () => customSetItems || setItems,
    [customSetItems],
  );

  const getFiles = useCallback(
    utils.debounce(
      async (
        currentPage: number,
        searchString: string,
        setItemsFinal: React.Dispatch<React.SetStateAction<T[]>>,
      ) => {
        try {
          setLoading(true);

          const { count, rows, totalPages } = await makeRequest(
            currentPage,
            searchString,
          );
          if (resetDataFlag.current) {
            setItemsFinal(rows);
            resetDataFlag.current = false;
          } else if (lastSearchString.current !== searchString) {
            lastSearchString.current = searchString;
            setItemsFinal(rows);
          } else {
            setItemsFinal((old: T[]) => uniqBy([...old, ...rows], 'Id'));
          }
          setTotalPages(totalPages);
          setTotalItems(count);
        } catch (e) {
          utils.toastError(e);
        } finally {
          setLoading(false);
        }
      },
      debounceTime,
    ),
    [debounceTime, ...dependencies],
  );

  useEffect(() => {
    setCurrentPage(1);
    resetDataFlag.current = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...resetDeps]);

  useEffect(() => {
    if (skipFirstRef.current && first.current) {
      first.current = false;
      return;
    }

    if (!mainDep) return;

    if (!!searchString) getFiles(1, searchString, setItemsFinal);
    else getFiles(currentPage, searchString, setItemsFinal);

    getFiles(currentPage, searchString, setItemsFinal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, searchString, ...dependencies, setItemsFinal]);

  useEffectSkipFirst(() => {
    if (!searchString) setCurrentPage(1);
  }, [searchString]);

  return {
    items,
    loading,
    searchString,
    currentPage,
    totalPages,
    totalItems,
    setItems,
    onContainerScrolled,
    setSearchString,
    setCurrentPage,
  };
}
