import React from 'react';
import classNames from 'classnames';

import { FixtureStatusType, FIXTURE_STATUSES } from 'models/Fixture';
import {
  BetStatusStyleType,
  BettingTipStatusType,
  BETTING_TYPE_STATUS,
} from 'models/Bet';

import './BetStatus.styles.scss';

type BetStatusProps = {
  className?: string;
  status: FixtureStatusType | BettingTipStatusType;
  styleType?: BetStatusStyleType;
};

const BET_STATUS_TITLE = {
  [FIXTURE_STATUSES.FINISHED]: 'Finished',
  [FIXTURE_STATUSES.IN_PROGRESS]: 'Active',
  [FIXTURE_STATUSES.NOT_STARTED_YET]: 'Upcoming',
  [BETTING_TYPE_STATUS.Lose]: 'Lose',
  [BETTING_TYPE_STATUS.Refund]: 'Refund',
  [BETTING_TYPE_STATUS.Win]: 'Win',
  [BETTING_TYPE_STATUS['In progress']]: 'In progress',
} as Record<FixtureStatusType | BettingTipStatusType, string>;

const BetStatus: React.FC<BetStatusProps> = (props) => {
  const { className, status, styleType = 'solid' } = props;

  const lowerCaseStyle = (status.toLocaleLowerCase() as any).replaceAll(
    /[_]/g,
    '-',
  );

  const statusClasses = classNames(
    'bb-bet-status',
    `bb-bet-status--${styleType}`,
    `bb-bet-status--${styleType}--${lowerCaseStyle}`,
    className,
  );

  return (
    <div className={statusClasses}>
      <p className="bb-bet-status__text">{BET_STATUS_TITLE[status]}</p>
    </div>
  );
};

export default BetStatus;
