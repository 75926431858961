import React from 'react';

export default () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_3_446)">
      <path
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68628 6 5.99999 8.68629 5.99999 12C5.99999 15.3137 8.68628 18 12 18Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M12 3V6M12 18V21" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M18.364 5.63605L16.2426 7.75737M7.75735 16.2426L5.63603 18.364"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M18.364 18.364L16.2426 16.2427M7.75735 7.75738L5.63603 5.63606"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M21 12L18 12M5.99998 12L2.99998 12"
        stroke="currentColor"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_3_446">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
