import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { Field, useField } from 'react-final-form';
import FixtureCard from 'routers/MainRouter/pages/Stadium/components/FixtureCard';
import BaskettBall from 'assets/svgs/sports/BaskettBall';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import FormWrapper from 'components/FormWrapper';
import { Loader } from 'ncoded-component-library';
import { FixtureType } from 'models/Fixture';
import useInfinitePagination from 'hooks/useInfinitePagination';
import InfiniteScroll from 'components/InfiniteScroll';
import { SportType } from 'models/Sport';
import { sportTypes } from 'routers/MainRouter/services/constants';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import api from 'api';

import './FixturesForm.styles.scss';

type FixturesFormProps = {
  className?: string;
};

const FixturesForm: React.FC<FixturesFormProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-fixtures', className);

  const [activeGameIndex, setActiveGameIndex] = useState<number>();
  const {
    input: { value: sportCategory },
  } = useField<SportType>('sportCategory');
  const { nextStep } = useContext(StepsContext);

  const {
    loading: loadingFixtures,
    items: fixtures,
    totalItems: totalFixtures,
    onContainerScrolled: onFixtureScroll,
  } = useInfinitePagination<FixtureType>({
    makeRequest: (currentPage: number) => {
      return api.fixture
        .getNotStartedFixtures({
          page: currentPage,
          limit: 30,
          sport: sportCategory,
          timezoneOffset: new Date().getTimezoneOffset() / 60,
        })
        .then(({ data }) => {
          return data;
        });
    },
  });

  const handleOnGame = useCallback(
    (index: number) => {
      setActiveGameIndex(index);
      nextStep();
    },
    [nextStep],
  );

  return (
    <FormWrapper className={classes} id="betModalForm">
      <Field name="selectedGame">
        {({ input }) => (
          <>
            {loadingFixtures && <Loader />}
            <div className="bb-fixtures__header">
              <label>{sportTypes[sportCategory]}</label>
              <label>{totalFixtures || '0'}</label>
            </div>
            <InfiniteScroll
              className="bb-fixtures__content"
              onScroll={onFixtureScroll}
            >
              {fixtures.length === 0 && !loadingFixtures ? (
                <EmptyPlaceholder title="There aren't any games for selected sport!" />
              ) : (
                fixtures?.map((fixture: FixtureType, index: number) => (
                  <FixtureCard
                    input={input}
                    key={index}
                    game={fixture}
                    fixtureStatus={fixture.fixtureStatus}
                    fixtureDate={fixture.startDate}
                    active={activeGameIndex === index}
                    sportIcon={BaskettBall}
                    onClick={() => handleOnGame(index)}
                  />
                ))
              )}
            </InfiniteScroll>
          </>
        )}
      </Field>
    </FormWrapper>
  );
};

export default FixturesForm;
