import React from 'react';

export default () => (
  <svg
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
  >
    <path d="M256,490.667C126.6,490.667,21.333,385.4,21.333,256S126.6,21.333,256,21.333,490.667,126.6,490.667,256,385.4,490.667,256,490.667Zm0-448c-117.63,0-213.333,95.7-213.333,213.333S138.37,469.333,256,469.333,469.333,373.63,469.333,256,373.63,42.667,256,42.667Z" />
    <path d="M342.646,394.667a10.6,10.6,0,0,1-4.437-.969L256,356.073,173.792,393.7a10.661,10.661,0,0,1-15.031-10.922l10.38-89.807-61.187-66.56a10.666,10.666,0,0,1,5.745-17.674l88.62-17.88L246.708,112.1a10.665,10.665,0,0,1,18.583,0l44.391,78.758,88.62,17.88a10.666,10.666,0,0,1,5.745,17.674l-61.187,66.56,10.38,89.807a10.661,10.661,0,0,1-10.594,11.891ZM256,333.677a10.632,10.632,0,0,1,4.438.969l69.438,31.781-8.766-75.859a10.647,10.647,0,0,1,2.74-8.443L375.531,225.9l-74.854-15.1a10.654,10.654,0,0,1-7.182-5.219L256,139.057l-37.495,66.523a10.654,10.654,0,0,1-7.182,5.219l-74.854,15.1,51.682,56.221a10.647,10.647,0,0,1,2.74,8.443l-8.766,75.859,69.438-31.781A10.632,10.632,0,0,1,256,333.677Z" />
  </svg>
);
