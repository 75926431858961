import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FieldRenderProps } from 'react-final-form';
import SettingsCard from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/SettingsCard';
import { ExtractPropTypes } from 'types';
import './SettingsCardField.styles.scss';

type SettingsCardProps = ExtractPropTypes<typeof SettingsCard>;

type SettingsCardFieldProps = SettingsCardProps &
  FieldRenderProps<string, HTMLElement>;

const SettingsCardField: React.FC<SettingsCardFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    label,
    className,
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'bb-settings-card-field',
    { 'bb-settings-card-field--error': hasError },
    className,
  );

  return (
    <section className={classes}>
      <SettingsCard {...input} {...rest} />
      {hasError && <small>{error}</small>}
    </section>
  );
};

export default SettingsCardField;
