import { useCallback } from 'react';

export default function (className: string) {
  const replayAnimation = useCallback(() => {
    const container = document.querySelector(`.${className}`);

    if (container) {
      container.classList.add('hidden');

      requestAnimationFrame(() => {
        container.classList.remove('hidden');
      });
    }
  }, [className]);

  return replayAnimation;
}
