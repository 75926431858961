import React, { useCallback, useContext, useRef } from 'react';
import classNames from 'classnames';
import AppearAnimation from 'components/AppearAnimation';
import { Button, Dropdown, Loader } from 'ncoded-component-library';
import Arrow from 'assets/svgs/Arrow';
import { useHistory } from 'react-router';
import Dots from 'assets/svgs/Dots';
import InfiniteScroll from 'components/InfiniteScroll';
import NotificationCard from './components/NotificationCard';
import NotificationContext from 'routers/MainRouter/pages/MainPage/providers/Notification/Notification.context';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { NotificationType } from 'models/Notification';
import NotificationIcon from 'assets/svgs/navigation/Notification.icon';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import GlowScroll from 'components/GlowScroll';

import './Notifications.styles.scss';

type NotificationsProps = {
  className?: string;
};

const Notifications: React.FC<NotificationsProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-notifications', className);

  const history = useHistory();

  const {
    loadingNotifications,
    notifications,
    onNotificationScroll,
    setNotificationRead,
    readAllNotifications,
  } = useContext(NotificationContext);

  const dropdownRef = useRef<DropdownRef>(null);

  const getNotificationPath = useCallback(
    (notification: NotificationType): Location => {
      switch (notification.type) {
        case 'Message sent': {
          return {
            pathname: '/chat',
            search: `session=${notification.content?.sessionId}`,
          } as Location;
        }
        case 'Created bet': {
          return {
            pathname: `/bets`,
            search: `?source=public-bets&betId=${notification.content.betId}`,
          } as Location;
        }
        case 'Accepted bet': {
          return {
            pathname: `/bets`,
            search: `?source=public-bets&betId=${notification.content.betId}`,
          } as Location;
        }
        case 'Won bet': {
          return {
            pathname: `/bets`,
            search: `?source=public-bets&betId=${notification.content.betId}`,
          } as Location;
        }
        case 'Lost bet': {
          return {
            pathname: `/bets`,
            search: `?source=public-bets&betId=${notification.content.betId}`,
          } as Location;
        }
        case 'Created friend request': {
          return {
            pathname: `/menu/friends`,
            search: `?source=friend-request`,
          } as Location;
        }
        case 'Accepted friend request': {
          return {
            pathname: `/menu/friends`,
            search: `?source=my-friends`,
          } as Location;
        }
        default:
          return {
            pathname: '/stadium',
          } as Location;
      }
    },
    [],
  );

  return (
    <GlowScroll className={classes}>
      <AppearAnimation
        duration={700}
        animationName="moveRight"
        timingFunction={EASE_IN_OUT_CUBIC}
      >
        <div className="bb-notifications__container">
          <div className="bb-notifications__header">
            <Button
              styleType="secondary"
              icon={Arrow}
              onClick={() => history.goBack()}
            />
            <p>notifications</p>
            <Dropdown
              ref={dropdownRef}
              className="bb-notifications__options"
              renderAsPortal={true}
              trigger={<Button styleType="secondary" icon={Dots} />}
            >
              <Button
                styleType="secondary"
                icon={NotificationIcon}
                iconPosition="left"
                onClick={() => {
                  readAllNotifications();
                  dropdownRef.current.setIsOpen(false);
                }}
              >
                Read All Notifications
              </Button>
            </Dropdown>
          </div>
          <InfiniteScroll onScroll={onNotificationScroll}>
            {notifications.length === 0 && (
              <EmptyPlaceholder
                title="There aren't any notifications yet!"
                description="Notifications will be listed here."
              />
            )}

            {notifications.map((notification, index) => {
              return (
                <NotificationCard
                  to={getNotificationPath(notification)}
                  key={index}
                  notification={notification}
                  onClick={() => setNotificationRead(notification)}
                />
              );
            })}
          </InfiniteScroll>
          {loadingNotifications && <Loader />}
        </div>
      </AppearAnimation>
    </GlowScroll>
  );
};

export default Notifications;
