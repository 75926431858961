import api from 'api';
import useEffectSkipFirst from 'hooks/useEffectSkipFirst';
import useInfinitePagination from 'hooks/useInfinitePagination';
import useQueryParams from 'hooks/useQueryParams';
import { FixtureType } from 'models/Fixture';
import { LeagueType, SportType } from 'models/Sport';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FixtureSortTypes } from '../../components/TimeFilterModal/TimeFilterModal.component';
import StadiumContext from './Stadium.context';
import utils from 'utils';

export type FixtureFilterData = {
  date?: string;
  sort?: FixtureSortTypes;
  league?: LeagueType | 'all';
};

const NAVIGATION_SUBTYPES = ['bets-list', 'bet-stats'] as const;
type StadiumNavigationKeys = typeof NAVIGATION_SUBTYPES[number];
const QUERY_STADIUM_TABS: Record<StadiumNavigationKeys, number> = {
  'bets-list': 0,
  'bet-stats': 1,
};

type StadiumProps = {
  children?: React.ReactNode;
};

const Stadium: React.FC<StadiumProps> = (props) => {
  const { children } = props;

  const [fixtureFilter, setFixtureFilter] = useState<FixtureFilterData>();

  const [selectedSport, setSelectedSport] = useState<SportType | 'all'>('all');
  const [selectedFixture, setSelectedFixture] = useState<FixtureType>(null);

  const {
    params: { fixtureId, source },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{
    fixtureId: string;
    source: StadiumNavigationKeys;
  }>();

  const activeRadioIndex = useMemo(() => {
    if (source === 'bet-stats' || source === 'bets-list')
      return QUERY_STADIUM_TABS[source];
    else return QUERY_STADIUM_TABS['bets-list'];
  }, [source]);

  const setActiveSource = useCallback(
    (value: number) => {
      setQueryParam('source', NAVIGATION_SUBTYPES[value]);
    },
    [setQueryParam],
  );

  useEffect(() => {
    if (selectedFixture) setQueryParam('fixtureId', selectedFixture.Id);
    else {
      removeQueryParam('fixtureId');
      setSelectedFixture(null);
    }
  }, [removeQueryParam, selectedFixture, setQueryParam]);

  useEffect(() => {
    const getActiveFixture = async () => {
      try {
        const { data } = await api.fixture.getFixtureById(fixtureId);
        setSelectedFixture(data);
      } catch (e) {
        utils.toastError(e);
      }
    };
    if (fixtureId) getActiveFixture();
  }, [fixtureId]);

  const {
    loading: loadingFixtures,
    items: fixtures,
    searchString: fixturesSearchString,
    setSearchString: setFixtureSearchString,
    setItems: setFixtures,
    onContainerScrolled: onFixturesScroll,
  } = useInfinitePagination<FixtureType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.fixture
        .loadFixturesForSport({
          ...fixtureFilter,
          page: currentPage,
          limit: 30,
          sport: selectedSport === 'all' ? null : selectedSport,
          searchString,
          timezoneOffset: new Date().getTimezoneOffset() / 60,
        })
        .then(({ data }) => data);
    },
    dependencies: [selectedSport, fixtureFilter],
    resetDeps: [selectedSport, fixtureFilter],
  });

  useEffect(() => {
    const onPopState = (ev: PopStateEvent) => {
      if (selectedFixture) {
        ev.preventDefault();
        setSelectedFixture(null);
      }
    };
    window.addEventListener('popstate', onPopState);
    return () => {
      window.removeEventListener('popstate', onPopState);
    };
  }, [selectedFixture]);

  useEffectSkipFirst(() => setFixtureFilter(null), [selectedSport]);

  return (
    <StadiumContext.Provider
      value={{
        loadingFixtures,
        fixtures,
        fixturesSearchString,
        fixtureFilter,
        selectedSport,
        selectedFixture,
        activeRadioIndex,
        setFixtures,
        setFixtureFilter,
        setFixtureSearchString,
        setSelectedSport,
        setSelectedFixture,
        setActiveSource,
        onFixturesScroll,
      }}
    >
      {children}
    </StadiumContext.Provider>
  );
};

export default Stadium;
