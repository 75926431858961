import React, { useCallback } from 'react';
import { Field, Form } from 'react-final-form';

import useFormHelperElements from 'hooks/useFormHelperElements';
import { required } from 'services/validators';
import Modal from 'components/Modal';
import InputField from 'components/Fields/InputField';
import { ConfirmBetData } from 'models/ConfirmationModals';
import { ConfirmModalProps } from '../ConfirmModal/ConfirmModal.component';
import InfoIcon from 'assets/svgs/Info.icon';

import './ConfirmBetModal.styles.scss';

type ConfirmBetModalProps = Omit<ConfirmModalProps, 'onClose' | 'onConfirm'> & {
  children?: React.ReactNode;
  content?: React.ReactNode;
  outcomeMessage?: string;
  maxPayment?: number;
  onClose?: (data: ConfirmBetData) => void;
  onConfirm?: (data: ConfirmBetData) => void;
};

const ConfirmBetModal: React.FC<ConfirmBetModalProps> = ({
  children,
  title,
  description,
  outcomeMessage,
  maxPayment,
  content,
  okText = 'OK',
  cancelText = 'Cancel',
  onConfirm,
  onClose,
  ...modalProps
}) => {
  const { Cancel, Submit } = useFormHelperElements();

  const onFormSubmit = useCallback(
    ({ amount }: ConfirmBetData) => {
      onConfirm({ amount: amount, confirm: true });
    },
    [onConfirm],
  );

  return (
    <Modal
      open
      renderAsPortal={false}
      className="bb-bet-confirm-popup"
      {...modalProps}
      title={<p>{title}</p>}
    >
      <Form
        onSubmit={onFormSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="amount"
              component={InputField}
              type="number"
              label="your payment"
              placeholder="$0.00"
              step="0.01"
              min={0.01}
              max={Number(maxPayment.toFixed(2))}
              validate={required('You must enter bet amount')}
            />
            <div className="bb-bet-confirm-popup__data">{`Max bet $${maxPayment.toFixed(
              2,
            )}`}</div>
            <div className="bb-bet-confirm-popup__outcome-message">
              <InfoIcon />
              {outcomeMessage}
            </div>
            <div className="bb-bet-confirm-popup__form-actions">
              <Cancel onClick={() => onClose({ amount: 0, confirm: false })}>
                {cancelText}
              </Cancel>
              <Submit type="submit">{okText}</Submit>
            </div>
          </form>
        )}
      />
    </Modal>
  );
};

export default ConfirmBetModal;
