import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { WalletAddressType, WalletType } from 'models/Wallet';
import UpdateIcon from 'assets/svgs/Update.icon';
import confirm from 'modules/confirm';
import utils from 'utils';
import api from 'api';

import './CoinAddressCard.styles.scss';

type CoinAddressCardProps = {
  className?: string;
  isAddressSet?: boolean;
  onChange?: (value: WalletType) => void;
} & Partial<WalletType>;

const CoinAddressCard: React.FC<CoinAddressCardProps> = (props) => {
  const {
    className,
    cryptoCoinAddress = '',
    isWithdrawalEnabled,
    isAddressSet = true,
    coinType,
    onChange,
  } = props;

  const isAddressPanding = useMemo(
    () => isAddressSet && !isWithdrawalEnabled,
    [isAddressSet, isWithdrawalEnabled],
  );

  const isAddressChangeable = useMemo(
    () => !isAddressSet || isWithdrawalEnabled,
    [isAddressSet, isWithdrawalEnabled],
  );

  const classes = classNames(
    'bb-coin-address-card',
    { 'bb-coin-address-card--disabled': isAddressPanding },
    className,
  );

  const updateWalletAddress = useCallback(
    async (address: string) => {
      try {
        const coinData = {
          coinType: coinType,
          walletAddress: address,
        } as WalletAddressType;

        const { data } = await api.coins.updateWalletAddresses(coinData);
        await utils.toastSuccess(
          `Successfully changed wallet address for ${coinType} coin type`,
        );
        onChange(data);
      } catch (e) {
        utils.toastError(e);
      }
    },
    [coinType, onChange],
  );

  const handleOnAccept = useCallback(async () => {
    const { address: newAddress, confirmed } = await confirm.coinConfirmation({
      title: `Change Wallet Address`,
      description: '',
      address: cryptoCoinAddress,
    });

    if (confirmed) updateWalletAddress(newAddress);
  }, [updateWalletAddress, cryptoCoinAddress]);

  return (
    <div className={classes}>
      <div className="bb-coin-address-card__content">
        <p className="bb-coin-address-card__content--address">
          {cryptoCoinAddress}
        </p>
        <p className="bb-coin-address-card__content--coin-type">{coinType}</p>
      </div>

      <div className="bb-coin-address-card__actions">
        {isAddressPanding && <p>pending</p>}
        {isAddressChangeable && (
          <Button variant="link" onClick={handleOnAccept} icon={UpdateIcon} />
        )}
      </div>
    </div>
  );
};

export default CoinAddressCard;
