import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-320 -208) translate(80 160) translate(240 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M16.819 2.178c1.332-.002 2.622.453 3.576 1.408.956.954 1.41 2.245 1.409 3.577-.002 1.787-.793 3.677-2.283 5.168-.835.836-1.798 1.45-2.796 1.83-.744.284-1.51.438-2.26.452l-2.079.039c-1.254.01-2.844.782-3.87 1.708.115.249.18.516.18.782 0 .473-.183.952-.543 1.315l-2.986 2.99c-.36.362-.84.554-1.312.553-.464.001-.952-.127-1.312-.488-.36-.36-.544-.912-.543-1.384 0-.473.04-.806.543-1.313l.324-.325.228-.229.835-.834.295-.295.145-.145.538-.537.621-.62c.36-.362.84-.545 1.312-.544.267 0 .533.064.78.18.928-1.027 1.699-2.616 1.709-3.87l.039-2.08c.014-.75.167-1.515.45-2.26.381-.998.996-1.96 1.831-2.795 1.492-1.49 3.381-2.281 5.169-2.283zm-6.415 10.603c-.094.403-.227.801-.392 1.189.388-.165.785-.298 1.189-.392-.147-.115-.29-.238-.425-.372-.135-.135-.257-.278-.372-.425zm4.775-.631l-1.21 1.211c.127.01.255.016.384.016h.09c.596-.011 1.222-.135 1.842-.371l-.185.064-.92-.92zm-2.473-2.474l-1.719 1.719c.167.345.388.66.663.936.276.276.59.497.936.663l1.719-1.718-1.6-1.6zm5.565-.618l-2.217 2.218 1.239 1.24c.475-.284.932-.638 1.354-1.06.35-.35.654-.724.909-1.114L18.27 9.058zm-2.473-2.473L13.58 8.802l1.598 1.6 2.218-2.218-1.6-1.6zm-4.89 1.297c-.192.558-.293 1.12-.303 1.658v.089c0 .13.005.257.016.383l1.21-1.21zm9.643-1.102l-1.405 1.404 1.011 1.01c.274-.683.411-1.376.41-2.03 0-.13-.005-.258-.016-.384zM13.64 4.426c-.39.255-.765.558-1.115.909-.421.421-.775.878-1.059 1.353l1.24 1.24 2.217-2.218zm4.945-.629L16.673 5.71l1.598 1.6 1.913-1.913c-.166-.345-.387-.66-.663-.936s-.591-.497-.936-.664zM4.67 2c1.366.001 2.477 1.108 2.478 2.473-.001 1.366-1.228 2.473-2.465 2.474-1.236-.001-2.479-1.108-2.48-2.474C2.205 3.108 3.305 2.001 4.671 2zM16.82 3.415c-.655-.001-1.349.136-2.031.41l1.01 1.01L17.2 3.432c-.125-.01-.253-.016-.382-.016z"
              transform="translate(-320 -208) translate(80 160) translate(240 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
