import React from 'react';

export default () => (
  <svg
    width="17"
    height="12"
    viewBox="0 0 17 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.447716 0.447716 0 1 0H15C15.5523 0 16 0.447716 16 1V3H14C12.8954 3 12 3.89543 12 5V7C12 8.10457 12.8954 9 14 9H16V11C16 11.5523 15.5523 12 15 12H1C0.447716 12 0 11.5523 0 11V1ZM14 4C13.4477 4 13 4.44772 13 5V7C13 7.55228 13.4477 8 14 8H16C16.5523 8 17 7.55228 17 7V5C17 4.44772 16.5523 4 16 4H14Z"
      fill="white"
    />
  </svg>
);
