import { FilterFeedBetData } from 'models/Bet';
import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';

export const feedRadioSlides = [
  {
    element: 'Friends',
    value: 'FRIENDS',
  },
  {
    element: 'Popular',
    value: 'POPULAR',
  },
] as Array<RadioSlideType<string>>;

export const filterSlides = [
  {
    element: 'Finished',
    value: 'PREVIOUS',
  },
  {
    element: 'Active',
    value: 'OPEN',
  },
  {
    element: 'Closed',
    value: 'CLOSED',
  },
] as Array<RadioSlideType>;

export const sortSlides = [
  {
    element: 'Wins',
    value: 'WINS',
  },
  {
    element: 'Losses',
    value: 'LOSES',
  },
] as Array<RadioSlideType>;

export const defaultFilterFeedData = {
  sport: 'all',
  show: 'PREVIOUS',
  filter: 'FRIENDS',
  betsFilterType: 'WINS',
} as FilterFeedBetData;
