import { useCallback, useEffect } from 'react';
import useLatestRef from './useLatestRef';

type ClickOutsideArgs = {
  element: HTMLElement;
  notClosingRefs?: HTMLElement[];
  callback: () => void;
};

export default function useClickOutside(args: ClickOutsideArgs) {
  const { element, notClosingRefs, callback } = args;

  const callbackRef = useLatestRef(callback);

  const handleOnClick = useCallback(
    (event: Event) => {
      if (!element) return;

      const notClosing = notClosingRefs?.some((ref) =>
        (event.target as HTMLElement).contains(ref),
      );

      if (notClosing) return;
      if ((event.target as HTMLElement)?.contains(element)) {
        callbackRef.current();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [element, notClosingRefs],
  );

  useEffect(() => {
    document.body.addEventListener('mousedown', handleOnClick, true);
    document.body.addEventListener('touch', handleOnClick, true);

    return () => {
      document.body.addEventListener('mousedown', handleOnClick, true);
      document.body.addEventListener('touch', handleOnClick, true);
    };
  }, [handleOnClick]);
}
