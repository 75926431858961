import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-272 -208) translate(80 160) translate(192 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M14.07 19.606l1.843.5c-.503.242-1.033.44-1.584.587-.537.144-1.08.237-1.624.28l1.365-1.367zm-4.446-4.393l2.747.743.768 2.814-2.17 2.17c-.87-.1-1.721-.327-2.525-.672l-.835-3.039 2.015-2.016zm-5.039 1.888l1.827.493.504 1.833c-.454-.311-.883-.666-1.28-1.063-.392-.392-.743-.815-1.05-1.263zm11.112-3.495l2.711.732.824 3.02c-.521.703-1.143 1.327-1.844 1.851l-3.06-.828-.73-2.676 2.1-2.1zM5.171 10.874l2.886.78.724 2.635-2.087 2.087-2.952-.796c-.356-.824-.588-1.696-.687-2.588l2.116-2.118zm15.522 3.456c-.143.536-.335 1.052-.569 1.544l-.488-1.787 1.332-1.33c-.044.526-.135 1.052-.275 1.573zm-9.462-5.096l2.778.758.757 2.777-1.999 2-2.763-.748-.768-2.792 1.995-1.995zm6.036-1.618l2.996.817c.354.82.584 1.688.682 2.576l-2.14 2.142-2.85-.77-.739-2.713 2.051-2.052zM3.88 8.121l.492 1.785-1.34 1.34c.045-.527.136-1.054.276-1.575.144-.539.336-1.057.572-1.55zm2.675-3.286l3.061.834.731 2.682-2.068 2.068-2.667-.72-.842-3.057c.506-.684 1.108-1.293 1.785-1.807zm9.014-1.098l.816 2.996-2.052 2.051-2.778-.758-.732-2.68L13.1 3.068c.85.104 1.681.33 2.468.67zm2.796 1.9c.395.394.748.82 1.057 1.271l-1.83-.5-.498-1.83c.45.31.877.663 1.271 1.058zM11.378 3.02l-1.44 1.44-1.932-.527c.527-.26 1.084-.472 1.665-.627.564-.152 1.136-.246 1.707-.286z"
              transform="translate(-272 -208) translate(80 160) translate(192 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
