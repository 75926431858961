import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { FriendshipType } from 'models/Friends';
import React, { useCallback, useEffect, useState } from 'react';
import FriendsContext from './Friends.context';
import { NotificationType } from 'models/Notification';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import { useHistory } from 'react-router';
import utils from 'utils';
import menuService from '../../../../../services/index';
import { UserGlobalType } from 'models/User';

type FriendsProps = {
  children?: React.ReactNode;
};

const Friends: React.FC<FriendsProps> = (props) => {
  const { children } = props;

  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    loading: loadingMyFriends,
    searchString: myFriendSearchString,
    setSearchString: setMyFriendSearchString,
    items: myFriends,
    setItems: setMyFriends,
    onContainerScrolled: onMyFriendsScroll,
  } = useInfinitePagination<FriendshipType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.friends
        .getUserFriends({
          searchString,
          page: currentPage,
          limit: 15,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: menuService.mapFriendsData(data.rows),
          };
        });
    },
  });

  const {
    loading: loadingFriendRequests,
    searchString: friendRequestSearchString,
    setSearchString: setFriendRequestSearchString,
    items: friendRequests,
    setItems: setFriendRequests,
    onContainerScrolled: onFriendRequestsScroll,
  } = useInfinitePagination<FriendshipType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.friends
        .getFriendRequests({
          searchString,
          page: currentPage,
          limit: 15,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: menuService.mapFriendsData(data.rows),
          };
        });
    },
  });

  const {
    loading: loadingGlobalUsers,
    searchString: globalUsersSearchString,
    setSearchString: setGlobalUsersSearchString,
    items: globalUsers,
    setItems: setGlobalUsers,
    onContainerScrolled: onGlobalUsersScroll,
  } = useInfinitePagination<UserGlobalType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.user
        .getUsers({
          searchString,
          page: currentPage,
          limit: 15,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: menuService.mapGlobalUsersData(data.rows),
          };
        });
    },
  });

  const acceptFriendRequest = useCallback(
    async (friendshipData: FriendshipType) => {
      try {
        setLoading(true);

        const mapedFriendshipData =
          menuService.mapFriendRequestData(friendshipData);

        const isFriendshipExists =
          myFriends.filter((friendship) => {
            return mapedFriendshipData.sender.Id === friendship.sender.Id;
          }).length > 0;

        const {
          data: { friendship },
        } = await api.friends.acceptFriendRequest(mapedFriendshipData.Id);

        if (!isFriendshipExists) {
          setMyFriends((oldFriends) => [mapedFriendshipData, ...oldFriends]);
        } else {
          setMyFriends((oldFriends) =>
            oldFriends.map((oldFriendship) => {
              if (oldFriendship.sender.Id === mapedFriendshipData.sender.Id)
                return mapedFriendshipData;
              else return oldFriendship;
            }),
          );
        }

        setFriendRequests((oldFriends) =>
          oldFriends.filter((friend) => friend.Id !== mapedFriendshipData.Id),
        );

        setGlobalUsers((oldUsers) =>
          oldUsers.map((user) => {
            if (user.Id === mapedFriendshipData.sender.Id)
              return {
                ...user,
                friends: [friendship],
                friendshipStatus: 'FRIEND',
              };
            else return user;
          }),
        );

        return friendship;
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [myFriends, setFriendRequests, setGlobalUsers, setMyFriends],
  );

  const sendFriendRequest = useCallback(
    async (userId: string) => {
      try {
        const { data: friendRequest } = await api.friends.sendFriendRequst(
          userId,
        );

        setGlobalUsers((oldUsers) =>
          oldUsers.map((user) => {
            if (user.Id === userId)
              return {
                ...user,
                friendshipStatus: 'PENDING',
                friendsRequests: [friendRequest],
              };
            else return user;
          }),
        );

        return friendRequest;
      } catch (e) {
        utils.toastError(e);
      }
    },
    [setGlobalUsers],
  );

  const deleteFriendship = useCallback(
    async (friendshipId: string) => {
      try {
        setLoading(true);
        await api.friends.deleteFriendship(friendshipId);

        setMyFriends((oldFriends) =>
          oldFriends.filter((friend) => friend.Id !== friendshipId),
        );

        setGlobalUsers((oldUsers) =>
          oldUsers.map((user) => {
            if (user.friends[0]?.Id === friendshipId)
              return {
                ...user,
                friendshipStatus: 'NOT_FRIEND',
              };
            else return user;
          }),
        );
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [setGlobalUsers, setMyFriends],
  );

  const deleteFriendRequest = useCallback(
    async (friendRequestId: string) => {
      try {
        setLoading(true);
        await api.friends.deleteFriendRequest(friendRequestId);

        setGlobalUsers((oldUsers) =>
          oldUsers.map((user) => {
            if (user.friendsRequests[0]?.Id === friendRequestId)
              return {
                ...user,
                friendshipStatus: 'NOT_FRIEND',
              };
            else return user;
          }),
        );
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [setGlobalUsers],
  );

  useEffect(() => {
    const friendRequestAccepted = (data: NotificationType) => {
      const isFriendshipExists =
        myFriends.filter((friendship) => {
          return data.fromUser.Id === friendship.sender.Id;
        }).length > 0;

      const friendshipData = menuService.mapFriendData(data.content.friendship);

      if (!isFriendshipExists)
        setMyFriends((oldFriends) => [friendshipData, ...oldFriends]);
      else
        setMyFriends((oldFriends) =>
          oldFriends.map((friendship) => {
            if (friendship.sender.Id === friendshipData.sender.Id)
              return friendshipData;
            else return friendship;
          }),
        );

      setGlobalUsers((oldUsers) =>
        oldUsers.map((user) => {
          if (user.Id === data.fromUser.Id)
            return {
              ...user,
              friendshipStatus: 'FRIEND',
              friends: [data.content.friendship],
            };
          else return user;
        }),
      );
    };

    const friendRequestCreated = (data: NotificationType) => {
      const isFriendRequestExist =
        friendRequests.filter(
          (friendRequest) =>
            data.content.friendRequest.user1.Id === friendRequest.user1.Id,
        ).length > 0;

      const friendRequestData = menuService.mapFriendData(
        data.content.friendRequest,
      );
      if (!isFriendRequestExist)
        setFriendRequests((oldFriends) => [friendRequestData, ...oldFriends]);
      else
        setFriendRequests((oldFriends) =>
          oldFriends.map((friendRequest) => {
            if (friendRequest.sender.Id === friendRequestData.sender.Id)
              return friendRequestData;
            else return friendRequest;
          }),
        );

      setGlobalUsers((oldUsers) =>
        oldUsers.map((user) => {
          if (user.Id === data.fromUser.Id)
            return {
              ...user,
              friendshipStatus: 'PENDING',
              friendsRequests: [data.content.friendRequest],
            };
          else return user;
        }),
      );
    };

    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_ACCEPTED,
      friendRequestAccepted,
    );

    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_CREATED,
      friendRequestCreated,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_ACCEPTED,
        friendRequestAccepted,
      );

      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_CREATED,
        friendRequestCreated,
      );
    };
  }, [
    history,
    myFriends,
    friendRequests,
    setFriendRequests,
    setGlobalUsers,
    setMyFriends,
  ]);

  return (
    <FriendsContext.Provider
      value={{
        myFriends,
        loadingMyFriends,
        myFriendSearchString,
        setMyFriendSearchString,
        setMyFriends,
        onMyFriendsScroll,
        friendRequests,
        loadingFriendRequests,
        friendRequestSearchString,
        setFriendRequestSearchString,
        setFriendRequests,
        onFriendRequestsScroll,
        globalUsers,
        loadingGlobalUsers,
        globalUsersSearchString,
        setGlobalUsersSearchString,
        setGlobalUsers,
        onGlobalUsersScroll,
        loading,
        acceptFriendRequest,
        sendFriendRequest,
        deleteFriendship,
        deleteFriendRequest,
      }}
    >
      {children}
    </FriendsContext.Provider>
  );
};

export default Friends;
