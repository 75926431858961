import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';
import { Link } from 'react-router-dom';
import InfiniteScroll from 'components/InfiniteScroll';
import { UserBetData } from 'models/Bet';
import ShareModal from 'routers/MainRouter/pages/MainPage/components/ShareModal';
import UserImage from 'components/UserImage';
import { useParams } from 'react-router';
import AppearAnimation from 'components/AppearAnimation';
import useInfinitePagination from 'hooks/useInfinitePagination';
import api from 'api';
import utils from 'utils';
import { UserProfileType } from 'models/User';
import Flag from 'assets/svgs/flags/Flag';
import UserBetCard from 'components/UserBetCard';
import {
  ChatSessionClientData,
  ChatSessionExistType,
  ChatSessionType,
} from 'models/Chats';
import ChatModal from 'routers/MainRouter/pages/Chat/components/ChatModal';
import chatService from 'routers/MainRouter/pages/Chat/chatService';
import credentialsService from 'services/credentialsService';
import LoadButton from 'components/LoadButton';
import FriendsContext from '../Friends/providers/Friends/Friends.context';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import GlowScroll from 'components/GlowScroll';
import { NotificationType } from 'models/Notification';
import Settings from 'assets/svgs/Settings';

import './Profile.styles.scss';

type ProfileProps = {
  className?: string;
};

const Profile: React.FC<ProfileProps> = (props) => {
  const { className } = props;
  const classes = classNames('bb-profile', className);

  const [loading, setLoading] = useState<boolean>(false);
  const [isChatOpen, setIsChatOpen] = useState<boolean>(false);
  const [isShareOpen, setIsShareOpen] = useState<boolean>(false);

  const [openSession, setOpenSession] = useState<ChatSessionClientData>(null);
  const [userData, setUserData] = useState<UserProfileType>(null);

  const { id: userId } = useParams<Record<string, string | undefined>>();

  const {
    loading: loadingFriends,
    friendRequests,
    deleteFriendship,
    sendFriendRequest,
    acceptFriendRequest,
  } = useContext(FriendsContext);

  const isMyProfile = useMemo(
    () => userId === credentialsService.user.Id,
    [userId],
  );

  const {
    loading: loadingBets,
    items: userBets,
    onContainerScrolled: onUserBetsScroll,
  } = useInfinitePagination<UserBetData>({
    makeRequest: (currentPage: number) => {
      return api.bets
        .getUserBetsById(
          {
            page: currentPage,
            limit: 5,
          },
          userId,
        )
        .then(({ data }) => data);
    },
  });

  const isAccpeting = useMemo(() => {
    return friendRequests.find(
      (request) => request?.sender?.Id === userData?.user?.Id,
    ) && userData.friendshipStatus === 'PENDING'
      ? true
      : false;
  }, [friendRequests, userData]);

  const handleOnMessage = useCallback(async () => {
    try {
      const { data } = await api.chat.createChatSession(userData.friendship.Id);

      if ((data as ChatSessionExistType).sessionId) {
        const { data: session } = await api.chat.getChatSessionById(
          data.sessionId,
        );
        setOpenSession(chatService.mapChatSession(session));
      } else
        setOpenSession(chatService.mapChatSession(data as ChatSessionType));

      setIsChatOpen(true);
    } catch (e) {
      utils.toastError(e);
    }
  }, [userData]);

  const handleOnAddFriend = useCallback(async () => {
    try {
      setLoading(true);
      const friendRequest = await sendFriendRequest(userData.user.Id);
      setUserData((oldData) => {
        return {
          ...oldData,
          friendshipStatus: 'PENDING',
          friendRequest,
        };
      });
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoading(false);
    }
  }, [sendFriendRequest, userData]);

  const handleOnUnfriend = useCallback(async () => {
    deleteFriendship(userData.friendship.Id);
    setUserData((oldData) => {
      return { ...oldData, friendshipStatus: 'NOT_FRIEND', friendship: null };
    });
  }, [deleteFriendship, userData]);

  const handleOnDeleteRequest = useCallback(async () => {
    try {
      setLoading(true);
      await api.friends.deleteFriendRequest(userData.friendRequest.Id);
      setUserData((oldData) => {
        return {
          ...oldData,
          friendshipStatus: 'NOT_FRIEND',
          friendRequest: null,
        };
      });
    } catch (e) {
      utils.toastError(e);
    } finally {
      setLoading(false);
    }
  }, [userData]);

  const handleOnAcceptFriendRequest = useCallback(async () => {
    const friendship = await acceptFriendRequest(userData?.friendRequest);
    setUserData((oldData) => {
      return {
        ...oldData,
        friendshipStatus: 'FRIEND',
        friendRequest: null,
        friendship,
      };
    });
  }, [acceptFriendRequest, userData]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        setLoading(true);

        const { data } = await api.user.getUserById(userId);
        setUserData(data);
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    };
    loadUserData();
  }, [userId]);

  useEffect(() => {
    const friendRequestAccepted = (data: NotificationType) => {
      setUserData((oldData) => {
        return {
          ...oldData,
          friendshipStatus: 'FRIEND',
          friendship: data.content.friendship,
          friendRequest: null,
        };
      });
    };

    const friendRequestCreated = (data: NotificationType) => {
      setUserData((oldData) => {
        return {
          ...oldData,
          friendshipStatus: 'PENDING',
          friendRequest: data.content.friendRequest,
          friendship: null,
        };
      });
    };

    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_ACCEPTED,
      friendRequestAccepted,
    );

    eventSocketService.addListener(
      socketEventNames.FRIEND_REQUEST_CREATED,
      friendRequestCreated,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_ACCEPTED,
        friendRequestAccepted,
      );

      eventSocketService.removeListener(
        socketEventNames.FRIEND_REQUEST_CREATED,
        friendRequestCreated,
      );
    };
  }, []);

  return (
    <AppearAnimation duration={600} delay={300} animationName="appear">
      <InfiniteScroll onScroll={onUserBetsScroll} className={classes}>
        {loading && <Loader />}
        {!loading && (
          <GlowScroll className="bb-profile__content">
            <div className="bb-profile__content__user">
              <UserImage src={userData?.user.profileImageUrl} />

              <div className="bb-menu__profile__data">
                <p>{`${userData?.user.username}`}</p>
                <div>
                  <Flag />
                  <p>{`${userData?.user.wins} wins`}</p>
                  <Flag />
                  <p>{`${userData?.user.loses} loss`}</p>
                </div>
              </div>

              {isMyProfile && (
                <Link
                  to="/menu/profile-settings"
                  className="bb-profile__profile-settings"
                >
                  <Settings />
                </Link>
              )}
            </div>

            <div className="bb-profile__content__container">
              <div className="bb-profile__content__container__data">
                <div>
                  <p>{userData?.user.betsCount}</p>
                  <p> My Bets</p>
                </div>
                <div>
                  <p>{userData?.user.teamsCount}</p>
                  <p>Teams</p>
                </div>
                <div>
                  <p>{userData?.user.friendsCount}</p>
                  <p>Friends</p>
                </div>
              </div>

              {!isMyProfile && (
                <div className="bb-profile__content__container__actions">
                  {userData?.friendshipStatus === 'FRIEND' && (
                    <LoadButton
                      styleType="secondary"
                      loading={loadingFriends}
                      onClick={() => handleOnUnfriend()}
                    >
                      Unfriend
                    </LoadButton>
                  )}

                  {userData?.friendshipStatus === 'NOT_FRIEND' && (
                    <LoadButton
                      styleType="primary"
                      loading={loading}
                      onClick={() => handleOnAddFriend()}
                    >
                      Add Friend
                    </LoadButton>
                  )}

                  {userData?.friendshipStatus === 'PENDING' && !isAccpeting && (
                    <LoadButton
                      styleType="secondary"
                      loading={loading}
                      onClick={() => handleOnDeleteRequest()}
                    >
                      Requested
                    </LoadButton>
                  )}

                  {isAccpeting && (
                    <LoadButton
                      styleType="primary"
                      loading={loadingFriends}
                      onClick={() => handleOnAcceptFriendRequest()}
                    >
                      Accept Friend Request
                    </LoadButton>
                  )}

                  {userData?.friendshipStatus === 'FRIEND' && (
                    <Button onClick={() => handleOnMessage()}>Message</Button>
                  )}
                </div>
              )}
            </div>
          </GlowScroll>
        )}

        {userBets.length === 0 && (
          <EmptyPlaceholder title="There isn't any created bet yet!" />
        )}

        {userBets?.map((ticket: UserBetData) => {
          return (
            <UserBetCard
              key={ticket.Id}
              betData={ticket}
              onShare={() => setIsShareOpen(true)}
            />
          );
        })}

        {isShareOpen && (
          <ShareModal shareContentId="" onClose={() => setIsShareOpen(false)} />
        )}

        {isChatOpen && (
          <ChatModal
            chatSession={openSession}
            onClose={() => {
              setIsChatOpen(false);
              setOpenSession(null);
            }}
          />
        )}

        {loadingBets && <Loader />}
      </InfiniteScroll>
    </AppearAnimation>
  );
};

export default Profile;
