import React, { useEffect, useRef } from 'react';

type BodyProps = {
  className?: string;
};

const Body: React.FC<BodyProps> = (props) => {
  const { className } = props;

  const hasRunOnce = useRef<boolean>(false);

  useEffect(() => {
    if (hasRunOnce.current) return;

    hasRunOnce.current = true;
    if (className) document.body.classList.add(className);

    return () => {
      document.body.classList.remove(className);
    };
  }, [className]);

  return null;
};

export default Body;
