export type IcehockeyOutcome = 'SCORES';

//Score outcome
export type IcehockeyScoresOutcome =
  | 'TOTAL_SCORES'
  | 'TEAM_TOTAL_SCORES'
  | 'SCORES_WINNER';

//Periods
export type IcehockeyPeriodTypes =
  | 'MATCH'
  | 'FIRST_PERIOD'
  | 'SECOND_PERIOD'
  | 'THIRD_PERIOD';

export type IcehockeyOutcomeSubtype = IcehockeyScoresOutcome;

export const ICEHOCKEY_OUTCOMES = {
  SCORES: 'Scores',
} as const;

export const ICEHOCKEY_SUBOUTCOMES = {
  SCORES_WINNER: 'Match Winner',
  TOTAL_SCORES: 'Total Scores',
  TEAM_TOTAL_SCORES: 'Team Total Scores',
} as const;

export const ICEHOCKET_PERIODS = {
  MATCH: 'Match',
  FIRST_PERIOD: 'First Period',
  SECOND_PERIOD: 'Second Period',
  THIRD_PERIOD: 'Third Period',
} as const;
