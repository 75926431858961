import React, { useMemo } from 'react';
import { ExtractPropTypes } from 'types';
import { FieldRenderProps } from 'react-final-form';
import AmountSlider from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/AmountSlider';
import toastr from 'modules/toastr';
import classNames from 'classnames';

import './AmountSliderField.styles.scss';
import RadioSlider from 'components/RadioSlider';
import Numeric from 'components/Numeric';

type AmountSliderProps = ExtractPropTypes<typeof AmountSlider>;

type AmountSliderFieldProps = AmountSliderProps &
  FieldRenderProps<number, HTMLElement>;

const NumericField: React.FC<AmountSliderFieldProps> = (props) => {
  const {
    input,
    meta: { error, submitFailed },
    label,
    className,
    ...rest
  } = props;

  const hasError = useMemo(() => error && submitFailed, [error, submitFailed]);

  const radioClasses = classNames('bb-radio-slider-field', {
    'bb-radio-slider-field--error': hasError,
  });

  return (
    <>
      <Numeric styleType="secondary" hasDecimals {...input} />
      {hasError && toastr.showError({ text: error, type: 'Error' })}
      <RadioSlider className={radioClasses} {...rest} {...input} />
    </>
  );
};

export default NumericField;
