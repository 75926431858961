import { useEffect, useState } from 'react';

const MEDIA_MATCHES = {
  isBelowMobile: `(max-width: 375px)`,
  isMobile: `(max-width: 568px)`,
  isAbovePhablet: `(min-width: 769px)`,
  isPhablet: `(max-width: 768px)`,
  isLaptop: `(min-width: 769px) and (max-width: 1439px)`,
  isPc: `(min-width: 1440px) and (max-width: 1919px)`,
  isQHD: `(min-width: 1920px) and (max-width: 2440px)`,
  is4k: `(min-width: 2441px) `,
  isTouchDevice: '(hover: none), (pointer: coarse)',
} as const;

type QUERY = keyof typeof MEDIA_MATCHES;

export default function useMatchMedia(query: QUERY) {
  const [matches, setMatches] = useState<boolean>(false);

  useEffect(() => {
    const handleResize = () =>
      setMatches(window.matchMedia(MEDIA_MATCHES[query]).matches);

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [query]);

  return matches;
}
