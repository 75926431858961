import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ExtractPropTypes } from 'types';
import { RangeSlider } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import classNames from 'classnames';
import { RangeValueType } from 'ncoded-component-library/build/components/molecules/RangeSlider/RangeSlider.component';

import './RangeSliderField.styles.scss';

const boundaryValue = (min: number, max: number, value: number) => {
  return value < min ? min : value > max ? max : value;
};

export type RangeValue = {
  from: number;
  to: number;
};

type RangeSliderProps = ExtractPropTypes<typeof RangeSlider>;

type RangeSliderFieldProps = Omit<RangeSliderProps, 'onChange'> &
  FieldRenderProps<RangeValue, HTMLElement> & {
    className?: string;
    label?: string;
  };

const RangeSliderField: React.FC<RangeSliderFieldProps> = (props) => {
  const {
    input: { value: pValue, onChange, ...inputRest },
    meta: { error, touched },
    className,
    label,
    min,
    max,
    variant,
    onChange: pOnChange,
    ...rest
  } = props;

  const hasError = useMemo(() => error && touched, [error, touched]);
  const classes = classNames(
    'bb-range-slider-field',
    { 'bb-range-slider-field--error': hasError },
    className,
  );

  const changeRef = useRef<boolean>(false);

  const [rangeValue, setRangeValue] = useState<RangeValue>({
    from: min,
    to: max,
  });

  const value = useMemo(
    () =>
      typeof pValue === 'number'
        ? ({
            min: boundaryValue(min, max, pValue),
            max,
            range: max - pValue,
          } as RangeValueType)
        : typeof pValue === 'string'
        ? ({
            min: boundaryValue(min, max, Number(pValue)),
            max,
          } as RangeValueType)
        : ({ min: pValue.from, max: pValue.to } as RangeValueType),
    [max, min, pValue],
  );

  const handleOnChange = useCallback((value: RangeValueType) => {
    const rangeValue = {
      from: Number(value.min.toFixed(2)),
      to: Number(value.max.toFixed(2)),
    } as RangeValue;

    changeRef.current = true;
    setRangeValue(rangeValue);
  }, []);

  useEffect(() => {
    if (changeRef.current) {
      if (variant === 'single') onChange(rangeValue.from);
      else if (variant === 'double') onChange(rangeValue);

      changeRef.current = false;
    }
  }, [onChange, rangeValue, variant]);

  return (
    <div className={classes}>
      <span>{min}</span>
      <RangeSlider
        className="bb-range-slider-field__range-slider"
        {...rest}
        {...inputRest}
        onChange={handleOnChange}
        functionType="linear"
        value={value}
        min={min}
        max={max}
        variant={variant}
      />
      <span>{max}</span>
    </div>
  );
};

export default RangeSliderField;
