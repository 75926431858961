import { ObjectValues } from 'types';
import {
  BaseballOutcome,
  BaseballOutcomeSubType,
  BaseballPeriodTypes,
  BASEBALL_OUTCOMES,
  BASEBALL_PERIODS,
  BASEBALL_SUBOUTCOMES,
} from './outcomes/Baseball';
import {
  BasektballOutcome,
  BasketballOutcomeSubType,
  BasketballPeriodTypes,
  BASKETBALL_OUTCOMES,
  BASKETBALL_PERIODS,
  BASKETBALL_SUBOUTCOMES,
} from './outcomes/Basketball';
import {
  ICEHOCKET_PERIODS,
  IcehockeyOutcome,
  IcehockeyOutcomeSubtype,
  IcehockeyPeriodTypes,
  ICEHOCKEY_OUTCOMES,
  ICEHOCKEY_SUBOUTCOMES,
} from './outcomes/Icehockey';
import {
  SoccerOutcome,
  SoccerOutcomeSubType,
  SoccerPeriodTypes,
  SOCCER_OUTCOMES,
  SOCCER_PERIODS,
  SOCCER_SUBOUTCOMES,
} from './outcomes/Soccer';

export interface OutcomeDataMap {
  [key: string]: string;
}

export const BETTING_TIPS = {
  OVER: 'OVER',
  UNDER: 'UNDER',
} as const;
export type BettingTips = ObjectValues<typeof BETTING_TIPS>;

export type OutcomeType =
  | BasektballOutcome
  | BaseballOutcome
  | IcehockeyOutcome
  | SoccerOutcome;

export type OutcomeSubType =
  | BasketballOutcomeSubType
  | BaseballOutcomeSubType
  | IcehockeyOutcomeSubtype
  | SoccerOutcomeSubType;

export type PeriodTypes =
  | BasketballPeriodTypes
  | BaseballPeriodTypes
  | IcehockeyPeriodTypes
  | SoccerPeriodTypes;

export type OutcomeData = {
  type: OutcomeType;
  subtype: OutcomeSubType;
  limit?: number | string;
  team: 1 | 2;
  handicap?: number | string;
  bettingTip?: BettingTips;
  period: PeriodTypes;
};

export type OutcomeModel = {
  active: boolean;
  type: OutcomeType;
  subtypes: Array<OutcomeSubType>;
  periods: Array<PeriodTypes>;
  data: OutcomeData;
};

export const outcomeTypes = {
  ...BASKETBALL_OUTCOMES,
  ...BASEBALL_OUTCOMES,
  ...ICEHOCKEY_OUTCOMES,
  ...SOCCER_OUTCOMES,
} as const;

export const outcomeSubtypes = {
  ...BASKETBALL_SUBOUTCOMES,
  ...BASEBALL_SUBOUTCOMES,
  ...ICEHOCKEY_SUBOUTCOMES,
  ...SOCCER_SUBOUTCOMES,
} as const;

export const periodTypes = {
  ...BASKETBALL_PERIODS,
  ...BASEBALL_PERIODS,
  ...ICEHOCKET_PERIODS,
  ...SOCCER_PERIODS,
} as const;

export const overunderTypes = {
  OVER: 'Over',
  UNDER: 'Under',
} as const;
