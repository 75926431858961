import React, { useCallback, useContext, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import {
  BetData,
  BETTING_STATUS,
  BETTING_TYPE_STATUS,
  UserBetData,
} from 'models/Bet';
import confirm from 'modules/confirm/index';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import ShowOutcomes from 'components/ShowOutcomes';
import UserImage from 'components/UserImage';
import generateBetMessage from 'modules/message';
import { FIXTURE_STATUSES } from 'models/Fixture';
import { useBetInformation } from 'hooks/useBetInformation';
import { LEAGUES } from 'models/Sport';
import utils from 'utils';
import api from 'api';

import './BetCard.styles.scss';

type BetCardProps = {
  className?: string;
  betData: BetData;
  showOutcomes?: boolean;
  showStatus?: boolean;
  onAccept?: (amount: number, betData: UserBetData) => void;
  onClick?: () => void;
};

const BetCard: React.FC<BetCardProps> = (props) => {
  const {
    className,
    showOutcomes = true,
    showStatus = true,
    betData,
    onClick,
    onAccept,
  } = props;

  const {
    fixtureStatus,
    fixtureResults,
    firstTeam,
    secondTeam,
    startDate: fixtureStartDate,
    league,
  } = betData.fixture;

  const { firstTeamScore, secondTeamScore } = fixtureResults ?? {};

  const {
    Id: betId,
    user,
    userId: userBetId,
    createdAt,
    betTips,
    currentMaxPayment,
    betType,
    betStatus,
    betOutcomes,
    playersCount,
    numberOfOutcomes,
  } = betData ?? {};

  const classes = classNames('bb-bet', className);

  const {
    isMyBet,
    outcomeData,
    betOdd,
    bettingTeamName,
    generateOppositeOutcome,
  } = useBetInformation(betData);

  const { userData, setCurrentBalance, setUserBets } = useContext(MainContext);
  const [loading, setLoading] = useState<boolean>(false);

  const userBetAmount = useMemo(
    () =>
      betTips &&
      betTips
        ?.filter((betTip) => betTip.userId === userData.Id)
        .reduce((acc, currentData) => (acc += currentData.bettingTipAmount), 0),
    [betTips, userData],
  );

  const acceptBet = useCallback(
    async (betId: string, amount?: number) => {
      try {
        setLoading(true);

        const {
          data: { bettingTip },
        } = await api.bets.acceptBet({
          betId,
          betPayment: amount,
        });

        setCurrentBalance((oldBalance) => oldBalance - amount);

        setUserBets((oldBets) =>
          oldBets.find((b) => b.betId === bettingTip.betId)
            ? oldBets.map((bet) => {
                if (bet.betId === bettingTip.betId) return bettingTip;
                else return bet;
              })
            : [bettingTip, ...oldBets],
        );

        onAccept?.(amount, bettingTip);
        utils.toastSuccess('Successfully accepted bet!');
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [onAccept, setCurrentBalance, setUserBets],
  );

  const handleOnAccept = useCallback(async () => {
    if (betType === 'Multi') {
      const { amount, confirm: isConfirmed } = await confirm.betConfirmation({
        title: `accept bet`,
        description: `Enter bet Amount and click OK to accept bet.`,
        maxPayment: currentMaxPayment,
        outcomeMessage: generateBetMessage(outcomeData, bettingTeamName),
      });

      if (isConfirmed) acceptBet(betId, amount);
    } else if (betType === 'Single') {
      if (
        await confirm.simpleConfirmation({
          title: 'Accept Bet',
          description: `${generateBetMessage(
            outcomeData,
            bettingTeamName,
          )}. Click OK to accept this bet with $${currentMaxPayment.toFixed(
            2,
          )}!`,
        })
      )
        acceptBet(betId, currentMaxPayment);
    }
  }, [
    acceptBet,
    betId,
    betType,
    outcomeData,
    bettingTeamName,
    currentMaxPayment,
  ]);

  const statusClasses = classNames(
    'bb-bet__status',
    { 'bb-bet__status--win': betStatus === 'Win' },
    { 'bb-bet__status--lose': betStatus === 'Lose' },
    { 'bb-bet__status--refund': betStatus === 'Refund' },
  );

  const bettingTicketInfo = useMemo(
    () =>
      fixtureStatus === FIXTURE_STATUSES.FINISHED ? (
        `${firstTeamScore} : ${secondTeamScore}`
      ) : fixtureStatus === FIXTURE_STATUSES.NOT_STARTED_YET ? (
        <>
          <span>{`${utils.toDateFormat(fixtureStartDate.toString())}`}</span>
          <span>{`${utils.toHourMinFormat(fixtureStartDate.toString())}`}</span>
        </>
      ) : fixtureStatus === FIXTURE_STATUSES.IN_PROGRESS ? (
        `Game In Progress`
      ) : null,
    [firstTeamScore, fixtureStartDate, fixtureStatus, secondTeamScore],
  );

  const betStatusText = useMemo(
    () =>
      betStatus === BETTING_TYPE_STATUS.Win
        ? `${user.username} won this bet`
        : betStatus === BETTING_TYPE_STATUS.Lose
        ? `${user.username} lost this bet`
        : BETTING_STATUS[betStatus],
    [betStatus, user],
  );

  return (
    <div className={classes}>
      {loading && <Loader />}
      <div className="bb-bet__header">
        <UserImage
          userId={userBetId}
          src={user.profileImageUrl}
          active={user.isOnline}
        />

        <div>
          <p>{`${user.username}`}</p>
          <p>{`${utils.toHourMinFormat(
            createdAt.toString(),
          )} ${utils.toDateFormat(createdAt.toString())}`}</p>
        </div>

        {showStatus && (
          <div className={statusClasses}>
            <p className="bb-bet__status__text">{betStatusText}</p>
          </div>
        )}
      </div>

      <div className="bb-bet__content">
        <div className="bb-bet__content__team" onClick={onClick}>
          <BetTeam {...firstTeam} key="first-team" />
          <div className="bb-bet__content__team__container">
            <p className="bb-bet__content__team--info">{bettingTicketInfo}</p>
            <p className="bb-bet__content__team--vs">VS</p>
            <p className="bb-bet__content__team--league">{LEAGUES[league]}</p>
          </div>
          <BetTeam {...secondTeam} key="second-team" />
        </div>

        {showOutcomes && (
          <ShowOutcomes
            fixture={betData.fixture}
            betOutcomes={betOutcomes.map((outcome) =>
              !isMyBet
                ? generateOppositeOutcome(outcome.outcomeData)
                : outcome.outcomeData,
            )}
          />
        )}
      </div>

      <div className="bb-bet__footer">
        <div className="bb-bet__footer__content">
          <div>
            <p>{numberOfOutcomes}</p>
            <p>outcomes</p>
          </div>
          <div>
            <p>{playersCount}</p>
            <p>players</p>
          </div>
          {userBetAmount !== undefined && (
            <div>
              <p>{`$${userBetAmount.toFixed(2)}`}</p>
              <p>your bet</p>
            </div>
          )}
          <div>
            <p>{`${betOdd.toFixed(2)}`}</p>
            <p>odds</p>
          </div>
        </div>
        {fixtureStatus !== 'FINISHED' &&
          betStatus === 'Open' &&
          userData.Id !== userBetId && (
            <Button ripple onClick={handleOnAccept}>
              Accept Bet
            </Button>
          )}
      </div>
    </div>
  );
};

export default BetCard;
