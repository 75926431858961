import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="8"
    height="11"
    viewBox="0 0 8 11"
  >
    <g fill="none" fillRule="evenodd">
      <g fill="currentColor">
        <path
          d="M930.5 616h7c.276 0 .5.224.5.5v9.5c0 .276-.224.5-.5.5-.108 0-.213-.035-.3-.1L934 624l-3.2 2.4c-.22.166-.534.12-.7-.1-.065-.087-.1-.192-.1-.3v-9.5c0-.276.224-.5.5-.5z"
          transform="translate(-930 -616)"
        />
      </g>
    </g>
  </svg>
);
