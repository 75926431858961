import { UserBetData } from './Bet';

export const TRANSACTION_NAMES: Record<
  string,
  | 'Accept Bet'
  | 'Create Bet'
  | 'Win Bet'
  | 'Deposit'
  | 'Withdraw'
  | 'Refund'
  | 'Deposit - on pending'
  | 'Withdraw - on pending'
  | 'Referral - paid off'
  | 'Deposit - waiting for payment'
> = {
  ACCEPT_BET: 'Accept Bet',
  CREATE_BET: 'Create Bet',
  WIN_BET: 'Win Bet',
  DEPOSIT: 'Deposit',
  WITHDRAW: 'Withdraw',
  REFUND: 'Refund',
  DEPOSIT_PENDING: 'Deposit - on pending',
  DEPOSIT_WAITING_FOR_PAYMENT: 'Deposit - waiting for payment',
  WITHDRAW_PENDING: 'Withdraw - on pending',
  REFERRAL_PAID_OFF: 'Referral - paid off',
} as const;
export type TransactionNames = typeof TRANSACTION_NAMES[number];

export const TRANSACTION_TYPES: Record<
  string,
  'Income' | 'Outcome' | 'Pending' | 'Canceled'
> = {
  INCOME: 'Income',
  OUTCOME: 'Outcome',
  PENDING: 'Pending',
  CANCELED: 'Canceled',
};

export type TransactionTypes = typeof TRANSACTION_TYPES[number];

export const COIN_TYPES = {
  USDC: 'USDC',
  USDT: 'USDT.ERC20',
  'USDC.BEP20': 'USDC.BEP20',
  CREDIT_CARD: 'CREDIT_CARD',
} as const;

export type CoinTypes = typeof COIN_TYPES[keyof typeof COIN_TYPES];
export type CoinObjectType = typeof COIN_TYPES;

export const PAYMENT_STATUS: Record<
  string,
  'PENDING' | 'COMPLETE' | 'CANCELED'
> = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  CANCELED: 'CANCELED',
};
export type PaymentTransactionStatus = typeof PAYMENT_STATUS[number];

export const PAYMENT_TRANSACTION_TYPE: Record<
  string,
  'DEPOSIT' | 'WITHDRAWAL'
> = {
  DEPOSIT: 'DEPOSIT',
  WITHDRAWAL: 'WITHDRAWAL',
};
export type PaymentTransactionType = typeof PAYMENT_TRANSACTION_TYPE[number];

export const STRIPE_PAYMENT_TRANSACTION_STATUS = {
  WAITING_FOR_PAYMENT: 'Waiting',
  PENDING: 'Pending',
  SUCCESSED: 'Succeessed',
  FAILED: 'Failed',
} as const;

export type StripePaymentTransactionStatus =
  typeof STRIPE_PAYMENT_TRANSACTION_STATUS[keyof typeof STRIPE_PAYMENT_TRANSACTION_STATUS];

export type TransactionType = {
  Id: string;
  createdAt: Date;
  transactionType: TransactionTypes;
  transactionName: TransactionNames;
  transactionValue: number;
  paymentTransaction: PaymentTransaction;
  stripePaymentTransaction: StripePaymentTransaction;
  clientSecret?: string;
  updatedAt: Date;
  walletId: string;
  date?: string;
  userBettingTip?: UserBetData;
  userBettingTipId?: string;
};

export type PaymentType = {
  transactionType: TransactionTypes;
  transactionName: TransactionNames;
  transactionValue: number;
  coinType: CoinTypes;
};

export type PaymentResponse = {
  message: string;
  checkout_url: string;
  address: string;
  amount: number;
  coinType: CoinTypes;
  qr_url: string;
};

export type PaymentTransaction = {
  Id: string;
  amount: number;
  checkoutUrl: string;
  coinPaymentsTransactionId: string;
  createdAt: Date;
  cryptoCurrency: CoinTypes;
  paymentStatus: PaymentTransactionStatus;
  paymentType: PaymentTransactionType;
  updatedAt: Date;
  userId: string;
  userTransactionId: string;
  walletAddress: string;
};

export type StripePaymentTransaction = {
  Id: string;
  amount: number;
  clientSecret: string;
  type?: string;
  createdAt: string;
  updatedAt: string;
  status: StripePaymentTransactionStatus;
  stripeCustomerId: string;
  stripePaymentIntentId: string;
  userId: string;
  userTransactionId: string;
};

export interface WithdrawalType extends PaymentType {
  walletAddress: string;
}
