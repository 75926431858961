import api from 'api';
import { AxiosError } from 'axios';
import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import Toggle from 'components/Toggle';
import { Button, Loader } from 'ncoded-component-library';
import React, { useCallback, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import storageService, { STORAGE_KEYS } from 'services/storageService';
import { composeValidators, minLength, required } from 'services/validators';
import Animation from 'components/Animation';
import utils from 'utils';

import './LoginModal.styles.scss';

type LoginModalProps = ModalProps & {
  onSubmit?: (values: any) => void;
  onForgotPassword: () => void;
};

const LoginModal: React.FC<LoginModalProps> = (props: LoginModalProps) => {
  const { open, onSubmit, onForgotPassword, ...rest } = props;

  const history = useHistory();
  const [loginErrorMessage, setErrorLoginMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  const onFormSubmit = useCallback(
    async (values: any) => {
      try {
        setLoading(true);
        const { data } = await api.auth.login(values);

        storageService.setItem(STORAGE_KEYS.TOKEN, data.token);
        storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, data.refreshToken);
        storageService.setItem(STORAGE_KEYS.USER, data.user);

        history.push('/');
      } catch (e) {
        setErrorLoginMessage(utils.getStringError(e as AxiosError));
      } finally {
        setLoading(false);
      }
    },
    [history],
  );

  return (
    <Animation
      open={open}
      appearAnimation="moveUp"
      appearAnimations={{
        moveUp: { duration: 800, timingFunction: EASE_IN_OUT_CUBIC },
      }}
      disappearAnimations={{
        moveDown: { duration: 400, timingFunction: EASE_IN_OUT_CUBIC },
      }}
    >
      <Modal
        {...rest}
        className="bb-auth-modal"
        open
        title={<p>LOG IN</p>}
        onX={() => {
          rest.onClose();
          setErrorLoginMessage('');
        }}
      >
        <Form
          onSubmit={onFormSubmit}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <Field
                label="username or email"
                name="username"
                placeholder="Enter your username"
                component={AnimatedField}
                delay={100}
                validate={composeValidators(
                  required('Username is required'),
                  minLength('Username must have more than 6 characters', 6),
                )}
              />

              <Field
                label="password"
                name="password"
                component={AnimatedField}
                delay={200}
                placeholder="Enter your password"
                type="password"
                validate={composeValidators(
                  required('Password is required'),
                  minLength('Password must have more than 6 characters', 6),
                )}
              />

              <StandardAppearAnimation delay={300}>
                <div className="bb-auth__toggle">
                  <p>Remember Me</p>
                  <Field name="rememberMe" component={Toggle} />
                </div>
              </StandardAppearAnimation>
              <StandardAppearAnimation delay={400}>
                <Button type="submit" styleType="primary">
                  Log In
                </Button>
              </StandardAppearAnimation>
              <StandardAppearAnimation delay={500}>
                <Button
                  variant="link"
                  styleType="secondary"
                  type="button"
                  onClick={() => {
                    rest.onClose();
                    onForgotPassword();
                  }}
                >
                  Forgot Password?
                </Button>
              </StandardAppearAnimation>
            </form>
          )}
        />
        {loading && <Loader />}
        {loginErrorMessage && (
          <StandardAppearAnimation>
            <div>
              <p>{loginErrorMessage}</p>
            </div>
          </StandardAppearAnimation>
        )}
      </Modal>
    </Animation>
  );
};

export default LoginModal;
