import Camera from 'assets/svgs/Camera';
import CreditCard from 'assets/svgs/CreditCard';
import Gallery from 'assets/svgs/Gallery';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import FormWrapper from 'components/FormWrapper';
import useFormHelperElements from 'hooks/useFormHelperElements';
import { Button } from 'ncoded-component-library';
import React, { useCallback, useEffect, useState } from 'react';
import { Field, useField } from 'react-final-form';

import './DriverForm.styles.scss';

type DriverFormProps = {};

const DriverForm: React.FC<DriverFormProps> = (props) => {
  const [fileSource, setFileSource] = useState<File>(null);
  const { Submit, ErrorMessage } = useFormHelperElements();

  const {
    input: { value: driverFile },
  } = useField('driverlicence');

  const deviceCameraHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, input: any) => {
      if (event.target.files && event.target.files.length !== 0) {
        setFileSource(event.target.files[0]);
        input.onChange(event.target.files[0]);
      }
    },
    [],
  );

  useEffect(() => {
    if (driverFile) {
      setFileSource(driverFile);
    }
  }, [driverFile]);

  return (
    <FormWrapper className="bb-driver-details">
      <div>
        <StandardAppearAnimation delay={500} duration={400}>
          <div className="bb-driver-details__container">
            <div>
              {fileSource && (
                <img src={URL.createObjectURL(fileSource)} alt="" />
              )}
              {!fileSource && <CreditCard />}
            </div>
            <Field name="driverlicence">
              {({ input, meta }) => (
                <div className="bb-driver-details__container__content">
                  <Button
                    styleType="secondary"
                    type="button"
                    icon={<Camera />}
                    iconPosition="left"
                  >
                    CAMERA
                    <label>
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        capture="environment"
                        onChange={(event) => deviceCameraHandler(event, input)}
                      />
                    </label>
                  </Button>
                  <Button
                    styleType="secondary"
                    type="button"
                    icon={<Gallery />}
                    iconPosition="left"
                  >
                    PHOTO LIBRARY
                    <label>
                      <input
                        type="file"
                        name="file"
                        hidden
                        accept="image/*"
                        onChange={(event) => deviceCameraHandler(event, input)}
                      />
                    </label>
                  </Button>
                </div>
              )}
            </Field>
          </div>
        </StandardAppearAnimation>
      </div>
      <div className="bb-driver-details__footer">
        <Submit type="submit" disabled={!fileSource}>
          Finish
        </Submit>
        <ErrorMessage />
      </div>
    </FormWrapper>
  );
};

export default DriverForm;
