import React from 'react';
import BetProvider from './providers/Bet';
import Bets from './Bets.page';

const wrapper = () => (
  <BetProvider>
    <Bets />
  </BetProvider>
);

export default wrapper;
