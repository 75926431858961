import { BetData } from 'models/Bet';
import { BETTING_TIPS, OutcomeData, OutcomeSubType } from 'models/Outcome';
import { useCallback, useContext, useMemo } from 'react';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';

export const TEAM_WINNER_OUTCOME_TYPES = [
  'RUNS_WINNER',
  'GOALS_WINNER',
  'POINTS_WINNER',
  'SCORES_WINNER',
] as Array<Partial<OutcomeSubType>>;

export const TEAM_LOGO_OUTCOME_TYPES = [
  ...TEAM_WINNER_OUTCOME_TYPES,
  'FOULS_WINNER',
  'CORNERS_WINNER',
  'OFFSIDES_WINNER',
  'FREE_THROWS_MADE_WINNER',
  'TWO_POINTS_MADE_WINNER',
  'THREE_POINTS_MADE_WINNER',
  'OFFENSIVE_REBOUNDS_WINNER',
  'DEFENSIVE_REBOUNDS_WINNER',
  'TOTAL_REBOUNDS_WINNER',
  'ASSISTS_WINNER',
  'BLOCKS_WINNER',
  'STEALS_WINNER',
  'TURNOVERS_WINNER',
] as Array<Partial<OutcomeSubType>>;

export function useBetInformation({
  betOutcomes,
  userId: userBetId,
  betOdd: betOddValue,
  fixture: { firstTeam, secondTeam },
}: BetData) {
  const {
    userData: { Id: userId },
  } = useContext(MainContext);

  const isMyBet = useMemo(() => userId === userBetId, [userBetId, userId]);

  const betOdd = useMemo(
    () => (isMyBet ? betOddValue : 1 + 1 / (betOddValue - 1)),
    [betOddValue, isMyBet],
  );

  const generateOppositeOutcome = useCallback(
    (data: OutcomeData) => {
      return {
        ...data,
        team: data.team
          ? !isMyBet && TEAM_LOGO_OUTCOME_TYPES.includes(data.subtype)
            ? data.team === 1
              ? 2
              : 1
            : data.team
          : undefined,
        handicap:
          data.handicap !== undefined
            ? isMyBet
              ? data.handicap
              : data.handicap === 0
              ? 0
              : -data.handicap
            : undefined,
        bettingTip: data.bettingTip
          ? !isMyBet
            ? data.bettingTip === BETTING_TIPS.OVER
              ? BETTING_TIPS.UNDER
              : BETTING_TIPS.OVER
            : data.bettingTip
          : undefined,
      };
    },
    [isMyBet],
  );

  const outcomeData = useMemo(
    () =>
      !isMyBet
        ? generateOppositeOutcome(betOutcomes[0].outcomeData)
        : betOutcomes[0].outcomeData,
    [betOutcomes, generateOppositeOutcome, isMyBet],
  );

  const bettingTeamName = useMemo(
    () => (outcomeData.team === 1 ? firstTeam.teamName : secondTeam.teamName),
    [outcomeData, firstTeam.teamName, secondTeam.teamName],
  );

  return {
    isMyBet,
    betOdd,
    outcomeData,
    bettingTeamName,
    generateOppositeOutcome,
  };
}
