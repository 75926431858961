import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import classNames from 'classnames';
import useCSSVariables from 'hooks/useCSSVariables';
import useCallbackRef from 'hooks/useCallbackRef';
import './ImageSlider.styles.scss';

export type ImageType = {
  imageUrl?: string;
  index?: number;
};

type ImageSliderProps = {
  className?: string;
  images?: Array<ImageType>;
};

type PointerEv =
  | React.MouseEvent<HTMLDivElement, MouseEvent>
  | React.TouchEvent<HTMLDivElement>;

// to do - clean up

const ImageSlider: React.FC<ImageSliderProps> = (props) => {
  const { className, images } = props;

  const classes = classNames('bb-image-slider', className);

  const [activeImageIndex, setActiveImageIndex] = useState<number>(0);
  const [slideImageWidth, setSlideImageWidth] = useState<number>();
  const [translateSlideXOffset, setTranslateSlideXOffset] = useState<number>(0);
  const [translateMouseXOffset, setTranslateMouseXOffset] = useState<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [slideBoundary, setSlideBoundary] = useState<number>(0);
  const [dotIndex, setDotIndex] = useState(0);
  const translateX = useRef(0);

  const trackingInterval = useRef<any>(null);
  const trackingTranslate = useRef(0);
  const trackingXOffset = useRef(0);
  const trackedVelocity = useRef(0);
  const trackedAmplitude = useRef(0);
  const timesTracked = useRef(0);
  const timestamp = useRef(0);

  const imageContainerRef = useRef<HTMLDivElement>();
  const expansionDot = useRef<HTMLDivElement>();
  const animatingAtm = useRef(false);

  const [activeDot, activeDotRef] = useCallbackRef<HTMLDivElement>(null);
  const [rootContainer, rootContainerRef] =
    useCallbackRef<HTMLDivElement>(null);

  const expandDotRef = useRef(1);

  const handleDotClick = useCallback(
    (index: number) => {
      setTranslateSlideXOffset(-(index * slideImageWidth));
      setActiveImageIndex(index);
    },
    [slideImageWidth],
  );

  const xOffsetDot = useMemo(() => {
    if (!activeDot || !rootContainer) return;

    const dotRect = activeDot.getBoundingClientRect();
    const rootRect = rootContainer.getBoundingClientRect();
    return dotRect.left - rootRect.left;
  }, [activeDot, rootContainer]);

  const onWindowResize = useCallback(() => {
    if (imageContainerRef.current) {
      setSlideImageWidth(
        (imageContainerRef.current as HTMLElement).clientWidth,
      );
      setSlideBoundary(50);
    }
  }, []);

  useEffect(() => {
    const goingLeft = translateMouseXOffset > 0;
    const amount = ~~Math.abs(translateMouseXOffset / slideImageWidth);
    const amount2 =
      ((Math.abs(translateMouseXOffset) - amount * slideImageWidth) %
        slideImageWidth) /
      slideImageWidth;
    const parsedRatio = amount2 * 100;

    if (
      (!goingLeft && activeImageIndex === images.length - 1) ||
      (activeImageIndex === 0 && goingLeft)
    )
      return;

    if (parsedRatio >= 70) {
      const total = amount + (amount2 >= 0.7 ? 1 : 0);
      setDotIndex(activeImageIndex + (!goingLeft ? total : -total));
      expandDotRef.current = ((100 - parsedRatio) / 30) * 120;
    } else {
      setDotIndex(activeImageIndex + (goingLeft ? -amount : amount));
      expandDotRef.current = parsedRatio === 0 ? 1 : (parsedRatio / 70) * 120;
    }
  }, [activeImageIndex, images.length, slideImageWidth, translateMouseXOffset]);

  useEffect(() => setDotIndex(activeImageIndex), [activeImageIndex]);

  useEffect(() => {
    onWindowResize();
    window.addEventListener('resize', onWindowResize);
    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [onWindowResize]);

  const calculateXStart = useCallback((event: PointerEv) => {
    // touch event
    if (
      (event as React.TouchEvent).targetTouches &&
      (event as React.TouchEvent).targetTouches.length >= 1
    ) {
      return (event as React.TouchEvent).targetTouches[0].clientX;
    }

    // mouse event
    else return (event as React.MouseEvent).clientX;
  }, []);

  const track = useCallback(() => {
    const now = Date.now();
    const elapsed = now - timestamp.current;
    timestamp.current = now;
    const delta = trackingTranslate.current - trackingXOffset.current;
    trackingXOffset.current = trackingTranslate.current;
    timesTracked.current += 1;
    const v = (1000 * delta) / (1 + elapsed);
    trackedVelocity.current = 0.8 * v + 0.2 * trackedVelocity.current;
  }, []);

  const tap = useCallback(
    (event: PointerEv) => {
      translateX.current = calculateXStart(event);
      setIsDragging(true);

      trackedVelocity.current = trackedAmplitude.current = 0;
      trackingXOffset.current = trackingTranslate.current;
      timesTracked.current = 0;
      timestamp.current = Date.now();
      clearInterval(trackingInterval.current);
      trackingInterval.current = setInterval(track, 50);

      event.stopPropagation();
    },
    [calculateXStart, track],
  );

  const drag = useCallback(
    (event: PointerEv) => {
      if (isDragging) {
        const currentX = calculateXStart(event);
        const difference = translateX.current - currentX;
        trackingTranslate.current = -difference;
        setTranslateMouseXOffset(-difference);
      }
    },
    [calculateXStart, isDragging],
  );

  const release = useCallback(
    (event: PointerEv) => {
      const ratio = Math.abs(
        (100 * translateMouseXOffset) / imageContainerRef.current.clientWidth,
      );
      setTranslateMouseXOffset(0);
      setIsDragging(false);

      clearInterval(trackingInterval.current);
      if (timesTracked.current === 0) track();
      const { current: velocity } = trackedVelocity;

      if (ratio < slideBoundary && Math.abs(velocity) < 100) {
        expandDotRef.current = 1;

        return;
      }

      const slidesPassed = ~~(ratio / 100) + (ratio % 100 >= 70 ? 1 : 0) || 1;
      const goingLeft = translateMouseXOffset > 0;
      if (
        ratio < 70 &&
        !(
          (!goingLeft && activeImageIndex === images.length - 1) ||
          (activeImageIndex === 0 && goingLeft)
        )
      ) {
        const ratio2 = ~~ratio;
        const stepUp = (120 - expandDotRef.current) / ratio2;
        const stepDown = -120 / (100 - ratio2);

        let total = expandDotRef.current;
        const keyframes = `
        @keyframes dot-expansion {
          ${Array.from({ length: 101 })
            .map((_, ind) => {
              total +=
                ind <= ratio2 && total + stepUp < 120 ? stepUp : stepDown;

              return `
            ${ind}% {
              --dotTransformOrigin: ${
                translateMouseXOffset < 0
                  ? ind <= ratio2
                    ? 'left'
                    : 'right'
                  : ind <= ratio2
                  ? 'right'
                  : 'left'
              };
              --expand: ${total};
              --compress: ${1 / total};
            }
          `;
            })
            .join(' ')}
        }
        `;

        const keyframesParent = `
        @keyframes dot-expansion-parent {
          ${Array.from({ length: 101 })
            .map(
              (_, ind) => `${ind}% {
              --xOffset: ${
                (activeImageIndex +
                  (ind <= ratio2 ? 0 : translateMouseXOffset < 0 ? 1 : -1)) *
                12
              }px;}`,
            )
            .join(' ')}
            `;

        let style = document.querySelector('[data-animate="dot-expansion"]');

        if (!style) {
          style = document.createElement('style');
          document.body.append(style);
          style.setAttribute('data-animate', 'dot-expansion');
        }
        style.innerHTML = `${keyframes} ${keyframesParent}`;

        animatingAtm.current = true;
        expansionDot.current.setAttribute(
          'style',
          `animation: dot-expansion 200ms ease-in-out;
          `,
        );

        expansionDot.current.parentElement.setAttribute(
          'style',
          `animation: dot-expansion-parent 200ms ease-in-out;
          `,
        );

        expansionDot.current.addEventListener(
          'animationend',
          () => {
            expansionDot.current.removeAttribute('style');
            expansionDot.current.parentElement.removeAttribute('style');
          },
          { once: true },
        );
      }

      setActiveImageIndex((old) =>
        translateMouseXOffset > 0
          ? old === 0
            ? old
            : old - slidesPassed
          : old === images.length - 1
          ? old
          : old + slidesPassed,
      );

      expandDotRef.current = 1;
    },
    [
      images.length,
      slideBoundary,
      track,
      activeImageIndex,
      translateMouseXOffset,
    ],
  );

  useEffect(() => {
    setTranslateSlideXOffset(-activeImageIndex * slideImageWidth);
    trackingTranslate.current = -activeImageIndex * slideImageWidth;
  }, [activeImageIndex, slideImageWidth]);

  const offsetFromHoveredSlide = useMemo(
    () =>
      dotIndex * slideImageWidth -
      Math.abs(translateSlideXOffset + translateMouseXOffset),
    [dotIndex, slideImageWidth, translateMouseXOffset, translateSlideXOffset],
  );

  const scopedPaginationVariables = useCSSVariables({
    xOffset: `${xOffsetDot}px`,
    dotTransformOrigin: offsetFromHoveredSlide > 0 ? 'right' : 'left',
    expand: expandDotRef.current,
  });

  const scopedImageVariables = useCSSVariables({
    translateOffset: `${translateSlideXOffset + translateMouseXOffset}px`,
  });

  return (
    <div className={classes} ref={imageContainerRef}>
      <div
        className={classNames('bb-image-slider__image-container', {
          transition: !isDragging,
        })}
      >
        {scopedImageVariables}
        {images.map((image: ImageType, index: number) => {
          return (
            <div
              key={index}
              className="bb-image-slider__image-container__image"
              onMouseDown={tap}
              onMouseMove={drag}
              onMouseUp={release}
              onTouchStart={tap}
              onTouchMove={drag}
              onTouchEnd={release}
              style={{
                transform: `translate3d(${index * 100}%, 0, 0)`,
              }}
            >
              <img src={image.imageUrl} alt="" />
            </div>
          );
        })}
      </div>
      <div className="bb-image-slider__dot-container" ref={rootContainerRef}>
        {scopedPaginationVariables}
        {Array.from({ length: images.length }).map((_, index: number) => {
          return (
            <div
              key={index}
              ref={index === dotIndex ? activeDotRef : null}
              className="bb-image-slider__dot-container__dot"
              onClick={() => handleDotClick(index)}
            />
          );
        })}
        <div
          className={classNames('bb-image-slider__slide-dot', {
            'transition-transform': !isDragging,
          })}
        >
          <div ref={expansionDot} />
        </div>
      </div>
    </div>
  );
};

export default ImageSlider;
