import api from 'api';
import classNames from 'classnames';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { BetData } from 'models/Bet';
import { Loader } from 'ncoded-component-library';
import React, { useCallback, useContext, useImperativeHandle } from 'react';
import BetCard from 'routers/MainRouter/pages/Bets/components/BetCard';
import StadiumContext from 'routers/MainRouter/pages/Stadium/providers/Stadium/Stadium.context';
import { ScrollableListFCRef } from '../../models/ScrollableList';

import './BetList.styles.scss';

type BetListProps = {
  className?: string;
};

const BetList: React.ForwardRefRenderFunction<
  ScrollableListFCRef,
  BetListProps
> = ({ className }, ref) => {
  const { selectedFixture } = useContext(StadiumContext);

  const {
    loading,
    items: currentBets,
    setItems: setCurrentBets,
    onContainerScrolled,
  } = useInfinitePagination<BetData>({
    makeRequest: (currentPage: number) => {
      return api.fixture
        .getFixtureDetails({
          fixtureId: selectedFixture?.Id,
          page: currentPage,
          limit: 10,
        })
        .then(({ data }) => data);
    },
    mainDep: selectedFixture,
  });

  const acceptBetHandler = useCallback(
    (paymentAmount: number, betId: string) => {
      const betData = currentBets.find((bet) => bet.Id === betId);

      if (betData.currentMaxPayment === +paymentAmount)
        setCurrentBets((oldBets) => oldBets.filter((bet) => bet.Id !== betId));
      else
        setCurrentBets((oldBets) =>
          oldBets.map((bet) => {
            if (bet.Id === betId) {
              return {
                ...bet,
                currentMaxPayment: bet.currentMaxPayment - paymentAmount,
              };
            } else return bet;
          }),
        );
    },
    [currentBets, setCurrentBets],
  );

  useImperativeHandle(ref, () => ({ onContainerScrolled }), [
    onContainerScrolled,
  ]);

  return (
    <div className={classNames('bb-bet-list', className)}>
      {loading && <Loader className="bb-fixture-details__loader" />}

      {currentBets.length === 0 && (
        <EmptyPlaceholder
          title="There aren't bets created for this game yet!"
          description="Bets will be listed here when someone place bet on this game."
        />
      )}
      {currentBets?.map((bet: BetData, index: number) => {
        return (
          <BetCard
            key={index}
            betData={bet}
            showStatus={false}
            onAccept={(amount) => acceptBetHandler(amount, bet.Id)}
          />
        );
      })}
    </div>
  );
};

export default React.forwardRef(BetList);
