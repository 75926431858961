import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'ncoded-component-library';
import { ButtonProps } from 'ncoded-component-library/build/components/atoms/Button/Button.component';
import classNames from 'classnames';

import './CopyToClipboard.styles.scss';

type CopyToClipboardProps = {
  className?: string;
  code: string;
  showCode?: boolean;
  copyText?: string;
  copiedText?: string;
} & Omit<ButtonProps, 'ref'>;

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({
  className,
  code,
  showCode = true,
  copiedText = 'Copied',
  copyText = 'Copy code',
  ...rest
}) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleClick = useCallback(() => {
    setIsCopied(true);

    if (navigator.clipboard) {
      navigator.clipboard.writeText(code);
      return;
    }

    const textField = document.createElement('textarea');
    textField.innerText = code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    document.body.removeChild(textField);
  }, [code]);

  useEffect(() => {
    if (!isCopied) return;
    let timeoutId: NodeJS.Timeout;
    timeoutId = setTimeout(() => setIsCopied(false), 3000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isCopied]);

  return (
    <div className={classNames('bb-copy-to-clipboard', className)}>
      <Button
        {...rest}
        className={classNames('bb-copy-to-clipboard__button', {
          copied: isCopied,
        })}
        onClick={handleClick}
      >
        {isCopied ? copiedText : copyText}
      </Button>
      {showCode && <label className="bb-copy-to-clipboard__code">{code}</label>}
    </div>
  );
};

export default CopyToClipboard;
