import React from 'react';

export default (props: React.SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="currentColor"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-195 -194) translate(12 68) translate(183 126)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M12 3c4.97 0 9 4.03 9 9s-4.03 9-9 9-9-4.03-9-9 4.03-9 9-9zm.896 5.606L7 14.503v2.5h2.5l5.896-5.897-2.5-2.5zm2.458-1.75c-.196-.195-.512-.195-.708 0L13.603 7.9l2.5 2.5 1.043-1.043c.196-.195.196-.511 0-.707z"
              transform="translate(-195 -194) translate(12 68) translate(183 126)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
