import React, { useCallback } from 'react';
import { Button, Dropdown } from 'ncoded-component-library';
import { EmailIcon, EmailShareButton } from 'react-share/es/';
import classNames from 'classnames';
import {
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

import { ExtractPropTypes } from 'types';
import CopyToClipboard from 'modules/confirm/modals/ReferralCodeModal/CopyToClipboard';
import useMatchMedia from 'hooks/useMatchMedia';
import ShareIcon from 'assets/svgs/Share';
import LinkIcon from 'assets/svgs/Link.icon';
import env from 'env';

import './SocialShare.styles.scss';

type SocialButtonOptions = ExtractPropTypes<typeof EmailShareButton>;
type SocialShareType = 'Facebook' | 'Whatsapp' | 'Twitter' | 'Email' | 'link';

type SocialShareProps = {
  children?: React.ReactNode;
  className?: string;
  shareUrl: string;
  socials?: Array<SocialShareType>;
} & Partial<SocialButtonOptions>;

const SocialShare: React.FC<SocialShareProps> = (props) => {
  const {
    className,
    shareUrl = '/',
    socials = ['Facebook', 'Whatsapp', 'Email', 'link'],
    ...rest
  } = props;

  const classes = classNames('bb-social-share', className);

  const isMobileDevice = useMatchMedia('isPhablet');

  const checkSocialLink = useCallback(
    (social: SocialShareType, link: React.ReactNode) => {
      if (!socials.includes(social)) return null;
      else return link;
    },
    [socials],
  );

  return (
    <Dropdown
      renderAsPortal
      portalClassName="bb-social-share"
      className={classes}
      trigger={
        <Button
          className="bb-social-share__trigger"
          styleType="secondary"
          iconPosition="left"
          icon={<ShareIcon />}
        >
          Share
        </Button>
      }
    >
      <>
        {checkSocialLink(
          'Facebook',
          isMobileDevice ? (
            <a
              className="bb-social-share__button"
              href={`fb-messenger://share/?link=${shareUrl}&app_id=${env.FACEBOOK_APP_ID}`}
            >
              <FacebookMessengerIcon />
              <span>Send via Messenger</span>
            </a>
          ) : (
            <FacebookMessengerShareButton
              appId={env.FACEBOOK_APP_ID}
              className="bb-social-share__button"
              url={shareUrl}
              {...rest}
            >
              <FacebookMessengerIcon />
              <span>Send via Messenger</span>
            </FacebookMessengerShareButton>
          ),
        )}

        {checkSocialLink(
          'Whatsapp',
          <WhatsappShareButton
            className="bb-social-share__button"
            url={shareUrl}
            {...rest}
          >
            <WhatsappIcon />
            <span>Share via Whatsapp</span>
          </WhatsappShareButton>,
        )}

        {checkSocialLink(
          'Email',
          <EmailShareButton
            className="bb-social-share__button"
            url={shareUrl}
            {...rest}
          >
            <EmailIcon />
            <span>Share via Email</span>
          </EmailShareButton>,
        )}

        {checkSocialLink(
          'link',
          <CopyToClipboard
            className="bb-social-share__link-button"
            showCode={false}
            code={shareUrl}
            copyText="Copy Link"
            copiedText="Link copied"
            iconPosition="left"
            icon={<LinkIcon />}
          />,
        )}
      </>
    </Dropdown>
  );
};

export default SocialShare;
