import httpClient from 'api/httpClient';
import { WalletAddressType, WalletTypeResponse } from 'models/Wallet';

const coinsPath = (routePath: TemplateStringsArray) => `coins${routePath}`;

function getWalletAddresses() {
  return httpClient.get<WalletTypeResponse>(coinsPath`/coin-settings`);
}

function updateWalletAddresses(data: WalletAddressType) {
  return httpClient.post(coinsPath`/wallet/address`, data);
}

function createPaymentIntent(amount: number) {
  return httpClient.post<{
    clientSecret: string;
    stripePaymentTransactionId: string;
  }>('/stripe/create-payment-intent', { amount });
}

function stripePayout(stripePaymentTransactionId: string) {
  return httpClient.post(`/stripe/pay/${stripePaymentTransactionId}`);
}

export default {
  getWalletAddresses,
  updateWalletAddresses,
  createPaymentIntent,
  stripePayout,
};
