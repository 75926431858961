import BaseBall from 'assets/svgs/sports/BaseBall';
import BaskettBall from 'assets/svgs/sports/BaskettBall';
import Boxing from 'assets/svgs/sports/Boxing';
import Hockey from 'assets/svgs/sports/Hockey';
import PingPong from 'assets/svgs/sports/PingPong';
import Rugby from 'assets/svgs/sports/Rugby';
import Soccer from 'assets/svgs/sports/Soccer';
import Tennis from 'assets/svgs/sports/Tennis';
import VolleyBall from 'assets/svgs/sports/VolleyBall';
import { RadioSlideType } from 'components/RadioSlider/RadioSlider.component';

export const sportsRadioSlides = [
  {
    element: 'All',
    value: 'all',
  },
  {
    element: BaskettBall,
    value: 'BASKETBALL',
  },
  {
    element: Hockey,
    value: 'ICE_HOCKEY',
  },
  {
    element: Rugby,
    value: 'RUGBY',
  },
  {
    element: Soccer,
    value: 'SOCCER',
  },
  {
    element: BaseBall,
    value: 'BASEBALL',
  },
  {
    element: Tennis,
    value: 'TENNIS',
  },
  {
    element: VolleyBall,
    value: 'VOLLEYBALL',
  },
  {
    element: Boxing,
    value: 'BOXING',
  },
  {
    element: PingPong,
    value: 'PINGPONG',
  },
] as Array<RadioSlideType>;

export const outcomeSlides = [
  {
    element: '1 Outcome',
    value: '1',
  },
  {
    element: '3 Outcome',
    value: '3',
  },
  {
    element: '5 Outcome',
    value: '5',
  },
] as Array<RadioSlideType>;

export const teamSlides = [
  {
    element: 'Team Members',
    value: 0,
  },
  {
    element: 'Upcoming Games',
    value: 1,
  },
] as Array<RadioSlideType<number>>;

export const betSlides = [
  {
    element: 'Buddy Bets',
    value: 0,
  },
  {
    element: 'Public Bets',
    value: 1,
  },
] as Array<RadioSlideType<number>>;

export const ticketSlides = [
  {
    element: 'Players',
    value: '1',
  },
  {
    element: 'Bet Stats',
    value: '2',
  },
] as Array<RadioSlideType>;

export const friendSlides = [
  {
    element: 'My Friends',
    value: 0,
  },
  {
    element: 'Friend Requests',
    value: 1,
  },
  {
    element: 'Find',
    value: 2,
  },
] as Array<RadioSlideType<number>>;

export const gameSlides = [
  {
    element: 'Bets List',
    value: 0,
  },
  {
    element: 'Teams Stats H2H',
    value: 1,
  },
] as Array<RadioSlideType<number>>;

export const shareFriendSlides = [
  {
    element: 'All friends',
    value: 0,
  },
  {
    element: 'Friends Online',
    value: 1,
  },
] as Array<RadioSlideType<number>>;
