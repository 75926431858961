import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import Plus from 'assets/svgs/Plus';
import Send from 'assets/svgs/Send';
import AppearAnimation from 'components/AppearAnimation';
import Modal, { ModalProps } from 'components/Modal/Modal.component';
import { Input, Button, Loader } from 'ncoded-component-library';
import utils from 'utils';
import useCallbackRef from 'hooks/useCallbackRef';
import { ChatMessage, ChatSessionClientData, MessageType } from 'models/Chats';
import api from 'api';
import classNames from 'classnames';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import InfiniteScroll from 'components/InfiniteScroll';
import useInfinitePagination from 'hooks/useInfinitePagination';
import BetChatCard from '../BetChatCard';
import credentialsService from 'services/credentialsService';
import UserImage from 'components/UserImage';
import NotificationContext from 'routers/MainRouter/pages/MainPage/providers/Notification/Notification.context';
import { useHistory } from 'react-router';

import './ChatModal.styles.scss';

type ChatModalProps = {
  chatSession: ChatSessionClientData;
  onMessageSent?: (sessionId: string, message: MessageType) => void;
} & ModalProps;

const ChatModal: React.FC<ChatModalProps> = (props) => {
  const {
    chatSession: {
      Id: sessionId,
      senders,
      sender,
      sender: {
        participant: { username, profileImageUrl, isOnline, Id: userId },
      },
      chatableType,
      group,
    },
    onMessageSent,
    ...rest
  } = props;

  const [message, setMessage] = useState<string>('');
  const [messageContainer, messageContainerRef] =
    useCallbackRef<HTMLDivElement>(null);

  const { readAllSessionMessageNotification } = useContext(NotificationContext);

  const user = useMemo(() => credentialsService.user, []);
  const history = useHistory();

  const sessionName = useMemo(
    () =>
      chatableType === 'Group'
        ? group?.name
        : chatableType === 'Friendship'
        ? `${username}`
        : '',
    [chatableType, username, group],
  );

  const sessionImage = useMemo(
    () =>
      chatableType === 'Group'
        ? group?.image?.publicUrl
        : chatableType === 'Friendship'
        ? profileImageUrl
        : '',
    [chatableType, group, profileImageUrl],
  );

  const {
    loading,
    items: messages,
    setItems: setMessages,
    onContainerScrolled: onMessageScroll,
  } = useInfinitePagination<MessageType>({
    makeRequest: (currentPage: number) => {
      return api.chat
        .getChatMessages(
          {
            page: currentPage,
            limit: 40,
          },
          sessionId,
        )
        .then(({ data }) => data);
    },
  });

  const sendMessage = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      try {
        if (!message) return;
        const newMessage: ChatMessage = {
          sessionId,
          text: message,
          files: [],
        };

        const messageData: MessageType = {
          ...newMessage,
          userId: user.Id,
          sessionId,
          createdAt: new Date(),
        };

        setMessage('');
        setMessages((oldMessages) => [messageData, ...oldMessages]);
        onMessageSent?.(sessionId, messageData);

        await api.chat.sendMessage(newMessage);
      } catch (e) {
        utils.toastError(e);
      }
    },
    [message, sessionId, user.Id, onMessageSent, setMessages],
  );

  const getMessageSender = useCallback(
    (userId: string) => {
      if (chatableType !== 'Group') return sender.participant;
      const groupSender = senders.find(
        (sender) => sender.participantId === userId,
      );
      return groupSender?.participant;
    },
    [chatableType, sender, senders],
  );

  const mapMessagesData = useCallback((messages: Array<MessageType>) => {
    return messages.map((message, index) => {
      let currentDate, nextDate, messageDate;

      if (index < messages.length - 1)
        nextDate = utils.toDateFormat(messages[index + 1].createdAt.toString());
      currentDate = utils.toDateFormat(messages[index].createdAt.toString());

      if (
        index === messages.length - 1 ||
        (index === 0 && currentDate !== nextDate) ||
        currentDate !== nextDate
      )
        messageDate = currentDate;
      else messageDate = null;

      return {
        ...message,
        date: messageDate,
      } as MessageType;
    });
  }, []);

  const mapedMessages = useMemo(() => {
    return mapMessagesData(messages);
  }, [mapMessagesData, messages]);

  useEffect(() => {
    if (!messageContainer) return;
    messageContainer.scrollIntoView();
  }, [messageContainer]);

  useEffect(() => {
    readAllSessionMessageNotification(sessionId);
  }, [readAllSessionMessageNotification, sessionId]);

  useEffect(() => {
    const messageCreated = (data: { message: MessageType }) => {
      if (sessionId === data.message?.sessionId) {
        const isMessageExist = messages.find((messageData: MessageType) => {
          return messageData.Id === data.message?.Id;
        });
        if (!isMessageExist) {
          setMessages((old) => [data.message, ...old]);
        }
      }
    };

    eventSocketService.addListener<{ message: MessageType }>(
      socketEventNames.MESSAGE_CREATED,
      messageCreated,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.MESSAGE_CREATED,
        messageCreated,
      );
    };
  }, [message, messages, sessionId, setMessages]);

  return (
    <AppearAnimation duration={800} animationName="moveIn">
      <Modal
        className="bb-chat-modal"
        {...rest}
        open
        title={
          <>
            <UserImage
              src={sessionImage}
              active={isOnline}
              onClick={() =>
                chatableType !== 'Group' &&
                history.push(`menu/profile/${userId}`)
              }
            />

            <div>
              <p>{sessionName}</p>
              <p>{isOnline ? `ONLINE` : `OFFLINE`}</p>
            </div>
          </>
        }
      >
        <div>{loading && <Loader />}</div>
        {messages?.length === 0 && <p>Start Chatting...</p>}

        <InfiniteScroll
          onScroll={onMessageScroll}
          className="bb-chat-modal__container"
        >
          {mapedMessages.map((message: MessageType, index: number) => {
            const messageSender = getMessageSender(message.userId);
            return (
              <div key={index}>
                {message.date && (
                  <div className="bb-chat-modal__message-date">
                    <p>{message.date}</p>
                  </div>
                )}
                <div
                  className={classNames(
                    'bb-chat-modal__message-container',
                    {
                      'bb-chat-modal__message-container--recieved':
                        message.userId !== user.Id,
                    },
                    {
                      'bb-chat-modal__message-container--sent':
                        message.userId === user.Id,
                    },
                  )}
                >
                  {message.userId !== user.Id && (
                    <UserImage
                      className="bb-chat-modal__message-indicator"
                      src={messageSender.profileImageUrl}
                      active={messageSender.isOnline}
                      userId={messageSender.Id}
                    />
                  )}

                  <div
                    className={
                      message.bet
                        ? 'bb-chat-modal__message-container__content--full-width'
                        : 'bb-chat-modal__message-container__content'
                    }
                    ref={index === 0 ? messageContainerRef : null}
                  >
                    {message.bet && <BetChatCard bet={message.bet} />}
                    {message.text && (
                      <div className="bb-chat-modal__message-container__message">
                        {message.text}
                      </div>
                    )}
                    <p>
                      {utils.toHourMinFormat(message.createdAt?.toString())}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </InfiniteScroll>

        <form
          className="bb-chat-modal__footer-without-margin"
          onSubmit={(event) => sendMessage(event)}
        >
          <Button styleType="secondary" icon={Plus} type="button" />
          <Input
            value={message}
            autoComplete="off"
            onChange={(event) => setMessage(event.target.value)}
            placeholder="Start typing..."
          />
          <Button styleType="secondary" icon={Send} type="submit" />
        </form>
      </Modal>
    </AppearAnimation>
  );
};

export default ChatModal;
