import React, { useCallback, useContext, useRef, useMemo } from 'react';
import RadioSliderField from 'components/Fields/RadioSliderField';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { Field, withTypes } from 'react-final-form';
import { Button } from 'ncoded-component-library';
import {
  LEAGUES_SLIDES,
  timeSlides,
} from 'routers/MainRouter/pages/MainPage/components/BetFilterModal/filterSlides';
import { FixtureFilterData } from '../../providers/Stadium/Stadium.provider';
import { OverlayRef } from 'components/Overlay';
import { sportTypes } from 'routers/MainRouter/services/constants';
import { LeagueType } from 'models/Sport';
import CalendarField from 'components/Fields/CalendarField';
import StadiumContext from '../../providers/Stadium/Stadium.context';
import Animation from 'components/Animation';

import './TimeFilterModal.styles.scss';
import {
  moveDownWithBackground,
  moveUpWithBackground,
} from 'models/Animations';

export type FixtureSortTypes = 'TIME_STARTED' | 'POPULARITY';

type TimeFilterModalProps = {
  filterData: FixtureFilterData;
  onSubmit: (data: FixtureFilterData) => void;
} & ModalProps;

const FilterModal: React.FC<TimeFilterModalProps> = (props) => {
  const { filterData, open, onSubmit, ...modalRest } = props;

  const timeFilterModalRef = useRef<OverlayRef>(null);
  const { selectedSport, setFixtureFilter } = useContext(StadiumContext);

  const { Form } = withTypes<FixtureFilterData>();

  const leaguesSlides = useMemo(
    () =>
      selectedSport !== 'all'
        ? LEAGUES_SLIDES[selectedSport]?.length > 1
          ? [
              ...[{ element: 'All leagues', value: 'all' }],
              ...LEAGUES_SLIDES[selectedSport],
            ]
          : null
        : null,
    [selectedSport],
  );

  const onFormSubmit = useCallback(
    (values: FixtureFilterData) => {
      if (onSubmit) onSubmit(values);
      modalRest.onClose();
    },
    [onSubmit, modalRest],
  );

  return (
    <Animation
      open={open}
      duration={600}
      appearAnimations={moveUpWithBackground}
      disappearAnimations={moveDownWithBackground}
    >
      <Modal
        {...modalRest}
        open
        className="bb-time-filter-modal"
        title={<p>filters</p>}
        ref={timeFilterModalRef}
        onX={modalRest.onClose}
      >
        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            sort: filterData?.sort,
            date: filterData?.date,
            league: filterData?.league,
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="bb-time-filter-modal__container">
                <Field
                  className="bb-time-filter-modal__calendar"
                  name="date"
                  onlyFuture
                  component={CalendarField}
                  value={filterData?.date}
                />
                <p>sort</p>
                <Field<FixtureSortTypes>
                  component={RadioSliderField}
                  slideSteps={timeSlides}
                  name="sort"
                  inputName="sort"
                  value={filterData?.sort}
                />
                {!!leaguesSlides && (
                  <>
                    <p>{sportTypes[selectedSport] + ' leagues'}</p>
                    <Field<LeagueType | 'all'>
                      component={RadioSliderField}
                      slideSteps={leaguesSlides}
                      name="league"
                      inputName="league"
                      value={filterData?.league}
                      defaultValue="all"
                    />
                  </>
                )}
              </div>
              <div className="bb-time-filter-modal__footer-without-margin">
                <Button
                  styleType="secondary"
                  onClick={() => {
                    form.reset();
                    setFixtureFilter(null);
                    modalRest.onClose();
                  }}
                >
                  Clear Filter
                </Button>
                <Button styleType="primary" type="submit">
                  Apply Filter
                </Button>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default FilterModal;
