import { FriendshipType } from 'models/Friends';
import { UserGlobalType, UserType } from 'models/User';
import credentialsService from 'services/credentialsService';

type MenuService = {
  mapFriendsData: (values: Array<FriendshipType>) => Array<FriendshipType>;
  mapFriendData: (value: FriendshipType) => FriendshipType;
  mapFriendRequestData: (value: FriendshipType) => FriendshipType;
  mapGlobalUsersData: (values: Array<UserType>) => Array<UserGlobalType>;
};

function mapFriendsData(values: Array<FriendshipType>) {
  return values.map((friendship) => mapFriendData(friendship));
}

function mapFriendData(friendship: FriendshipType) {
  return friendship.userId1 === credentialsService.user.Id
    ? { ...friendship, sender: friendship.user2, areFriends: true }
    : { ...friendship, sender: friendship.user1, areFriends: true };
}

function mapFriendRequestData(friendship: FriendshipType) {
  return {
    ...friendship,
    sender: friendship.user1,
    areFriends: true,
  } as FriendshipType;
}

function mapGlobalUsersData(users: Array<UserType>) {
  return users.map((user) => {
    return {
      ...user,
      friendshipStatus:
        user.friendsRequests.length > 0
          ? 'PENDING'
          : user.friends.length > 0
          ? 'FRIEND'
          : 'NOT_FRIEND',
    } as UserGlobalType;
  });
}

export default {
  mapFriendsData,
  mapFriendData,
  mapFriendRequestData,
  mapGlobalUsersData,
} as MenuService;
