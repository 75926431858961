import React, { useCallback, useContext } from 'react';
import '../../styles/YearAndMonthContainer.styles.scss';
import { Pickers, MONTH_NAMES } from '../../utils';
import classNames from 'classnames';
import { setValidMonthDay } from '../../../utils';
import DatepickerContext from '../../provider/Datepicker.context';
import * as utils from '../../../utils';

import './MonthPicker.scss';

const { DAY_PICKER } = Pickers;

type MonthPickerProps = {
  className?: string;
};

const classBase = (suffix: TemplateStringsArray | string = '') =>
  `year-month-picker__field${suffix}`;

const MonthPicker: React.FC<MonthPickerProps> = ({ className }) => {
  const { setShowInMenu, setCurrentDate, minDate, maxDate, date, currentDate } =
    useContext(DatepickerContext);

  const MonthPickerClassNames = classNames(
    'year-month-picker',
    'month-picker',
    className,
  );

  const isYearValid = useCallback(
    (year: number) => utils.isYearValid(year, minDate, maxDate),
    [maxDate, minDate],
  );

  const isMonthSelected = useCallback(
    (month: number) => {
      return month === date.month && currentDate.year === date.year;
    },
    [currentDate.year, date.month, date.year],
  );

  const isMonthValid = useCallback(
    (month: number) =>
      utils.isMonthValid(month, currentDate, minDate, maxDate) &&
      isYearValid(currentDate.year),
    [currentDate, minDate, maxDate, isYearValid],
  );

  const handleOnClick = useCallback(
    (index) => {
      if (isMonthValid(index + 1)) {
        setCurrentDate?.((oldVal) => setValidMonthDay(oldVal, index + 1));
        setShowInMenu?.(DAY_PICKER);
      }
    },
    [isMonthValid, setCurrentDate, setShowInMenu],
  );

  return (
    <main className={MonthPickerClassNames}>
      {MONTH_NAMES.map((month, index) => (
        <div
          className={classNames(classBase(), {
            [classBase`--selected`]: isMonthSelected(index + 1),
            [classBase`--valid`]: isMonthValid(index + 1),
            [classBase`--invalid`]: !isMonthValid(index + 1),
          })}
          onClick={() => handleOnClick(index)}
          key={month}
        >
          <div className="year-month-picker__field__content">{month}</div>
        </div>
      ))}
    </main>
  );
};

export default MonthPicker;
