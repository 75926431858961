import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { TeamData } from 'models/Team';
import './TeamCard.styles.scss';

type TeamCardProps = {
  className?: string;
  data?: TeamData;
  sportIcon?: React.ReactNode;
  onClick?: () => void;
};

const TeamCard: React.FC<TeamCardProps> = (props) => {
  const {
    className,
    data: {
      team: { teamName, logoUrl, publicTeamLogoUrl },
      loses,
      wins,
      winLosePercentage,
    },
    sportIcon,
    onClick,
  } = props;
  const classes = classNames('bb-team-card', className);

  const renderIcon = useCallback((item: React.ReactNode | string) => {
    if (typeof item === 'string') return item;
    else {
      const IconToRender = item as React.FC;
      return <IconToRender />;
    }
  }, []);

  const logoImage = useMemo(
    () => (logoUrl ? logoUrl : publicTeamLogoUrl),
    [logoUrl, publicTeamLogoUrl],
  );

  return (
    <div className={classes} onClick={onClick}>
      <img src={logoImage} alt="logo" />
      <div className="bb-team-card__content">
        <p>{teamName}</p>
        <div className="bb-team-card__content__data">
          <div className="bb-team-card__flag--wins" />
          <p>{`${wins} WINS`}</p>
          <div className="bb-team-card__flag--loss" />
          <p>{`${loses} LOSS`}</p>
          <div className="bb-team-card__rect" />
          <p>{`${winLosePercentage.toFixed(2)}%`}</p>
        </div>
      </div>
      {sportIcon && (
        <div className="bb-team-card__sport">{renderIcon(sportIcon)}</div>
      )}
    </div>
  );
};

export default TeamCard;
