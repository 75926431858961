import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import classNames from 'classnames';
import useCSSVariables from 'hooks/useCSSVariables';
import utils from 'utils';
import useCallbackRef from 'hooks/useCallbackRef';
import ResizeObserver from 'resize-observer-polyfill';

import './RadioSlider.styles.scss';

export type RadioSlideType<T = string> = {
  element?: React.ReactNode | string;
  value?: T;
  label?: string;
};

type RadioSliderProps<T extends string | number> = {
  className?: string;
  inputName?: string;
  name?: string;
  value?: T;
  inputValue?: T;
  defaultIndex?: number;
  slideSteps?: Array<RadioSlideType<T>>;
  showValueOfRadioSlide?: boolean;
  onChange?: (activeRadio: T) => void;
};

function RadioSlider<T extends string | number>(props: RadioSliderProps<T>) {
  const {
    className,
    name,
    value,
    inputName,
    inputValue,
    slideSteps,
    defaultIndex = 0,
    showValueOfRadioSlide = false,
    onChange,
  } = props;
  const [offsetMap, setOffestMap] = useState<
    Array<{ leftOffset: Number; width: number }>
  >([]);

  const radioIndex = useMemo(
    () =>
      slideSteps.findIndex(
        (element) => element.value === (value || inputValue),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [slideSteps, value],
  );

  const [activeIndex, setActiveIndex] = useState<number>(
    radioIndex !== -1 ? radioIndex : defaultIndex || 0,
  );

  const classes = classNames([
    'bb-radio-slider',
    { 'bb-radio-slider--first-selected': activeIndex === 0 },
    { 'bb-radio-slider--last-selected': activeIndex === slideSteps.length - 1 },
    className,
  ]);

  const isValNumber = useMemo(
    () => typeof slideSteps[0]?.value === 'number',
    [slideSteps],
  );

  const [rootContainer, rootContainerRef] = useCallbackRef<HTMLDivElement>();

  useLayoutEffect(() => {
    const ro = new ResizeObserver((entries: any) => {
      setOffestMap(
        (Array.from(rootContainer.children) as HTMLElement[]).map((item) => {
          const itemRect = item.getBoundingClientRect();

          return {
            leftOffset: item.offsetLeft,
            width: itemRect.width,
          };
        }),
      );
    });

    setTimeout(() => {
      if (!rootContainer) return;

      ro.observe(rootContainer);
    }, 0);

    return () => {
      if (rootContainer) ro.unobserve(rootContainer);
    };
  }, [rootContainer]);

  const handleOnChange = useCallback(
    (
      { target: { value } }: React.ChangeEvent<HTMLInputElement>,
      index: number,
    ) => {
      onChange?.((isValNumber ? +(value as string) : value) as T);
      setActiveIndex(index);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange],
  );

  const scopedVariables = useCSSVariables({
    width: `${offsetMap[activeIndex]?.width}px`,
    xOffset: `${offsetMap[activeIndex]?.leftOffset}px`,
  });

  useEffect(
    () =>
      radioIndex !== -1
        ? setActiveIndex(radioIndex)
        : setActiveIndex(defaultIndex),
    [radioIndex, defaultIndex],
  );

  return (
    <div className={classes} ref={rootContainerRef}>
      {scopedVariables}
      {slideSteps.map((item, index) => (
        <label
          key={index}
          className={classNames({
            'bb-radio-slider__selected': activeIndex === index,
          })}
        >
          <input
            type="radio"
            name={inputName || name}
            hidden
            value={item.value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handleOnChange(event, index)
            }
          />
          {utils.renderIcon(item.element)}
          {showValueOfRadioSlide && index !== 0 && <p>{item.label}</p>}
        </label>
      ))}
    </div>
  );
}

export default RadioSlider;
