import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <path
            fill="#FFF"
            fillOpacity="0"
            d="M0 0H32V32H0z"
            transform="translate(-616 -616) translate(616 616)"
          />
          <path
            fill="currentColor"
            fillRule="nonzero"
            d="M16 1c3.314 0 6 2.686 6 6v6h3.5c.276 0 .5.224.5.5v15c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-15c0-.276.224-.5.5-.5H10V7c0-3.314 2.686-6 6-6zm0 16c-1.105 0-2 .895-2 2 0 .74.402 1.387 1 1.732V24.5c0 .276.224.5.5.5h1c.276 0 .5-.224.5-.5l.001-3.768c.597-.346.999-.992.999-1.732 0-1.105-.895-2-2-2zm0-14c-2.21 0-4 1.79-4 4v6h8V7c0-2.21-1.79-4-4-4z"
            transform="translate(-616 -616) translate(616 616)"
          />
        </g>
      </g>
    </g>
  </svg>
);
