import Auth from 'pages/Auth';
import redirect from 'router/modules/redirect';
import { EnhancedRouteProps } from './routes/EnhancedRoute/EnhancedRoute.component';
import MainPage from 'routers/MainRouter/pages/MainPage';
import Catalog from 'pages/Catalog';

export const routes = [
  {
    path: '/start',
    authorized: false,
    onlyPublic: true,
    component: Auth,
  },
  {
    path: '/catalog',
    authorized: false,
    component: Catalog,
    exact: false,
  },
  {
    path: '',
    authorized: true,
    component: MainPage,
    exact: false,
  },

  {
    path: '*',
    component: redirect('/start'),
  },
] as Array<EnhancedRouteProps>;
