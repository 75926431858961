import React from 'react';
import { NavLink, NavLinkProps } from 'react-router-dom';
import classNames from 'classnames';
import LoadButton from 'components/LoadButton';
import UserImage from 'components/UserImage';
import { UserType } from 'models/User';
import './FriendShipCard.styles.scss';

type FriendShipCardProps = {
  className?: string;
  user: UserType;
  loading?: boolean;
  text?: string;
  icon?: React.ReactNode;
  buttonStyle?: 'primary' | 'secondary';
  onAction: () => void; //mouse click event on button element
} & React.PropsWithoutRef<NavLinkProps>;

const FriendShipCard: React.FC<FriendShipCardProps> = (props) => {
  const {
    className,
    user,
    loading,
    text,
    icon,
    buttonStyle = 'primary',
    onAction,
    ...rest
  } = props;

  const { username, profileImageUrl, isOnline } = user;

  const classes = classNames('bb-friendship-card', className);

  return (
    <div className={classes}>
      <NavLink {...rest}>
        <UserImage src={profileImageUrl} active={isOnline} />
        <p>{`${username}`}</p>
      </NavLink>

      <LoadButton
        loading={loading}
        iconPosition="right"
        styleType={buttonStyle}
        onClick={onAction}
      >
        {text}
      </LoadButton>
    </div>
  );
};

export default FriendShipCard;
