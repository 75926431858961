import { MutableRefObject, useEffect, useRef } from 'react';

export default function useLatestRef<T>(value: T) {
  const latestRef = useRef<T>(value);

  useEffect(() => {
    latestRef.current = value;
  });

  return latestRef as MutableRefObject<T>;
}
