import classNames from 'classnames';
import React from 'react';
import { mockPlayers } from '../../../../mockData';
import PlayerCard from '../../../PlayerCard';

import './TeamPlayers.styles.scss';

type TeamPlayersProps = {
  className?: string;
};

const TeamPlayers: React.FC<TeamPlayersProps> = (props) => {
  const { className } = props;

  const classes = classNames('bb-team-players', className);

  return (
    <div className={classes}>
      {mockPlayers.map((player, index: number) => {
        return <PlayerCard key={index} {...player} />;
      })}
    </div>
  );
};

export default TeamPlayers;
