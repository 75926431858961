import { FixtureStatusType, FixtureType } from './Fixture';
import { OutcomeData } from './Outcome';
import { SportType } from './Sport';
import { UserType } from './User';

export type BetPrivacy = 'Private' | 'Public';
export type BetType = 'Multi' | 'Single';
export type BetStatus = 'Open' | 'Close';

export type ShowBetTypes = 'ALL_BETS' | 'UPCOMING' | 'PREVIOUS';
export type FilterBetTypes = 'ALL_BETS' | 'MY_BETS' | 'ACCEPTED';
export type SortBetTypes = 'PRICE' | 'MOST_RECENT' | 'POPULARITY';

export type ShowFeedBetTypes = 'FRIENDS' | 'POPULAR';
export type FilterFeedBetTypes = 'PREVIOUS' | 'OPEN' | 'CLOSED';
export type OutcomeFilterBetsTypes = 'WINS' | 'LOSES';

export const BET_STATUSES = {
  UPCOMING: 'UPCOMING',
  ACTIVE: 'ACTIVE',
  FINISHED: 'FINISHED',
} as const;

export type ShowBetTypesEnhanced =
  | (Exclude<ShowBetTypes, 'ALL_BETS'> & ShowBetTypes)
  | 'IN_PROGRESS';

export const BETTING_STATUS = {
  Win: 'Win',
  Lose: 'Lose',
  Open: 'Open',
  Close: 'Close',
  Refund: 'Refund',
  'Payment panding': 'Payment panding',
} as const;

export const BETTING_TYPE_STATUS = {
  Win: 'Win',
  Lose: 'Lose',
  Refund: 'Refund',
  'In progress': 'In progress',
} as const;

export type BettingStatusType =
  typeof BETTING_STATUS[keyof typeof BETTING_STATUS];

export type BettingTipStatusType =
  typeof BETTING_TYPE_STATUS[keyof typeof BETTING_TYPE_STATUS];

export const bettingType = ['Accepted', 'Created'] as const;
export type BettingType = typeof bettingType[number];

export type BetDataType = {
  betAmount: number;
  betType: BetType;
  betOdd: number;
  betPrivacy: BetPrivacy;
  fixtureId?: string;
  outcomes?: Array<OutcomeData>;
  sessionIds?: Array<string>;
};

export type TicketData = {
  Id: string;
  averageBet?: number;
  activeBetsCount?: number;
  firstBetId?: string;
  firstUserCreatedId?: string;
  bets?: Array<BetData>;
  betsCount?: number;
  betOdd?: number;
  betOutcomes?: Array<{ outcomeData: OutcomeData }>;
  betType?: BetType;
  playersCount: number;
  numberOfOutcomes: number;
  totalAmount: number;
  totalFirstTip: number;
  totalSecondTip: number;
  ticketStatus?: 'Active' | 'Inactive';
  createdAt: Date;
  updatedAt: Date;
  sport?: SportType;
  fixture: FixtureType;
  fixtureId: string;
  user?: UserType;
};

export type BetData = {
  Id: string;
  betOdd?: number;
  betOutcomes?: Array<{ outcomeData: OutcomeData }>;
  betPrivacy?: BetPrivacy;
  betStatus?: BettingStatusType;
  bettingTipStatus?: BettingTipStatusType;
  betTips?: Array<BetTipsType>;
  betType?: BetType;
  bettingAmount?: number;
  cashoutAmount?: number;
  createdAt?: Date;
  currentMaxPayment?: number;
  currentPayment?: number;
  fixture?: FixtureType;
  fixtureId?: string;
  maxPayment?: number;
  numberOfOutcomes?: number;
  playersCount?: number;
  refundedMoney?: number;
  sport?: SportType;
  ticketId?: string;
  ticket?: TicketData;
  totalBetAmount?: number;
  updatedAt?: Date;
  user: UserType;
  userId?: string;
};

export type UserBetData = {
  Id?: string;
  bet: BetData;
  betId: string;
  betOdd: number;
  betCashout: number;
  bettingTipAmount: number;
  bettingTipStatus: BettingTipStatusType;
  bettingType: BettingType;
  createdAt: Date;
  fixtureStatus: FixtureStatusType;
  sport: SportType;
  updatedAt: Date;
  userId: string;
  winLoseAmount: number;
};

export type BetStatType = {
  outcome: string;
  totalFirstTip: number;
  totalSecondTip: number;
};

export type FilterBetData = {
  sport?: SportType | 'all';
  // show?: ShowBetTypes;
  filter?: FilterBetTypes;
  sort?: SortBetTypes;
};

export type FilterFeedBetData = {
  sport?: SportType | 'all';
  show?: FilterFeedBetTypes;
  filter?: ShowFeedBetTypes;
  betsFilterType?: OutcomeFilterBetsTypes;
};

export type BetTipsType = {
  Id: string;
  betOdd: number;
  bet: UserBetData;
  bettingTipAmount: number;
  bettingType: 'Created' | 'Accepted';
  user: UserType;
  userId: string;
};

export const HANDICAP_SLIDES = {
  STRAIGHT_UP: 'STRAIGHT_UP',
  HANDICAP: 'HANDICAP',
} as const;

export type HandicapSlides =
  typeof HANDICAP_SLIDES[keyof typeof HANDICAP_SLIDES];

export type BetStatusStyleType = 'solid' | 'outline';
