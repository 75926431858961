import api from 'api';
import Search from 'assets/svgs/Search';
import { AxiosError } from 'axios';
import Animation from 'components/Animation';
import InfiniteScroll from 'components/InfiniteScroll';
import LoadButton from 'components/LoadButton';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import UserImage from 'components/UserImage';
import useInfinitePagination from 'hooks/useInfinitePagination';
import { ChatSessionClientData, ChatSessionType } from 'models/Chats';
import { Input, Loader } from 'ncoded-component-library';
import React, { useCallback, useState } from 'react';
import chatService from 'routers/MainRouter/pages/Chat/chatService';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import utils from 'utils';

import './ShareModal.styles.scss';

type ShareModalProps = {
  shareContentId: string; // in most cases shareContentId is betId
  onSubmit?: () => void;
} & ModalProps;

const ShareModal: React.FC<ShareModalProps> = (props) => {
  const { shareContentId, open, onSubmit, ...rest } = props;

  const [loadingIndex, setLoadingIndex] = useState<number>();

  const {
    loading: loadingSessions,
    items: sessions,
    searchString,
    setSearchString,
    onContainerScrolled: onSessionScroll,
  } = useInfinitePagination<ChatSessionType>({
    makeRequest: (currentPage: number, searchString: string) => {
      return api.chat
        .getChatSessions({
          page: currentPage,
          limit: 15,
          searchString,
        })
        .then(({ data }) => {
          return {
            ...data,
            rows: chatService.mapChatSessions(data.rows),
          };
        });
    },
  });

  const getChatName = useCallback((session: ChatSessionClientData) => {
    return session.chatableType === 'Friendship'
      ? `${session.sender.participant.username}`
      : session.chatableType === 'Group'
      ? `${session.group.name}`
      : '';
  }, []);

  const onSendUserHandler = useCallback(
    async (sessionId: string, index: number) => {
      try {
        setLoadingIndex(index);
        await api.chat.shareBet(sessionId, shareContentId);
      } catch (e) {
        utils.toastError(e as AxiosError);
      } finally {
        setLoadingIndex(null);
      }
    },
    [shareContentId],
  );

  return (
    <Animation
      open={open}
      duration={800}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        {...rest}
        open
        onX={rest.onClose}
        className="bb-share-modal"
        title={<p>share with friend</p>}
      >
        {loadingSessions && <Loader />}
        <Input
          prefixNode={<Search />}
          placeholder="Search..."
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />

        <InfiniteScroll
          onScroll={onSessionScroll}
          className="bb-share-modal__container"
        >
          {sessions.map((session: ChatSessionClientData, index: number) => {
            const sessionName = getChatName(session);
            return (
              <div className="bb-share-modal__container__card" key={index}>
                <div>
                  <UserImage
                    userId={session.sender.participant?.Id}
                    src={session.sender.participant?.profileImageUrl || ''}
                    alt="profile"
                    active={session.sender?.participant.isOnline}
                  />
                  <p>{`${sessionName}`}</p>
                </div>
                <LoadButton
                  styleType="primary"
                  onClick={() => onSendUserHandler(session.Id, index)}
                  loading={index === loadingIndex}
                >
                  send
                </LoadButton>
              </div>
            );
          })}
        </InfiniteScroll>
      </Modal>
    </Animation>
  );
};

export default ShareModal;
