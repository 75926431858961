import React, { useCallback, useState, useMemo } from 'react';
import {
  composeValidators,
  equalValues,
  minLength,
  required,
} from 'services/validators';
import classNames from 'classnames';
import { ModalProps } from 'components/Modal/Modal.component';
import Animation from 'components/Animation';
import Modal from 'components/Modal';
import AnimatedField from 'components/animated/AnimatedField';
import StandardAppearAnimation from 'components/AppearAnimation/components/StandardAppearAnimation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import { Field, withTypes } from 'react-final-form';
import { Button, Loader } from 'ncoded-component-library';
import useQueryParams from 'hooks/useQueryParams';
import toastr from 'modules/toastr';
import api from 'api';

import './ResetPasswordModal.styles.scss';

export type ResetPasswordType = {
  password: string;
  confirmPassword: string;
};

type ResetPasswordModalProps = {
  className?: string;
} & ModalProps;

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = (props) => {
  const { className, open, ...rest } = props;

  const {
    params: { token },
  } = useQueryParams<{ token: string }>();

  const [loading, setLoading] = useState<boolean>(false);

  const classes = classNames('bb-reset-password-modal', className);
  const { Form } = withTypes<ResetPasswordType>();

  const validationObject = useMemo(
    () => ({
      password: composeValidators(
        required('Password is required'),
        minLength('Password must have more than 6 characters', 6),
      ),
      confirmPassword: composeValidators(
        required('Password is required'),
        minLength('Password must have more than 6 characters', 6),
        equalValues('Password must match', 'password'),
      ),
    }),
    [],
  );

  const handleOnResetPassword = useCallback(
    async (values: ResetPasswordType) => {
      try {
        if (!token) return;
        setLoading(true);

        await api.auth.resetPassword(token, values.password);

        toastr.show({
          type: 'Success',
          text: 'Success',
          description: 'Your password is changed successfully',
          disappearAfter: 5000,
        });

        rest.onClose();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    },
    [rest, token],
  );

  return (
    <Animation
      open={open}
      appearAnimation="moveUp"
      appearAnimations={{
        moveUp: { duration: 800, timingFunction: EASE_IN_OUT_CUBIC },
      }}
      disappearAnimations={{
        moveDown: { duration: 400, timingFunction: EASE_IN_OUT_CUBIC },
      }}
    >
      <Modal
        {...rest}
        open
        className={classes}
        title={<p>reset password</p>}
        onX={() => rest.onClose()}
      >
        {loading && <Loader />}
        <Form
          onSubmit={handleOnResetPassword}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <StandardAppearAnimation delay={100}>
                <p className="bb-reset-password-modal__text">
                  Create a new password
                </p>
              </StandardAppearAnimation>

              <Field
                name="password"
                label="password"
                type="password"
                placeholder="Enter new password"
                delay={200}
                component={AnimatedField}
                validate={validationObject.password}
              />
              <Field
                name="confirmPassword"
                label="confirm password"
                type="password"
                placeholder="Confirm new password"
                delay={300}
                component={AnimatedField}
                validate={validationObject.confirmPassword}
              />
              <StandardAppearAnimation delay={400}>
                <Button ripple styleType="primary" type="submit">
                  Reset
                </Button>
              </StandardAppearAnimation>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default ResetPasswordModal;
