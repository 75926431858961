import React, {
  useMemo,
  useState,
  useCallback,
  useRef,
  useContext,
  useEffect,
} from 'react';
import classNames from 'classnames';
import { ModalProps } from 'components/Modal/Modal.component';
import Modal from 'components/Modal';
import AppearAnimation from 'components/AppearAnimation';
import api from 'api';
import useInfinitePagination from 'hooks/useInfinitePagination';
import {
  CoinTypes,
  COIN_TYPES,
  PaymentType,
  StripePaymentTransaction,
  TransactionType,
  TRANSACTION_NAMES,
  TRANSACTION_TYPES,
} from 'models/Transactions';
import { Button, Loader } from 'ncoded-component-library';
import InfiniteScroll from 'components/InfiniteScroll';
import utils from 'utils';
import walletSteps from './formSteps';
import StepProgress from 'components/StepProgress';
import { withTypes } from 'react-final-form';
import StepsProvider, {
  StepProviderRef,
} from 'components/FormWrapper/providers/Steps/Steps.provider';
import { CSSTransition } from 'react-transition-group';
import { Step } from 'components/FormWrapper/providers/Steps/Steps.context';
import { socketEventNames } from 'services/socket/socketService';
import eventSocketService from 'services/socket/eventSocketService';
import MainContext from '../../providers/Main/Main.context';
import TransactionItem from './components/TransactionItem';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import { NotificationType } from 'models/Notification';
import useCallbackRef from 'hooks/useCallbackRef';
import { OverlayRef } from 'components/Overlay';
import Animation from 'components/Animation';
import Withdraw from './components/Withdraw';
import Arrow from 'assets/svgs/Arrow';
import { FormApi } from 'final-form';
import {
  moveDownWithBackground,
  moveUpWithBackground,
} from 'models/Animations';

import './WalletModal.styles.scss';

declare global {
  interface Window {
    activeTransactionView: boolean;
  }
}

export type DepositFormType = {
  transactionValue?: string;
  paymentMethod?: CoinTypes;
  checkoutUrl?: string;
  address?: string;
  amount?: number;
  coinType?: CoinTypes;
  clientSecret?: string;
  stripeTransactionId?: string;
  qr_url?: string;
};

type WalletModalProps = {
  className?: string;
} & ModalProps;

const WalletModal: React.FC<WalletModalProps> = (props) => {
  const { className, open, ...rest } = props;
  const classes = classNames('bb-wallet-modal', className);

  const [loading, setLoading] = useState<boolean>(false);
  const [showDeposit, setShowDeposit] = useState<boolean>(false);
  const [showWithdrawl, setShowWithdrawl] = useState<boolean>(false);

  const [depoisitInitialValues, setDepositInitalValues] =
    useState<DepositFormType>();

  const { currentBalance } = useContext(MainContext);
  const { Form: DepositForm } = withTypes<Partial<DepositFormType>>();

  const [
    { currentStep, steps, nextStep, prevStep } = {} as StepProviderRef,
    stepsProviderRef,
  ] = useCallbackRef<StepProviderRef>();

  const modalRef = useRef<OverlayRef>(null);
  const formRef =
    useRef<FormApi<DepositFormType, Partial<DepositFormType>>>(null);

  const {
    loading: loadingTransaction,
    items: transactions,
    setItems: setTransactions,
    onContainerScrolled: onTransactionScroll,
  } = useInfinitePagination<TransactionType>({
    makeRequest: (currentPage: number) => {
      return api.transactions
        .getUserTransactions({ page: currentPage, limit: 20 })
        .then(({ data }) => data);
    },
    resetDeps: [open],
    dependencies: [open],
  });

  const lastTransaction = useRef<TransactionType>(null);

  const closeModal = useCallback(() => {
    rest.onClose();
    setTimeout(() => {
      setShowDeposit(false);
      setShowWithdrawl(false);
      setDepositInitalValues(null);

      window.activeTransactionView = false;
    }, 800);
  }, [rest]);

  const mapTransactionsData = useCallback(
    (transactions: Array<TransactionType>) => {
      return transactions.map((transaction, index) => {
        let prevDate = '';

        if (index > 0 && index <= transactions.length - 1)
          prevDate = utils.toDateFormat(
            transactions[index - 1].createdAt.toString(),
          );
        else lastTransaction.current = transactions[transactions.length - 1];

        const newDate = utils.toDateFormat(transaction.createdAt.toString());

        return {
          ...transaction,
          date: newDate !== prevDate ? newDate : null,
        } as TransactionType;
      });
    },
    [],
  );

  const makeDeposit = useCallback(
    async (value: DepositFormType) => {
      try {
        setLoading(true);
        const { transactionValue, paymentMethod } = value;

        const transactionData = {
          transactionType: TRANSACTION_TYPES.OUTCOME,
          transactionName: TRANSACTION_NAMES.DEPOSIT,
          transactionValue: parseFloat(transactionValue),
          coinType: paymentMethod,
        } as PaymentType;

        const { data } = await api.transactions.makeDeposit(transactionData);

        formRef.current.change('checkoutUrl', data.checkout_url);
        formRef.current.change('address', data.address);
        formRef.current.change('amount', data.amount);
        formRef.current.change('coinType', data.coinType);
        formRef.current.change('qr_url', data.qr_url);

        nextStep();
      } catch (e) {
        utils.toastError(e);
      } finally {
        setLoading(false);
      }
    },
    [nextStep],
  );

  const onDepositSubmit = useCallback(
    async (values: DepositFormType) => {
      if (
        currentStep === 0 &&
        values.paymentMethod !== COIN_TYPES.CREDIT_CARD
      ) {
        makeDeposit(values);
        return;
      }

      if (
        currentStep === 1 &&
        values.paymentMethod === COIN_TYPES.CREDIT_CARD
      ) {
        closeModal();
        return;
      }

      nextStep();
    },
    [currentStep, nextStep, makeDeposit, closeModal],
  );

  const animatedStep = useMemo(() => {
    return steps?.map((step: Step, index: number) => (
      <CSSTransition
        key={step.title}
        in={currentStep === index}
        unmountOnExit
        timeout={0}
        classNames="bb-wallet-modal__step"
      >
        <AppearAnimation duration={300} animationName="appear">
          <div className="bb-wallet-modal__step">
            <step.component />
          </div>
        </AppearAnimation>
      </CSSTransition>
    ));
  }, [steps, currentStep]);

  const mappedTransactions = useMemo(() => {
    return mapTransactionsData(transactions);
  }, [mapTransactionsData, transactions]);

  const onDepositMade = useCallback(
    (data: NotificationType) => {
      const {
        content: { transaction },
      } = data;

      setTransactions((oldTransactions) => {
        const transactionExists = oldTransactions.some(
          (t) => t.Id === transaction.Id,
        );

        if (!transactionExists)
          return mapTransactionsData([transaction, ...oldTransactions]);

        return oldTransactions.map((t) => {
          if (t.Id === transaction.Id) return { ...t, transaction };
          else return t;
        });
      });
    },
    [mapTransactionsData, setTransactions],
  );

  const onWithdrawal = useCallback(
    (data: NotificationType) => {
      const {
        content: { transaction },
      } = data;
      setTransactions((oldTransactions) => [transaction, ...oldTransactions]);
    },
    [setTransactions],
  );

  const onTransactionViewStatus = useCallback(
    (stripTransaction: StripePaymentTransaction) => {
      setDepositInitalValues({
        transactionValue: `${stripTransaction.amount}`,
        clientSecret: stripTransaction.clientSecret,
        stripeTransactionId: stripTransaction.Id,
        paymentMethod: COIN_TYPES.CREDIT_CARD,
      });

      setShowDeposit(true);
      window.activeTransactionView = true;
    },
    [],
  );

  useEffect(() => {
    eventSocketService.addListener(socketEventNames.DEPOSIT, onDepositMade);
    eventSocketService.addListener(socketEventNames.WITHDRAWAL, onWithdrawal);

    return () => {
      eventSocketService.removeListener(
        socketEventNames.DEPOSIT,
        onDepositMade,
      );
      eventSocketService.removeListener(
        socketEventNames.WITHDRAWAL,
        onWithdrawal,
      );
    };
  }, [onDepositMade, onWithdrawal]);

  return (
    <Animation
      open={open}
      duration={800}
      appearAnimations={moveUpWithBackground}
      disappearAnimations={moveDownWithBackground}
    >
      <Modal
        {...rest}
        open
        ref={modalRef}
        className={classes}
        onX={closeModal}
        title={
          <>
            {showDeposit ? (
              <p>Make Deposit</p>
            ) : showWithdrawl ? (
              <p>Make Withdraw</p>
            ) : (
              <p> My Wallet</p>
            )}
            {currentStep > 0 &&
              currentStep !== undefined &&
              !window.activeTransactionView && (
                <Button
                  className="bb-wallet-modal__prev-step-button"
                  onClick={prevStep}
                  icon={Arrow}
                />
              )}
          </>
        }
      >
        {!showDeposit && !showWithdrawl && (
          <div className="bb-wallet-modal__container">
            <div className="bb-wallet-modal__balance">
              <div>
                <p>current balance</p>
                <p>{`$${utils.getDotedNumber(
                  currentBalance.toFixed(2).toString(),
                )}`}</p>
              </div>
              <div>
                <Button onClick={() => setShowDeposit(true)}>Deposit</Button>
                <Button
                  onClick={() => setShowWithdrawl(true)}
                  styleType="secondary"
                >
                  Withdraw
                </Button>
              </div>
            </div>
            <InfiniteScroll
              className="bb-wallet-modal__transactions"
              onScroll={onTransactionScroll}
            >
              {mappedTransactions.length === 0 && (
                <EmptyPlaceholder
                  title="There aren't any transactions made!"
                  description="Transactions will be listed here after you create your first bet or make a deposit."
                />
              )}
              {mappedTransactions.map((transaction) => {
                return (
                  <TransactionItem
                    key={transaction.Id}
                    date={transaction.date}
                    createdAt={transaction.createdAt}
                    transactionValue={transaction.transactionValue}
                    transactionName={transaction.transactionName}
                    cryptoTransaction={transaction.paymentTransaction}
                    stripeTransaction={transaction.stripePaymentTransaction}
                    onClick={() =>
                      onTransactionViewStatus(
                        transaction.stripePaymentTransaction,
                      )
                    }
                  />
                );
              })}
              {loadingTransaction && <Loader />}
            </InfiniteScroll>
          </div>
        )}

        {showDeposit && (
          <DepositForm
            initialValues={depoisitInitialValues}
            onSubmit={onDepositSubmit}
          >
            {({ form }) => {
              formRef.current = form;
              return (
                <StepsProvider ref={stepsProviderRef} steps={walletSteps}>
                  {loading && <Loader />}
                  <StepProgress
                    totalSteps={steps?.length}
                    value={currentStep}
                  />
                  {animatedStep}
                </StepsProvider>
              );
            }}
          </DepositForm>
        )}

        {showWithdrawl && <Withdraw />}
      </Modal>
    </Animation>
  );
};

export default WalletModal;
