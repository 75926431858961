import {
  BETTING_TIPS,
  OutcomeData,
  OutcomeSubType,
  OutcomeType,
} from 'models/Outcome';
import { ObjectValues } from 'types';

const BASEBALL_SUBOUTCOME_NAMES = {
  RUNS: 'runs',
  GOALS: 'goals',
} as const;
export type BaseballSubOutcomeNames = ObjectValues<
  typeof BASEBALL_SUBOUTCOME_NAMES
>;

const SOCCER_SUBOUTCOME_NAMES = {
  GOALS: 'goals',
  FOULS: 'fouls',
  CORNERS: 'corners',
  OFFSIEDS: 'offsides',
} as const;
export type SoccerSubOutcomeNames = ObjectValues<
  typeof SOCCER_SUBOUTCOME_NAMES
>;

const ICEHOCKEY_SUBOUTCOME_NAMES = {
  SCORES: 'scores',
} as const;

export type IcehockeySubOutcomeNames = ObjectValues<
  typeof ICEHOCKEY_SUBOUTCOME_NAMES
>;

const BASKETBALL_SUBOUTCOMES_NAMES = {
  POINTS: 'points',
  FREE_THROWS: 'free throws',
  TWO_POINTS: 'two points',
  THREE_POINTS: 'three points',
  OFFENSIVE_REBOUNDS: 'offensive rebounds',
  DEFENSIVE_REBOUNDS: 'defensive rebounds',
  TOTAL_REBOUNDS: 'rebounds',
  ASSISTS: 'assists',
  BLOCKS: 'blocks',
  STEALS: 'steals',
  TURNOVERS: 'turnovers',
} as const;

export type BasketballSubOutcomeNames = ObjectValues<
  typeof BASKETBALL_SUBOUTCOMES_NAMES
>;

type SubOutcomeNames =
  | BaseballSubOutcomeNames
  | SoccerSubOutcomeNames
  | IcehockeySubOutcomeNames
  | BasketballSubOutcomeNames;

const generateWinnerMessage = (
  data: OutcomeData,
  teamName: string,
  outcomeSubtype: SubOutcomeNames,
) =>
  data.handicap === 0
    ? `${teamName} must win the game`
    : `For this bet to cash ${teamName} must win ${
        data.handicap > 0 ? 'or lose' : ''
      } by ${
        data.handicap > 0
          ? Math.floor(data.handicap as number)
          : Math.round(Math.abs(Math.floor(data.handicap as number)))
      } or ${data.handicap > 0 ? 'less' : 'more'} ${outcomeSubtype}`;

const generateTeamTotalMessage = (
  data: OutcomeData,
  teamName: string,
  outcomeSubtype: SubOutcomeNames,
) =>
  `For this bet to cash there must be ${
    data.bettingTip === BETTING_TIPS.OVER ? 'more' : 'less'
  } than ${data.limit} ${outcomeSubtype} scored by ${teamName}`;

const generateTotalMessage = (
  data: OutcomeData,
  outcomeSubtype: SubOutcomeNames,
) =>
  `For this bet to cash there must be  ${
    data.bettingTip === BETTING_TIPS.OVER ? 'more' : 'less'
  } than ${data.limit} ${outcomeSubtype} scored by both teams combined`;

const BET_OUTCOME_MESSAGES = {
  //baseall bet messages
  RUNS: {
    TOTAL_RUNS: (data) =>
      generateTotalMessage(data, BASEBALL_SUBOUTCOME_NAMES.RUNS),
    TEAM_TOTAL_RUNS: (data, teamName) =>
      generateTeamTotalMessage(data, teamName, BASEBALL_SUBOUTCOME_NAMES.RUNS),
    RUNS_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, BASEBALL_SUBOUTCOME_NAMES.RUNS),
  },
  //soccer bet messages
  GOALS: {
    TOTAL_GOALS: (data) =>
      generateTotalMessage(data, SOCCER_SUBOUTCOME_NAMES.GOALS),
    TEAM_TOTAL_GOALS: (data, teamName) =>
      generateTeamTotalMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.GOALS),
    GOALS_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.GOALS),
  },
  FOULS: {
    TOTAL_FOULS: (data) =>
      generateTotalMessage(data, SOCCER_SUBOUTCOME_NAMES.FOULS),
    TEAM_TOTAL_FOULS: (data, teamName) =>
      generateTeamTotalMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.FOULS),
    FOULS_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.FOULS),
  },
  CORNERS: {
    TOTAL_CORNERS: (data) =>
      generateTotalMessage(data, SOCCER_SUBOUTCOME_NAMES.CORNERS),
    TEAM_TOTAL_CORNERS: (data, teamName) =>
      generateTeamTotalMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.CORNERS),
    CORNERS_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.CORNERS),
  },
  OFFSIDES: {
    TOTAL_OFFSIDES: (data) =>
      generateTotalMessage(data, SOCCER_SUBOUTCOME_NAMES.OFFSIEDS),
    TEAM_TOTAL_OFFSIDES: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        SOCCER_SUBOUTCOME_NAMES.OFFSIEDS,
      ),
    OFFSIDES_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, SOCCER_SUBOUTCOME_NAMES.OFFSIEDS),
  },
  //icehockey bet messages
  SCORES: {
    TOTAL_SCORES: (data) =>
      generateTotalMessage(data, ICEHOCKEY_SUBOUTCOME_NAMES.SCORES),
    TEAM_TOTAL_SCORES: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        ICEHOCKEY_SUBOUTCOME_NAMES.SCORES,
      ),
    SCORES_WINNER: (data, teamName) =>
      generateWinnerMessage(data, teamName, ICEHOCKEY_SUBOUTCOME_NAMES.SCORES),
  },
  //basketball bet messages
  POINTS: {
    TOTAL_POINTS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.POINTS),
    TEAM_TOTAL_POINTS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.POINTS,
      ),
    POINTS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.POINTS,
      ),
  },
  FREE_THROWS_MADE: {
    TOTAL_FREE_THROWS_MADE: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.FREE_THROWS),
    TEAM_TOTAL_FREE_THROWS_MADE: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.FREE_THROWS,
      ),
    FREE_THROWS_MADE_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.FREE_THROWS,
      ),
  },
  TWO_POINTS_MADE: {
    TOTAL_TWO_POINTS_MADE: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.TWO_POINTS),
    TEAM_TOTAL_TWO_POINTS_MADE: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TWO_POINTS,
      ),
    TWO_POINTS_MADE_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TWO_POINTS,
      ),
  },
  THREE_POINTS_MADE: {
    TOTAL_THREE_POINTS_MADE: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.THREE_POINTS),
    TEAM_TOTAL_THREE_POINTS_MADE: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.THREE_POINTS,
      ),
    THREE_POINTS_MADE_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.THREE_POINTS,
      ),
  },
  OFFENSIVE_REBOUNDS: {
    TOTAL_OFFENSIVE_REBOUNDS: (data) =>
      generateTotalMessage(
        data,
        BASKETBALL_SUBOUTCOMES_NAMES.OFFENSIVE_REBOUNDS,
      ),
    TEAM_TOTAL_OFFENSIVE_REBOUNDS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.OFFENSIVE_REBOUNDS,
      ),
    OFFENSIVE_REBOUNDS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.OFFENSIVE_REBOUNDS,
      ),
  },
  DEFENSIVE_REBOUNDS: {
    TOTAL_DEFENSIVE_REBOUNDS: (data) =>
      generateTotalMessage(
        data,
        BASKETBALL_SUBOUTCOMES_NAMES.DEFENSIVE_REBOUNDS,
      ),
    TEAM_TOTAL_DEFENSIVE_REBOUNDS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.DEFENSIVE_REBOUNDS,
      ),
    DEFENSIVE_REBOUNDS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.DEFENSIVE_REBOUNDS,
      ),
  },
  TOTAL_REBOUNDS: {
    TOTAL_TOTAL_REBOUNDS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.TOTAL_REBOUNDS),
    TEAM_TOTAL_TOTAL_REBOUNDS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TOTAL_REBOUNDS,
      ),
    TOTAL_REBOUNDS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TOTAL_REBOUNDS,
      ),
  },
  ASSISTS: {
    TOTAL_ASSISTS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.ASSISTS),
    TEAM_TOTAL_ASSISTS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.ASSISTS,
      ),
    ASSISTS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.ASSISTS,
      ),
  },
  BLOCKS: {
    TOTAL_BLOCKS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.BLOCKS),
    TEAM_TOTAL_BLOCKS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.BLOCKS,
      ),
    BLOCKS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.BLOCKS,
      ),
  },
  STEALS: {
    TOTAL_STEALS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.STEALS),
    TEAM_TOTAL_STEALS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.STEALS,
      ),
    STEALS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.STEALS,
      ),
  },
  TURNOVERS: {
    TOTAL_TURNOVERS: (data) =>
      generateTotalMessage(data, BASKETBALL_SUBOUTCOMES_NAMES.TURNOVERS),
    TEAM_TOTAL_TRUNOVERS: (data, teamName) =>
      generateTeamTotalMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TURNOVERS,
      ),
    TURNOVERS_WINNER: (data, teamName) =>
      generateWinnerMessage(
        data,
        teamName,
        BASKETBALL_SUBOUTCOMES_NAMES.TURNOVERS,
      ),
  },
} as Record<
  Partial<OutcomeType>,
  Record<
    Partial<OutcomeSubType>,
    (data: OutcomeData, teamName?: string) => string
  >
>;

export default function generateBetMessage(
  data: OutcomeData,
  teamName?: string,
): string {
  return (
    BET_OUTCOME_MESSAGES?.[data.type]?.[data.subtype](data, teamName) || ''
  );
}
