import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FieldRenderProps } from 'react-final-form';
import { ExtractPropTypes } from 'types';
import Calendar from 'components/Calendar';

import './CalendarField.styles.scss';

type CalendarProps = ExtractPropTypes<typeof Calendar>;
type CalendarFieldProps = CalendarProps &
  FieldRenderProps<any, HTMLElement> & {
    className?: string;
    label?: string;
  };

const CalendarField: React.FC<CalendarFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    label,
    className,
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);
  const classes = classNames('bb-calendar-field', className);

  return (
    <div className={classes}>
      {label && (
        <>
          <p className="bb-calendar-field__label">{label}</p> <hr />
        </>
      )}
      <Calendar {...rest} {...input} />
      {hasError && <small className="bb-calendar-field__error">{error}</small>}
    </div>
  );
};

export default CalendarField;
