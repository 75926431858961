import api from 'api';
import bus, { AuthEventTypes } from 'modules/bus';
import credentialsService from './credentialsService';
import storageService, { STORAGE_KEYS } from './storageService';

export type RefreshTokenType = {
  refreshingAtm: boolean;
  checkRefreshingTreshold: number;
  refreshToken: Function;
  reinit: Function;
};

export default {
  refreshingAtm: false,
  checkRefreshingTreshold: 10000,
  async refreshToken() {
    this.refreshingAtm = true;

    const {
      data: { token, refreshToken },
    } = await api.auth.sendRefreshToken(credentialsService.refreshToken);

    storageService.setItem(STORAGE_KEYS.TOKEN, token);
    storageService.setItem(STORAGE_KEYS.REFRESH_TOKEN, refreshToken);

    bus.broadcastEvent(AuthEventTypes.REFRESH_TOKEN_SUCCESS);

    this.refreshingAtm = false;
  },
  reinit() {
    this.refreshingAtm = false;
  },
} as RefreshTokenType;
