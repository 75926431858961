import RadioSliderField from 'components/Fields/RadioSliderField';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { FilterBetData, FilterBetTypes, SortBetTypes } from 'models/Bet';
import React, { useCallback, useContext, useRef } from 'react';
import { Field, Form } from 'react-final-form';
import { sportsRadioSlides } from '../../constants';
import { filterSlides, sortSlides } from './filterSlides';
import { Button } from 'ncoded-component-library';
import { OverlayRef } from 'components/Overlay';
import Animation from 'components/Animation';
import { EASE_IN_OUT_CUBIC } from 'routers/MainRouter/services/constants';
import MainContext from '../../providers/Main/Main.context';
import { defaultFilterBetsData } from '../../providers/Main/Main.provider';

import './BetFilterModal.styles.scss';

type BetFilterModalProps = {
  betData: FilterBetData;
  onSubmit: (data: FilterBetData) => void;
} & ModalProps;

const BetFilterModal: React.FC<BetFilterModalProps> = (props) => {
  const { betData, open, onSubmit, ...modalRest } = props;

  const betModalRef = useRef<OverlayRef>(null);

  const { filter, sport, sort } = betData;
  const { setFilterBetsData } = useContext(MainContext);

  const onFormSubmit = useCallback(
    (values: FilterBetData) => {
      if (onSubmit) onSubmit(values);
      modalRest.onClose();
    },
    [onSubmit, modalRest],
  );

  return (
    <Animation
      open={open}
      duration={600}
      appearAnimation="moveUp"
      disappearAnimation="moveDown"
      timingFunction={EASE_IN_OUT_CUBIC}
    >
      <Modal
        className="bb-bet-filter-modal"
        {...modalRest}
        open
        onX={modalRest.onClose}
        ref={betModalRef}
        title={<p>filters</p>}
      >
        <Form
          onSubmit={onFormSubmit}
          initialValues={{
            sport,
            filter,
            sort,
          }}
          render={({ handleSubmit, form }) => (
            <form onSubmit={handleSubmit}>
              <div className="bb-bet-filter-modal__container">
                <p>sport</p>
                <Field
                  component={RadioSliderField}
                  slideSteps={sportsRadioSlides}
                  name="sport"
                  inputName="sport"
                  value={sport}
                  showValueOfRadioSlide
                />
                {/* <p>show</p>
                <Field<ShowBetTypes>
                  component={RadioSliderField}
                  slideSteps={showSlides}
                  name="show"
                  inputName="show"
                  value={show}
                /> */}
                <p>filter</p>
                <Field<FilterBetTypes>
                  component={RadioSliderField}
                  slideSteps={filterSlides}
                  name="filter"
                  inputName="filter"
                  value={filter}
                />
                <p>sort</p>
                <Field<SortBetTypes>
                  component={RadioSliderField}
                  slideSteps={sortSlides}
                  name="sort"
                  inputName="sort"
                  value={sort}
                />
              </div>
              <div className="bb-bet-filter-modal__footer">
                <Button
                  styleType="secondary"
                  onClick={() => {
                    form.reset();
                    setFilterBetsData(defaultFilterBetsData);
                    modalRest.onClose();
                  }}
                >
                  Clear Filter
                </Button>
                <Button styleType="primary" type="submit">
                  Apply Filter
                </Button>
              </div>
            </form>
          )}
        />
      </Modal>
    </Animation>
  );
};

export default BetFilterModal;
