import React, { useState } from 'react';
import LoginModal from './components/LoginModal';
import TLogo from '../../assets/svgs/TLogo';
import TopcheezText from '../../assets/svgs/TopcheezText';
import CreateAcountModal from './components/CreateAcountModal';
import { Button } from 'ncoded-component-library';
import AppearAnimation from 'components/AppearAnimation';
import useReplayAnimation from 'hooks/useReplayAnimation';
import ResetPasswordModal from './components/ResetPasswordModal';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import useQueryParams from 'hooks/useQueryParams';

import './Auth.styles.scss';

export const AUTH_PARAMS = {
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'reset-password',
} as const;

type AuthParams = typeof AUTH_PARAMS[keyof typeof AUTH_PARAMS];

const Login: React.FC = () => {
  const [loginModal, setLoginModal] = useState<boolean>(false);
  const [createAccountModal, setCreateAccountModal] = useState<boolean>(false);

  const replayAnimation = useReplayAnimation('bb-auth__content');
  const {
    params: { source },
    setQueryParam,
    removeQueryParam,
  } = useQueryParams<{ source: AuthParams; token: string }>();

  return (
    <div className="bb-auth">
      <AppearAnimation animationName="moveIn" duration={800}>
        <div className="bb-auth__content">
          <img src="/assets/images/background.png" alt="auth" />
          <div className="bb-auth__content__bg">
            <AppearAnimation animationName="moveIn" duration={800} delay={100}>
              <div className="bb-auth__content__bg__logo">
                <TLogo className="sign-logo" />
              </div>
            </AppearAnimation>

            <AppearAnimation animationName="moveIn" duration={800} delay={200}>
              <div className="bb-auth__content__bg__logo">
                <TopcheezText className="text-logo" />
              </div>
            </AppearAnimation>

            <AppearAnimation animationName="moveIn" duration={800} delay={300}>
              <p className="text-message">just bet on it!</p>
            </AppearAnimation>
          </div>
          <AppearAnimation animationName="moveIn" duration={800} delay={300}>
            <div className="bb-auth__content__actions">
              <AppearAnimation
                animationName="moveIn"
                duration={800}
                delay={300}
              >
                <Button
                  styleType="primary"
                  animateOnClick
                  ripple
                  onClick={() => setCreateAccountModal(true)}
                >
                  Create Account
                </Button>
              </AppearAnimation>
              <AppearAnimation
                animationName="moveIn"
                duration={800}
                delay={500}
              >
                <Button
                  styleType="secondary"
                  animateOnClick
                  ripple
                  onClick={() => setLoginModal(true)}
                >
                  Log In
                </Button>
              </AppearAnimation>
            </div>
          </AppearAnimation>
        </div>
      </AppearAnimation>

      <LoginModal
        open={loginModal}
        onClose={() => {
          setLoginModal(false);
          replayAnimation();
        }}
        onForgotPassword={() => {
          setLoginModal(false);
          setQueryParam('source', AUTH_PARAMS.FORGOT_PASSWORD);
        }}
      />

      <CreateAcountModal
        open={createAccountModal}
        onClose={() => {
          setCreateAccountModal(false);
          replayAnimation();
        }}
      />

      <ForgotPasswordModal
        open={source === AUTH_PARAMS.FORGOT_PASSWORD}
        onClose={() => {
          removeQueryParam('source');
          replayAnimation();
        }}
      />

      <ResetPasswordModal
        open={source === AUTH_PARAMS.RESET_PASSWORD}
        onClose={() => {
          removeQueryParam('source');
          removeQueryParam('token');
          replayAnimation();
        }}
      />
    </div>
  );
};

export default Login;
