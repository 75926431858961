export type SportType =
  | 'BASKETBALL'
  | 'AMERICAN_FOOTBALL'
  | 'RUGBY'
  | 'SOCCER'
  | 'BASEBALL'
  | 'TENNIS'
  | 'VOLLEYBALL'
  | 'BOXING'
  | 'PINGPONG'
  | 'ICE_HOCKEY';

export type Sport = {
  sport?: SportType;
  sportIcon?: React.ReactNode;
  value?: string;
};

export const LEAGUES = {
  NBA: 'NBA',
  NHL: 'NHL',
  NFL: 'NFL',
  MLB: 'MLB',
  NCAA: 'NCAA',
  PREMIER_LEAGUE: 'Premier League',
  CHAMPIONS_LEAGUE: 'Champions League',
} as const;

export type LeagueType =
  | 'NBA'
  | 'NCAA'
  | 'NFL'
  | 'MLB'
  | 'NHL'
  | 'PREMIER_LEAGUE'
  | 'CHAMPIONS_LEAGUE';
