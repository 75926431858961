import React from 'react';
import StadiumProvider from './providers/Stadium';
import Stadium from './Stadium.page';

const wrapper = () => (
  <StadiumProvider>
    <Stadium />
  </StadiumProvider>
);

export default wrapper;
