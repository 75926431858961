export type SoccerOutcome = 'GOALS' | 'FOULS' | 'CORNERS' | 'OFFSIDES';

//Goal outcome
export type SoccerGoalsOutcome =
  | 'GOALS_WINNER'
  | 'TOTAL_GOALS'
  | 'TEAM_TOTAL_GOALS';

//Foul outcome
export type SoccerFoulOutcome =
  | 'FOULS_WINNER'
  | 'TOTAL_FOULS'
  | 'TEAM_TOTAL_FOULS';

//Corners outcome
export type SoccerCornersOutcome =
  | 'CORNERS_WINNER'
  | 'TOTAL_CORNERS'
  | 'TEAM_TOTAL_CORNERS';

//Offsides outcome
export type SoccerOffsidesOutcome =
  | 'OFFSIDES_WINNER'
  | 'TOTAL_OFFSIDES'
  | 'TEAM_TOTAL_OFFSIDES';

//PERIODS
export type SoccerPeriodTypes = 'MATCH';

export type SoccerOutcomeSubType =
  | SoccerGoalsOutcome
  | SoccerFoulOutcome
  | SoccerCornersOutcome
  | SoccerOffsidesOutcome;

export const SOCCER_OUTCOMES = {
  GOALS: 'Goals',
  FOULS: 'Fouls',
  CORNERS: 'Corners',
  OFFSIDES: 'Offsides',
} as const;

export const SOCCER_SUBOUTCOMES = {
  GOALS_WINNER: 'Match Winner',
  TOTAL_GOALS: 'Total Goals',
  TEAM_TOTAL_GOALS: 'Team Total Goals',
  FOULS_WINNER: 'Fouls Winner',
  TOTAL_FOULS: 'Total Fouls',
  TEAM_TOTAL_FOULS: 'Team Total Fouls',
  CORNERS_WINNER: 'Corners Winner',
  TOTAL_CORNERS: 'Total Corners',
  TEAM_TOTAL_CORNERS: 'Team Total Corners',
  OFFSIDES_WINNER: 'Offsides Winner',
  TOTAL_OFFSIDES: 'Total Offsides',
  TEAM_TOTAL_OFFSIDES: 'Team Total Offsides',
} as const;

export const SOCCER_PERIODS = {
  MATCH: 'Match',
} as const;
