import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { Button, Loader } from 'ncoded-component-library';

import api from 'api';
import { BetData, FilterFeedBetData } from 'models/Bet';
import useInfinitePagination from 'hooks/useInfinitePagination';
import {
  defaultFilterFeedData,
  feedRadioSlides,
} from './components/FeedFilterModal/filterSlides';
import AppearAnimation from 'components/AppearAnimation';
import InfiniteScroll from 'components/InfiniteScroll';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import RadioSlider from 'components/RadioSlider';
import GlowScroll from 'components/GlowScroll';
import FeedCard from './components/FeedCard';
import FeedFilterModal from './components/FeedFilterModal';
import Slider from 'assets/svgs/Slider';

import './Feed.styles.scss';

type FeedProps = {
  className?: string;
};

const Feed: React.FC<FeedProps> = ({ className }) => {
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false);

  const [filterFeedData, setFilterFeedData] = useState<FilterFeedBetData>(
    defaultFilterFeedData,
  );

  const radioSliderOnChange = useCallback(
    (value: string) =>
      setFilterFeedData(
        (old) => ({ ...old, filter: value } as FilterFeedBetData),
      ),
    [],
  );

  const filterModalOnSubmit = useCallback(
    (value: FilterFeedBetData) =>
      setFilterFeedData((old) => ({ filter: old.filter, ...value })),
    [setFilterFeedData],
  );

  const filterModalOnClose = useCallback(
    () => setIsFilterOpen(false),
    [setIsFilterOpen],
  );

  const filterModalOnClear = useCallback(
    () => setFilterFeedData(defaultFilterFeedData),
    [setFilterFeedData],
  );

  const {
    loading: loadingFeedBets,
    items: feedBets,
    onContainerScrolled: onFeedBetsScroll,
  } = useInfinitePagination<BetData>({
    makeRequest: async (currentPage: number) => {
      return await api.bets
        .getFeedBets({
          limit: 10,
          page: currentPage,
          show: filterFeedData.show,
          filter: filterFeedData.filter,
          betsFilterType: filterFeedData.betsFilterType,
          sport: filterFeedData.sport === 'all' ? null : filterFeedData.sport,
        })
        .then(({ data }) => data);
    },
    dependencies: [filterFeedData],
    resetDeps: [filterFeedData],
  });

  return (
    <>
      <FeedFilterModal
        open={isFilterOpen}
        betData={filterFeedData}
        onClose={filterModalOnClose}
        onSubmit={filterModalOnSubmit}
        onClear={filterModalOnClear}
      />

      <GlowScroll className={classNames('bb-feed', className)}>
        <AppearAnimation duration={150} animationName="appear">
          <div className="bb-feed__container">
            <div className="bb-feed__header">
              <RadioSlider
                slideSteps={feedRadioSlides}
                showValueOfRadioSlide
                name="feedbets"
                value={filterFeedData.filter}
                onChange={radioSliderOnChange}
              />

              <Button
                styleType="secondary"
                icon={Slider}
                onClick={() => setIsFilterOpen(true)}
              />
            </div>
            <div className="bb-feed__content">
              {loadingFeedBets && <Loader />}

              <InfiniteScroll onScroll={onFeedBetsScroll}>
                {feedBets?.length === 0 ? (
                  <EmptyPlaceholder title="There aren't any bets for current filters" />
                ) : (
                  feedBets?.map((bet: BetData) => (
                    <FeedCard key={bet.Id} betData={bet} />
                  ))
                )}
              </InfiniteScroll>
            </div>
          </div>
        </AppearAnimation>
      </GlowScroll>
    </>
  );
};

export default Feed;
