import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import { Button, ImageSlider } from 'ncoded-component-library';
import { OverlayRef } from 'components/Overlay';
import Animation from 'components/Animation';

import api from 'api';
import utils from 'utils';
import {
  moveDownWithBackground,
  moveUpWithBackground,
} from 'models/Animations';
import useCallbackRef from 'hooks/useCallbackRef';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import {
  ImageSlideType,
  ImageSliderRef,
} from 'ncoded-component-library/build/components/organisms/ImageSlider/ImageSlider.component';
import OnboardingSlide from './components/OnboardingSlide';
import SocialShare from 'components/SocialShare';
import useEffectOnceWhen from 'hooks/useEffectOnceWhen';
import env from 'env';

import './WelcomeWizard.styles.scss';

const WelcomeWizard: React.FC<ModalProps> = (props) => {
  const { open, onClose, ...modalRest } = props;
  const {
    userData: { username, isOnboarded: userOnboarded },
    setUserData,
  } = useContext(MainContext);

  const [shareUrl, setShareUrl] = useState<string>('');

  const welcomeWizardModalRef = useRef<OverlayRef>(null);
  const [imageSlider, imageSliderRef] = useCallbackRef<ImageSliderRef>(null);

  const tutorialSlides: ImageSlideType[] = useMemo(
    () => [
      {
        imageUrl: '/assets/images/slide1.png',
        element: (
          <OnboardingSlide
            heading="Welcome to TopCheez"
            text={` Hi ${username}! Welcome to TopCheez. This is an exciting new way to
                bet against your friends, and other real people, with no house in
                between. This results in lower fees and a more fun experience.`}
          />
        ),
      },
      {
        imageUrl: '/assets/images/slide2.png',
        element: (
          <OnboardingSlide
            heading="Getting started"
            text={
              'To make a deposit, click the button on the top left, click deposit, choose your payment method. To get started, go tothe Stadium and select a game.'
            }
          />
        ),
      },
      {
        imageUrl: '/assets/images/slide3.png',
        element: (
          <OnboardingSlide
            heading="Selecting your bet"
            text={`You can choose from any number of options per game. If you want to keep things simple, click on the team icon and you can choose who you want to win. If you want to get fancier, click the ‘advanced button’.`}
          />
        ),
      },
      {
        imageUrl: '/assets/images/slide4.png',
        element: (
          <OnboardingSlide
            heading="Choose your odds"
            text={
              'Use the slider to select the odds you want. Odds of 2 mean that you think the outcome is equally likely for each team. Below this means the team that you are betting on is even more likely to win.'
            }
          />
        ),
      },
      {
        imageUrl: '/assets/images/slide5.png',
        element: (
          <OnboardingSlide
            heading="Publish your bet"
            text={
              'You can set your bet as public (recommended) or private and then publish it into the TopCheez ecosystem. We will hold the funds in escrow until the bet is finalized. If you win, you will see your wallet automatically update.'
            }
          />
        ),
      },
      {
        imageUrl: '/assets/images/slide6.png',
        element: (
          <OnboardingSlide
            heading="Thanks for playing"
            text={`Good luck! Remember to always bet within your limits. Invite your friends to join you and get $10 if they deposit more than $20.`}
          />
        ),
      },
    ],
    [username],
  );

  const isLastStep = useMemo(
    () => tutorialSlides.length - 1 === imageSlider?.activeImageIndex,
    [imageSlider, tutorialSlides],
  );

  const handleFinish = useCallback(async () => {
    try {
      if (!userOnboarded) {
        const {
          data: { isOnboarded },
        } = await api.user.finishOnboarding();
        setUserData((old) => ({ ...old, isOnboarded }));
      }
      onClose();
    } catch (e) {
      utils.toastError(e);
    }
  }, [onClose, setUserData, userOnboarded]);

  const handleNextButtonClick = useCallback(() => {
    if (!imageSlider) return;

    const activeIndex = imageSlider.activeImageIndex;

    if (activeIndex === 5) {
      handleFinish();
      return;
    }

    const nextIndex = (activeIndex + 1) % tutorialSlides.length;
    imageSlider.setActiveImageIndex(nextIndex);
  }, [imageSlider, tutorialSlides.length, handleFinish]);

  const getReferralCode = useCallback(async () => {
    const { data } = await api.referralCode.getReferralCode();
    setShareUrl(`${env.UI_ENDPOINT}/start?referral_code=${data.code}`);
  }, []);

  useEffectOnceWhen(() => {
    getReferralCode();
  }, isLastStep);

  return (
    <Animation
      open={open}
      duration={600}
      appearAnimations={moveUpWithBackground}
      disappearAnimations={moveDownWithBackground}
      onExited={onClose}
    >
      <Modal
        {...modalRest}
        open
        className="bb-welcome-wizard"
        title={<p>welcome</p>}
        ref={welcomeWizardModalRef}
        renderAsPortal={false}
        onX={onClose}
      >
        <ImageSlider
          slides={tutorialSlides}
          arrowControls={false}
          ref={imageSliderRef}
        />

        <div className="bb-welcome-wizard__footer">
          {isLastStep ? (
            <SocialShare
              className="bb-onboarding-slide__social-share"
              shareUrl={shareUrl}
            />
          ) : (
            <Button
              styleType="secondary"
              className="bb-welcome-wizard__skip-button"
              onClick={onClose}
            >
              Skip
            </Button>
          )}
          <Button styleType="primary" onClick={handleNextButtonClick}>
            {isLastStep ? 'Finish' : 'Next'}
          </Button>
        </div>
      </Modal>
    </Animation>
  );
};

export default WelcomeWizard;
