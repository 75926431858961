import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g>
              <g>
                <g>
                  <g>
                    <g fill="#FFF" fillOpacity="0">
                      <path
                        d="M0 0H24V24H0z"
                        transform="translate(-152 -330) translate(8 56) translate(16 124) translate(16 76) translate(0 68) translate(100) translate(12 6)"
                      />
                    </g>
                    <path
                      fill="currentColor"
                      fillRule="nonzero"
                      d="M19 12c0 .552-.448 1-1 1H6c-.552 0-1-.448-1-1s.448-1 1-1h12c.552 0 1 .448 1 1z"
                      transform="translate(-152 -330) translate(8 56) translate(16 124) translate(16 76) translate(0 68) translate(100) translate(12 6)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
