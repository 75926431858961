import { FixtureType } from 'models/Fixture';
import { SportType } from 'models/Sport';
import { createContext } from 'react';
import utils from 'utils';
import { FixtureFilterData } from './Stadium.provider';

type StadiumContextProps = {
  loadingFixtures: boolean;
  fixtures: Array<FixtureType>;
  fixturesSearchString: string;
  fixtureFilter: FixtureFilterData;
  selectedSport: SportType | 'all';
  selectedFixture: FixtureType;
  activeRadioIndex: number;
  setActiveSource: (value: number) => void;
  setFixtures: (fixtures: Array<FixtureType>) => void;
  setFixtureSearchString: (value: string) => void;
  setFixtureFilter: (value: FixtureFilterData) => void;
  setSelectedSport: (sport: SportType | 'all') => void;
  setSelectedFixture: (value: FixtureType) => void;
  onFixturesScroll: () => void;
};

export default createContext<StadiumContextProps>({
  loadingFixtures: false,
  fixtures: [],
  fixturesSearchString: '',
  fixtureFilter: null,
  selectedSport: 'all',
  selectedFixture: null,
  activeRadioIndex: 1,
  setFixtures: utils.noop,
  setFixtureSearchString: utils.noop,
  setFixtureFilter: utils.noop,
  setSelectedSport: utils.noop,
  setSelectedFixture: utils.noop,
  setActiveSource: utils.noop,
  onFixturesScroll: utils.noop,
});
