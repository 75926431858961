import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import env from 'env';

import 'firebase/messaging';
import React from 'react';

const firebaseConfig = {
  projectId: 'bet-bears',
  appId: env.FIREBASE_APP_ID,
  apiKey: env.FIREBASE_API_KEY,
  authDomain: env.FIREBASE_AUTH_DOMAIN,
  storageBucket: env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.FIREBASE_MESSAGING_SENDER_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export function requestNotificationPermission() {
  return new Promise((resolve) => {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') resolve(true);
      else if (permission === 'denied') resolve(false);
    });
  });
}

export async function generateToken(
  tokenFound: React.MutableRefObject<boolean>,
) {
  try {
    const token = await getToken(messaging, {
      vapidKey: env.FIRE_BASE_VAPID_KEY,
    });

    if (token) tokenFound.current = true;
    else tokenFound.current = false;

    if (await requestNotificationPermission()) return token;
    else return null;
  } catch (e) {
    console.error(e);
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log('Message received', payload);
      resolve(payload);
    });
  });
