import { Step } from 'components/FormWrapper/providers/Steps/Steps.context';
import DepositAmountStep from './depositsteps/DepositAmountStep';
import WalletCheckoutDetails from './depositsteps/WalletCheckoutDetails';

export const DEPOSIT_STEPS = {
  DEPOSIT_AMOUNT_STEP: 0,
  CHECKOUT_DETAILS: 1,
} as const;

export default [
  {
    component: DepositAmountStep,
    title: 'Choose Payment Method',
  },
  {
    component: WalletCheckoutDetails,
    title: 'Credit Card Details',
  },
] as Step[];
