import React, { useCallback, useMemo } from 'react';
import { Input } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { ExtractPropTypes } from 'types';
import classNames from 'classnames';
import utils from 'utils';
import './InputField.styles.scss';

type InputProps = ExtractPropTypes<typeof Input>;

type InputFieldProps = FieldRenderProps<any | string, HTMLElement> & {
  isNumeric?: boolean;
  showError?: boolean;
} & InputProps;

const getDottedValue = (value: string): string =>
  `$${utils.getDotedNumber(value.replace(/,.@|\D+/g, ''))}`;

const InputField: React.FC<InputFieldProps> = (props) => {
  const {
    input: { value: fValue, onChange, ...restInput },
    meta: { touched, error },
    label,
    className,
    isNumeric,
    showError = true,
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'bb-input-field',
    { 'bb-input-field__error': hasError },
    className,
  );

  const value: string = useMemo(
    () => (isNumeric ? getDottedValue(fValue) : fValue),
    [fValue, isNumeric],
  );

  const handleOnChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;

      if (!isNumeric) return onChange(value);
      onChange(getDottedValue(value).slice(1));
    },
    [isNumeric, onChange],
  );

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <Input
        {...rest}
        {...restInput}
        value={value}
        autoComplete="off"
        hasError={hasError}
        onChange={handleOnChange}
      />
      {showError && hasError && <small>{error}</small>}
    </div>
  );
};

export default InputField;
