import { Button, Loader } from 'ncoded-component-library';
import React from 'react';
import { ExtractPropTypes } from 'types';
import './LoadButton.styles.scss';

type ButtonProps = ExtractPropTypes<typeof Button>;

type LoadButtonProps = {
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
} & ButtonProps;

const LoadButton: React.FC<LoadButtonProps> = (props) => {
  const { children, className, loading, ...rest } = props;

  return (
    <Button className={className} {...rest}>
      {loading ? <Loader inline overlay={false} /> : children}
    </Button>
  );
};

export default LoadButton;
