import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { DropdownRef } from 'ncoded-component-library/build/components/molecules/Dropdown/Dropdown.component';
import { Button, Dropdown, Loader } from 'ncoded-component-library';
import classNames from 'classnames';
import api from 'api';

import utils, { mapOutcomesData } from 'utils';
import { LEAGUES } from 'models/Sport';
import { FIXTURE_STATUSES } from 'models/Fixture';
import { BETTING_TYPE_STATUS, UserBetData } from 'models/Bet';
import { useBetInformation } from 'hooks/useBetInformation';
import generateBetMessage from 'modules/message';
import confirm from 'modules/confirm';
import BetTeam from 'routers/MainRouter/pages/MainPage/components/NewBetModal/components/BetTeam';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';
import NewBetContext from 'routers/MainRouter/pages/MainPage/components/NewBetModal/providers/NewBet/NewBet.context';
import ShowOutcomes from 'components/ShowOutcomes';
import BetStatus from 'components/BetStatus';
import UserList from 'components/UserList';
import UserImage from 'components/UserImage';
import Checkbox from 'assets/svgs/Checkbox';
import InfoIcon from 'assets/svgs/Info.icon';
import EditIcon from 'assets/svgs/Edit';
import CloseIcon from 'assets/svgs/Close';
import Dots from 'assets/svgs/Dots';

import './UserBetCard.styles.scss';

type UserBetCardProps = {
  className?: string;
  active?: boolean;
  betData?: UserBetData;
  onShare?: () => void;
};

const UserBetCard: React.FC<UserBetCardProps> = (props) => {
  const {
    className,
    active,
    betData: {
      bet,
      betId,
      betOdd,
      bettingTipStatus,
      bettingTipAmount,
      fixtureStatus,
      winLoseAmount,
      createdAt,
    },
    onShare,
  } = props;

  const {
    user,
    betOutcomes,
    totalBetAmount,
    fixture,
    betPrivacy,
    betTips,
    bettingAmount,
    betType,
    userId: userBetId,
    sport,
  } = bet ?? {};

  const { firstTeamScore, secondTeamScore } = fixture.fixtureResults ?? {};

  const { isMyBet, outcomeData, bettingTeamName, generateOppositeOutcome } =
    useBetInformation(bet);

  const {
    firstTeam,
    secondTeam,
    league,
    startDate: fixtureStartData,
  } = fixture ?? {};

  const classes = classNames(
    'bb-user-bet',
    { 'bb-user-bet--active': active },
    className,
  );

  const privateUserList = useMemo(
    () =>
      betPrivacy === 'Private'
        ? betTips.filter((betTip) => betTip.bettingType !== 'Created')
        : null,
    [betPrivacy, betTips],
  );

  const publicUserList = useMemo(
    () =>
      betPrivacy === 'Public'
        ? betTips
            .filter((bet) => bet.bettingType !== 'Created')
            .map((bet) => bet.user)
        : null,
    [betPrivacy, betTips],
  );

  const winLoseClasses = classNames(
    'bb-user-bet__footer__content__card',
    { 'bb-user-bet__footer__content__card--win': bettingTipStatus === 'Win' },
    { 'bb-user-bet__footer__content__card--lose': bettingTipStatus === 'Lose' },
    {
      'bb-user-bet__footer__content__card--refund':
        bettingTipStatus === 'Refund',
    },
  );

  const infoClasses = classNames(
    'bb-user-bet__footer__content__card',
    'bb-user-bet__footer__content__card--info',
  );

  const isFirstTeamWinner = useMemo(
    () => firstTeamScore > secondTeamScore,
    [firstTeamScore, secondTeamScore],
  );

  const isSecondTeamWinner = useMemo(
    () => firstTeamScore < secondTeamScore,
    [firstTeamScore, secondTeamScore],
  );

  const winLoseMessage = useMemo(
    () =>
      bettingTipStatus === 'Win'
        ? 'You Won'
        : bettingTipStatus === 'Lose'
        ? 'You Lost'
        : bettingTipStatus === 'Refund'
        ? 'Refund'
        : null,
    [bettingTipStatus],
  );

  const bettingTicketInfo = useMemo(
    () =>
      fixtureStatus === FIXTURE_STATUSES.FINISHED ? (
        `${firstTeamScore} : ${secondTeamScore}`
      ) : fixtureStatus === FIXTURE_STATUSES.NOT_STARTED_YET ? (
        <>
          <span>{`${utils.toDateFormat(fixtureStartData.toString())}`}</span>
          <span>{`${utils.toHourMinFormat(fixtureStartData.toString())}`}</span>
        </>
      ) : fixtureStatus === FIXTURE_STATUSES.IN_PROGRESS ? (
        `Game In Progress`
      ) : null,
    [firstTeamScore, fixtureStartData, fixtureStatus, secondTeamScore],
  );

  const isFixtureFinished = useMemo(
    () => fixtureStatus === FIXTURE_STATUSES.FINISHED,
    [fixtureStatus],
  );

  const dropdownRef = useRef<DropdownRef>(null);
  const { setCurrentBalance, setUserBets, userBets } = useContext(MainContext);

  const cancelBet = useCallback(
    async (betId: string) => {
      if (
        await confirm.simpleConfirmation({
          title: `Cancel a bet`,
          description: `Are you sure you want to cancel a bet?`,
        })
      ) {
        try {
          await api.bets.cancelBet(betId);
          setUserBets(userBets.filter((el) => el.betId !== betId));
          setCurrentBalance((oldBalance) => oldBalance + bettingAmount);
          utils.toastSuccess('Successfully canceled bet!');
        } catch (e) {
          utils.toastError(e);
        }
      }
    },
    [bettingAmount, setCurrentBalance, setUserBets, userBets],
  );

  const { setNewBetModal } = useContext(MainContext);
  const { setInitialFixtureData, editingBetId, isBetEditing } =
    useContext(NewBetContext);

  const [loading, setLoading] = useState<boolean>(false);

  const loadFixtureOutcomes = useCallback(async () => {
    try {
      setLoading(true);

      const {
        data: { outcomes: outcomesData },
      } = await api.fixture.getFixtureOutcomes({
        fixtureId: fixture.Id,
      });
      return outcomesData;
    } catch (e) {
      utils.toastError(e);
      setNewBetModal(false);
    } finally {
      setLoading(false);
    }
  }, [fixture.Id, setNewBetModal]);

  const handleEditBet = useCallback(async () => {
    isBetEditing.current = true;
    editingBetId.current = betId;

    const outcomeData = await loadFixtureOutcomes();
    setNewBetModal(true);

    const refinedOutcomeData = mapOutcomesData(
      outcomeData,
      betOutcomes[0].outcomeData,
    );

    setInitialFixtureData((oldData) => {
      return {
        ...oldData,
        sportCategory: sport,
        selectedGame: fixture,
        outcomes: refinedOutcomeData,
        betAmount: bettingTipAmount,
        betOdd: betOdd,
        publishSettings: betPrivacy,
        betType: betType,
      };
    });
  }, [
    setNewBetModal,
    isBetEditing,
    editingBetId,
    betId,
    loadFixtureOutcomes,
    betOutcomes,
    setInitialFixtureData,
    sport,
    fixture,
    bettingTipAmount,
    betOdd,
    betPrivacy,
    betType,
  ]);

  return (
    <div className={classes}>
      {loading && <Loader />}
      <div className="bb-user-bet__header">
        <UserImage
          userId={userBetId}
          src={user.profileImageUrl}
          alt={user.username}
        />

        <div>
          <p>{`${user?.username}`}</p>
          <p>{`${utils.toHourMinFormat(
            createdAt.toString(),
          )} ${utils.toDateFormat(createdAt.toString())}`}</p>
        </div>

        {bettingTipStatus === BETTING_TYPE_STATUS['In progress'] ? (
          bet.playersCount === 1 && (
            <Dropdown
              ref={dropdownRef}
              className="bb-user-bet__header__dropdown"
              renderAsPortal
              trigger={
                <Button
                  className="dots-button"
                  styleType="secondary"
                  icon={Dots}
                />
              }
            >
              <Button
                styleType="secondary"
                icon={EditIcon}
                iconPosition="left"
                onClick={() => {
                  window.isNewBetModalClose = false;
                  handleEditBet();
                  dropdownRef.current.setIsOpen(false);
                }}
              >
                Edit bet
              </Button>
              <Button
                styleType="secondary"
                icon={CloseIcon}
                iconPosition="left"
                onClick={() => {
                  cancelBet(betId);
                  dropdownRef.current.setIsOpen(false);
                }}
              >
                Cancel bet
              </Button>
            </Dropdown>
          )
        ) : (
          <BetStatus status={bettingTipStatus} />
        )}
      </div>

      <div className="bb-user-bet__content">
        <div className="bb-user-bet__teams">
          <BetTeam
            {...firstTeam}
            active={isFixtureFinished && isFirstTeamWinner}
            hasBookmark
            key="first-team-score"
          />

          <div className="bb-user-bet__teams__container">
            <p className="bb-user-bet__teams--info">{bettingTicketInfo}</p>
            <p className="bb-user-bet__teams--vs">VS</p>
            <p className="bb-user-bet__teams--league">{LEAGUES[league]}</p>
          </div>

          <BetTeam
            {...secondTeam}
            active={isFixtureFinished && isSecondTeamWinner}
            hasBookmark
            key="second-team-score"
          />
        </div>

        <p>OUTCOME</p>

        <ShowOutcomes
          fixture={fixture}
          betOutcomes={betOutcomes.map((outcome) =>
            !isMyBet
              ? generateOppositeOutcome(outcome.outcomeData)
              : outcome.outcomeData,
          )}
        />

        <p className="bb-user-bet__bet-message">
          <InfoIcon />
          {generateBetMessage(outcomeData, bettingTeamName)}
        </p>

        {publicUserList?.length > 0 && (
          <UserList
            type="users"
            users={publicUserList}
            info={`${publicUserList.length} players`}
          />
        )}

        {privateUserList?.length > 0 &&
          privateUserList.map((userBet) => (
            <div className="bb-user-bet__content__private" key={userBet.Id}>
              <UserImage
                userId={userBet.user.Id}
                src={userBet.user.profileImageUrl}
                active={userBet.user.isOnline}
              />
              <div className="bb-user-bet__content__accepted">
                <Checkbox />
                <span>{`${
                  userBet.bettingType
                } with $${userBet.bettingTipAmount.toFixed(2)}`}</span>
              </div>
            </div>
          ))}
      </div>

      <div className="bb-user-bet__footer">
        <div className="bb-user-bet__footer__content">
          <div className={infoClasses}>
            <p>{`$${totalBetAmount.toFixed(2)}`}</p>
            <p>total</p>
          </div>
          <div className={infoClasses}>
            <p>{`$${bettingTipAmount.toFixed(2)}`}</p>
            <p>your bet</p>
          </div>
          <div className={infoClasses}>
            <p>{`${betOdd.toFixed(2)}`}</p>
            <p>odds</p>
          </div>
          {bettingTipStatus !== 'In progress' && (
            <div className={winLoseClasses}>
              <p>{`$${winLoseAmount.toFixed(2)}`}</p>
              <p>{winLoseMessage}</p>
            </div>
          )}
        </div>
      </div>

      <div className="bb-user-bet__share">
        <Button styleType="secondary" onClick={onShare}>
          Share With Friends
        </Button>
      </div>
    </div>
  );
};

export default UserBetCard;
