import React, { useCallback } from 'react';
import classNames from 'classnames';
import { SportType } from 'models/Sport';
import './SportCategory.styles.scss';
import utils from 'utils';

type SportCategoryProps = {
  className?: string;
  sport?: SportType;
  value?: string;
  sportIcon?: React.ReactNode;
  active?: boolean;
  input?: any;
  meta?: any;
  onClick?: (value: SportType) => void;
};

const SportCategory: React.FC<SportCategoryProps> = (props) => {
  const {
    className,
    sportIcon,
    sport,
    value,
    active,
    input,
    onClick,
    ...rest
  } = props;

  const classes = classNames(
    'bb-sport-category',
    { 'bb-sport-category--active': active },
    className,
  );



  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!input) onClick(sport);
      else {
        onClick(sport);
        input.onChange(sport);
      }
    },
    [input, onClick, sport],
  );

  return (
    <div className={classes} onClick={handleOnClick} {...rest}>
      {utils.renderIcon(sportIcon)}
      <label>{value}</label>
    </div>
  );
};

export default SportCategory;
