import React, { useEffect } from 'react';
import { Field, useForm } from 'react-final-form';
import { ExtractPropTypes } from 'types';
import './FieldWrapper.styles.scss';

type FieldWrapperProps = ExtractPropTypes<typeof Field>;

const FieldWrapper: React.FC<FieldWrapperProps> = (props) => {
  const { children, name, defaultValue, ...rest } = props;

  const { change } = useForm();

  useEffect(() => {
    change(`${name}`, defaultValue);
  }, [change, defaultValue, name]);

  useEffect(() => {
    return () => {
      // this callback is called when component is unmounted
      if (!window.submittingForm && !window.nextStep)
        change(`${name}`, undefined);
    };
  }, [change, name]);

  return (
    <Field name={name} defaultValue={defaultValue} {...rest}>
      {children}
    </Field>
  );
};

export default FieldWrapper;
