import useInfinitePagination from 'hooks/useInfinitePagination';
import { FilterBetData, ShowBetTypesEnhanced, UserBetData } from 'models/Bet';
import { TeamData } from 'models/Team';
import { CoinTypes } from 'models/Transactions';
import { UserSettingsType, UserType } from 'models/User';
import { WalletType } from 'models/Wallet';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import credentialsService from 'services/credentialsService';
import eventSocketService from 'services/socket/eventSocketService';
import { socketEventNames } from 'services/socket/socketService';
import { TeamFilterData } from '../Teams/Teams.provider';
import MainContext from './Main.context';
import utils from 'utils';
import api from 'api';

export const defaultFilterBetsData = {
  sport: 'all',
  filter: 'ALL_BETS',
  sort: 'PRICE',
} as FilterBetData;

type MainProps = {
  children?: React.ReactNode;
};

const Main: React.FC<MainProps> = (props) => {
  const { children } = props;

  const oldUserData = useMemo(() => credentialsService.user, []);

  const [userData, setUserData] = useState<UserType>(oldUserData);

  const [coinsWithAddresses, setCoinstWithAddress] =
    useState<Array<WalletType>>();
  const [coinsWithoutAddress, setCoinsWithoutAddress] =
    useState<Array<CoinTypes>>();

  const [currentBalance, setCurrentBalance] = useState<number>(0);
  const [newBetModal, setNewBetModal] = useState<boolean>(false);
  const [welcomeWizardModal, setWelcomeWizardModal] = useState<boolean>(false);
  const [settingsData, setSettingsData] = useState<UserSettingsType>();
  const [filterShowBetsData, setFilterShowBetsData] =
    useState<ShowBetTypesEnhanced>('UPCOMING');

  const [filterBetsData, setFilterBetsData] = useState<FilterBetData>(
    defaultFilterBetsData,
  );

  const [filterTeamsData, setFilterTeamsData] = useState<TeamFilterData>({
    limitWins: 0,
    limitLoses: 10,
  });

  const {
    loading: loadingFavorites,
    items: favoriteTeams,
    setItems: setFavoriteTeams,
    onContainerScrolled: onFavoritesScroll,
  } = useInfinitePagination<TeamData>({
    makeRequest: (currentPage: number) => {
      return api.teams
        .getFavoriteTeamsForUser({
          ...filterTeamsData,
          page: currentPage,
          limit: 10,
        })
        .then(({ data }) => data);
    },
    dependencies: [filterTeamsData],
    resetDeps: [filterTeamsData],
  });

  const {
    loading: loadingBets,
    items: userBets,
    setItems: setUserBets,
    onContainerScrolled: onUserBetsScroll,
  } = useInfinitePagination<UserBetData>({
    makeRequest: (currentPage: number) => {
      return api.bets
        .getUserBets({
          show: filterShowBetsData,
          filter: filterBetsData.filter,
          sort: filterBetsData.sort,
          page: currentPage,
          limit: 5,
          sport: filterBetsData.sport === 'all' ? null : filterBetsData.sport,
        })
        .then(({ data }) => data);
    },
    dependencies: [filterBetsData, filterShowBetsData, userData],
    resetDeps: [filterBetsData, filterShowBetsData, userData],
  });

  const addTeamToFavoritesList = useCallback(
    (team: TeamData) => {
      setFavoriteTeams((oldTeams) => [team, ...oldTeams]);
    },
    [setFavoriteTeams],
  );

  const removeTeamFromFavoritesList = useCallback(
    (teamId: string) => {
      setFavoriteTeams((oldTeams) =>
        oldTeams.filter((team) => team.teamId !== teamId),
      );
    },
    [setFavoriteTeams],
  );

  const updateCoinsList = useCallback(
    (wallet: WalletType) => {
      if (coinsWithoutAddress.includes(wallet.coinType))
        setCoinsWithoutAddress((oldCoins) =>
          oldCoins.filter((coin) => coin !== wallet.coinType),
        );

      const isCoinExist = coinsWithAddresses.find(
        (coin) => coin.coinType === wallet.coinType,
      );

      if (!isCoinExist)
        setCoinstWithAddress((oldCoins) => [wallet, ...oldCoins]);
      else
        setCoinstWithAddress((oldCoins) =>
          oldCoins.map((coin) => {
            if (coin.coinType === wallet.coinType) return wallet;
            else return coin;
          }),
        );
    },
    [coinsWithAddresses, coinsWithoutAddress],
  );

  useEffect(() => {
    const loadBalance = async () => {
      try {
        const { data } = await api.user.getCurrentBalance();
        setCurrentBalance(data.currentBalance);
      } catch (e) {
        utils.toastError(e);
      }
    };

    const loadUserData = async () => {
      try {
        const { data } = await api.user.getUser();
        setUserData(data);
        setWelcomeWizardModal(!data.isOnboarded);
      } catch (e) {
        utils.toastError(e);
      }
    };
    loadUserData();
    loadBalance();
  }, []);

  useEffect(() => {
    const loadUserSettings = async () => {
      try {
        const { data } = await api.user.getUserSettings();
        setSettingsData(data);
      } catch (e) {
        utils.toastError(e);
      }
    };

    const loadWalletAddresses = async () => {
      try {
        const { data } = await api.coins.getWalletAddresses();
        setCoinstWithAddress(data.coinsWithAddress);
        setCoinsWithoutAddress(data.coinsWithoutAddress);
      } catch (e) {
        utils.toastError(e);
      }
    };
    loadUserSettings();
    loadWalletAddresses();
  }, []);

  useEffect(() => {
    const onCheckConnection = () => {
      eventSocketService.sendEvent(socketEventNames.CONFIRM_CONNECTION, null);
    };

    eventSocketService.addListener(
      socketEventNames.CHECK_CONNECTION,
      onCheckConnection,
    );

    return () => {
      eventSocketService.removeListener(
        socketEventNames.CHECK_CONNECTION,
        onCheckConnection,
      );
    };
  }, []);

  return (
    <MainContext.Provider
      value={{
        loadingBets,
        loadingFavorites,
        newBetModal,
        welcomeWizardModal,
        currentBalance,
        userBets,
        userData,
        favoriteTeams,
        filterBetsData,
        filterTeamsData,
        filterShowBetsData,
        settingsData,
        coinsWithAddresses,
        coinsWithoutAddress,
        updateCoinsList,
        setCoinsWithoutAddress,
        setCoinstWithAddress,
        setSettingsData,
        setNewBetModal,
        setWelcomeWizardModal,
        setFilterBetsData,
        setFilterTeamsData,
        setFilterShowBetsData,
        setCurrentBalance,
        setUserBets,
        setUserData,
        setFavoriteTeams,
        addTeamToFavoritesList,
        removeTeamFromFavoritesList,
        onFavoritesScroll,
        onUserBetsScroll,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};

export default Main;
