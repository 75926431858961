import { BetData } from 'models/Bet';
import { FixtureType } from 'models/Fixture';
import { OutcomeModel } from 'models/Outcome';
import PaginatedData from 'models/PaginatedData';
import { LeagueType, SportType } from 'models/Sport';
import { FixtureSortTypes } from 'routers/MainRouter/pages/Stadium/components/TimeFilterModal/TimeFilterModal.component';
import httpClient from '../httpClient';

const fixturePath = (routePath: TemplateStringsArray) =>
  `fixtures/${routePath}`;

function getFixtureDetails(params: {
  fixtureId?: string;
  limit?: number;
  page: number;
}) {
  return httpClient.get<PaginatedData<BetData>>(fixturePath`fixtureDetails`, {
    params,
  });
}

function getFixtureOutcomes(params: { fixtureId: string }) {
  return httpClient.get<{ outcomes: Array<OutcomeModel> }>(
    fixturePath`outcomes`,
    { params },
  );
}

function getFixtureById(fixtureId: string) {
  return httpClient.get<FixtureType>(`fixtures/${fixtureId}`);
}

function getUpcomingFixtures(params: {
  teamId: string;
  page: number;
  limit?: number;
}) {
  return httpClient.get<PaginatedData<FixtureType>>(
    fixturePath`teamUpcomingGames`,
    {
      params,
    },
  );
}

function getNotStartedFixtures(params: {
  page: number;
  limit?: number;
  sport: SportType;
  sort?: string;
  date?: string;
  timezoneOffset: number;
}) {
  return httpClient.get<PaginatedData<FixtureType>>(
    fixturePath`notStartedFixtures`,
    { params },
  );
}

function loadFixturesForSport(params: {
  page: number;
  limit?: number;
  sport?: SportType;
  date?: string;
  sort?: FixtureSortTypes;
  league?: LeagueType | 'all';
  searchString?: string;
  timezoneOffset: number;
}) {
  if ((params as any).sport === 'ALL') delete params.sport;
  if (params.searchString) params.page = 1;
  return httpClient.get<PaginatedData<FixtureType>>(fixturePath``, {
    params,
  });
}

export default {
  getFixtureOutcomes,
  getFixtureDetails,
  getUpcomingFixtures,
  getFixtureById,
  getNotStartedFixtures,
  loadFixturesForSport,
};
