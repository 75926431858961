import React from 'react';
import classNames from 'classnames';

import utils from 'utils';
import { UserType } from 'models/User';
import { FixtureStatusType } from 'models/Fixture';
import { BetStatusStyleType, BettingTipStatusType } from 'models/Bet';
import UserImage from 'components/UserImage';
import BetStatus from 'components/BetStatus';

import './UserBetInfo.styles.scss';

type UserBetInfoProps = {
  className?: string;
  user: UserType;
  betCreatedAt?: Date;
  betStatus?: FixtureStatusType | BettingTipStatusType;
  betStatusStyleType?: BetStatusStyleType;
};

const UserBetInfo: React.FC<UserBetInfoProps> = (props) => {
  const {
    className,
    user: { Id, profileImageUrl, username },
    betCreatedAt,
    betStatus,
    betStatusStyleType,
  } = props;

  return (
    <div className={classNames('bb-user-bet-info', className)}>
      <UserImage
        userId={Id}
        src={profileImageUrl}
        alt={username}
        className="bb-user-bet-info__user-image"
      />

      <div className="bb-user-bet-info__details">
        <p className="bb-user-bet-info__details__name">{`${username}`}</p>
        <p className="bb-user-bet-info__details__time">{`${utils.toHourMinFormat(
          betCreatedAt?.toString(),
        )}`}</p>
      </div>

      <BetStatus status={betStatus} styleType={betStatusStyleType} />
    </div>
  );
};

export default UserBetInfo;
