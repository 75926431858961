import httpClient from 'api/httpClient';
import { FriendshipType } from 'models/Friends';
import PaginatedData from 'models/PaginatedData';

const friendPath = (routePath: TemplateStringsArray) => `friends/${routePath}`;

function getUserFriends(params: {
  limit: number;
  page: number;
  searchString: string;
}) {
  return httpClient.get<PaginatedData<FriendshipType>>(friendPath``, {
    params,
  });
}

function getFriendRequests(params: {
  limit?: number;
  page: number;
  searchString: string;
}) {
  return httpClient.get<PaginatedData<FriendshipType>>(friendPath`requests`, {
    params,
  });
}

function getPandingReqests() {
  return httpClient.get(friendPath`pending`);
}

function sendFriendRequst(userId: string) {
  return httpClient.post<FriendshipType>(`friends/${userId}`);
}

function acceptFriendRequest(friendRequest: string) {
  return httpClient.post<{ friendship: FriendshipType }>(
    `friends/${friendRequest}/accept`,
  );
}

function deleteFriendRequest(friendRequestId: string) {
  return httpClient.delete(`friends/${friendRequestId}/requests`);
}

function deleteFriendship(friendshipId: string) {
  return httpClient.delete(`friends/${friendshipId}`);
}

export default {
  getUserFriends,
  getFriendRequests,
  getPandingReqests,
  sendFriendRequst,
  acceptFriendRequest,
  deleteFriendRequest,
  deleteFriendship,
};
