import React from 'react';

export default () => (
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none">
    <g clipPath="url(#clip0_503_29)">
      <rect
        x="4.8"
        y="4"
        width="16"
        height="16"
        rx="4"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.8 9H20.8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_503_29">
        <rect
          width="24"
          height="24"
          fill="currentColor"
          transform="translate(0.799999)"
        />
      </clipPath>
    </defs>
  </svg>
);
