import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import Modal from 'components/Modal';
import { ModalProps } from 'components/Modal/Modal.component';
import StepsProvider from 'components/FormWrapper/providers/Steps';
import { StepProviderRef } from 'components/FormWrapper/providers/Steps/Steps.provider';
import useCallbackRef from 'hooks/useCallbackRef';
import newBetFormSteps from './formSteps';
import StepProgress from 'components/StepProgress';
import { Button, Loader } from 'ncoded-component-library';
import AppearAnimation from 'components/AppearAnimation';
import { CSSTransition } from 'react-transition-group';
import arrayMutators from 'final-form-arrays';
import { OutcomeDataFiledProps } from './components/OutcomeCard/OutcomeCard.component';
import { OutcomeData } from 'models/Outcome';
import { BetDataType } from 'models/Bet';
import { OverlayRef } from 'components/Overlay';
import MainContext from '../../providers/Main/Main.context';
import { Form, UseFieldConfig } from 'react-final-form';
import NewBetContext, {
  InitialFixtureData,
} from './providers/NewBet/NewBet.context';
import { ChatSessionClientData } from 'models/Chats';
import Animation from 'components/Animation';
import Arrow from 'assets/svgs/Arrow';
import { AxiosError } from 'axios';
import utils from 'utils';
import api from 'api';

import './NewBetModal.styles.scss';
import {
  moveDownWithBackground,
  moveUpWithBackground,
} from 'models/Animations';

export const BET_STEPS = {
  SPORT_CATEGORY_STEP: 0,
  GAME_FOR_BET_STEP: 1,
  OUTCOMES_STEP: 2,
  BET_AMOUNT_STEP: 3,
  ODDS_STEP: 4,
  PUBLISH_SETTINGS_STEP: 5,
  SHARE_WITH_FRIEND_STEP: 6,
  BET_TYPE_STEP: 7,
  REVIEW_STEP: 8,
} as const;

type NewBetModalProps = ModalProps &
  UseFieldConfig<InitialFixtureData> & {
    className?: string;
  };

const NewBetModal: React.FC<NewBetModalProps> = (props) => {
  const { className, initialValue, open, ...rest } = props;

  const classes = classNames(
    'bb-newbet-modal',
    { 'bb-newbet-modal--open': open },
    className,
  );

  const [loading, setLoading] = useState<boolean>(false);
  const { setCurrentBalance, setUserBets } = useContext(MainContext);
  const { isBetEditing, editingBetId } = useContext(NewBetContext);

  const newBetModalRef = useRef<OverlayRef>(null);
  const formValues = useRef(null);

  const [
    {
      currentStep,
      steps,
      nextStep,
      prevStep,
      setCurrentStep,
    } = {} as StepProviderRef,
    stepsProviderRef,
  ] = useCallbackRef<StepProviderRef>();

  const animatedStep = useMemo(() => {
    return steps?.map((step, index) => (
      <CSSTransition
        key={step.title}
        in={currentStep === index}
        unmountOnExit
        timeout={0}
        classNames="bb-newbet-modal__step"
      >
        <AppearAnimation duration={300} animationName="appear">
          <div className="bb-newbet-modal__step">
            <step.component />
          </div>
        </AppearAnimation>
      </CSSTransition>
    ));
  }, [steps, currentStep]);

  const mapOutcomesData = useCallback(
    (outcomes: Array<OutcomeDataFiledProps>) => {
      return outcomes
        .filter((outcome: OutcomeDataFiledProps) => outcome.active)
        .map((outcome: OutcomeDataFiledProps) => {
          return {
            ...outcome.data,
            ...(outcome.data.limit && {
              limit: parseFloat(outcome.data?.limit as string),
            }),
            ...(outcome.data.handicap && {
              handicap: parseFloat(outcome.data.handicap as string),
            }),
          };
        }) as Array<OutcomeData>;
    },
    [],
  );

  const handleOnPrevStep = useCallback(() => {
    if (currentStep === 6 && formValues.current.publishSettings === 'Private')
      setCurrentStep(5);
    else if (
      currentStep === 6 &&
      formValues.current.publishSettings === 'Public'
    )
      setCurrentStep(4);
    else prevStep();
  }, [currentStep, prevStep, setCurrentStep]);

  const balanceAfterEditing = useCallback(
    (oldAmount: number, newAmount: number) => {
      const betAmountDifference = Math.abs(newAmount - oldAmount);
      if (newAmount < oldAmount) {
        setCurrentBalance((oldBalance) => oldBalance + betAmountDifference);
      }
      if (newAmount > oldAmount) {
        setCurrentBalance((oldBalance) => oldBalance - betAmountDifference);
      }
    },
    [setCurrentBalance],
  );

  const onFormSubmit = useCallback(
    async (values: any) => {
      if (!isBetEditing.current) {
        nextStep();
        if (currentStep < steps.length - 1) return;
      }

      try {
        setLoading(true);
        const {
          betAmount,
          betType,
          betOdd: betOddValue,
          outcomes,
          sessions,
          sportCategory,
          publishSettings: betPrivacy,
          selectedGame: { Id: fixtureId },
        } = values;

        const betOdd =
          typeof betOddValue === 'string'
            ? parseFloat(betOddValue)
            : betOddValue;

        const outcomesData = mapOutcomesData(outcomes);

        const betData = {
          betAmount,
          betOdd,
          betType,
          sport: sportCategory,
          betPrivacy,
          fixtureId,
          sessionIds: sessions?.map(
            (session: ChatSessionClientData) => session.Id,
          ),
          outcomes: outcomesData,
        } as BetDataType;

        if (!isBetEditing.current) {
          const { data } = await api.bets.createBet(betData as BetDataType);
          utils.toastSuccess('Successfully created new bet');
          setUserBets((oldBets) => [data.bettingTip, ...oldBets]);
          setCurrentBalance((oldBalance) => oldBalance - betAmount);
        }

        if (isBetEditing.current) {
          const { data } = await api.bets.editBet(
            betData,
            editingBetId.current,
          );
          const {
            betOdd,
            betPrivacy,
            betStatus,
            betType,
            bettingAmount,
            currentMaxPayment,
            maxPayment,
            ticketId,
            totalBetAmount,
            updatedAt,
            cashoutAmount,
            currentPayment,
            numberOfOutcomes,
            playersCount,
            refundedMoney,
          } = data;

          utils.toastSuccess('Successfully updated bet');
          balanceAfterEditing(initialValue.betAmount, betAmount);

          setUserBets((old) =>
            old.map((bet) => {
              const { bet: Bets } = bet;

              if (Bets.Id === data.Id) {
                return {
                  ...bet,
                  betOdd,
                  bettingTipAmount: bettingAmount,
                  bet: {
                    ...Bets,
                    betOdd,
                    betOutcomes: [
                      {
                        outcomeData: {
                          ...values.outcomes.find(
                            (outcome: any) => outcome.active === true,
                          ).data,
                        },
                      },
                    ],
                    betPrivacy,
                    betStatus,
                    betType,
                    bettingAmount,
                    currentMaxPayment,
                    maxPayment,
                    ticketId,
                    totalBetAmount,
                    updatedAt,
                    cashoutAmount,
                    currentPayment,
                    numberOfOutcomes,
                    playersCount,
                    refundedMoney,
                  },
                };
              } else return bet;
            }),
          );
        }

        window.isNewBetModalClose = true;
        setTimeout(() => {
          if (isBetEditing.current) isBetEditing.current = false;
          setCurrentStep(0);
        }, 800);

        rest.onClose();
      } catch (e) {
        utils.toastError(e as AxiosError);
      } finally {
        setLoading(false);
      }
    },
    [
      rest,
      currentStep,
      steps,
      mapOutcomesData,
      nextStep,
      isBetEditing,
      setCurrentBalance,
      setUserBets,
      editingBetId,
      balanceAfterEditing,
      initialValue,
      setCurrentStep,
    ],
  );

  useEffect(() => {
    if (!initialValue || setCurrentStep === undefined) return;

    setCurrentStep(BET_STEPS.OUTCOMES_STEP);
  }, [initialValue, setCurrentStep]);

  const onClickBack = useCallback(() => {
    if (isBetEditing.current && currentStep === BET_STEPS.OUTCOMES_STEP) return;

    handleOnPrevStep();
  }, [isBetEditing, currentStep, handleOnPrevStep]);

  return (
    <Animation
      open={open}
      duration={800}
      appearAnimations={moveUpWithBackground}
      disappearAnimations={moveDownWithBackground}
    >
      <Modal
        {...rest}
        open
        className={classes}
        ref={newBetModalRef}
        onX={() => {
          window.isNewBetModalClose = true;
          window.prevStep = false;
          setTimeout(() => setCurrentStep(0), 600);
          rest.onClose();
        }}
        title={
          <>
            {currentStep > 0 && (
              <Button
                className="bb-newbet-modal__prev-btn"
                onClick={onClickBack}
                icon={Arrow}
              />
            )}
            <p>{newBetFormSteps[currentStep]?.title}</p>
          </>
        }
      >
        <Form
          initialValues={{
            betOdd: 2,
            betType: 'Multi',
            publishSettings: 'Public',
            ...initialValue,
          }}
          mutators={{
            ...arrayMutators,
          }}
          onSubmit={onFormSubmit}
          id="betModalForm"
        >
          {({ values }) => {
            formValues.current = values;
            return (
              <StepsProvider ref={stepsProviderRef} steps={newBetFormSteps}>
                <StepProgress value={currentStep} totalSteps={steps?.length} />
                {loading && <Loader />}
                <div className="bb-newbet-modal__container">{animatedStep}</div>
              </StepsProvider>
            );
          }}
        </Form>
      </Modal>
    </Animation>
  );
};

export default NewBetModal;
