import { FilterBetData, ShowBetTypesEnhanced, UserBetData } from 'models/Bet';
import { TeamData } from 'models/Team';
import { CoinTypes } from 'models/Transactions';
import { UserSettingsType, UserType } from 'models/User';
import { WalletType } from 'models/Wallet';
import { createContext } from 'react';
import utils from 'utils';
import { TeamFilterData } from '../Teams/Teams.provider';

export type MainContextProps = {
  loadingBets: boolean;
  loadingFavorites: boolean;
  newBetModal: boolean;
  welcomeWizardModal: boolean;
  userData: UserType;
  userBets: Array<UserBetData>;
  currentBalance: number;
  favoriteTeams: Array<TeamData>;
  filterShowBetsData: ShowBetTypesEnhanced;
  filterBetsData: FilterBetData;
  filterTeamsData: TeamFilterData;
  settingsData: UserSettingsType;
  coinsWithAddresses: Array<WalletType>;
  coinsWithoutAddress: Array<CoinTypes>;
  setSettingsData: React.Dispatch<React.SetStateAction<UserSettingsType>>;
  setNewBetModal: (value: boolean) => void;
  setWelcomeWizardModal: (value: boolean) => void;
  setFavoriteTeams: (value: Array<TeamData>) => void;
  setUserBets: React.Dispatch<React.SetStateAction<Array<UserBetData>>>;
  setUserData: React.Dispatch<React.SetStateAction<UserType>>;
  setFilterShowBetsData: (value: ShowBetTypesEnhanced) => void;
  setFilterBetsData: (value: FilterBetData) => void;
  setFilterTeamsData: (value: TeamFilterData) => void;
  setFollowersSearchString: (value: string) => void;
  setCurrentBalance: React.Dispatch<React.SetStateAction<number>>;
  setLoadingBets: (value: boolean) => void;
  setLoadingTeams: (value: boolean) => void;
  setActiveNaviagtionSource: (activeIndex: number) => void;
  setCoinstWithAddress: (values: Array<WalletType>) => void;
  setCoinsWithoutAddress: (values: Array<CoinTypes>) => void;
  addTeamToFavoritesList: (value: TeamData) => void;
  removeTeamFromFavoritesList: (teamId: string) => void;
  updateCoinsList: (value: WalletType) => void;
  onFavoritesScroll: () => void;
  onUserBetsScroll: () => void;
  onFollowersScroll: () => void;
};

export default createContext<Partial<MainContextProps>>({
  loadingFavorites: false,
  loadingBets: false,
  newBetModal: false,
  currentBalance: 0,
  userBets: [],
  coinsWithAddresses: [],
  coinsWithoutAddress: [],
  userData: null,
  favoriteTeams: [],
  filterBetsData: null,
  filterTeamsData: null,
  filterShowBetsData: null,
  setNewBetModal: utils.noop,
  setFilterBetsData: utils.noop,
  setFilterTeamsData: utils.noop,
  setFilterShowBetsData: utils.noop,
  setFavoriteTeams: utils.noop,
  setFollowersSearchString: utils.noop,
  setUserBets: utils.noop,
  setUserData: utils.noop,
  setCurrentBalance: utils.noop,
  setLoadingBets: utils.noop,
  setLoadingTeams: utils.noop,
  setActiveNaviagtionSource: utils.noop,
  setCoinstWithAddress: utils.noop,
  setCoinsWithoutAddress: utils.noop,
  addTeamToFavoritesList: utils.noop,
  removeTeamFromFavoritesList: utils.noop,
  updateCoinsList: utils.noop,
  onFavoritesScroll: utils.noop,
  onUserBetsScroll: utils.noop,
  onFollowersScroll: utils.noop,
});
