import React, { useContext } from 'react';
import classNames from 'classnames';
import { Field, useField } from 'react-final-form';
import FormWrapper from 'components/FormWrapper';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import { betAmountSlides } from 'routers/MainRouter/pages/MainPage/constants';
import useFormHelperElements from 'hooks/useFormHelperElements';
import AmountSliderField from 'components/Fields/AmountSliderField';

import './BetAmountForm.styles.scss';

const BetAmountForm: React.FC = () => {
  const classes = classNames('bb-bet-amount');

  const { nextStep } = useContext(StepsContext);

  const { ErrorMessage, SubmitBet, SaveBetEdit } = useFormHelperElements();

  const {
    input: { value: betAmount },
  } = useField<number>('betAmount');

  return (
    <FormWrapper onSubmit={nextStep} className={classes} id="betModalForm">
      <div className="bb-bet-amount__container">
        <label>choose your bet amount</label>
        <Field
          name="betAmount"
          component={AmountSliderField}
          value={betAmount}
          defaultValue={1}
          inputName="betAmount"
          slideSteps={betAmountSlides}
          defaultIndex={betAmountSlides.length - 1}
        />
      </div>
      <div className="bb-bet-amount__footer">
        <ErrorMessage />
        <SaveBetEdit>Save</SaveBetEdit>
        <SubmitBet>Next Step</SubmitBet>
      </div>
    </FormWrapper>
  );
};

export default BetAmountForm;
