import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  CoinTypes,
  COIN_TYPES,
  PaymentTransaction,
  PaymentTransactionStatus,
  PAYMENT_STATUS,
  StripePaymentTransaction,
  STRIPE_PAYMENT_TRANSACTION_STATUS as STRIPE_STATUS,
  TransactionNames,
  TRANSACTION_NAMES,
} from 'models/Transactions';
import utils from 'utils';
import { Button } from 'ncoded-component-library';
import Collapsible from 'components/Collapsible';
import { COINS_TRANSLATIONS } from '../../depositsteps/DepositAmountStep/DepositAmountStep.component';

import './TransactionItem.styles.scss';

const CRYPTO_STATUS_TRANSLATIONS = {
  PENDING: 'Pending',
  COMPLETE: 'Complete',
  CANCELED: 'Canceled',
} as Record<PaymentTransactionStatus, string>;

type TransactionItemProps = {
  className?: string;
  createdAt: Date;
  date: string;
  transactionName: TransactionNames;
  transactionValue: number;
  cryptoTransaction: PaymentTransaction;
  stripeTransaction: StripePaymentTransaction;
  onClick?: () => void;
};

const TransactionItem: React.FC<TransactionItemProps> = (props) => {
  const {
    className,
    date,
    createdAt,
    transactionName,
    transactionValue,
    cryptoTransaction,
    stripeTransaction,
    onClick,
  } = props;

  const {
    amount: cryptoAmount,
    walletAddress,
    cryptoCurrency,
    paymentStatus,
    checkoutUrl,
  } = cryptoTransaction || {};

  const {
    status: stripeStatus,
    type: transactionType = COIN_TYPES.CREDIT_CARD,
    amount: stripeAmount,
  } = stripeTransaction || {};

  const classes = classNames('bb-transaction-item', className);

  const isLostTransaction = useMemo(
    () =>
      transactionName === TRANSACTION_NAMES.ACCEPT_BET ||
      transactionName === TRANSACTION_NAMES.CREATE_BET ||
      transactionName === TRANSACTION_NAMES.WITHDRAW,
    [transactionName],
  );

  const isWonTransaction = useMemo(
    () =>
      transactionName === TRANSACTION_NAMES.WIN_BET ||
      transactionName === TRANSACTION_NAMES.REFUND ||
      transactionName === TRANSACTION_NAMES.DEPOSIT ||
      transactionName === TRANSACTION_NAMES.REFERRAL_PAID_OFF,
    [transactionName],
  );

  const isDepositPanding = useMemo(
    () =>
      transactionName === TRANSACTION_NAMES.DEPOSIT_PENDING ||
      transactionName === TRANSACTION_NAMES.DEPOSIT_WAITING_FOR_PAYMENT,
    [transactionName],
  );

  const isWithdrawPanding = useMemo(
    () => transactionName === TRANSACTION_NAMES.WITHDRAW_PENDING,
    [transactionName],
  );

  const dotClass = classNames(
    'bb-transaction-item__dot',
    { 'bb-transaction-item__dot--lose': isLostTransaction },
    { 'bb-transaction-item__dot--win': isWonTransaction },
    {
      'bb-transaction-item__dot--pending':
        isDepositPanding || isWithdrawPanding,
    },
  );

  const transactionClasses = classNames(
    { 'bb-transaction-item--win': isWonTransaction },
    { 'bb-transaction-item--lose': isLostTransaction },
    { 'bb-transaction-item--pending': isDepositPanding || isWithdrawPanding },
  );

  const showButtonAction = useMemo(
    () =>
      stripeStatus === STRIPE_STATUS.WAITING_FOR_PAYMENT ||
      stripeStatus === STRIPE_STATUS.PENDING ||
      paymentStatus === PAYMENT_STATUS.PENDING,
    [paymentStatus, stripeStatus],
  );

  const transactionAction = useMemo(
    () =>
      showButtonAction && (
        <Button
          onClick={() => {
            if (cryptoCurrency !== COIN_TYPES.CREDIT_CARD && checkoutUrl) {
              window.open(checkoutUrl, '_blank');
              return;
            }

            onClick?.();
          }}
        >
          View Status
        </Button>
      ),
    [checkoutUrl, cryptoCurrency, showButtonAction, onClick],
  );

  const cryptoTransactionDetails = useMemo(
    () =>
      paymentStatus === PAYMENT_STATUS.PENDING && (
        <div className="bb-transaction-item__preview__container">
          <div className="box">
            <p className="box__title">Payment amount</p>
            <p className="box__value">{cryptoAmount}</p>
          </div>

          <div className="box">
            <p className="box__title">Payment type</p>
            <p className="box__value">{cryptoCurrency}</p>
          </div>

          <div className="box">
            <p className="box__title">Payment status</p>
            <p className="box__value">
              {
                CRYPTO_STATUS_TRANSLATIONS[
                  paymentStatus as PaymentTransactionStatus
                ]
              }
            </p>
          </div>

          <div className="box">
            <p className="box__title">Wallet address</p>
            <p className="box__value">{walletAddress}</p>
          </div>

          {transactionAction}
        </div>
      ),
    [
      cryptoAmount,
      cryptoCurrency,
      paymentStatus,
      walletAddress,
      transactionAction,
    ],
  );

  const stripeTransactionDetails = useMemo(
    () =>
      (stripeStatus === STRIPE_STATUS.WAITING_FOR_PAYMENT ||
        stripeStatus === STRIPE_STATUS.PENDING) && (
        <div className="bb-transaction-item__preview__container">
          <div className="box">
            <p className="box__title">Payment amount</p>
            <p className="box__value">{`$${utils.getDotedNumber(
              stripeAmount.toString(),
            )}`}</p>
          </div>

          <div className="box">
            <p className="box__title">Payment type</p>
            <p className="box__value">
              {COINS_TRANSLATIONS[transactionType as CoinTypes]}
            </p>
          </div>

          <div className="box">
            <p className="box__title">Payment status</p>
            <p className="box__value">{stripeStatus}</p>
          </div>

          {transactionAction}
        </div>
      ),
    [stripeAmount, stripeStatus, transactionType, transactionAction],
  );

  return (
    <div className={classes}>
      {date && (
        <div className="bb-transaction-item__date">
          <p>{date}</p>
        </div>
      )}

      <Collapsible
        defaultOpen={false}
        trigger={
          <div className="bb-transaction-item__container">
            <div className="bb-transaction-item__container__data">
              <div className={dotClass} />
              <p>{utils.toHourMinFormat(createdAt.toString())}</p>
              <p>{transactionName}</p>
            </div>
            <p className={transactionClasses}>
              {`${isLostTransaction ? ' - ' : ''} $${transactionValue.toFixed(
                2,
              )}`}
            </p>
          </div>
        }
      >
        <div className="bb-transaction-item__content">
          {!!cryptoTransaction && cryptoTransactionDetails}
          {!!stripeTransaction && stripeTransactionDetails}
        </div>
      </Collapsible>
    </div>
  );
};

export default TransactionItem;
