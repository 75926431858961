import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="120"
    height="75"
    viewBox="0 0 120 75"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <g transform="translate(-128 -222) translate(8 56) translate(16 82) translate(104 84)">
              <rect width="120" height="75" fill="#2D4566" rx="4" />
              <rect
                width="112"
                height="11"
                x="4"
                y="4"
                fill="#0D2A52"
                rx=".5"
              />
              <path
                fill="#627590"
                d="M31.5 22c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-27c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h27zm84 0c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-27c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h27zm-94-5c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-17c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h17zm94 0c.276 0 .5.224.5.5v2c0 .276-.224.5-.5.5h-33c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5h33zM70.5 65c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-29c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h29zm23-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-52c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h52zm-33-6c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h19zm15 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm18 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm-33-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-19c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h19zm15 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm-23-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm8 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-5c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h5zm12-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-31c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h31zm-20-6c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm23 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-20c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h20zm-23-4c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-11c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h11zm23 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-20c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h20zm40 0c.276 0 .5.224.5.5v1c0 .276-.224.5-.5.5h-33c-.276 0-.5-.224-.5-.5v-1c0-.276.224-.5.5-.5h33z"
              />
              <rect
                width="32"
                height="42"
                x="4"
                y="29"
                fill="#0D2A52"
                rx=".5"
              />
              <path
                fill="#2D4566"
                d="M20.28 37.024c.549.038 1.119.12 1.71.248s1.183.36 1.774.696c.592.335 1.122.748 1.59 1.238.47.49.856 1.138 1.16 1.942.303.805.46 1.719.471 2.742.01.223.01.442 0 .655-.085.98-.186 1.86-.304 2.637.31.395.464 1.034.464 1.918 0 1.194-.474 1.988-1.423 2.382-.287.81-.628 1.45-1.023 1.918-.671.757-1.007 1.428-1.007 2.014 0 .831.213 1.638.64 2.422.426.783 1.033 1.366 1.822 1.75.629.32 1.606.72 2.933 1.199 1.327.48 2.515.935 3.564 1.366 1.05.432 1.756.829 2.118 1.191.373.373.66.874.863 1.503.203.628.315 1.166.336 1.614l.026.541H36v4H4v-4h.001c.004-.178.014-.382.031-.62.021-.3.136-.787.344-1.463.207-.677.498-1.202.87-1.575.363-.362 1.066-.76 2.11-1.19 1.045-.432 2.23-.888 3.557-1.367 1.327-.48 2.304-.88 2.933-1.2.789-.383 1.396-.966 1.822-1.75.427-.783.64-1.59.64-2.42 0-.587-.336-1.258-1.007-2.015-.395-.469-.736-1.108-1.023-1.918-.949-.394-1.423-1.188-1.423-2.382 0-.884.155-1.523.464-1.918-.086-.596-.181-1.476-.288-2.637-.021-.17-.045-.397-.072-.68-.027-.282 0-.764.08-1.446.08-.682.248-1.257.503-1.726.405-.714.92-1.207 1.543-1.479.623-.271 1.132-.322 1.526-.152.011 0 .016-.005.016-.016.022-.106.07-.247.144-.423.075-.176.23-.41.464-.703s.506-.52.815-.68c.17-.085.448-.152.831-.2.384-.047.85-.053 1.399-.016z"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
