import React from 'react';
import classNames from 'classnames';
import NavigationTab from './NavigationTab/NavigationTab.component';
import './NavigationTabs.styles.scss';
import { MainRoute } from 'routers/MainRouter/routes';

type NavigationTabsProps = {
  items?: Array<MainRoute>;
  className?: string;
  activeIndex?: number;
};

const NavigationTabs: React.FC<NavigationTabsProps> = (props) => {
  const { className, items } = props;

  const classes = classNames('bb-navigation-tabs', className);
  return (
    <div className={classes}>
      {items.map((item, index) => {
        return <NavigationTab key={index} {...item} />;
      })}
    </div>
  );
};

export default NavigationTabs;
