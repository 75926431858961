import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-560 -160) translate(80 160) translate(480)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M14.691 5c.19 0 .363.107.447.276l.724 1.448c.084.169.258.276.447.276H19.5c.276 0 .5.224.5.5v10c0 .276-.224.5-.5.5h-15c-.276 0-.5-.224-.5-.5v-10c0-.276.224-.5.5-.5h3.191c.19 0 .363-.107.447-.276l.724-1.448C8.946 5.107 9.12 5 9.309 5h5.382zM12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 2c1.105 0 2 .895 2 2s-.895 2-2 2-2-.895-2-2 .895-2 2-2zm7-2h-2v1h2V8z"
              transform="translate(-560 -160) translate(80 160) translate(480)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
