import React, { useCallback, useState } from 'react';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Button, Loader } from 'ncoded-component-library';
import { useForm } from 'react-final-form';
import classNames from 'classnames';
import utils from 'utils';
import api from 'api';

import './CreditCardForm.styles.scss';

export type CreditCardFormProps = {
  className?: string;
  transactionValue: string;
  stripeTransactionId: string;
};

const CreditCardForm: React.FC<CreditCardFormProps> = (props) => {
  const { className, transactionValue, stripeTransactionId } = props;

  const classes = classNames('bb-credit-card-form', className);

  const { submit } = useForm();

  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState<boolean>(false);

  const submitPayment = useCallback(async () => {
    try {
      if (!stripe || !elements) {
        utils.toastError('Error with stripe configuration');
        return;
      }

      setLoading(true);

      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (result?.error?.code === 'card_declined') {
        utils.toastStringError('Credit card is declined!');
        setLoading(false);
        return;
      }

      if (result?.error?.type === 'validation_error') {
        setLoading(false);
        return;
      }

      await api.coins.stripePayout(stripeTransactionId);

      setLoading(false);
      submit();
    } catch (e) {
      console.error(e);
      utils.toastError(e);
    }
  }, [elements, stripeTransactionId, stripe, submit]);

  return (
    <div className={classes}>
      {!!elements && <PaymentElement />}
      <Button
        className="bb-credit-card-form__cashouut-button"
        type="button"
        disabled={!stripe}
        onClick={submitPayment}
      >
        {`Pay $${transactionValue}`}
      </Button>

      {loading && <Loader />}
    </div>
  );
};

export default CreditCardForm;
