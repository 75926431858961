import httpClient from 'api/httpClient';
import { SportType } from 'models/Sport';

function getOutcomesForSport(sport: SportType) {
  return httpClient.get('/outcomes', { params: { sport } });
}

export default {
  getOutcomesForSport,
};
