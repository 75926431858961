import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { sportCategories } from 'routers/MainRouter/pages/MainPage/constants';
import { Sport } from 'models/Sport';
import SportCategory from '../../components/SportCategory';
import { Field } from 'react-final-form';
import StepsContext from 'components/FormWrapper/providers/Steps/Steps.context';
import FormWrapper from 'components/FormWrapper';

import './SportCategoryForm.styles.scss';

type SportCategoryFormProps = {
  className?: string;
};

const SportCategoryForm: React.FC<SportCategoryFormProps> = (props) => {
  const { className } = props;

  window.prevStep = false;

  const classes = classNames('bb-choose-category', className);
  const [activeSportIndex, setActiveSportIndex] = useState<number>();

  const { nextStep } = useContext(StepsContext);

  const handleOnSportCategory = useCallback(
    (index: number) => {
      setActiveSportIndex(index);
      nextStep();
    },
    [nextStep],
  );

  return (
    <FormWrapper className={classes} id="betModalForm">
      <Field name="sportCategory">
        {({ input }) => (
          <>
            {sportCategories.map((item: Sport, index: number) => (
              <SportCategory
                input={input}
                key={index}
                sport={item.sport}
                value={item.value}
                sportIcon={item.sportIcon}
                active={activeSportIndex === index}
                onClick={() => handleOnSportCategory(index)}
              />
            ))}
          </>
        )}
      </Field>
    </FormWrapper>
  );
};

export default SportCategoryForm;
