import React, { useMemo } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import Numeric from 'components/Numeric';
import { FieldRenderProps } from 'react-final-form';

import './NumericField.styles.scss';

type NumericProps = ExtractPropTypes<typeof Numeric>;

type NumericFieldProps = NumericProps &
  FieldRenderProps<any | number, HTMLElement> & {
    showTextError?: boolean;
  };

const NumericField: React.FC<NumericFieldProps> = (props) => {
  const {
    input,
    meta: { touched, error },
    label,
    className,
    showTextError = true,
    ...rest
  } = props;

  const hasError = useMemo(() => touched && error, [error, touched]);
  const classes = classNames(
    'bb-numeric-field',
    { 'bb-numeric-field--error': hasError },
    className,
  );

  return (
    <div className={classes}>
      <Numeric step={0.5} {...rest} {...input} />
      {hasError && showTextError && (
        <small className="bb-numeric-field__error">{error}</small>
      )}
    </div>
  );
};

export default NumericField;
