import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-416 -208) translate(80 160) translate(336 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M3.084 14.042l6.875 6.874c-.544.057-1.073.083-1.576.084-.985 0-1.873-.1-2.605-.274-.733-.18-1.305-.403-1.71-.793-.39-.405-.613-.978-.794-1.711C3.1 17.49 3 16.602 3 15.617c0-.502.027-1.031.084-1.575zm9.42-10.716l8.17 8.17c-.445 2.121-1.395 4.326-3.12 6.058-1.731 1.724-3.936 2.674-6.057 3.12l-8.171-8.17c.446-2.122 1.396-4.327 3.12-6.058 1.732-1.724 3.936-2.674 6.058-3.12zm-.32 3.825c-.245.246-.245.644 0 .89l1.443 1.442L12 11.11l-1.442-1.442c-.246-.246-.644-.246-.89 0s-.246.644 0 .89L11.11 12l-1.627 1.627-1.442-1.443c-.246-.246-.644-.246-.89 0s-.246.645 0 .89l1.442 1.443-.184.184c-.245.246-.245.644 0 .89.246.246.644.246.89 0l.184-.184 1.443 1.442c.246.246.644.246.89 0 .246-.245.246-.643 0-.89l-1.443-1.442L12 12.89l1.443 1.442c.246.246.644.246.89 0 .245-.245.245-.643 0-.89L12.89 12l1.627-1.627 1.443 1.443c.246.246.644.246.89 0 .245-.246.245-.644 0-.89l-1.443-1.443.185-.184c.245-.246.245-.644 0-.89-.247-.246-.645-.246-.89 0l-.185.184-1.442-1.442c-.246-.246-.645-.246-.89 0zM15.617 3c.985 0 1.873.1 2.605.274.733.18 1.306.403 1.71.793.391.405.613.978.794 1.711.174.732.274 1.62.274 2.605 0 .502-.027 1.031-.084 1.575l-6.874-6.874c.544-.057 1.073-.083 1.575-.084z"
              transform="translate(-416 -208) translate(80 160) translate(336 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
