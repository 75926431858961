import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-335 -74) translate(12 68) translate(323 6)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M12 16c.828 0 1.5.672 1.5 1.5S12.828 19 12 19s-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zm0-5.5c.828 0 1.5.672 1.5 1.5s-.672 1.5-1.5 1.5-1.5-.672-1.5-1.5.672-1.5 1.5-1.5zM12 5c.828 0 1.5.672 1.5 1.5S12.828 8 12 8s-1.5-.672-1.5-1.5S11.172 5 12 5z"
              transform="translate(-335 -74) translate(12 68) translate(323 6)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
