import React, { useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { ExtractPropTypes } from 'types';
import { Select } from 'ncoded-component-library';
import { FieldRenderProps } from 'react-final-form';
import { OptionValue } from 'ncoded-component-library/build/components/molecules/Select/Select.component';

import './SelectField.styles.scss';

type SelectComponentProps = ExtractPropTypes<typeof Select>;

type SelectFieldProps = SelectComponentProps &
  FieldRenderProps<OptionValue, HTMLElement> & {
    label?: string;
    className?: string;
    tooltip?: React.FC;
  };

const SelectField: React.FC<SelectFieldProps> = (props) => {
  const {
    children,
    label,
    input: { onChange, ...restInput },
    meta: { touched, error },
    className,
    options,
    tooltip,
    ...rest
  } = props;
  const hasError = useMemo(() => touched && error, [error, touched]);

  const classes = classNames(
    'bb-select-field',
    { 'bb-select-field--error': hasError },
    className,
  );

  const handleOnChange = useCallback(
    (values: OptionValue<any>) => {
      onChange(values.value);
    },
    [onChange],
  );

  return (
    <div className={classes}>
      {label && <label>{label}</label>}
      <Select
        {...rest}
        {...restInput}
        multiple={false}
        hasError={hasError}
        searchInputProps={{
          type: 'search',
          autoComplete: 'chrome-off',
        }}
        options={options}
        onChange={handleOnChange}
      />
      {hasError && <small>{error}</small>}
    </div>
  );
};

export default SelectField;
