import { Step } from 'components/FormWrapper/providers/Steps/Steps.context';
import AddressForm from './steps/AddressForm';
import DriverForm from './steps/DriverForm';
import PasswordForm from './steps/PasswordForm';
import PersonalDetailsForm from './steps/PersonalDetailsForm';
import ReferralCodeForm from './steps/ReferralCodeForm';
import SecurityForm from './steps/SecurityForm';
import UserDetailsForm from './steps/UserDetailsForm';

export default [
  {
    component: UserDetailsForm,
    title: 'User Details',
  },
  {
    component: SecurityForm,
    title: 'Security Settings',
  },
  {
    component: PasswordForm,
    title: 'Password setup',
  },
  {
    component: ReferralCodeForm,
    title: 'Have a referral code?',
  },
  {
    component: PersonalDetailsForm,
    title: 'Personal Details',
  },
  {
    component: AddressForm,
    title: 'Address',
  },
  {
    component: DriverForm,
    title: 'Driver License',
  },
] as Step[];
