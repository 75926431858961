import React, { useContext } from 'react';
import classNames from 'classnames';

import { Button } from 'ncoded-component-library';
import MainContext from 'routers/MainRouter/pages/MainPage/providers/Main/Main.context';

import './OnboardingTutorialOption.styles.scss';

type OnboardingTutorialOptionProps = {
  className?: string;
};

const OnboardingTutorialOption: React.FC<OnboardingTutorialOptionProps> = ({
  className,
}) => {
  const { setWelcomeWizardModal } = useContext(MainContext);

  return (
    <Button
      className={classNames('bb-onboarding-tutorial-option', className)}
      styleType="secondary"
      onClick={() => setWelcomeWizardModal(true)}
    >
      Onboarding Tutorial
    </Button>
  );
};

export default OnboardingTutorialOption;
