import React from 'react';

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g>
            <path
              fill="#FFF"
              fillOpacity="0"
              d="M0 0H24V24H0z"
              transform="translate(-80 -208) translate(80 160) translate(0 48)"
            />
            <path
              fill="currentColor"
              fillRule="nonzero"
              d="M5.378 11.737l6.884 6.884c-.634-.073-1.261-.223-1.866-.45-.211-.08-.428-.118-.639-.118-.41 0-.786.143-1.075.431l-1.86 1.86C6.387 20.78 5.81 21 5.24 21c-.571 0-1.147-.22-1.583-.656-.437-.436-.657-1.012-.656-1.583 0-.572.22-1.148.656-1.584l1.807-1.807c.31-.311.462-.715.464-1.156 0-.223-.04-.452-.122-.675-.214-.585-.356-1.19-.427-1.802zM13.162 3c1.508 0 3.018.43 4.312 1.298 2.38 1.596 3.518 4.15 3.514 6.66 0 1.976-.798 3.935-2.291 5.428l-.232.223c-1.31 1.207-2.936 1.885-4.599 2.033l-8.509-8.51C5.552 7.944 6.667 5.82 8.69 4.4c1.329-.933 2.902-1.4 4.473-1.4zm-.583 2.89c-1.2.002-2.177.98-2.179 2.18.002 1.2.98 2.177 2.18 2.179 1.2-.002 2.177-.98 2.179-2.18-.002-1.2-.98-2.177-2.18-2.179z"
              transform="translate(-80 -208) translate(80 160) translate(0 48)"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
