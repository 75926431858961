import httpClient from 'api/httpClient';
import { GroupResponseType } from 'models/Friends';
import PaginatedData from 'models/PaginatedData';
import {
  ChatMessage,
  ChatSessionType,
  GroupChatData,
  MessageType,
} from '../../models/Chats';

const chatPath = (routePath: TemplateStringsArray) => `chats/${routePath}`;

function getChatSessions(params: {
  page: number;
  limit: number;
  searchString?: string;
}) {
  return httpClient.get<PaginatedData<ChatSessionType>>(chatPath`sessions`, {
    params,
  });
}

function getChatMessages(params: { page: number; limit: number }, sid: string) {
  return httpClient.get<PaginatedData<MessageType>>(
    `chats/sessions/${sid}/messages`,
    {
      params,
    },
  );
}

function getChatSessionById(sid: string) {
  return httpClient.get<ChatSessionType>(`chats/sessions/${sid}`);
}

function sendMessage({ sessionId, text, files }: ChatMessage) {
  const formData = new FormData();
  formData.append('text', text);
  formData.append('file', files[0]);
  return httpClient.post(`chats/sessions/${sessionId}/messages`, formData);
}

function shareBet(sessionId: string, betId: string) {
  return httpClient.post(`chats/sessions/${sessionId}/messages/bets`, {
    betId,
    text: '',
  });
}

function createGroupChatSession({ name, userIds }: GroupChatData) {
  return httpClient.post<GroupResponseType>(chatPath`groups`, {
    name,
    userIds,
  });
}

function createChatSession(friendshipId: string) {
  return httpClient.post(`chats/friendships/${friendshipId}`);
}

function uploadGroupImage(file: File, groupId: string) {
  const formData = new FormData();
  formData.append('file', file);
  return httpClient.post(`groups/${groupId}/images`, formData);
}

export default {
  getChatSessions,
  getChatMessages,
  getChatSessionById,
  createGroupChatSession,
  createChatSession,
  sendMessage,
  shareBet,
  uploadGroupImage,
};
